import React, { useState, useEffect } from "react";
import { Button, FormControlLabel, Modal, TextField } from "@mui/material";
import { FormGroup } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { setClubMeetingTimes } from "../../Hooks/clubs";

import ErrorAlert from "../../errorAlert";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery, useMutation } from "react-query";

import { useTranslation, Trans } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function AddEditMeetingTimes({
  handleWindowClose,
  selectedClubData,
  showMeetings = true,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(setClubMeetingTimes);

  const [weekDay, setWeekDay] = useState(-1);
  const [meetingsString, setMeetingsString] = useState([]);
  const [meetingsArray, setMeetingsArray] = useState([]);

  const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  useEffect(() => {
    storeMeetingTimes(selectedClubData.meetings);
  }, []);

  const handleOpen = async () => {
    console.log("HERE handleOpen");
    await storeMeetingTimes(selectedClubData.meetings);
    setOpen(true);
  };

  const storeMeetingTimes = async (meetings) => {
    if (meetings) {
      const theMeetings = [];
      const theMeetingsArray = {};

      let count = 0;
      meetings.map((meeting) => {
        const startTime =
          meeting.startTime.split(":")[0] +
          ":" +
          meeting.startTime.split(":")[1];
        const endTime =
          meeting.endTime.split(":")[0] + ":" + meeting.endTime.split(":")[1];

        if (count == 0) {
          theMeetings.push(
            t("weekdayShort." + meeting.weekday) +
              " " +
              startTime +
              "-" +
              endTime
          );
        } else if (count == 1) {
          theMeetings.push("...");
        }
        count++;

        theMeetingsArray[meeting.weekday] = {
          startTime: startTime,
          endTime: endTime,
        };
      });

      setMeetingsString(theMeetings);
      setMeetingsArray(theMeetingsArray);
    }
  };

  const copyWeeksdays = async (meetings) => {
    const newWeekDayArray = [];
    weekdays.map((day) => {
      if (meetings[day]) newWeekDayArray[day] = meetings[day];
    });

    return newWeekDayArray;
  };

  const selectWeekday = async (e) => {
    const weekday = e.target.id;
    const checked = e.target.checked;
    console.log("weekday: " + weekday + " " + checked);

    const newMeetingsArray = await copyWeeksdays(meetingsArray);

    // if unchecked, remove weekday from the meetingsArray
    if (!checked) {
      if (newMeetingsArray[weekday]) {
        delete newMeetingsArray[weekday];
      }
    } else {
      if (!newMeetingsArray[weekday]) {
        newMeetingsArray[weekday] = {
          startTime: "",
          endTime: "",
        };
      }
    }

    document.getElementById(e.target.id + "_startTimeText").disabled = !checked;

    document.getElementById(e.target.id + "_endTimeText").disabled = !checked;

    setMeetingsArray(newMeetingsArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const clubMeetingtimes = [];
    weekdays.map((day) => {
      const checkbox = document.getElementById(day);
      if (checkbox && checkbox.checked) {
        const startTimeText = document.getElementById(day + "_startTimeText");
        const endTimeText = document.getElementById(day + "_endTimeText");

        if (startTimeText && endTimeText) {
          const startTimeString = startTimeText.value;
          const endTimeString = endTimeText.value;

          console.log(
            "Times to be sent: " +
              day +
              " " +
              startTimeString +
              "-" +
              endTimeString
          );

          clubMeetingtimes.push({
            weekday: day,
            startTime: startTimeString, // as string
            endTime: endTimeString,
          });
        }
      }
    });

    console.log(clubMeetingtimes);

    mutate(
      {
        id: selectedClubData.id,
        clubMeetingTimes: clubMeetingtimes,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleWindowClose(clubMeetingtimes);
          storeMeetingTimes(clubMeetingtimes);
          handleClose();
        },
      }
    );
  };

  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={0} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {showMeetings && <Box mr={1}>{meetingsString}</Box>}
        <Button
          variant="outlined"
          color="secondary"
          // startIcon={<AddIcon />}
          sx={{ height: 25, m: 0 }}
          onClick={handleOpen}
        >
          ...
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {selectedClubData && (
            <div>
              <h1>{selectedClubData.name}</h1>
              <p>{t("admin.clubs.meetingTimes_info")}</p>
              <FormGroup
                id="weekdaySelector"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        onChange={(e) => selectWeekday(e)}
                        display="flex"
                        id="mon"
                        checked={meetingsArray["mon"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.monday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="mon_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["mon"] ? meetingsArray["mon"].startTime : ""
                    }
                    disabled={meetingsArray["mon"] ? false : true}
                  />
                  <TextField
                    id="mon_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["mon"] ? meetingsArray["mon"].endTime : ""
                    }
                    disabled={meetingsArray["mon"] ? false : true}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        display="flex"
                        onChange={(e) => selectWeekday(e)}
                        id="tue"
                        checked={meetingsArray["tue"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.tuesday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="tue_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["tue"] ? meetingsArray["tue"].startTime : ""
                    }
                    disabled={meetingsArray["tue"] ? false : true}
                  />
                  <TextField
                    id="tue_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["tue"] ? meetingsArray["tue"].endTime : ""
                    }
                    disabled={meetingsArray["tue"] ? false : true}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        display="flex"
                        onChange={(e) => selectWeekday(e)}
                        id="wed"
                        checked={meetingsArray["wed"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.wednesday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="wed_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["wed"] ? meetingsArray["wed"].startTime : ""
                    }
                    disabled={meetingsArray["wed"] ? false : true}
                  />
                  <TextField
                    id="wed_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["wed"] ? meetingsArray["wed"].endTime : ""
                    }
                    disabled={meetingsArray["wed"] ? false : true}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        display="flex"
                        onChange={(e) => selectWeekday(e)}
                        id="thu"
                        checked={meetingsArray["thu"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.thursday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="thu_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["thu"] ? meetingsArray["thu"].startTime : ""
                    }
                    disabled={meetingsArray["thu"] ? false : true}
                  />
                  <TextField
                    id="thu_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["thu"] ? meetingsArray["thu"].endTime : ""
                    }
                    disabled={meetingsArray["thu"] ? false : true}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        display="flex"
                        onChange={(e) => selectWeekday(e)}
                        id="fri"
                        checked={meetingsArray["fri"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.friday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="fri_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["fri"] ? meetingsArray["fri"].startTime : ""
                    }
                    disabled={meetingsArray["fri"] ? false : true}
                  />
                  <TextField
                    id="fri_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["fri"] ? meetingsArray["fri"].endTime : ""
                    }
                    disabled={meetingsArray["fri"] ? false : true}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        display="flex"
                        onChange={(e) => selectWeekday(e)}
                        id="sat"
                        checked={meetingsArray["sat"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.saturday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="sat_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["sat"] ? meetingsArray["sat"].startTime : ""
                    }
                    disabled={meetingsArray["sat"] ? false : true}
                  />
                  <TextField
                    id="sat_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["sat"] ? meetingsArray["sat"].endTime : ""
                    }
                    disabled={meetingsArray["sat"] ? false : true}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    display="flex"
                    control={
                      <Checkbox
                        display="flex"
                        onChange={(e) => selectWeekday(e)}
                        id="sun"
                        checked={meetingsArray["sun"] ? true : false}
                      ></Checkbox>
                    }
                    label={t("weekday.sunday")}
                    sx={{ m: 1, width: "30%" }}
                  />
                  <TextField
                    id="sun_startTimeText"
                    label={t("admin.clubs.meetingTimes_startTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateStartTime(e)}
                    defaultValue={
                      meetingsArray["sun"] ? meetingsArray["sun"].startTime : ""
                    }
                    disabled={meetingsArray["sun"] ? false : true}
                  />
                  <TextField
                    id="sun_endTimeText"
                    label={t("admin.clubs.meetingTimes_endTime")}
                    sx={{ m: 1, width: "35%" }}
                    // onChange={(e) => updateEndTime(e)}
                    defaultValue={
                      meetingsArray["sun"] ? meetingsArray["sun"].endTime : ""
                    }
                    disabled={meetingsArray["sun"] ? false : true}
                  />
                </div>
              </FormGroup>

              <Button
                variant="contained"
                color="primary"
                sx={{ left: "35%", marginTop: "1rem" }}
                type="submit"
              >
                {t("admin.clubs.meetingTimes_SubmitButtonText")}
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
