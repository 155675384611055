import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useQuery } from "react-query";

import { getGedus } from "../Hooks/gedus";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const GeduSelect = ({ defaultGedu, saveSelectedGedu, label }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedGedu, setSelectedGedu] = useState(null);
  const gedus = useQuery({
    queryKey: ["gedus"],
    queryFn: getGedus,
  });

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          if (defaultGedu) {
            setSelectedGedu({
              id: defaultGedu.id,
              firstName: defaultGedu.firstName,
              lastName: defaultGedu.lastName,
            });
            // setInputValue(`${defaultGedu.firstName} ${defaultGedu.lastName}`)
          } else {
            setSelectedGedu(null);
            setInputValue("");
          }
          setIsOpen(true);
        }}
      >
        {label ?? t("clubView.chooseGedu")}
      </Button>
      <Dialog open={isOpen} disableScrollLock>
        <DialogTitle>{t("clubView.chooseGedu")}</DialogTitle>
        <DialogContent>
          <Autocomplete
            value={selectedGedu}
            disableClearable
            onChange={(_event, newValue) => setSelectedGedu(newValue)}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={gedus.data ?? []}
            sx={{ width: 300 }}
            isOptionEqualToValue={(a, b) => a?.id === b?.id}
            getOptionLabel={(gedu) =>
              gedu !== null
                ? `${gedu.firstName} ${gedu.lastName}${
                    gedu.discordUserName
                      ? " [" + gedu.discordUserName + "]"
                      : ""
                  }`
                : ""
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>
            {t("general.dialog.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              saveSelectedGedu(selectedGedu);
              setIsOpen(false);
            }}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
