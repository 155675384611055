import React, { useState, useEffect } from "react";
import { Button, Label, Modal, TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";

import ErrorAlert from "../../errorAlert";
// import { useQuery, useMutation } from "react-query";
// import { getGamers } from "../../Hooks/users";

import { useTranslation, Trans } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function GamerList({ selectedUser }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  // const {
  //   data: dataGamers,
  //   error: errorGamers,
  //   isLoading: isLoadingGamers,
  //   refetch: refetchGamers,
  // } = useQuery(["gamers", selectedUser], () => getGamers(selectedUser.id), {
  //   enabled: false,
  // });

  useEffect(() => {}, []);

  const handleOpen = async () => {
    // refetchGamers(selectedUser.id);
    // if (dataGamers) {
    //   console.log(dataGamers);
    // }
    setOpen(true);
  };
  const copyInvitationURL = async () => {
    const element = document.getElementById("invitationURL");
    element.select();
    document.execCommand("copy");
  };

  return (
    <div>
      <Box
        m={0} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {selectedUser && selectedUser.gamers.length > 0 && (
          <Button
            variant="outlined"
            color="secondary"
            // startIcon={<AddIcon />}
            sx={{ height: 25, m: 0 }}
            onClick={handleOpen}
          >
            {t("admin.users.gamersButton")}
          </Button>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedUser && (
            <div>
              <h1>
                {selectedUser.firstName} {selectedUser.lastName}
              </h1>
              <p>{t("admin.users.gamers_info")}</p>
              {selectedUser.gamers.map((gamer, index) => (
                <div key={"gamer" + index}>
                  {gamer.firstName} {gamer.lastName}
                  <TextField
                    id="invitationURL"
                    label={t("admin.users.gamers_invitationUrl")}
                    sx={{ m: 1, width: "80%" }}
                    defaultValue={gamer.invitationUrl}
                  />
                  <ContentCopyIcon
                    onClick={() => {
                      copyInvitationURL();
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
