import React, { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { createClubInstructions } from "../../Hooks/clubs";

import ErrorAlert from "../../errorAlert";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery, useMutation } from "react-query";
import { getCountries } from "../../Hooks/country";
import { getMunicipalities } from "../../Hooks/municipality";
import { getSchoolsForMuni } from "../../Hooks/school";

import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function AddClubInstruction({
  handleNewClubInstructions,
  DeleteButton,
  clubInstructionTypeCodes,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [instructions, setInstructions] = React.useState("");
  const [introduction, setIntroduction] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(createClubInstructions);
  const {
    data: countries,
    error: errorCountries,
    isLoading,
  } = useQuery(["countries"], getCountries);
  const {
    data: municipalitiess,
    error: errorMunicipalities,
    isLoading: isLoadingmuni,
  } = useQuery(["munis"], () => getMunicipalities(), {
    enabled: true,
  });
  const {
    data: schoolss,
    error: errorSchools,
    isLoading: isLoadingSchools,
  } = useQuery(["schools"], () => getSchoolsForMuni(), {
    enabled: true,
  });
  const [municipalities, setMunicipalities] = useState([]);
  const [schools, setSchools] = useState([]);
  const [statusSelect, setStatusSelect] = useState(-1);
  const [clubInstructionTypeSelect, setClubInstructionTypeSelect] =
    useState(-1);
  const [weekDay, setWeekDay] = useState(-1);
  const [country, setCountry] = useState(-1);
  const [municipality, setMunicipality] = useState(-1);
  const [school, setSchool] = useState(-1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate(
      {
        name: document.getElementById("club-instruction-name").value,
        type: clubInstructionTypeSelect,
        introduction: document.getElementById("club-introduction").value,
        instructions: document.getElementById("club-instructions").value,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleNewClubInstructions(e.data.clubInstructions);
          handleClose();
        },
      }
    );
  };

  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ height: 40, mr: 1 }}
          onClick={handleOpen}
        >
          Add Club Instruction
        </Button>
        {DeleteButton}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {clubInstructionTypeCodes.length > 0 && (
            <div>
              <TextField
                required
                id="club-instruction-name"
                label={t("admin.clubs.addClub_Name")}
                sx={{ m: 1, width: "100%" }}
              />

              <TextField
                id="club-instruction-type"
                select
                label={t("admin.clubs.addClubInstructions_Type")}
                value={clubInstructionTypeSelect}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => setClubInstructionTypeSelect(e.target.value)}
                defaultValue={-1}
              >
                <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClubInstructions_TypeFirstEntry")}{" "}
                </MenuItem>
                {clubInstructionTypeCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {code.label}{" "}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="club-introduction"
                label={t("admin.clubs.addClubInstructions_Introduction")}
                sx={{ m: 1, width: "100%" }}
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
              />

              <TextField
                id="club-instructions"
                label={t("admin.clubs.addClubInstructions_Instructions")}
                sx={{ m: 1, width: "100%" }}
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              />

              <Button
                variant="contained"
                color="primary"
                sx={{ left: "35%", marginTop: "1rem" }}
                type="submit"
              >
                Save Club Instructions{" "}
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
