import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import { getUsers, deleteUser, updateUser } from "../../Hooks/users";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import AddEditUser from "./addEditUser";
import SetUserRoles from "./setUserRoles";
import GamerList from "./gamerList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";

import { useTranslation } from "react-i18next";

export default function UserList() {
  const { t } = useTranslation();

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowData, setRowData] = React.useState([]);

  const columns = [
    //{ field: 'id', headerName: 'ID', flex: 1, editable: false },
    {
      field: "firstName",
      headerName: t("admin.users.tableHeader_firstname"),
      flex: 1,
      editable: true,
    },
    {
      field: "lastName",
      headerName: t("admin.users.tableHeader_lastname"),
      flex: 1,
      editable: true,
    },
    {
      field: "username",
      headerName: t("admin.users.tableHeader_username"),
      flex: 1,
      editable: true,
    },
    {
      field: "email",
      headerName: t("admin.users.tableHeader_email"),
      flex: 1,
      editable: true,
    },
    {
      field: "roles",
      headerName: t("admin.users.tableHeader_roles"),
      minWidth: 250,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <SetUserRoles
            handleWindowClose={handleSetUserRoleClose}
            selectedUserData={params.row}
          />
        );
      },
      valueGetter: (params) => params.row.roles,
    },
    {
      field: "gamers",
      headerName: t("admin.users.tableHeader_gamers"),
      sortable: false,
      minWidth: 120,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <GamerList selectedUser={params.row} />;
      },
    },

    {
      field: "actions",
      headerName: "",
      sortable: false,
      minWidth: 100,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <AddEditUser
            handleNewUser={handleNewUser}
            handleUserUpdate={handleUserUpdate}
            users={rows}
            selectedUser={params.row}
          />
        );
      },
    },
  ];
  const { data, error, isLoading } = useQuery(["users"], getUsers);
  const { mutate } = useMutation(deleteUser);
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  useEffect(() => {
    //getUsers();
    if (data) setRows(data);
    console.log(data);
  }, [data]);

  const handleNewUser = (domain) => {
    setRows((rows) => [...rows, domain]);
  };

  const handleUserUpdate = (domain) => {
    setRows((rows) => [...rows]);
  };

  const deleteRows = () => {
    selectionModel.forEach((user) => {
      mutate(user, {
        onSuccess: (e) => {
          console.log(e);
        },
      });
    });
    window.location.reload();
  };

  const handleSetUserRoleClose = () => {
    // do what needs to be done
  };

  return (
    <ContainerBox>
      <h1>{t("admin.users.title")}</h1>
      <div style={{ width: "100%" }}>
        {error && <ErrorAlert txt={error} />}
        {(isLoading || isDataLoading) && <CircularProgress />}
        {
          // rows.length > 0 &&
          <>
            <AddEditUser
              handleNewUser={handleNewUser}
              handleUserUpdate={handleUserUpdate}
              DeleteButton={
                <Button
                  variant="contained"
                  onClick={deleteRows}
                  color="secondary"
                  key="RemoveSelectedUsersButton"
                  startIcon={<DeleteForeverIcon />}
                  disabled={disableBtn}
                  sx={{ height: 40 }}
                >
                  {t("admin.users.deleteUserButton")}
                </Button>
              }
              users={rows}
            />
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                if (newSelectionModel)
                  setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}

              //onCellEditCommit={processRowUpdate}
            />
          </>
        }
      </div>
    </ContainerBox>
  );
}
