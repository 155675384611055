import React from "react";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
} from "@mui/material";
import Button from "@mui/material/Button";
import ApprovalIcon from "@mui/icons-material/Approval";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import OpenIcon from "@mui/icons-material/OpenInNew";

import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../errorAlert";

import {
  getGeduInvoicingLinesByPeriod,
  setPeriodStatusFoo,
} from "../Hooks/report";

import { prepareClubName, formatDate, prepareTime } from "../Util/clubHelper";
import { isAdmin, isGedu } from "../Util/authHelper";
import InvoicingStatus from "../Util/InvoicingStatus";

import { useTranslation } from "react-i18next";
import { set } from "date-fns";

const InvoicingLines = (params) => {
  const { t } = useTranslation();

  const [isSavingStatus, setIsSavingStatus] = useState(false);
  const [savingStatusError, setSavingStatusError] = useState("");
  const [invoicingLines, setInvoicingLines] = useState([]);
  const [totalInvoicing, setTotalInvoicing] = useState({});
  const [summaryLines, setSummaryLines] = useState({});
  const [period, setPeriod] = useState({});
  const [periodStatus, setPeriodStatus] = useState("");
  const [periodId, setPeriodId] = useState(
    params?.period?.id ? params?.period?.id : ""
  );
  const [periodHasPriceErros, setPeriodHasPriceErrors] = useState(false);

  const [geduInfo, setGeduInfo] = useState(
    params?.geduInfo ? params.geduInfo : {}
  );

  const [appUserRoles, setAppUserRoles] = useState(undefined);

  const {
    data: invoicingLinesData,
    error: invoicingLinesError,
    isLoading: invoicingLinesLoading,
    refetch,
  } = useQuery(
    ["invoicingLines", periodId],
    () => getGeduInvoicingLinesByPeriod(periodId),
    {
      enabled: !!periodId, // Only enable this query when periodId is truthy
    }
  );

  const { mutate: setTheStatus } = useMutation(setPeriodStatusFoo);

  useEffect(() => {
    if (params.appUserRoles) {
      setAppUserRoles(params.appUserRoles);
    }

    //console.log(params.geduInfo);
  }, [params]);

  useEffect(() => {
    if (invoicingLinesData) {
      prepareInvoicingLines();
    }
  }, [invoicingLinesData]);

  const prepareInvoicingLines = () => {
    //    console.log("prepareInvoicingLines");
    //   console.log(invoicingLinesData);

    // console.log(">>>>>>>>>>>>>>>invoicingLinesData.period");
    // console.log(invoicingLinesData.period);

    const lines = [];
    const totalAmount = {};

    const summaryLines = {}; // key is invoiced club type, value is the total amount for that type

    invoicingLinesData.invoicingLines.map((line) => {
      const clubName = prepareClubName(line.club, t);

      // create an array of meetings weekdays
      const clubWeekdays = [];
      const meetingsData = [];
      if (line.club.meetings) {
        line.club.meetings.map((meeting, i) => {
          const meetingString =
            t("weekdayShort." + meeting.weekday) +
            " " +
            prepareTime(meeting.startTime) +
            "-" +
            prepareTime(meeting.endTime);
          meetingsData.push(meetingString);
          clubWeekdays.push(meeting.weekday);
        });
      }

      // create a single meetings string from the array
      const meetings = meetingsData.join(", ");

      const date = new Date(line.timestamp);

      const timestamp = formatDate(date, "DD.MM.YYYY hh:mm");

      const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      const weekday = weekdays[date.getDay()];
      const localizedWeekday = t("weekdayShort." + weekday);

      const invoicingWeekday = weekday;

      let hasPriceError = false;
      let amount = undefined;
      if (line.amount !== null && !isNaN(line.amount)) {
        // It's already a number, no need to parse
        amount = parseFloat(line.amount).toFixed(2);
      } else {
        hasPriceError = true;
        setPeriodHasPriceErrors(true); // set only if there is an error
      }

      let currency = line.currency?.name;

      if (!hasPriceError) {
        const previousAmount = totalAmount[currency];
        if (previousAmount) {
          // parse to float
          const previousAmountFloat = parseFloat(previousAmount);
          totalAmount[currency] = previousAmountFloat + parseFloat(amount);
        } else {
          totalAmount[currency] = parseFloat(amount);
        }
      }

      // add to summary
      if (!hasPriceError) {
        const previousAmount = summaryLines[line.club.type.name];
        if (previousAmount) {
          // parse to float
          const previousAmountFloat = parseFloat(previousAmount);
          summaryLines[line.club.type.name] =
            previousAmountFloat + parseFloat(amount);
        } else {
          summaryLines[line.club.type.name] = parseFloat(amount);
        }
      }

      let additionalInfo = "";
      // get the invoicing type
      additionalInfo += t("code." + line.type.name);
      // add subsctitute if it's a substitute
      additionalInfo += line.substitute
        ? " / " + t("invoicingLines.substitute")
        : "";

      // ensure the invoicing weekday is in the club weekdays
      let missMatchInWeekdays = false;
      if (!clubWeekdays.includes(invoicingWeekday)) {
        missMatchInWeekdays = true;
      }

      lines.push({
        id: line.id,
        date: date, // for sorting
        timestamp: timestamp,
        weekday: localizedWeekday,
        amount: amount,
        currency: currency,
        additionalInfo: additionalInfo,
        club: {
          id: line.club.id,
          name: clubName,
          type: line.club.type.name,
          meetings: meetings,
        },
        status: line.status.name,
        type: line.type.name,
        lesson: line.lesson,
        hasPriceError: hasPriceError,
        missMatchInWeekdays: missMatchInWeekdays,
      });
    });

    // order lines by date ASC
    lines.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    // period
    if (invoicingLinesData.period) {
      const startDate = new Date(invoicingLinesData.period.startDate);
      const endDate = new Date(invoicingLinesData.period.endDate);

      const start = formatDate(startDate, "DD.MM.YYYY");
      const end = formatDate(endDate, "DD.MM.YYYY");
      const text = `${start} - ${end}`;
      const period = {
        id: invoicingLinesData.period.id,
        startDate: start,
        endDate: end,
        status: invoicingLinesData.period.status.name,
        text: text,
        geduId: invoicingLinesData.gedu.id,
      };

      setPeriodStatus(invoicingLinesData.period.status.name);

      params.setHeaderData?.(period.id, {
        status: period.status,
        totalAmount: totalAmount,
      });

      setPeriod(period);
    }
    // add total invoicing
    setTotalInvoicing(totalAmount);

    // set summary lines
    setSummaryLines(summaryLines);

    //    console.log(lines);

    setInvoicingLines(lines);
  };

  const isGeduApprovalEnabled = () => {
    if (
      periodStatus === "INVOICING_STATUS_UNHANDLED" ||
      periodStatus === "INVOICING_STATUS_REJECTED"
    )
      return true;
    return false;
  };

  const isSOGApprovalEnabled = () => {
    if (periodStatus === "INVOICING_STATUS_GEDU_APPROVED") return true;
    return false;
  };

  const isInvoicedEnabled = () => {
    if (periodStatus === "INVOICING_STATUS_APPROVED") {
      // disable Invoiced button if the billing method is TRUSTER_COLLECTIVE
      //and the user is not admin
      if (isTrusterGedu()) {
        return false;
      }

      return true;
    }
    return false;
  };

  const isTrusterGedu = () => {
    return (
      geduInfo?.billingMethodCode?.name ===
        "GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE" && !isAdmin(appUserRoles)
    );
  };

  const handleGeduApproval = async () => {
    //    console.log("handleGeduApproval");
    setStatusToPeriod("INVOICING_STATUS_GEDU_APPROVED");
  };

  const handleSOGApproval = () => {
    //    console.log("handleSOGApproval");
    setStatusToPeriod("INVOICING_STATUS_APPROVED");
  };

  const handleSOGRejection = () => {
    //    console.log("handleSOGRejection");
    setStatusToPeriod("INVOICING_STATUS_REJECTED", "Rejected in SOGGA");
  };

  const handleInvoiced = () => {
    //    console.log("handleInvoiced");
    setStatusToPeriod("INVOICING_STATUS_INVOICED");
  };

  const setStatusToPeriod = async (status, notes) => {
    const body = {
      periodId: period.id,
      geduId: period.geduId,
      status: status,
      notes: notes,
    };

    try {
      setIsSavingStatus(true);
      params.isLoading?.(true);
      const response = await setPeriodStatusFoo(body);
      //console.log(response);
      if (response.success) {
        refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSavingStatus(false);
      params.isLoading?.(false);
    }

    // setTheStatus(body, {
    //   onSuccess: (e) => {
    //     console.log(e);
    //     console.log("Do refetch");
    //     //refetch();
    //   },
    // });
  };

  function getSummaryHeader() {
    return isAdmin(appUserRoles)
      ? t("invoicingLines.summarySubHeaderText") +
          " " +
          t("geduInvoicing.periodId") +
          ": " +
          period.id
      : t("invoicingLines.summarySubHeaderText");
  }

  return (
    <div>
      {invoicingLinesLoading && <CircularProgress />}
      {invoicingLinesError && <ErrorAlert txt={invoicingLinesError} />}
      {!invoicingLinesLoading && period && (
        <>
          <List key={"invoicingLines" + period?.id}>
            {/* <List subheader={<ListSubheader>{t("Header Text")}</ListSubheader>}> */}
            {invoicingLines.map((line) => (
              <React.Fragment key={line.id}>
                <ListItem key={"line" + line.id}>
                  {line.missMatchInWeekdays && (
                    <ListItemText
                      primary={`${line.weekday} ${line.timestamp} - ${t(
                        "invoicingLines.missmatchInWeekdays"
                      )}`}
                      secondary={`${line.additionalInfo}`}
                      sx={{ color: "#F55B9A" }} // Replace 'your_custom_color' with your desired color
                    />
                  )}
                  {!line.missMatchInWeekdays && (
                    <ListItemText
                      primary={`${line.weekday} ${line.timestamp}`}
                      secondary={`${line.additionalInfo}`}
                    />
                  )}
                  {!line.hasPriceError && (
                    <ListItemText
                      primary={`${line.amount} ${t("code." + line.currency)}`}
                    />
                  )}
                  {line.hasPriceError && (
                    <>
                      <ErrorIcon sx={{ mr: 1, color: "#F55B9A" }} />
                      <ListItemText
                        primary={`${t("invoicingLines.priceError")}`}
                        sx={{ color: "#F55B9A" }}
                      />
                    </>
                  )}
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={`${t("muniReport.openClub")}: ${line.club.name}`}
                    placement="top"
                  >
                    <Button
                      variant=""
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(`/club/?id=${line.club.id}`, "_blank");
                      }}
                      sx={{ ml: 0 }} // Adjust margin as needed
                    >
                      <OpenIcon />
                    </Button>
                  </Tooltip>
                  {line.missMatchInWeekdays && (
                    <ListItemText
                      secondaryTypographyProps={{ color: "#F55B9A" }}
                      primary={`${line.club.name}`}
                      secondary={`${t("code." + line.club.type)} - ${
                        line.club.meetings
                      }`}
                    />
                  )}
                  {!line.missMatchInWeekdays && (
                    <ListItemText
                      primary={`${line.club.name}`}
                      secondary={`${t("code." + line.club.type)} - ${
                        line.club.meetings
                      }`}
                    />
                  )}
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {!periodHasPriceErros && (
            <>
              <List
                key={"invoicingSummary" + period.id}
                subheader={
                  isAdmin(appUserRoles)
                    ? t("invoicingLines.summarySubHeaderText") +
                      " " +
                      period.text +
                      " [" +
                      period.id +
                      "]"
                    : t("invoicingLines.summarySubHeaderText") +
                      " " +
                      period.text
                }
              >
                {savingStatusError && <ErrorAlert txt={savingStatusError} />}
                {period && (
                  <>
                    {Object.keys(summaryLines).map((lineType) => (
                      <ListItem key={"summaryLines" + lineType}>
                        <ListItemText
                          key={"typeFor" + lineType}
                          primary={`${t("invoicingLines." + lineType)}:`}
                        />
                        <ListItemText
                          key={"totalFor" + lineType}
                          primary={`${parseFloat(
                            summaryLines[lineType]
                          ).toFixed(2)} ${t("code.CURRENCY_EUR")}`} // TODO - use the currency from the line
                        />
                      </ListItem>
                    ))}
                    <ListItem key={"currencyList" + period.id}>
                      <ListItemText
                        key={"periodDate" + period.id}
                        primary={`${t("invoicingLines.totalInvoicing")}:`}
                      />
                      {Object.keys(totalInvoicing).map((currency) => (
                        <ListItemText
                          key={"totalFor" + currency}
                          primary={`${parseFloat(
                            totalInvoicing[currency]
                          ).toFixed(2)} ${t("code." + currency)}`}
                        />
                      ))}
                    </ListItem>
                    <ListItem key={"totalNumberOfLessons" + period.id}>
                      <ListItemText
                        key={"totalNumberOfLessonsText" + period.id}
                        primary={`${t(
                          "invoicingLines.totalNumberOfLessons"
                        )}: ${invoicingLines?.length}`}
                      />
                    </ListItem>
                  </>
                )}
              </List>
              <InvoicingStatus
                status={periodStatus}
                isLoading={isSavingStatus}
              />

              {appUserRoles && isGedu(appUserRoles) && (
                <Button
                  size="small"
                  sx={{ minWidth: "100px", width: "145px", m: 1 }}
                  disabled={!isGeduApprovalEnabled()}
                  variant={isGeduApprovalEnabled() ? "contained" : ""}
                  onClick={() => {
                    handleGeduApproval();
                  }}
                >
                  <ApprovalIcon sx={{ mr: 1 }} />{" "}
                  {t("invoicingLines.geduApproval")}
                </Button>
              )}

              {appUserRoles && isAdmin(appUserRoles) && (
                <>
                  <Button
                    size="small"
                    sx={{
                      minWidth: "30px",
                      width: "145px",
                      mr: 2,
                      // backgroundColor: "#6AC66B",
                      // "&:disabled": {
                      //   backgroundColor: "lightgrey", // Define your custom disabled background color
                      // },
                    }}
                    // style={{
                    //   color: "#FFFFFF",
                    // }} // text color
                    disabled={!isSOGApprovalEnabled()}
                    variant={isSOGApprovalEnabled() ? "contained" : "outlined"}
                    onClick={() => {
                      handleSOGApproval();
                    }}
                  >
                    <ThumbUpIcon sx={{ mr: 1 }} />{" "}
                    {t("invoicingLines.sogApproval")}
                  </Button>
                  <Button
                    size="small"
                    sx={{ minWidth: "30px", width: "145px", mr: 2 }}
                    disabled={!isSOGApprovalEnabled()}
                    variant="outlined"
                    onClick={() => {
                      handleSOGRejection();
                    }}
                  >
                    <ThumbDownIcon sx={{ mr: 1 }} />{" "}
                    {t("invoicingLines.sogReject")}
                  </Button>
                </>
              )}
              <Button
                size="small"
                sx={{ minWidth: "30px", width: "145px", mr: 2 }}
                disabled={!isInvoicedEnabled()}
                variant={isInvoicedEnabled() ? "contained" : "outlined"}
                onClick={() => {
                  handleInvoiced();
                }}
              >
                <ReceiptIcon sx={{ mr: 1 }} /> {t("invoicingLines.invoiced")}
              </Button>
              {isTrusterGedu() && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {t("invoicingLines.trusterGeduInvoicingInfo")}
                </Typography>
              )}
            </>
          )}

          {periodHasPriceErros &&
            isGedu(appUserRoles) &&
            !isAdmin(appUserRoles) && (
              <>
                <ErrorIcon sx={{ mr: 1, color: "#F55B9A" }} />
                <ListItemText
                  primary={`${t("invoicingLines.errorSummarySubHeaderText")}`}
                  secondary={`${t(
                    "invoicingLines.errorSummaryDesciptionGedu"
                  )}`}
                  sx={{ color: "#F55B9A" }}
                />
              </>
            )}
          {periodHasPriceErros && isAdmin(appUserRoles) && (
            <>
              <ErrorIcon sx={{ mr: 1, color: "#F55B9A" }} />
              <ListItemText
                primary={`${t("invoicingLines.errorSummarySubHeaderText")}`}
                secondary={`${t("invoicingLines.errorSummaryDesciptionAdmin")}`}
                sx={{ color: "#F55B9A" }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InvoicingLines;
