import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { formatDate } from "../Util/clubHelper";

import { useTranslation } from "react-i18next";

export default function InvoicingStatus(params) {
  const { t } = useTranslation();
  const [invoicingStatus, setInvoicingStatus] = useState(undefined);
  const [minWidth, setMinWidth] = useState("200px");
  const [minHeight, setMinHeight] = useState("3.5em");
  const [userName, setUserName] = useState("");
  const [statusChangeDate, setStatusChangeDate] = useState(""); // Date of status change as string
  /*
"INVOICING_STATUS_PAID"
"INVOICING_STATUS_INVOICED"
"INVOICING_STATUS_APPROVED"
"INVOICING_STATUS_REJECTED"
"INVOICING_STATUS_GEDU_APPROVED"
"INVOICING_STATUS_UNHANDLED"

"INVOICING_STATUS_MUNI_APPROVED"

*/

  const statusMappings = {
    // CLUB_STATUS
    INVOICING_STATUS_PAID: {
      color: "white",
      backgroundColor: "#1AB061", // deeper green
    },
    INVOICING_STATUS_INVOICED: {
      color: "white",
      backgroundColor: "#6AC66B", // lighter green
    },
    INVOICING_STATUS_APPROVED: {
      color: "white",
      backgroundColor: "#4DB3F5", // blue-ish
    },
    INVOICING_STATUS_REJECTED: {
      color: "white",
      backgroundColor: "#F55B9A", // red-ish
    },
    INVOICING_STATUS_GEDU_APPROVED: {
      color: "white",
      backgroundColor: "#FD700D", // yellow-ish
    },
    INVOICING_STATUS_UNHANDLED: {
      color: "white",
      backgroundColor: "#9E9E9E", // grey-ish
    },
    UNKNOWN_CODE: {
      color: "white",
      backgroundColor: "#121212", // black
    },
  };

  useEffect(() => {
    //console.log("useEffect on InvoicingStatus " + params.status);
    prepareData(params.status);

    if (params.minWidth) {
      setMinWidth(params.minWidth);
    }
    if (params.minHeight) {
      setMinHeight(params.minHeight);
    }

    if (params.statusChangeData) {
      prepareStatusChangeData(params.statusChangeData);
    }
    // eslint-disable-next-line
  }, [params]);

  const prepareStatusChangeData = async (statusChangeData) => {
    // console.log("prepareStatusChangeData");
    // console.log(statusChangeData);
    if (statusChangeData) {
      if (statusChangeData.user && statusChangeData.timestamp) {
        const date = new Date(statusChangeData.timestamp);
        setUserName(statusChangeData.user);
        setStatusChangeDate(formatDate(date, "DD.MM.YYYY hh:mm:ss"));
      }
    }
  };

  const prepareData = async (status) => {
    if (status in statusMappings) setInvoicingStatus(status);
    else setInvoicingStatus("UNKNOWN_CODE");
  };

  return (
    <div
      style={{
        display: "inline-flex",
        // verticalAlign: "bottom",
        // marginTop: "0.8em",
        marginRight: "1em",
      }}
    >
      {invoicingStatus && (
        <div style={{ minWidth: minWidth }}>
          <Paper
            sx={{
              minWidth: { minWidth },
              verticalAlign: "middle",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 14,
                minHeight: { minHeight },
                paddingTop: "1em",
              }}
              color={statusMappings[invoicingStatus].color}
              backgroundColor={statusMappings[invoicingStatus].backgroundColor}
              gutterBottom
            >
              {params.isLoading && <CircularProgress sx={{ color: "white" }} />}
              {!params.isLoading && t("code." + invoicingStatus)}
              {userName && (
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 10,
                  }}
                  color={statusMappings[invoicingStatus].color}
                  backgroundColor={
                    statusMappings[invoicingStatus].backgroundColor
                  }
                  paddingBottom={2}
                  // gutterBottom
                >
                  {userName && statusChangeDate + " / " + userName}
                </Typography>
              )}
            </Typography>
          </Paper>
        </div>
      )}
    </div>
  );
}
