import { Fragment } from "react";

import { useQuery } from "react-query";

import {
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";

import OpenIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";

import ContainerBox from "../Container";

import { getGamerDetails } from "../Hooks/users";

import { useTranslation } from "react-i18next";
import ErrorAlert from "../errorAlert";

import { getStatusIcon, getStatusTypography } from "./common";

export default function GamerDetails() {
  const { t } = useTranslation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const gamerId = urlParams.get("id");

  const {
    data: gamerData,
    error,
    isLoading,
  } = useQuery(["gamerId", gamerId], () => getGamerDetails(gamerId));

  return (
    <ContainerBox>
      <Typography variant="h4">{t("gamerDetails.title")}</Typography>
      {error && <ErrorAlert txt={error} />}
      {isLoading && <CircularProgress />}
      {gamerData?.id && (
        <>
          <Card key={gamerId} sx={{ marginTop: 2 }}>
            <CardContent>
              <Stack direction="row" alignItems="center" gap={2}>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  {gamerData.discordAvatar && (
                    <CardMedia
                      component="img"
                      className="user-icon"
                      image={gamerData.discordAvatar}
                      alt="Avatar"
                    />
                  )}
                  {!gamerData.discordAvatar && (
                    <NoAvatarIcon className="user-icon" />
                  )}
                  {/* <GeneralTextFieldEditor
                    title={t("gamerDetails.setDiscordId")}
                    data={{
                      discordUserId: {
                        value: gamerData.discordUserId
                          ? gamerData.discordUserId
                          : "",
                        label: t("gamerDetails.discordIdLabel"),
                      },
                    }}
                    buttonText={t("gamerDetails.discordIdButtonText")}
                    onSave={(data) => {
                      console.log("TODO");
                      // updateUserData(data);
                    }}
                  /> */}
                </Stack>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap={1}
                >
                  <Typography variant="h6">
                    {gamerData.lastName + ", " + gamerData.firstName}
                  </Typography>
                  <Typography variant="body1">
                    {gamerData.metadata?.muniGamer ?? false
                      ? t("gamerSearch.muniGamer")
                      : t("gamerSearch.consumerGamer")}
                  </Typography>
                  <Typography variant="body1">
                    {gamerData.discordUserName
                      ? `${gamerData.discordUserName}`
                      : ``}
                    {gamerData.discordUserId
                      ? " [" + gamerData.discordUserId + "]"
                      : ``}
                  </Typography>
                  {(gamerData.email || gamerData.phoneNumber) && (
                    <Typography variant="body1">
                      {gamerData.email ?? ""}
                      {gamerData.phoneNumber
                        ? ` - ${gamerData.phoneNumber}`
                        : ``}
                    </Typography>
                  )}
                  <Typography variant="body1">
                    {t("gamerDetails.roles")}: {gamerData.roles}
                  </Typography>
                  <Typography variant="body1">
                    {t("gamerDetails.birthdate")}: {gamerData.birthdate}
                  </Typography>
                  <Typography variant="body1">
                    {t("gamerDetails.userId")}: {gamerData.id}
                  </Typography>
                  <Typography variant="body1">
                    {t("gamerDetails.metadata")}:{" "}
                    {JSON.stringify(gamerData.metadata)}
                  </Typography>
                  {/* Add more fields as needed */}
                  <Typography variant="body1">
                    {t("gamerDetails.lastLogin")}: {gamerData.lastLogin}
                  </Typography>
                  {gamerData.discordInvitationURL && (
                    <Stack direction="row" alignItems="center">
                      <TextField
                        id="invitationURL"
                        label={t("admin.users.gamers_invitationUrl")}
                        sx={{ m: 1, ml: 0, width: "80%" }}
                        defaultValue={gamerData.discordInvitationURL}
                      />
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            gamerData.discordInvitationURL
                          );
                        }}
                      >
                        <ContentCopyIcon color="blue" />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>
                {t("gamerDetails.subscriptionData.title")} (
                {gamerData.subscriptionPlans.length})
              </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {gamerData.subscriptionPlans.map((plan, index) => (
                  <Fragment key={plan.id}>
                    {index !== 0 && <Divider />}
                    <ListItem
                      key={plan.id}
                      alignItems="flex-start"
                      sx={{ flexDirection: "column" }}
                    >
                      <ListItemText
                        primary={`${plan.chargebeePlan.name} [${plan.chargebeePlan.chargebeeId}]`}
                      />
                      <Stack direction="row" alignItems="center">
                        <ListItemText
                          primary={
                            t("gamerDetails.subscriptionData.status") + ": "
                          }
                        />
                        {getStatusIcon(plan.chargebeeSubscription.status)}
                        {getStatusTypography(
                          plan.chargebeeSubscription.status,
                          t
                        )}
                      </Stack>
                      {/* <ListItemText
                      secondary={`${t("gamerDetails.subscriptionData.planId")}: ${plan.id}`}
                    />
                    <ListItemText
                      secondary={`${t("gamerDetails.subscriptionData.subscriptionId")}: ${plan.chargebeeSubscription.id}`}
                    /> */}
                      <ListItemText
                        secondary={`${t(
                          "gamerDetails.subscriptionData.chargebeeSubscriptionId"
                        )}: ${plan.chargebeeSubscription.chargebeeId}`}
                      />
                      <ListItemText
                        secondary={`${t(
                          "gamerDetails.subscriptionData.customerName"
                        )}: ${
                          plan.chargebeeSubscription.customerLastName +
                          ", " +
                          plan.chargebeeSubscription.customerFirstName
                        }`}
                      />
                      <ListItemText
                        secondary={`${t(
                          "gamerDetails.subscriptionData.customerEmail"
                        )}: ${plan.chargebeeSubscription.email}`}
                      />
                      <ListItemText
                        secondary={`${t(
                          "gamerDetails.subscriptionData.chargebeeCustomerId"
                        )}: ${plan.chargebeeSubscription.chargebeeCustomerId}`}
                      />
                      {plan.chargebeeSubscription.nextBillingAt && (
                        <ListItemText
                          secondary={`${t(
                            "gamerDetails.subscriptionData.nextBillingAt"
                          )}: ${plan.chargebeeSubscription.nextBillingAt}`}
                        />
                      )}
                      {plan.chargebeePlan.metadata && (
                        <ListItemText
                          secondary={`${t(
                            "gamerDetails.subscriptionData.planMetadata"
                          )}: ${JSON.stringify(plan.chargebeePlan.metadata)}`}
                        />
                      )}
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>
          <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>
                {t("gamerDetails.participationData.title")} (
                {gamerData.participations.length})
              </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {gamerData.participations.map((participation, index) => (
                  <Fragment key={participation.id}>
                    {index !== 0 && <Divider />}
                    <ListItem key={participation.id}>
                      <IconButton
                        sx={{ ml: "-1em" }}
                        onClick={() => {
                          window
                            .open(
                              `../club/?id=${participation.group.clubId}`,
                              "_blank"
                            )
                            .focus();
                        }}
                      >
                        <OpenIcon className="action-icon" />
                      </IconButton>
                      <Stack direction="column" alignItems="flex-start">
                        <ListItemText
                          primary={`${participation.group.club.name} | ${
                            participation.group.name
                          } | [${
                            participation.active
                              ? t("gamerDetails.active")
                              : t("gamerDetails.inactive")
                          }]`}
                        />
                        <ListItemText
                          secondary={
                            <>
                              {`${t(
                                "gamerDetails.participationData.clubId"
                              )}: ${participation.group.clubId}`}
                              <br />
                              {`${t(
                                "gamerDetails.participationData.groupId"
                              )}: ${participation.group.id}`}
                            </>
                          }
                        />
                      </Stack>
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>
          <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>
                {t("gamerDetails.parentData.title")} ({gamerData.parents.length}
                )
              </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {gamerData.parents.map((parent, index) => (
                  <Fragment key={parent.id}>
                    {index !== 0 && <Divider />}
                    <ListItem key={parent.id}>
                      <IconButton
                        sx={{ ml: "-1em" }}
                        onClick={() => {
                          window
                            .open(`../parentDetails/?id=${parent.id}`, "_blank")
                            .focus();
                        }}
                      >
                        <OpenIcon className="action-icon" />
                      </IconButton>
                      <Stack direction="column" alignItems="flex-start">
                        <ListItemText
                          primary={`${
                            parent.lastName + ", " + parent.firstName
                          } | ${parent.email} | ${parent.phoneNumber}`}
                        />
                        <ListItemText
                          secondary={`${t(
                            "gamerDetails.parentData.parentId"
                          )}: ${parent.id}`}
                        />
                      </Stack>
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>

          <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("gamerDetails.discordData.title")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {gamerData.guildMembers.map((guildMember, index) => (
                  <Fragment key={guildMember.guild.id}>
                    {index !== 0 && <Divider />}
                    <Stack direction="row">
                      <CardMedia
                        component="img"
                        className="user-icon"
                        image={`https://cdn.discordapp.com/icons/${guildMember.guild.id}/${guildMember.guild.icon}.png`}
                        alt="Avatar"
                      />
                      <ListItem
                        alignItems="flex-start"
                        sx={{ flexDirection: "column" }}
                      >
                        <ListItemText
                          primary={`${guildMember.guild.name} [${guildMember.guild.id}]`}
                        />
                        {guildMember.nickname && (
                          <ListItemText
                            primary={`${t(
                              "gamerDetails.discordData.serverNick"
                            )}: ${guildMember.nickname}`}
                          />
                        )}
                        <ListItemText
                          primary={`${t(
                            "gamerDetails.discordData.roles"
                          )}: ${guildMember.roles.join(", ")}`}
                        />
                        <ListItemText
                          primary={`${t(
                            "gamerDetails.discordData.joinedAt"
                          )}: ${guildMember.joinedAt}`}
                        />
                        <ListItemText
                          primary={`${t(
                            "gamerDetails.discordData.cooldown"
                          )}: ${
                            guildMember.cooldown ??
                            t("gamerDetails.discordData.noCooldown")
                          }`}
                        />
                        {guildMember.ban && (
                          <ListItemText
                            primary={`${t(
                              "gamerDetails.discordData.bannedReason"
                            )}: ${guildMember.ban.reason}`}
                          />
                        )}
                      </ListItem>
                    </Stack>
                  </Fragment>
                ))}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>
        </>
      )}
    </ContainerBox>
  );
}
