import React, { useState } from "react";
import "./club.css";
import { useQuery, useMutation } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import GroupsIcon from "@mui/icons-material/Groups";

import { updateLessonsEntriesForLesson } from "../Hooks/lessons";
import { isActiveStatus } from "../Util/authHelper";

import { useTranslation } from "react-i18next";
import { Dialog, Link } from "@mui/material";

import { getCodeDomainsByNames } from "../Hooks/codes";

import { RewardList } from "../Util/rewardList";
import { isAdmin } from "../Util/authHelper";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { getGroup } from "../Hooks/groups";

export default function ClubLessonParticipants(
  params /*: { setSaving, setNotSaving, lessonData, group, codes, locked, parentUpdateLessonEntries } */
) {
  const { t } = useTranslation();
  const [openRollcall, setOpenRollcall] = React.useState(false);
  const [savingEntries, setSavingEntries] = React.useState(false);

  const [editedData, setEditedData] = React.useState({});
  const [selectedLesson, setSelectedLesson] = React.useState(undefined);
  const [rowData, setRowData] = useState([]);
  const [openedRewardsId, setOpenedRewardsId] = useState(undefined);
  const lessonId = params.lessonData.id;
  const [headerText, setHeaderText] = useState("");
  // const { data, error, isLoading } = useQuery(["entries", lessonId], () =>
  //   getLessonsEntriesForLesson(lessonId)
  // );
  const { mutate } = useMutation(updateLessonsEntriesForLesson);

  const locked = params.locked;
  const setSaving = params.setSaving;
  const setNotSaving = params.setNotSaving;
  const minParticipantCount = parseInt(params.minParticipantCount, 10);
  const appUserRoles = params.appUserRoles;

  const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  function getLessonDate() {
    if (!selectedLesson) return undefined;

    const lessonDate = new Date(selectedLesson.date);
    const fmtDate = fmt(lessonDate);

    const weekday = weekdays[lessonDate.getDay()];
    const localized = t("weekday." + weekday);

    return localized + " " + fmtDate;
  }

  function fmt(date, format = "YYYY-MM-DD hh:mm") {
    if (!date) return undefined;

    const pad2 = (n) => n.toString().padStart(2, "0");

    const map = {
      YYYY: date.getFullYear(),
      MM: pad2(date.getMonth() + 1),
      DD: pad2(date.getDate()),
      hh: pad2(date.getHours()),
      mm: pad2(date.getMinutes()),
      ss: pad2(date.getSeconds()),
    };

    return Object.entries(map).reduce(
      (prev, entry) => prev.replace(...entry),
      format
    );
  }

  const { data: groupData } = useQuery(
    ["getGroup", params.group.id, params.clubData.discordGuildId, params.updateGeneration],
    () => getGroup(params.group.id, params.clubData.discordGuildId),
    {
      staleTime: 10_000,
    },
  );

  const prepareData = () => {
    setSelectedLesson(params.lessonData);

    const group = params.group;

    console.log("Group:");
    console.log(group);

    const participants = [];

    const initialEditedData = [];

    if (params.lessonData?.status?.name === "LESSON_STATUS_DONE") {
      setHeaderText(t("clubLessons.lessonEntriesTitleNoEdit"));
    } else {
      setHeaderText(t("clubLessons.lessonEntriesTitle"));
    }

    // Add participants from group
    // do this only for lessons that are NOT done (LESSON_STATUS_DONE)
    if (params.lessonData?.status?.name !== "LESSON_STATUS_DONE") {
      for (const participation of (groupData?.participations ?? [])) {
        if (participation.gamer && isActiveStatus(participation.status)) {
          console.log(participation.gamer);

          if (participation.gamer.waitingList) continue; // skip waiting list gamers

          const gamer = getGamer(
            participation,
            params.lessonData.lessonEntries
          );
          if (initialEditedData[gamer.id] === undefined) {
            initialEditedData[gamer.id] = {
              present: gamer.present,
              rewards: gamer.rewards ?? [],
            };
          }
          participants.push(gamer);
        }
      }
    }

    // add lesson entries that are not in group
    if (params.lessonData?.lessonEntries) {
      for (const entry of params.lessonData.lessonEntries) {
        if (
          entry.status === "LESSON_ENTRY_STATUS_PRESENT" &&
          participants.every(
            (gamer) => gamer.id !== entry.participation?.gamer?.id
          )
        ) {
          // console.log(entry);
          const gamer = getGamer(
            entry.participation,
            params.lessonData.lessonEntries
          );
          if (initialEditedData[gamer.id] === undefined) {
            initialEditedData[gamer.id] = {
              present: gamer.present,
              rewards: gamer.rewards ?? [],
            };
          }
          participants.push(gamer);
        }
      }
    }

    // sort by discord nick
    participants.sort((a, b) => {
      if (!a.discordUserName && b.discordUserName) return 1;
      const nameA = a.discordUserName ? a.discordUserName.toLowerCase() : ""; // ignore upper and lowercase
      const nameB = b.discordUserName ? b.discordUserName.toLowerCase() : ""; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    console.log("participants:");
    console.log(participants);

    setEditedData(initialEditedData);
    setRowData(participants);
  };

  const getGamer = (participation, lessonEntries) => {
    const gamer = participation.gamer;

    const fields = {
      id: gamer.id,
      firstName: gamer.firstName,
      lastName: gamer.lastName,
      discordUserName: gamer.discordUserName,
      participation: participation.id,
      participationObject: participation,
      //gamerRoles: participation.gamer.roles,
    };

    if (isAdmin(appUserRoles)) {
      if (gamer.birthdate) {
        fields.birthdate = gamer.birthdate;
      }
    }

    if (isAdmin(appUserRoles)) {
      if (gamer.gender) {
        fields.gender = t("code." + gamer.gender);
      }
    }

    if (isAdmin(appUserRoles)) {
      const birthDate = fields.birthdate ? fields.birthdate : "-";
      const gender = fields.gender ? fields.gender : undefined;

      fields.additionalUserInfo =
        "  (" + birthDate + (gender ? " / " + gender + ")" : ")");
    }

    // map existing lesson Entries to data
    fields.present = false;
    if (lessonEntries && lessonEntries.length > 0) {
      const entry = lessonEntries.find(
        (e) => e.participation?.gamer?.id === gamer.id
      );
      if (entry) {
        fields.present = entry.status === "LESSON_ENTRY_STATUS_PRESENT";
        fields.rewards =
          entry?.rewards?.map((reward, i) => ({
            id: i,
            code: reward.code,
            amount: reward.amount,
          })) ?? [];
      }
    }

    return fields;
  };

  const handleCheckbox = async (e, gamer) => {
    e.preventDefault();

    const newData = { ...editedData };
    newData[gamer.id].present = e.target.checked;

    setEditedData(newData);
  };

  const updateLessonEntries = async (e) => {
    e.preventDefault();

    setSavingEntries(true);
    setSaving();

    const data = {
      id: lessonId,
      fields: [],
    };

    for (const participant of rowData) {
      const edited = editedData[participant.id];
      if (participant !== edited) {
        // status has changed
        const lessonEntry = {
          participationId: participant.participation,
          userId: participant.id,
          present: edited.present,
          rewards: edited.rewards,
          notes: undefined, // TODO do we need notes per participant?
        };
        data.fields.push(lessonEntry);
      }
    }

    const newLessonEntries = rowData
      .filter((participant) => {
        const edited = editedData[participant.id];
        return participant !== edited;
      })
      .map((participant) => {
        const edited = editedData[participant.id];
        return {
          id: participant.id,
          status: edited.present
            ? "LESSON_ENTRY_STATUS_PRESENT"
            : "LESSON_ENTRY_STATUS_NOT_PRESENT",
          rewards: edited.rewards,
          participation: participant.participationObject,
        };
      });

    params.parentUpdateLessonEntries(newLessonEntries);
    setOpenRollcall(false);

    mutate(data, {
      onSuccess: (e) => {
        //console.log(e);

        // handle updating local data too
        // const newData = [...rowData];
        // for (const participant of newData) {
        //   const checked = editedData[participant.id].present;
        //   participant.present = checked ? true : false;
        // }
        // setRowData(newData);
        setSavingEntries(false);
        setNotSaving();
      },
      onError: (e) => {
        console.log(e);
      },
    });
  };

  const numParticipants = params.lessonData.lessonEntries.filter(
    (entry) => entry.status === "LESSON_ENTRY_STATUS_PRESENT"
  ).length;

  const participantColor = () => {
    let returnValue = "#1AB061"; // green by default
    // set color only for done lessons
    if (params.status !== "LESSON_STATUS_DONE") returnValue = "black";
    else {
      if (!minParticipantCount) returnValue = "black";
      else if (numParticipants < minParticipantCount)
        returnValue = "#F55B9A"; // redish
      else if (numParticipants == minParticipantCount)
        returnValue = "#FD700D"; // orange
      else if (numParticipants <= minParticipantCount + 2)
        returnValue = "#FAA901"; // yellowish
    }
    return returnValue;
  };

  const getPresentCount = () => {
    // editedData has value true if present
    let presentCount = 0;
    for (const key in editedData) {
      if (editedData[key].present) presentCount++;
    }
    return presentCount;
  };

  return (
    <Box>
      <Button
        size="small"
        onClick={() => {
          prepareData();
          setOpenRollcall(true);
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <GroupsIcon />
          <Typography sx={{ fontSize: 14, color: participantColor() }}>
            {t("clubLessons.editParticipants")} ({numParticipants})
          </Typography>
        </Stack>
      </Button>
      {/* Rollcall modal */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 435,
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
            paddingBottom: "20px",
          },
        }}
        maxWidth="sm"
        open={openRollcall}
        onClose={() => setOpenRollcall(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock
      >
        {/* <div className="create-modal-lessonEntries">
          <div className="create-form-hour-lessonEntries"> */}
        <form onSubmit={(e) => updateLessonEntries(e)}>
          <Typography
            sx={{ fontSize: 14, marginBottom: "0.5em" }}
            color="text.secondary"
          >
            <label>{getLessonDate()}</label>
          </Typography>
          <h2>{headerText}</h2>
          <Typography
            sx={{ fontSize: 14, marginBottom: "0.5em" }}
            color="text.secondary"
          >
            <label>
              {t("clubLessons.selectedCount")}: {getPresentCount()} /{" "}
              {rowData?.length}
            </label>
          </Typography>
          {rowData &&
            rowData.map((row, index) => (
              <Stack
                key={"participantBox" + index}
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Checkbox
                  disabled={locked}
                  key={`participant_${index}_foo`}
                  checked={editedData[row?.id]?.present}
                  onChange={(e) => handleCheckbox(e, row)}
                />
                {/* <Link href={`/profile/${row.id}`} sx={{ fontSize: 14 }} width="400px" color="text.primary"> */}
                {(groupData?.participations ?? []).every(
                  (participation) => participation.gamer?.id !== row.id
                ) && (
                  <Typography fontSize={10}>
                    ({t("participants.noLongerInGroup")})
                  </Typography>
                )}
                <Typography
                  sx={{ fontSize: 14 }}
                  width="400px"
                  color="text.primary"
                >
                  {row.firstName} {row.lastName}
                  {row.discordUserName ? " - " : ""}
                  {row.discordUserName}
                  {row.additionalUserInfo}
                </Typography>
                {/* </Link> */}
                {/* <Stack direction="row" width="100%" justifyContent="end">
                  <Button variant="contained" onClick={() => setOpenedRewardsId(row.id)}>{t("clubLessons.lessonEntryRewards")} ({editedData[row.id].rewards.length})</Button>
                </Stack> */}
              </Stack>
            ))}
          <Stack
            key={"participantButtons"}
            direction="row"
            alignItems="center"
            gap={1}
            marginTop={"1.5em"}
            paddingBottom={"1em"}
          >
            {!locked && (
              <Button
                onClick={() => setOpenRollcall(false)}
                variant="outlined"
                style={{ marginRight: "0.5em" }}
              >
                {t("general.dialog.cancel")}
              </Button>
            )}
            {!locked && (
              <Button
                type="submit"
                variant="contained"
                disabled={savingEntries}
                sx={{ marginRight: "0.5em" }}
              >
                {t("clubLessons.lessonEntriesSave")}
              </Button>
            )}
            {locked && (
              <Button
                onClick={() => setOpenRollcall(false)}
                variant="contained"
                style={{ marginRight: "0.5em" }}
              >
                {t("general.dialog.ok")}
              </Button>
            )}
            {savingEntries && <CircularProgress size="1.25rem" />}
          </Stack>
        </form>
        {/* <ExtraRewardsDialog 
          rewards={openedRewardsId !== undefined ? editedData[openedRewardsId].rewards : []} 
          isOpen={openedRewardsId !== undefined} 
          close={() => setOpenedRewardsId(undefined)} 
          saveRewards={(rewards) => {
            const newEditedData = { ...editedData };
            newEditedData[openedRewardsId].rewards = rewards;
            setEditedData(newEditedData);
          }}
        /> */}
      </Dialog>
    </Box>
  );
}

const ExtraRewardsDialog = ({ isOpen, close, rewards, saveRewards }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={isOpen}
      sx={{
        "& .MuiDialog-paper": {
          width: "70%",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          paddingBottom: "20px",
        },
      }}
      maxWidth="xs"
    >
      <Typography variant="h5">
        {t("clubLessons.lessonEntryRewards")}
      </Typography>
      <RewardList rewards={rewards} setRewards={saveRewards} />
      <Stack direction="row" justifyContent="end" marginTop={2} gap={1}>
        <Button
          variant="contained"
          disabled={rewards.some((row) => row.code === "")}
          onClick={close}
        >
          {t("general.dialog.ok")}
        </Button>
      </Stack>
    </Dialog>
  );
};
