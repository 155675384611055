import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import BiotechIcon from "@mui/icons-material/Biotech";
import TimesOneMobiledataIcon from "@mui/icons-material/TimesOneMobiledata";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Typography } from "@mui/material";

export const getStatusSpan = (status, t) => (
    {
        "active": <span style={{ color: "green" }}>{t("gamerDetails.subscriptionData.statusActive")}</span>,
        "cancelled": <span style={{ color: "red" }}>{t("gamerDetails.subscriptionData.statusCancelled")}</span>,
        "in_trial": <span style={{ color: "orange" }}>{t("gamerDetails.subscriptionData.statusInTrial")}</span>,
        "non_renewing": <span style={{ color: "green" }}>{t("gamerDetails.subscriptionData.statusNonRenewing")}</span>,
        "one_time": <span style={{ color: "green" }}>{t("gamerDetails.subscriptionData.statusOneTime")}</span>,
        "paused": <span style={{ color: "orange" }}>{t("gamerDetails.subscriptionData.statusPaused")}</span>,
        "future": <span style={{ color: "blue" }}>{t("gamerDetails.subscriptionData.statusFuture")}</span>,
    }[status] ?? <span style={{ color: "red" }}>ERROR: unknown status</span>);

export const getStatusIcon = (status) => (
    {
        "active": <PlayArrowIcon color="success" />,
        "cancelled": <StopIcon color="error" />,
        "in_trial": <BiotechIcon color="warning" />,
        "non_renewing": <TimesOneMobiledataIcon color="success" />,
        "one_time": <TimesOneMobiledataIcon color="success" />,
        "paused": <PauseIcon color="warning" />,
        "future": <ScheduleIcon color="info"/>,
    }[status]);

export const getStatusTypography = (status, t) => (
    {
        "active": <Typography sx={{ color: "green" }}>{t("gamerDetails.subscriptionData.statusActive")}</Typography>,
        "cancelled": <Typography sx={{ color: "red" }}>{t("gamerDetails.subscriptionData.statusCancelled")}</Typography>,
        "in_trial": <Typography sx={{ color: "orange" }}>{t("gamerDetails.subscriptionData.statusInTrial")}</Typography>,
        "non_renewing": <Typography sx={{ color: "green" }}>{t("gamerDetails.subscriptionData.statusNonRenewing")}</Typography>,
        "one_time": <Typography sx={{ color: "green" }}>{t("gamerDetails.subscriptionData.statusOneTime")}</Typography>,
        "paused": <Typography sx={{ color: "orange" }}>{t("gamerDetails.subscriptionData.statusPaused")}</Typography>,
        "future": <Typography sx={{ color: "blue" }}>{t("gamerDetails.subscriptionData.statusFuture")}</Typography>,
    }[status] ?? <Typography sx={{ color: "red" }}>ERROR: unknown status</Typography>);
