import React from "react";
import { Button, Modal, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { createCode } from "../../Hooks/codes";
import { useMutation } from "react-query";
import ErrorAlert from "../../errorAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function AddCodes({
  CodeDomainId,
  handleNewCode,
  DeleteButton,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(createCode);
  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate(
      {
        domain: CodeDomainId,
        name: document.getElementById("code-name").value,
        index: document.getElementById("code-index").value,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleNewCode(e.data.code);
          handleClose();
        },
      }
    );
  };

  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ height: 40, mr: 1 }}
          onClick={handleOpen}
        >
          Add Code
        </Button>
        {DeleteButton}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <TextField
            required
            id="code-name"
            label="Name"
            sx={{ m: 1, width: "100%" }}
          />
          <TextField
            required
            type="number"
            id="code-index"
            label="Index"
            sx={{ m: 1, width: "100%" }}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ left: "35%", marginTop: "1rem" }}
            type="submit"
          >
            Save Code
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
