import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useTranslation } from "react-i18next";

export const CustomTextFieldWithSave = ({ initialValue, onSave, rows = 1 }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(value !== initialValue);
  }, [value, initialValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = () => {
    onSave(value);
  };

  return (
    <div>
      <TextField
        label={t("customTextField.title")}
        variant="outlined"
        fullWidth
        multiline={rows > 1}
        rows={rows}
        value={value}
        onChange={handleChange}
        sx={{ mt: 2, mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={!isDirty}
      >
        {t("customTextField.save")}
      </Button>
    </div>
  );
};
