import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContainerBox from "../Container";
import ErrorAlert from "../errorAlert";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import { TranslatedDatePicker } from "../Util/translatedDatePicker";
import MuniSummaryReport from "./MuniSummaryReport";

import { isAdmin, isGedu, isParent, isGamer } from "../Util/authHelper";
import { formatDate } from "../Util/clubHelper";

import {
  getGeduInvoicingPeriods,
  getMuniSummaryClubsBetweenDates,
} from "../Hooks/report";

import { getCountries } from "../Hooks/country";
import { getMunicipalities } from "../Hooks/municipality";

import { useTranslation } from "react-i18next";
import { set, setMinutes } from "date-fns";

export default function MuniSummaryReportMainView(params) {
  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [isLoadingInvoicingData, setIsLoadingInvoicingData] = useState(false);

  const [periodType, setPeriodType] = useState("currentMonth");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [municipalities, setMunicipalities] = useState([]);
  const [currency, setCurrency] = useState({});

  const appUserRoles = params?.appAuthInfo?.appUserRoles;

  const {
    data: countryData,
    error: errorCountryData,
    isLoading: isLoadingCountryData,
    refetch: refetchCountryData,
  } = useQuery({
    queryKey: ["getCountries"],
    queryFn: () => getCountries(),
    enabled: true,
  });

  useEffect(() => {
    // Set the default dates for current month
    const { startDate, endDate } = getCurrentMonth();
    console.log(startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodType("currentMonth");
  }, []);

  useEffect(() => {
    if (countryData) {
      prepareCountries();
    }
    // eslint-disable-next-line
  }, [countryData]);

  const getCurrentMonth = () => {
    // create a struct with startDate and endDate for current month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const getPreviousMonth = () => {
    // create a struct with startDate and endDate for previous month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  // get next month
  const getNextMonth = () => {
    // create a struct with startDate and endDate for next month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const prepareCountries = async () => {
    console.log(countryData);
    const countries = [];
    countryData.countries.map((country) => {
      countries.push({
        value: country.id,
        label: country.name,
      });
    });

    // sort the countries by name
    countries.sort((a, b) => (a.label > b.label ? 1 : -1));
    setCountries(countries);
  };

  const handleSelectedCountry = async (event) => {
    console.log(event);
    setSelectedCountry(event);
  };

  const fetchData = async (country) => {
    setIsLoadingInvoicingData(true);
    const body = {
      countryId: country.value,
      startDate: formatDate(startDate, "YYYY-MM-DD"),
      endDate: formatDate(endDate, "YYYY-MM-DD"),
    };

    console.log(body);
    const response = await getMuniSummaryClubsBetweenDates(body);

    console.log(response);

    const municipalities = response.data.municipalities;

    setMunicipalities(municipalities);
    setCurrency(response.data.currencyCode);

    setIsLoadingInvoicingData(false);
  };

  const handleButtonClick = (period) => {
    setPeriodType(period);

    if (period === "currentMonth") {
      const { startDate, endDate } = getCurrentMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "previousMonth") {
      const { startDate, endDate } = getPreviousMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "nextMonth") {
      const { startDate, endDate } = getNextMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "custom") {
      // don't change the dates
    }
  };

  const handleSubmit = async () => {
    await fetchData(selectedCountry);
  };

  return (
    <div>
      <Stack direction="column" spacing={2}>
        {errorCountryData && <ErrorAlert txt={errorCountryData} />}
        {isLoadingCountryData && <CircularProgress />}

        {countries && countries.length > 0 && (
          // <div id="FilterDiv" style={{ marginBottom: 10 }}>
          <>
            <Stack direction="row" spacing={2} sx={{ display: "block", m: 1 }}>
              <Button
                variant={
                  periodType === "previousMonth" ? "contained" : "outlined"
                }
                onClick={() => handleButtonClick("previousMonth")}
              >
                {t("geduInvoicing.previousMonth")}
              </Button>
              <Button
                variant={
                  periodType === "currentMonth" ? "contained" : "outlined"
                }
                onClick={() => handleButtonClick("currentMonth")}
              >
                {t("geduInvoicing.currentMonth")}
              </Button>
              <Button
                variant={periodType === "nextMonth" ? "contained" : "outlined"}
                onClick={() => handleButtonClick("nextMonth")}
              >
                {t("geduInvoicing.nextMonth")}
              </Button>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ display: "block", m: 1 }}>
              <TranslatedDatePicker
                fullWidth
                label={t("geduInvoicing.startDate")}
                value={startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TranslatedDatePicker
                fullWidth
                type="date"
                label={t("geduInvoicing.endDate")}
                value={endDate}
                onChange={(newValue) => handleEndDateChange(newValue)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>

            <Stack direction="row" spacing={2} sx={{ display: "block", m: 2 }}>
              <div
                style={{
                  marginRight: "0.25em",
                  marginBottom: "0.25em",
                  maxWidth: 300,
                  display: "inline-flex",
                }}
              >
                <Select
                  name="geduFilter"
                  options={countries}
                  className="basic-multi-select"
                  placeholder={t("muniReport.selectCountry")}
                  value={selectedCountry}
                  onChange={(event) => {
                    handleSelectedCountry(event);
                  }}
                  isSearchable={true}
                  // isMulti
                  multiple={false} // Set to false for single-select
                  native={false} // Set to false to use the custom Select component
                />
              </div>

              <Button
                sx={{ m: 1 }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!startDate || !endDate || !selectedCountry}
              >
                {t("trusterReport.submit")}
              </Button>
            </Stack>
          </>
          // </div>
        )}
      </Stack>
      {isLoadingInvoicingData && <CircularProgress sx={{ m: 3 }} />}
      {!isLoadingInvoicingData && municipalities && selectedCountry && (
        <MuniSummaryReport
          municipalities={municipalities}
          refetch={fetchData}
          country={selectedCountry}
          currency={currency}
        />
      )}
    </div>
  );
}
