import axios from "axios";
import { API } from "./API";

export const getGamerProfile = async (id) => {
  const { data } = await axios.get(`${API}/users/gamers/${id}/profile`, {
    withCredentials: true,
  });

  return data.data;
};

export const unlockAchievementForProfile = async (args) => {
  const { userId, achievementId } = args;
  console.log(userId);
  console.log(achievementId);
  const { data } = await axios.put(`${API}/users/gamers/${userId}/unlockachievement`, { achievementId }, {
    withCredentials: true,
  });

  return data.data;
};

export const claimAchievements = async (userId, achievementIds) => {
  const { data } = await axios.put(`${API}/users/gamers/${userId}/claimachievements`, { achievementIds }, {
    withCredentials: true,
  });

  return data.data;
};