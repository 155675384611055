import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import GlobalStyles from '@mui/material/GlobalStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export default function PathPicker() {
  const theme = createTheme({
    palette: {
      sogyellow: {
        main: '#faa901',
        contrastText: "#fff"
      },
      sogpurple: {
        main: '#8f00e2',
        contrastText: "#fff"
      },
    },
  });

  const cards = [
    {
        title: 'Peliharrastus',
        description: 'Peliharrastuskerhoissa seikkaillaan aikuisen pelikasvattajan johdolla ja saadaan uusia ystäviä.',
        picture: "https://sog.gg/wp-content/uploads/2021/11/liitutaulu-300x300.png",
        weekday: 'Order now',
        time: 'contained',
        identifier: 'peliharrastus',
    },
    {
        title: 'Teknologia',
        description: 'Teknologiakerhoissa opitaan ohjelmointia, sähköpostia, serverihommia ja muuta jännää.',
        picture: 'https://sog.gg/wp-content/uploads/2021/11/liitutaulu-300x300.png',
        weekday: 'Order now',
        time: 'contained',
        identifier: 'teknologia',
    },
    {
        title: 'Esports',
        description: 'Esports Education valmennukset ovat lapselle ensimmäinen askel e-urheilun maailmaan.',
        picture: 'https://sog.gg/wp-content/uploads/2021/11/liitutaulu-300x300.png',
        weekday: 'Order now',
        time: 'contained',
        identifier: 'esports',
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        {cards.map((card) => (
              <Grid item key={card} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{ pt: '0',}}
                    image={card.picture}
                    alt="club picture"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography>
                        {card.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="sogpurple" size="small">Select</Button>
                    <Button variant="contained" color="sogpurple" size="small">Info</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}