import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import ReactSelect from "react-select";

import NoAvatarIcon from "@mui/icons-material/AccountCircle";
import OpenIcon from "@mui/icons-material/OpenInNew";

import { getCodeDomainsByNames } from "../Hooks/codes";
import { getAllMunicipalities } from "../Hooks/municipality";
import { geduSubstituteSearch } from "../Hooks/search";
import "../App.css";
import { useTranslation } from "react-i18next";
import { set } from "date-fns";

const GeduSubstituteSearch = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useState({
    weekday: "mon",
    startTime: "14:00",
    endTime: "15:00",
  });
  const [geduData, setGeduData] = useState([]);
  const [activeOnly, setActiveOnly] = useState(false); // State for the checkbox
  const [selectedDay, setSelectedDay] = useState("mon");
  const [startTime, setStartTime] = useState("14:00");
  const [endTime, setEndTime] = useState("15:00");

  const [geduLocations, setGeduLocations] = useState([]); // [ {value: "id", label: "name"}, ...]
  const [geduLanguages, setGeduLanguages] = useState([]);
  const [geduSkills, setGeduSkills] = useState([]);
  const [geduQualifications, setGeduQualifications] = useState([]);
  const [geduAttandanceTypes, setGeduAttendanceTypes] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState([]);
  const [selectedAttendanceTypes, setSelectedAttendanceType] = useState([]);

  const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const { data, isLoading, error, refetch } = useQuery(
    ["geduSubstituteSearch", searchParams],
    () => geduSubstituteSearch(searchParams),
    {
      enabled: false,
    }
  );

  // The names of the needed CodeDomains
  const codeDomainNames = [
    "GEDU_QUALIFICATION",
    "CLUB_LANGUAGE",
    "CLUB_CONTENT_TYPE", // gedu skills
    "CLUB_ATTANDANCE_TYPE",
  ];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  const {
    data: dataMunicipalities,
    error: errorMunicipalities,
    isLoading: isLoadingMunicipalities,
    refetch: refetchMunicipalities,
  } = useQuery(["municipalities"], getAllMunicipalities);

  const handleSearch = () => {
    refetch();
  };

  const selectLanguage = (data) => {
    setSelectedLanguage(data);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the language
    newSearchParams.languageId = data.value;
    // update the state
    setSearchParams(newSearchParams);
    console.log(newSearchParams);
  };

  const selectLocation = (data) => {
    setSelectedLocation(data);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the language
    newSearchParams.municipalityId = data.value;
    // update the state
    setSearchParams(newSearchParams);
    console.log(newSearchParams);
  };

  const selectSkill = (data) => {
    setSelectedSkill(data);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the language
    newSearchParams.skillId = data.value;
    // update the state
    setSearchParams(newSearchParams);
    console.log(newSearchParams);
  };

  const selectQualification = (data) => {
    setSelectedQualification(data);

    const qualificationIds = [];
    for (const qualification of data) {
      qualificationIds.push(qualification.value);
    }

    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the language
    newSearchParams.qualificationIds = qualificationIds; // multiple
    // update the state
    setSearchParams(newSearchParams);
    console.log(newSearchParams);
  };

  const selectAttandanceType = (data) => {
    setSelectedAttendanceType(data);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the language
    newSearchParams.attandanceTypeId = data.value;
    // update the state
    setSearchParams(newSearchParams);
    console.log(newSearchParams);
  };

  const onWeekdayChange = (e) => {
    setSelectedDay(e.target.value);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the weekday
    newSearchParams.weekday = e.target.value;
    // update the state
    setSearchParams(newSearchParams);
    console.log(newSearchParams);
  };

  const onChangeStartTime = (value) => {
    setStartTime(value);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the start time
    newSearchParams.startTime = value;
    // update the state
    setSearchParams(newSearchParams);

    console.log(newSearchParams);
  };

  const onChangeEndTime = (value) => {
    setEndTime(value);
    // copy the searchParams object
    const newSearchParams = { ...searchParams };
    // update the end time
    newSearchParams.endTime = value;
    // update the state
    setSearchParams(newSearchParams);

    console.log(newSearchParams);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  useEffect(() => {
    console.log("Data changed");
    console.log(data);
    if (data) prepareData();
  }, [data]);

  useEffect(() => {
    if (dataCodeDomains) createCodeDomains();
  }, [dataCodeDomains]);

  useEffect(() => {
    if (dataMunicipalities) createMunicipalities();
  }, [dataMunicipalities]);

  const prepareData = () => {
    const gedus = [];
    for (const gedu of data) {
      const cardData = {
        id: gedu.id,
        name: gedu.lastName + ", " + gedu.firstName,
        email: gedu.email,
        phoneNumber: gedu.phoneNumber,
        discordUserId: gedu.discordUserId,
        discordUserName: gedu.discordUserName,
        avatar: gedu.discordAvatar ? gedu.discordAvatar : "",
      };

      gedus.push(cardData);
    }
    // sort by name
    gedus.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    console.log(gedus);

    setGeduData(gedus);
  };

  const createCodeDomains = () => {
    console.log("substituteSearch.createCodeDomains");
    console.log(dataCodeDomains);

    const geduQualifications = [];
    const geduSkills = [];
    const clubLanguages = [];
    const geduAttendanceTypes = [];

    //        "GEDU_QUALIFICATION", "CLUB_LANGUAGE", "CLUB_CONTENT_TYPE";
    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name == "GEDU_QUALIFICATION") {
        codeDomain.codes.forEach((code) => {
          geduQualifications.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "CLUB_LANGUAGE") {
        codeDomain.codes.forEach((code) => {
          clubLanguages.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "CLUB_CONTENT_TYPE") {
        codeDomain.codes.forEach((code) => {
          geduSkills.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      } else if (codeDomain.name == "CLUB_ATTANDANCE_TYPE") {
        codeDomain.codes.forEach((code) => {
          geduAttendanceTypes.push({
            value: code.id,
            label: t("code." + code.name),
            index: code.index,
          });
        });
      }
    });

    clubLanguages.sort((a, b) => a.index - b.index);
    geduQualifications.sort((a, b) => a.index - b.index);
    geduSkills.sort((a, b) => a.index - b.index);
    geduAttendanceTypes.sort((a, b) => a.index - b.index);

    setGeduQualifications(geduQualifications);
    setGeduSkills(geduSkills);
    setGeduLanguages(clubLanguages);
    setGeduAttendanceTypes(geduAttendanceTypes);
  };

  const createMunicipalities = () => {
    console.log("substituteSearch.createMunicipalities");
    console.log(dataMunicipalities);

    const municipalities = [];
    for (const municipality of dataMunicipalities.municipalities) {
      const aMunicipality = {
        value: municipality.id,
        label: municipality.name,
      };
      municipalities.push(aMunicipality);
    }

    municipalities.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    setGeduLocations(municipalities);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {t("geduSubstituteSearch.title")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          key={"GeduSearchStack1"}
          direction="row"
          alignItems="baseline"
          gap={1}
        >
          <Stack direction="column" alignItems="flex-start" gap={1}>
            <Stack direction="row" alignItems="flex-start" gap={0}>
              <FormControl>
                <InputLabel
                  htmlFor="weekday-select"
                  sx={{ padding: 1, mt: 0.25, background: "white" }}
                >
                  {t("geduSubstituteSearch.weekday")}
                </InputLabel>
                <Select
                  value={selectedDay}
                  onChange={onWeekdayChange}
                  sx={{ m: 1, width: 150 }}
                  inputProps={{
                    name: "weekday",
                    id: "weekday-select",
                  }}
                >
                  {weekdays.map((day) => (
                    <MenuItem key={day} value={day}>
                      {t(`weekday.${day}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label={t("geduSubstituteSearch.startTime")}
                type="time"
                value={startTime}
                onChange={(e) => onChangeStartTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 minutes
                }}
                sx={{ m: 1, width: 125 }}
              />
              <TextField
                label={t("geduSubstituteSearch.endTime")}
                type="time"
                value={endTime}
                onChange={(e) => onChangeEndTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900, // 15 minutes
                }}
                sx={{ m: 1, width: 125 }}
              />
            </Stack>

            <Stack direction="row" alignItems="flex-start" gap={1}>
              <ReactSelect
                sx={{}}
                name="languageSelector"
                options={geduLanguages}
                className="gedusearch-single-select"
                placeholder={t(
                  "geduSubstituteSearch.languageSelectorPlaceholder"
                )}
                value={selectedLanguage}
                onChange={(data) => {
                  selectLanguage(data);
                }}
                isSearchable={true}
              />
              <ReactSelect
                sx={{}}
                name="locationSelector"
                options={geduLocations}
                className="gedusearch-single-select"
                placeholder={t(
                  "geduSubstituteSearch.locationSelectorPlaceholder"
                )}
                value={selectedLocation}
                onChange={(data) => {
                  selectLocation(data);
                }}
                isSearchable={true}
              />
            </Stack>
            <Stack direction="row" alignItems="flex-start" gap={1}>
              <ReactSelect
                sx={{}}
                name="skillSelector"
                options={geduSkills}
                className="gedusearch-single-select"
                placeholder={t("geduSubstituteSearch.skillSelectorPlaceholder")}
                value={selectedSkill}
                onChange={(data) => {
                  selectSkill(data);
                }}
                isSearchable={true}
              />
              <ReactSelect
                sx={{}}
                name="qualificationSelector"
                options={geduQualifications}
                className="gedusearch-single-select"
                placeholder={t(
                  "geduSubstituteSearch.qualificationSelectorPlaceholder"
                )}
                value={selectedQualification}
                onChange={(data) => {
                  selectQualification(data);
                }}
                isSearchable={true}
                isMulti
              />
            </Stack>
            <Stack direction="row" alignItems="flex-start" gap={1}>
              <ReactSelect
                sx={{}}
                name="attandanceTypeSelector"
                options={geduAttandanceTypes}
                className="gedusearch-single-select"
                placeholder={t(
                  "geduSubstituteSearch.attandanceTypeSelectorPlaceholder"
                )}
                value={selectedAttendanceTypes}
                onChange={(data) => {
                  selectAttandanceType(data);
                }}
                isSearchable={true}
              />
            </Stack>
            <Typography variant="body2" sx={{ m: 1.25, mt: -1.5 }}>
              {t("geduSubstituteSearch.searchHelpText")}
            </Typography>
          </Stack>

          {/* <FormControlLabel
          control={
            <Checkbox
              checked={activeOnly}
              onChange={(e) => setActiveOnly(e.target.checked)}
            />
          }
          label="Active Only"
          sx={{ marginBottom: 2 }}
        /> */}
          <Button variant="contained" type="submit">
            {t("geduSubstituteSearch.findButtonText")}
          </Button>
        </Stack>
      </form>

      {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
      {error && <Typography color="error">{error.message}</Typography>}

      {geduData && (
        <div>
          {geduData.map((result, index) => (
            <Card key={result.id} sx={{ marginTop: 2 }}>
              <CardContent>
                <Stack
                  key={"geduStack1" + index}
                  direction="row"
                  alignItems="center"
                  gap={2}
                >
                  {result.avatar && (
                    <CardMedia
                      component="img"
                      className="user-icon"
                      image={result.avatar}
                      alt="Avatar"
                      key={"geduCardMedia1." + index}
                    />
                  )}
                  {!result.avatar && <NoAvatarIcon className="user-icon" />}

                  <Stack
                    key={"geduStack2" + index}
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap={1}
                  >
                    <Typography variant="h6">{result.name}</Typography>
                    <Typography variant="body1">
                      {result.discordUserName
                        ? `${result.discordUserName}`
                        : ``}
                      {result.discordUserId
                        ? " [" + result.discordUserId + "]"
                        : ``}
                    </Typography>
                    <Typography variant="body1">
                      {result.email}
                      {result.phoneNumber ? ` - ${result.phoneNumber}` : ``}
                    </Typography>
                    {/* Add more fields as needed */}
                  </Stack>
                  <OpenIcon
                    className="action-icon"
                    onClick={() => {
                      window
                        .open(`./geduDetails/?id=${result.id}`, "_blank")
                        .focus();
                    }}
                    sx={{ ml: 2 }}
                  />
                </Stack>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </Container>
  );
};

export default GeduSubstituteSearch;
