import React, {useEffect, useState} from 'react';
import CountryList from './country';
import MunicipalityList from './municipality';
import SchoolList from './school';

export default function MuniManagement() {   
 
    const [view, setView] = useState(["", ""])

  
    useEffect(() =>{         
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);  
        if(urlParams.get("country")) setView(["municipality", urlParams.get("country")])
        if(urlParams.get("muni")) setView(["school", urlParams.get("school")])
        

    },[])  

    
   
    return(
        < >                           
            
            {
                view[0] === "" &&
                <CountryList />
            }
            {
                view[0] === "municipality" &&
                <MunicipalityList country={view[1]} />
            }
            {
                view[0] === "school" &&
                <SchoolList muni={view[1]} />
            }
             
        </>
    )
}