import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FloatInputField from "./floatInputField";

import { useTranslation } from "react-i18next";

const ClubFeeEditor = ({ data, onSave }) => {
  const { t } = useTranslation();

  const [selectedCurrency, setSelectedCurrency] = useState(data.currency);

  const [editedData, setEditedData] = useState(data);
  const [hasChanges, setHasChanges] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isCurrencySelected, setIsCurrencySelected] = useState(false); // Set it to true initially

  useEffect(() => {
    // Check for changes when data or editedData changes
    const changes = Object.keys(data).some(
      (key) => editedData[key] !== data[key]
    );

    if (selectedCurrency) {
      setIsCurrencySelected(true);
    }

    setHasChanges(changes);
  }, [data, editedData]);

  const handleCurrencyChange = (event) => {
    const selectedValue = event.target.value;
    handleFeeChange("currency", selectedValue);
    setSelectedCurrency(selectedValue);
    setIsCurrencySelected(selectedValue ? true : false); // Check if a currency is selected
  };

  const handleFeeChange = (key, newValue) => {
    console.log(key + ": " + newValue);
    const updatedData = {
      ...editedData,
    };

    updatedData[key] = newValue;

    console.log(updatedData);

    setEditedData(updatedData);
  };

  const handleSave = () => {
    onSave(editedData);
    setHasChanges(false);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("clubFeeEditor.openClubFeeEditor")}
      </Button>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>Club Fee Editor</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginBottom: 2, marginTop: 1 }}>
            <InputLabel>{t("clubFeeEditor.currencyLabel")}</InputLabel>
            <Select
              value={selectedCurrency}
              onChange={handleCurrencyChange}
              label={t("clubFeeEditor.currencyLabel")}
              sx={{ marginBottom: 1 }}
            >
              {data.currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {t("code." + currency.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FloatInputField
            label={t("clubFeeEditor.mainGeduFeeLabel")}
            initialValue={data.mainGeduFee}
            onChange={(newValue) => handleFeeChange("mainGeduFee", newValue)}
            sx={{ marginBottom: 2 }}
          />
          <FloatInputField
            label={t("clubFeeEditor.groupGeduFeeLabel")}
            initialValue={data.groupGeduFee}
            onChange={(newValue) => handleFeeChange("groupGeduFee", newValue)}
            sx={{ marginBottom: 2 }}
          />
          <FloatInputField
            label={t("clubFeeEditor.assistantGeduFeeLabel")}
            initialValue={data.assistantGeduFee}
            onChange={(newValue) =>
              handleFeeChange("assistantGeduFee", newValue)
            }
            sx={{ marginBottom: 2 }}
          />
          <FloatInputField
            label={t("clubFeeEditor.municipalityFeeLabel")}
            initialValue={data.municipalityFee}
            onChange={(newValue) =>
              handleFeeChange("municipalityFee", newValue)
            }
            sx={{ marginBottom: 2 }}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            {t("general.close")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!hasChanges || !isCurrencySelected}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClubFeeEditor;
