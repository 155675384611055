import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { API } from "../Hooks/API";
import "../Purchase/purchase.css";
import ContainerBox from "../Container";
import SOGLogo from "../logo.png";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export default function DiscordLink() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [text, setText] = useState(t("discord.connecting"));
  const [showLink, setShowLink] = useState(false);

  function generateRandomString() {
    const array = new Uint32Array(4);

    crypto.getRandomValues(array);

    const string = array.join("");

    return string;
  }

  useEffect(() => {
    handleLinking();
  }, []);

  const handleLinking = async () => {
    const code = searchParams.get("code");
    const result = searchParams.get("result");
    const searchParamError = searchParams.get("error");

    if (result === "success") {
      setText(t("discord.success"));
      setShowLink(true);
      return console.log("Success");
    }

    if (result === "failure") {
      setText(t("discord.failure"));
      return console.log("Failure");
    }

    if (result === "clickjack") {
      setText(t("discord.clickjack"));
      return console.log("You may have been clickjacked");
    }

    if (searchParamError === "access_denied") {
      setText(t("discord.authorizationCancelled"));
      return console.log("Authorization canceled at Discord end");
    }

    if (!code) {
      try {
        sessionStorage.clear();

        const token = searchParams.get("token");

        if (!token) {
          setText(t("discord.missingToken"));
          return console.error("Missing token");
        }

        const res = await axios.post(`${API}/discord/url`, { token });

        const state = generateRandomString();

        if (!state) {
          setText(t("discord.noStateError"));
          return console.error("State generation failed");
        }

        sessionStorage.setItem("oauth_state", state);
        sessionStorage.setItem("oauth_token", token);

        window.location.href = `${res.data.data.url}&state=${state}`;
      } catch (error) {
        if (error.response?.data?.code === "INVALID_TOKEN") {
          setText(t("discord.invalidToken"));
        } else if (error.response?.data?.code === "EXPIRED_TOKEN") {
          setText(t("discord.tokenExpired"));
        } else {
          setText(t("discord.unknownError"));
        }
        console.error(error);
      }
    }

    if (code) {
      try {
        const token = sessionStorage.getItem("oauth_token");
        const state = sessionStorage.getItem("oauth_state");

        if (!state || state !== searchParams.get("state")) {
          setSearchParams({ result: "failure" }, { replace: true });
          setText(t("discord.failure"));
          return console.error("You may have been clickjacked");
        }

        if (!token) {
          setText(t("discord.missingToken"));
          return console.error("Missing token");
        }

        await axios.post(`${API}/discord/link`, { token, code });

        sessionStorage.clear();

        setSearchParams({ result: "success" }, { replace: true });

        setText(t("discord.discordConnectSuccess"));
      } catch (error) {
        setText(t("discord.unknownError"));
        console.error(error);
      }
    }
  };

  return (
    <>
      <ContainerBox className="slot-page">
        <div className="slot-page">
          <img src={SOGLogo} alt="logo" className="logo-slot" />
          <div className="purchase-page">
            <h3>{text}</h3>
          </div>
          {showLink && (
            <Box>
              <a href="https://discord.gg/vzR3Vxedkj" target="_blank">
                {t("discord.successlinkText")}
              </a>
            </Box>
          )}
        </div>
      </ContainerBox>
    </>
  );
}
