import i18n from "../i18n";

export const prepareClubName = (club, t) => {
  let clubFullName = "";
  if ("CLUB_TYPE_MUNICIPALITY" === club.type?.name) {
    if (club.municipality) clubFullName += club.municipality + "-";
    if (club.school) clubFullName += club.school + "-";

    clubFullName += club.name;
  } else {
    if (t && i18n.exists("clubs." + club.sku)) {
      clubFullName = t("clubs." + club.sku);
    } else {
      clubFullName = club.name;
    }
  }
  return clubFullName;
};

export const prepareClubMeetingTimes = (club, t, short = true) => {
  const clubMeetings = [];
  for (const meeting of club.meetings) {
    let startTime = "";
    let endTime = "";
    let clubTime = "-";

    let weekday = "";

    if (meeting) {
      weekday = short
        ? t("weekdayShort." + meeting.weekday)
        : t("weekdays." + meeting.weekday);

      if (meeting.startTime) {
        const split = ("" + meeting.startTime).split(":");
        if (split.length > 1) startTime = split[0] + ":" + split[1];
        else startTime = meeting.startTime;
        clubTime = startTime;
      }

      if (meeting.endTime) {
        const split = meeting.endTime.split(":");
        if (split.length > 1) endTime = split[0] + ":" + split[1];
        else endTime = meeting.endTime;
        clubTime += "-" + endTime;
      }

      clubMeetings.push(weekday + " " + clubTime);
    }

    return clubMeetings;
  }
};

export const prepareTime = (time) => {
  let returnValue = undefined;
  if (time) {
    const splitted = time.split(":");
    if (splitted.length > 1) returnValue = splitted[0] + ":" + splitted[1];
    else returnValue = time;
  }
  return returnValue;
};

export const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const isDate = (value) => value instanceof Date;

export const formatDate = (date, format = "YYYY-MM-DD hh:mm") => {
  if (!date) return undefined;

  if (!isDate(date)) date = new Date(date);

  const pad2 = (n) => n.toString().padStart(2, "0");

  const map = {
    YYYY: date.getFullYear(),
    MM: pad2(date.getMonth() + 1),
    DD: pad2(date.getDate()),
    hh: pad2(date.getHours()),
    mm: pad2(date.getMinutes()),
    ss: pad2(date.getSeconds()),
  };

  return Object.entries(map).reduce(
    (prev, entry) => prev.replace(...entry),
    format
  );
};

export const prepareGroupName = (group, t, log = false) => {
  if (log) console.log(group);

  switch (group.type?.name) {
    case "GROUP_TYPE_CANCELLED":
      return t("clubView.cancelledGroupName");
    case "GROUP_TYPE_WAITING_LIST":
      return t("clubView.waitingListGroupName");
    case "GROUP_TYPE_DEFAULT":
      return t("clubView.defaultGroupName") + " " + group.name;
    default:
      return t("clubView.group") + " " + group.name;
  }
};
