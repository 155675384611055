import axios from "axios";
import { API } from "./API";

export const getSchools = async () => {
  const { data } = await axios.get(`${API}/schools`, { withCredentials: true });
  return data.data;
};

export const createSchool = async (body) => {
  const { data } = await axios.post(`${API}/schools/create`, body, {
    withCredentials: true,
  });
  return data;
};
export const editSchool = async (body) => {
  const { data } = await axios.put(`${API}/schools/${body.id}`, body, {
    withCredentials: true,
  });
  return data;
};

export const deleteSchool = async (id) => {
  const { data } = await axios.delete(`${API}/schools/${id}`, {
    withCredentials: true,
  });
  return data;
};

export const getSchoolsForMuni = async (params) => {
  let id = params && params.queryKey ? params.queryKey[1] : params;
  if (!id) return;

  if (!id) return;

  const { data } = await axios.get(`${API}/schools/getschoolsformuni/${id}`, {
    withCredentials: true,
  });
  return data.data;
};
