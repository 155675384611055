import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";

//get hooks, hooks call api
import { getAllActivities, deleteActivity, editActivity, getActivity } from "../../Hooks/activities";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";

//add Activities
import AddActivity from "./addActivity";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, MenuItem, TextField, Select } from "@mui/material";

import { useTranslation } from "react-i18next";

export default function ActivityList() {
  const { t } = useTranslation();

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);


  const columns = [
    //{ field: 'id', headerName: 'ID', flex:1,   },
    {
      field: "name",
      headerName: t("admin.activities.tableHeader_Name"),
      flex: 1,
      editable: true,
    },
    {
      field: "description",
      headerName: t("admin.activities.tableHeader_Description"),
      flex: 1,
      editable: true,
    },
    {
      field: "image",
      headerName: t("admin.activities.tableHeader_Image"),
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src={params.value}
            alt="Activity"
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          />
        </div>
      ),
    }
  ];
  const { data, error, isLoading, refetch } = useQuery(["activities"], getAllActivities);
  const { mutate } = useMutation(deleteActivity);
  const { mutate: editActivityInfo } = useMutation(editActivity);


  useEffect(() => {
    if (data && data.activities) {
      let newData = [];
      console.log(data);
      data.activities.forEach((e) => {
        newData.push({
          id: e.id,
          description: e.description,
          name: e.name,
          image: e.image,
        });
      });
      console.log(newData);
      setRows(newData);
    }
  }, [data]);



  const processRowUpdate = async (params) => {
    const updatedRows = rows.map((row) =>
      row.id === params.id ? { ...row, [params.field]: params.value } : row
    );

    try {
      await editActivity(params.id, {
        [params.field]: params.value,
      });

      setRows(updatedRows);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };



  const handleNewActivity = () => {
    refetch();
  };

  const deleteRows = () => {
    selectionModel.forEach((selectedRowId) => {
      const activity = rows.find((row) => row.id === selectedRowId);
      console.log(activity);
      if (activity) {
        mutate(activity.id, {
          onSuccess: () => {
          },
        });
      }
      setRows((prevRows) => prevRows.filter((row) => row.id !== activity.id));
    });

  };

  return (
    <ContainerBox>
      <div style={{ width: "100%" }}>
        {(error) && (<ErrorAlert txt={error} />)}
        {isLoading && <CircularProgress />}
        {
          <AddActivity
            handleNewActivity={handleNewActivity}
            DeleteButton={
              <Button
                variant="contained"
                onClick={deleteRows}
                color="secondary"
                startIcon={<DeleteForeverIcon />}
                disabled={disableBtn}
                sx={{ height: 40 }}
              >
                Delete Rows
              </Button>
            }
          />
        }
        {/* <TextField
          label="Search by Name"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          sx={{ mb: 2 }}
        /> */}
        {rows.length > 0 && (
          <>
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              onCellEditCommit={processRowUpdate}
            />
          </>
        )}
      </div>
    </ContainerBox>
  );
}

