import React, { useEffect, useState } from "react";
import {
  TextField,
  Alert,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { TranslatedDatePicker } from "../Util/translatedDatePicker";

const ParticipantInfo = ({ onParticipantInfoSet, participant }) => {
  const { t } = useTranslation();
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [childFirstName, setChildFirstName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [childBirthDate, setChildBirthDate] = useState(null);
  const [consentChecked, setConsentChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);
  const [participantInfo, setParticipantInfo] = useState(false);

  const emailRegex = /^\S+@\S+\.\S+$/;
  const phoneRegex = /^\+\d{1,3}\s\d{1,14}$/;

  useEffect(() => {
    console.log(participant);

    if (participant) {
      if (participant.parentFirstName)
        setParentFirstName(participant.parentFirstName);
      if (participant.parentLastName)
        setParentLastName(participant.parentLastName);
      if (participant.parentPhone) setParentPhone(participant.parentPhone);
      if (participant.parentEmail) setParentEmail(participant.parentEmail);
      if (participant.childFirstName)
        setChildFirstName(participant.childFirstName);
      if (participant.childLastName)
        setChildLastName(participant.childLastName);
      if (participant.childBirthDate)
        setChildBirthDate(participant.childBirthDate);
      if (participant.consent) setConsentChecked(participant.consent);

      setParticipantInfo(participant);
    }
  }, []);

  function isValidPhonenumber(value) {
    return /^\+\d{7,}$/.test(value.replace(/[\s()\-\.]|ext/gi, ""));
  }

  const sendParticipantInfo = async (propertyName, value) => {
    participantInfo[propertyName] = value;
    setParticipantInfo({ ...participantInfo });

    onParticipantInfoSet(participantInfo);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (event) => {
    // if (!emailRegex.test(event.target.value)) {
    //   setErrorMessage("Please enter a valid email address.");
    //   return false;
    // }
    setParentEmail(event.target.value);
    setIsEmailTouched(true);

    sendParticipantInfo("parentEmail", event.target.value);
    return true;
  };

  const handlePhoneChange = (event) => {
    // if (!isValidPhonenumber(event.target.value)) {
    //   setErrorMessage(
    //     "Please enter a valid phone number with the country code."
    //   );
    //   return false;
    // }

    setParentPhone(event.target.value);
    setIsPhoneTouched(true);

    sendParticipantInfo("parentPhone", event.target.value);
    return true;
  };

  const handleConsentChange = (event) => {
    setConsentChecked(event.target.checked);
    sendParticipantInfo("consent", event.target.checked);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {errorMessage && (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            {errorMessage}
          </Alert>
        )}
        <Box mb={4}>
          <h3>{t("muniRegister.participant.parentInfoHeader")}</h3>
          <TextField
            label={t("muniRegister.participant.parentFirstName")}
            value={parentFirstName}
            onChange={(e) => {
              setParentFirstName(e.target.value);
              sendParticipantInfo("parentFirstName", e.target.value);
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t("muniRegister.participant.parentLastName")}
            value={parentLastName}
            onChange={(e) => {
              setParentLastName(e.target.value);
              sendParticipantInfo("parentLastName", e.target.value);
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t("muniRegister.participant.parentPhone")}
            value={parentPhone}
            onChange={handlePhoneChange}
            variant="outlined"
            fullWidth
            margin="normal"
            error={isPhoneTouched && !isValidPhonenumber(parentPhone)}
            helperText={
              isPhoneTouched && !isValidPhonenumber(parentPhone)
                ? t("muniRegister.participant.infoValidPhone")
                : ""
            }
            required
          />
          <TextField
            label={t("muniRegister.participant.parentEmail")}
            value={parentEmail}
            onChange={handleEmailChange}
            variant="outlined"
            fullWidth
            margin="normal"
            error={isEmailTouched && !emailRegex.test(parentEmail)}
            helperText={
              isEmailTouched && !emailRegex.test(parentEmail)
                ? t("muniRegister.participant.infoValidEmail")
                : ""
            }
            required
          />
        </Box>
        <Box>
          <h3>{t("muniRegister.participant.childInfoHeader")}</h3>
          <TextField
            label={t("muniRegister.participant.childFirstName")}
            value={childFirstName}
            onChange={(e) => {
              setChildFirstName(e.target.value);
              sendParticipantInfo("childFirstName", e.target.value);
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t("muniRegister.participant.childLastName")}
            value={childLastName}
            onChange={(e) => {
              setChildLastName(e.target.value);
              sendParticipantInfo("childLastName", e.target.value);
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            required
          />
          <TranslatedDatePicker
            label={t("muniRegister.participant.childDateOfBirth")}
            value={childBirthDate}
            onChange={(date) => {
              setChildBirthDate(date);
              sendParticipantInfo("childBirthDate", date);
            }}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={consentChecked}
              onChange={handleConsentChange}
              sx={{ "&.Mui-checked": { color: "#8F00E2" }, color: "#8F00E2" }}
              required
            />
          }
          label={t("muniRegister.participant.consentLabel")}
        />
        <a href="https://sog.gg/privacy-policy/" target="_blank">
          {t("muniRegister.participant.privacyPolicyLinkText")}
        </a>
      </form>
    </div>
  );
};

export default ParticipantInfo;
