import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "./navbar.css";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LogoutIcon from "@mui/icons-material/Logout";
import CodeIcon from "@mui/icons-material/Code";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SecurityIcon from "@mui/icons-material/Security";
import ClubIcon from "@mui/icons-material/AllInclusive";
import MenuIcon from "@mui/icons-material/Menu";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PublicIcon from "@mui/icons-material/Public";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";
import axios from "axios";
import { API } from "../Hooks/API";

// import {ReactComponent as flag_fi} from '../Static/img/fi.svg';
// import {ReactComponent as flag_gb} from '../Static/img/gb.svg';
// import {ReactComponent as flag_es} from '../Static/img/es.svg';

import flag_fi from "../Static/img/fi.svg";
import flag_gb from "../Static/img/gb.svg";
import flag_es from "../Static/img/es.svg";
import { MenuItem } from "@mui/material";
import { setUserRoles } from "../Hooks/users";

export default function Footer(params) {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [openAdmin, setOpenAdmin] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth > 1100 ? 240 : 60);
  const [authValid, setAuthValid] = React.useState(false);
  const [appUserRoles, setAppUserRoles] = React.useState([]);
  const handleClickAdmin = () => {
    setOpenAdmin(!openAdmin);
  };

  useEffect(() => {
    try {
      setAuthValid(params.appAuthInfo.authValid);
      setAppUserRoles(params.appAuthInfo.appUserRoles);
    } catch (error) {
      window.location.href = "../login";
    }

    window.addEventListener(
      "resize",
      function (event) {
        setWidth(window.innerWidth > 1100 ? 240 : 60);
      },
      true
    );
  }, []);

  const isAllowed = (requiredRoles) => {
    if (!requiredRoles) return true;
    if (requiredRoles.length === 0) return true;

    let returnValue = false;

    //    console.log(appUserRoles);

    requiredRoles.map((requiredRole) => {
      // console.log(requiredRole);
      // console.log(appUserRoles.includes(requiredRole));
      if (appUserRoles.includes(requiredRole)) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const openBurger = () => {
    setWidth(240);
    document.getElementById("left-burger").style.display = "inline-block";
    document.getElementById("icon-burger").style.display = "none";
  };
  const closeBurger = () => {
    setWidth(60);
    document.getElementById("left-burger").style.display = "none";
    document.getElementById("icon-burger").style.display = "inline-block";
  };

  const changeLanguage = (event) => {
    console.log(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const languages = {
    en: { nativeName: "English", icon: flag_gb },
    fi: { nativeName: "Suomi", icon: flag_fi },
    es: { nativeName: "España", icon: flag_es },
  };

  const links = [
    {
      txt: "navbar.home",
      url: "/",
      icon: <HomeIcon />,
      collapse: false,
      requiredRoles: [],
    },
    {
      txt: "navbar.clubCalendar",
      url: "/club-calendar",
      icon: <EventNoteIcon />,
      requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
      collapse: false,
    },
    {
      txt: "navbar.activities",
      url: "/activity-generator",
      icon: <SportsScoreIcon />,
      requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
      collapse: false,
    },
    {
      txt: "navbar.reports",
      url: "/report",
      icon: <AssessmentIcon />,
      collapse: false,
      requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
    },
    {
      txt: "navbar.adminTitle",
      url: "",
      icon: <SecurityIcon />,
      collapse: openAdmin ? <ExpandLess /> : <ExpandMore />,
      requiredRoles: ["USER_ROLE_ADMIN"],
      collapseRender: (
        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/achievements")}
            >
              <ListItemIcon>
                <EmojiEventsIcon />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.achievements")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/clubs")}
            >
              <ListItemIcon>
                <ClubIcon />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.clubs")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/users")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.users")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/codedomains")}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.codes")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/municipalities")}
            >
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.municipalities")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/activities")}
            >
              <ListItemIcon>
                <SportsScoreIcon />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.activities")} />
            </ListItemButton>
            {/* 
                    <ListItemButton sx={{ pl: 4 }} onClick={ ()=> window.location.href = "/admin/entities"}>
                        <ListItemIcon>
                            <AccountTreeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Entities" />
                    </ListItemButton>
                    */}
          </List>
        </Collapse>
      ),
    },
    {
      txt: "navbar.logout",
      url: "/logout",
      icon: <LogoutIcon />,
      collapse: false,
      requiredRoles: [],
    },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {links.map(
          (link, index) =>
            isAllowed(link.requiredRoles) && (
              <div key={index}>
                <ListItem key={link.url} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (!link.collapse) {
                        window.location.href = link.url;
                      } else {
                        handleClickAdmin();
                      }
                    }}
                  >
                    <ListItemIcon>{link.icon}</ListItemIcon>

                    {width > 200 && <ListItemText primary={t(link.txt)} />}
                    {width > 200 && link.collapse && link.collapse}
                  </ListItemButton>
                </ListItem>
                {link.collapse && link.collapseRender}
              </div>
            )
        )}
      </List>
      <div style={{ alignItems: "center" }}>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 120, color: "white", borderColor: "white" }}
          size="small"
        >
          <InputLabel id="language-selected-label" sx={{ color: "white" }}>
            {t("navbar.languageSelectorLabel")}
          </InputLabel>
          <Select
            name="language"
            labelId="language-selected-label"
            label="{t('navbar.languageSelectorLabel')}"
            onChange={changeLanguage}
            defaultValue={i18n.resolvedLanguage}
            sx={{ color: "white", borderColor: "white" }}
          >
            {Object.keys(languages).map((lng, index) => (
              <MenuItem key={index} value={lng}>
                {languages[lng].nativeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );

  return (
    <>
      {/* <CssBaseline /> */}
      {/* BOTTOM NAV  */}
      <div id="bottom-nav">
        <Paper elevation={3} component="footer">
          <BottomNavigation
            className="bottom-nav-colors"
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              className="bottom-nav-colors"
              label={t("navbar.home")}
              icon={<HomeIcon />}
              onClick={() => {
                window.location.href = "/";
              }}
            />
            <BottomNavigationAction
              className="bottom-nav-colors"
              label={t("navbar.clubCalendar")}
              icon={<EventNoteIcon />}
              onClick={() => {
                window.location.href = "/club-calendar";
              }}
            />
            <BottomNavigationAction
              className="bottom-nav-colors"
              label={t("navbar.geduInvoicing")}
              icon={<CurrencyExchangeIcon />}
              onClick={() => {
                window.location.href = "/geduinvoicing";
              }}
            />
            <BottomNavigationAction
              className="bottom-nav-colors"
              label={t("navbar.logout")}
              icon={<LogoutIcon />}
              onClick={() => {
                window.location.href = "/logout";
              }}
            />
          </BottomNavigation>
        </Paper>
      </div>
    </>
  );
}
