import { useState } from "react";
import { useQuery } from "react-query";
import {
  Container,
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import OpenIcon from "@mui/icons-material/OpenInNew";
import Select from "react-select";

import { clubSearchDb } from "../Hooks/search"; // Adjust the path accordingly
import "../App.css";
import { useTranslation } from "react-i18next";
import { getCodeDomainsByNames } from "../Hooks/codes";
import { getGedus } from "../Hooks/gedus";
import { getAllMunicipalities } from "../Hooks/municipality";
import { prepareClubName } from "../Util/clubHelper";
import ClubStatus from "../Club-Calendar/clubStatus";

const ClubSearch = () => {
  const { t } = useTranslation();

  const [selectedAttendanceTypes, setSelectedAttendanceTypes] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState([]);
  const [selectedMeetingDays, setSelectedMeetingDays] = useState([]);
  const [selectedClubTypes, setSelectedClubTypes] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedGedus, setSelectedGedus] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [activeSearch, setActiveSearch] = useState(undefined);

  const { data: gedusData } = useQuery({
    queryKey: ["gedus"],
    queryFn: getGedus,
  });

  const { data: municipalitiesData } = useQuery({
    queryKey: ["municipalities"],
    queryFn: getAllMunicipalities,
  });

  const { data: codeData } = useQuery(["clubTypeCodes"], () => getCodeDomainsByNames([
    // keep in alphabetical order
    "CLUB_ATTANDANCE_TYPE",
    "CLUB_CONTENT_TYPE",
    "CLUB_LANGUAGE",
    "CLUB_STATUS",
    "CLUB_TYPE",
  ]));

  if (codeData) {
    codeData.sort((a, b) => a.name.localeCompare(b.name));
  }

  let attendanceTypes = codeData ? codeData[0].codes : [];
  let clubContentTypes = codeData ? codeData[1].codes : [];
  let languages = codeData ? codeData[2].codes : [];
  let statuses = codeData ? codeData[3].codes : [];
  let clubTypes = codeData ? codeData[4].codes : [];

  let gedus = gedusData ?? [];
  let municipalities = municipalitiesData?.municipalities ?? [];

  attendanceTypes.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));
  clubContentTypes.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));
  languages.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));
  statuses.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));
  clubTypes.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));

  gedus.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
  municipalities.sort((a, b) => a.name.localeCompare(b.name));

  const weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const processData = (data) => {
    const attendanceMap = new Map(attendanceTypes.map((c) => [c.id, "code." + c.name]));
    const languageMap = new Map(languages.map((c) => [c.id, "code." + c.name]));
    const clubTypeMap = new Map(clubTypes.map((c) => [c.id, "code." + c.name]));
    const clubContentMap = new Map(clubContentTypes.map((c) => [c.id, "code." + c.name]));
    const muniMap = new Map(municipalities.map((muni) => [muni.id, muni.name]));
    const statusMap = new Map(statuses.map((c) => [c.id, c.name]));
    const geduMap = new Map(gedus.map((gedu) => [gedu.id, `${gedu.firstName} ${gedu.lastName}`]));

    const clubs = data.map((clubData) => ({
      id: clubData.id,
      sku: clubData.sku,
      name: clubData.name,
      preparedName: prepareClubName(clubData, t),
      status: statusMap.get(clubData.statusId),
      attendance: attendanceMap.get(clubData.attendanceId),
      language: languageMap.get(clubData.language_id),
      clubType: clubTypeMap.get(clubData.typeId),
      content: clubContentMap.get(clubData.contentTypeId),
      municipality: muniMap.get(clubData.municipalityId),
      mainGedu: geduMap.get(clubData.ownerId),
      discordRoleId: clubData.discordRoleId,
      isMuniClub: clubTypeMap.get(clubData.typeId) === "code.CLUB_TYPE_MUNICIPALITY",
    }));

    clubs.sort((a, b) => a.name.localeCompare(b.name));
    return clubs;
  };

  const {
    data: clubsData,
    isLoading,
    error,
  } = useQuery(
    ["clubSearch", activeSearch],
    () => clubSearchDb(activeSearch).then(processData),
    { enabled: activeSearch !== undefined }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    let filters = {};
    if (selectedAttendanceTypes.length > 0)
      filters.attendanceTypes = selectedAttendanceTypes.map((c) => c.value);
    if (selectedClubTypes.length > 0)
      filters.clubTypes = selectedClubTypes.map((club) => club.value);
    if (selectedContentTypes.length > 0)
      filters.contentTypes = selectedContentTypes.map((c) => c.value);
    if (selectedGedus.length > 0)
      filters.gedus = selectedGedus.map((gedu) => gedu.value);
    if (selectedLanguages.length > 0)
      filters.languages = selectedLanguages.map((c) => c.value);
    if (selectedMunicipalities.length > 0)
      filters.municipalities = selectedMunicipalities.map((muni) => muni.value);
    if (selectedStatuses.length > 0)
      filters.statuses = selectedStatuses.map((c) => c.value);
    if (selectedMeetingDays.length > 0)
      filters.meetingDays = selectedMeetingDays.map((day) => day.value);

    setActiveSearch({ query: searchQuery, filters });
  };

  return (
    <Container maxWidth="lg">
      {/* <BetaNotification /> */}
      <Typography variant="h4" gutterBottom>
        {t("clubSearch.title")}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
      >
        <form onSubmit={handleSubmit}>
          <Stack
            direction="row"
            alignItems="baseline"
            gap={1}
            maxWidth="md"
          >
            <TextField
              label={t("clubSearch.searchLabel")}
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginBottom: 2 }}
              helperText={t("clubSearch.searchHelpText")}
            />
            <Button variant="contained" type="submit">
              {t("clubSearch.searchButtonText")}
            </Button>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        useFlexGap
        spacing={1}
        flexWrap="wrap"
        justifyContent="center"
      >
        <Select
          options={statuses.map((status) => ({ value: status.id, label: t("code." + status.name) }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterStatusPlaceholder")}
          value={selectedStatuses}
          onChange={setSelectedStatuses}
          isSearchable={true}
          isMulti
        />
        <Select
          options={clubTypes.map((clubType) => ({ value: clubType.id, label: t("code." + clubType.name) }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterClubTypePlaceholder")}
          value={selectedClubTypes}
          onChange={setSelectedClubTypes}
          isSearchable={true}
          isMulti
        />
        <Select
          options={clubContentTypes.map((contentType) => ({ value: contentType.id, label: t("code." + contentType.name) }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterContentTypePlaceholder")}
          value={selectedContentTypes}
          onChange={setSelectedContentTypes}
          isSearchable={true}
          isMulti
        />
        <Select
          options={attendanceTypes.map((attendanceType) => ({ value: attendanceType.id, label: t("code." + attendanceType.name) }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterAttendanceTypePlaceholder")}
          value={selectedAttendanceTypes}
          onChange={setSelectedAttendanceTypes}
          isSearchable={true}
          isMulti
        />
        <Select
          options={gedus.map((gedu) => ({ value: gedu.id, label: gedu.firstName + " " + gedu.lastName }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterMainGeduPlaceholder")}
          value={selectedGedus}
          onChange={setSelectedGedus}
          isSearchable={true}
          isMulti
        />
        <Select
          options={municipalities.map((muni) => ({ value: muni.id, label: muni.name }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterMuniPlaceholder")}
          value={selectedMunicipalities}
          onChange={setSelectedMunicipalities}
          isSearchable={true}
          isMulti
        />
        <Select
          options={languages.map((lang) => ({ value: lang.id, label: t("code." + lang.name) }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterLanguagePlaceholder")}
          value={selectedLanguages}
          onChange={setSelectedLanguages}
          isSearchable={true}
          isMulti
        />
        <Select
          options={weekdays.map((day) => ({ value: day, label: t("weekday." + day) }))}
          className="basic-multi-select"
          placeholder={t("clubSearch.filterMeetingDaysPlaceholder")}
          value={selectedMeetingDays}
          onChange={setSelectedMeetingDays}
          isSearchable={true}
          isMulti
        />
      </Stack>
      {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
      {error && <Typography color="error">{error.message}</Typography>}
      {clubsData && (
        <>
          <Typography textAlign="left">
            {clubsData.length} {clubsData.length === 1
              ? t("clubSearch.numResult")
              : t("clubSearch.numResults")}
          </Typography>
          {clubsData.map((club) => (
            <ClubInfoCard key={club.id} club={club} t={t} />
          ))}
        </>
      )}
    </Container>
  );
};

const ClubInfoCard = ({ club, t }) => (
  <Card sx={{ marginTop: 2 }}>
    <CardContent>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          gap="1em"
        >
          <ClubStatus status={club.status} />
          <a href={`./club/?id=${club.id}`} target="_blank" rel="noreferrer">
            <IconButton><OpenIcon className="action-icon" /></IconButton>
          </a>
        </Stack>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Typography variant="h5">{club.name}{club.name !== club.preparedName ? ` [${club.preparedName}]` : ""}</Typography>
          <Typography variant="body1">{t("clubData.clubId")}: {club.id}</Typography>
          <Typography variant="body1">{t("clubData.sku")}: {club.sku}</Typography>
          <Typography variant="body1">{t("clubData.type")}: {t(club.clubType)}</Typography>
          <Typography variant="body1">{t("clubData.attendance")}: {t(club.attendance)}</Typography>
          <Typography variant="body1">{t("clubData.contentType")}: {t(club.content)}</Typography>
          <Typography variant="body1">{t("clubData.language")}: {t(club.language)}</Typography>
          <Typography variant="body1">{t("clubData.ownerHeader")}: {club.mainGedu}</Typography>
          <Typography variant="body1">{t("clubData.discordRoleName")}: {club.discordRoleId}</Typography>
          {club.isMuniClub && <Typography variant="body1">{t("clubData.municipality")}: {club.municipality}</Typography>}
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default ClubSearch;
