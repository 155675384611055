import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useQuery } from "react-query";

import { getCodeDomainsByNames } from "../Hooks/codes";

const ClubTypeEditor = ({ data, onSave }) => {
  const { t } = useTranslation();

  const [editedData, setEditedData] = useState(data);

  const [isOpen, setIsOpen] = useState(false);

  const { data: codeData } = useQuery(["clubTypeCodes"], () => getCodeDomainsByNames([
    "CLUB_ATTANDANCE_TYPE",
    "CLUB_CONTENT_TYPE",
    "CLUB_TYPE",
  ]));

  if (codeData) {
    codeData.sort((a, b) => a.name.localeCompare(b.name));
  }

  let attendanceTypes = codeData ? codeData[0].codes : [];
  let clubContentTypes = codeData ? codeData[1].codes : [];
  let clubTypes = codeData ? codeData[2].codes : [];

  attendanceTypes.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));
  clubTypes.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));
  clubContentTypes.sort((a, b) => t("code." + a.name).localeCompare(t("code." + b.name)));

  const hasChanges = Object.keys(data).some(
    (key) => editedData[key]?.id !== data[key]?.id
  );

  const handleSave = () => {
    onSave(editedData);
    setIsOpen(false);
  };

  const handleClose = () => {
    setEditedData(data);
    setIsOpen(false);
  };

  const handleAttendanceTypeChange = (e) => {
    setEditedData({
      ...editedData,
      attendance: attendanceTypes.find((attendanceType) => attendanceType.id === e.target.value),
    });
  };

  const handleClubTypeChange = (e) => {
    setEditedData({
      ...editedData,
      clubType: clubTypes.find((clubType) => clubType.id === e.target.value),
    });
  };

  const handleContentTypeChange = (e) => {
    setEditedData({
      ...editedData,
      contentType: clubContentTypes.find((contentType) => contentType.id === e.target.value),
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("clubData.clubTypeEditor")}
      </Button>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>{t("clubData.clubTypeEditor")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>{t("clubData.attendance")}</InputLabel>
            <Select
              value={editedData?.attendance?.id ?? ""}
              onChange={handleAttendanceTypeChange}
              label={t("clubData.attendance")}
            >
              {attendanceTypes.map((attendanceType) => (
                <MenuItem key={attendanceType.id} value={attendanceType.id}>
                  {t("code." + attendanceType.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>{t("clubData.type")}</InputLabel>
            <Select
              value={editedData?.clubType?.id ?? ""}
              onChange={handleClubTypeChange}
              label={t("clubData.type")}
            >
              {clubTypes.map((clubType) => (
                <MenuItem key={clubType.id} value={clubType.id}>
                  {t("code." + clubType.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>{t("clubData.contentType")}</InputLabel>
            <Select
              value={editedData?.contentType?.id ?? ""}
              onChange={handleContentTypeChange}
              label={t("clubData.contentType")}
            >
              {clubContentTypes.map((contentType) => (
                <MenuItem key={contentType.id} value={contentType.id}>
                  {t("code." + contentType.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("general.close")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!hasChanges}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClubTypeEditor;
