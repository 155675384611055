import React from "react";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import dayjs from "dayjs";
import {
  Stack,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  TextField,
  MenuItem,
} from "@mui/material";

import ApprovalIcon from "@mui/icons-material/Approval";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import OpenIcon from "@mui/icons-material/OpenInNew";

import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../errorAlert";

import {
  getGeduInvoicingLinesByPeriod,
  getGeduInvoicingLineWithoutPeriod,
  setPeriodStatusFoo,
  setPeriodToLines,
} from "../Hooks/report";

import { prepareClubName, formatDate, prepareTime } from "../Util/clubHelper";
import { isAdmin, isGedu } from "../Util/authHelper";
import InvoicingStatus from "../Util/InvoicingStatus";

import { useTranslation } from "react-i18next";

const InvoicingLinesNoPeriod = ({
  appUserRoles,
  geduId,
  refetchInvoicingPeriods,
  hasInvoicingLines,
}) => {
  const { t } = useTranslation();

  const [isSavingMovePeriod, setIsSavingMovePeriod] = useState(false);
  const [savingStatusError, setSavingStatusError] = useState("");
  const [invoicingLines, setInvoicingLines] = useState([]);
  const [totalInvoicing, setTotalInvoicing] = useState({});
  const [period, setPeriod] = useState({});
  const [periodStatus, setPeriodStatus] = useState("");
  //const [geduId, setGeduId] = useState(geduId ? geduId : "");

  //const [appUserRoles, setAppUserRoles] = useState(undefined);

  // invocing period selector dialog
  const [isInvoicingPeriodDialogOpen, setIsInvoicingPeriodDialogOpen] =
    useState(false);

  const [openIncoivingPeriods, setOpenIncoivingPeriods] = useState([]);

  const [selectedInvoicingPeriod, setSelectedInvoicingPeriod] = useState("");
  const [filteredPeriods, setFilteredPeriods] = useState([]);

  const {
    data: invoicingLinesData,
    error: invoicingLinesError,
    isLoading: invoicingLinesLoading,
    refetch,
  } = useQuery(
    ["invoicingLines", geduId],
    () => getGeduInvoicingLineWithoutPeriod(geduId),
    {
      enabled: !!geduId, // Only enable this query when periodId is truthy
    }
  );

  useEffect(() => {
    if (invoicingLinesData) {
      prepareInvoicingLines();
    }
  }, [invoicingLinesData]);

  const prepareInvoicingLines = () => {
    // console.log("prepareInvoicingLinesNoPeriod");
    // console.log(invoicingLinesData);
    const lines = [];
    const totalAmount = {};
    invoicingLinesData.invoicingLines.map((line) => {
      const clubName = prepareClubName(line.club, t);

      const meetingsData = [];
      if (line.club.meetings) {
        line.club.meetings.map((meeting, i) => {
          const meetingString =
            t("weekdayShort." + meeting.weekday) +
            " " +
            prepareTime(meeting.startTime) +
            "-" +
            prepareTime(meeting.endTime);
          meetingsData.push(meetingString);
        });
      }

      // create a single meetings string from the array
      const meetings = meetingsData.join(", ");

      const date = new Date(line.timestamp);

      const timestamp = formatDate(date, "DD.MM.YYYY hh:mm");

      const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      const weekday = weekdays[date.getDay()];
      const localizedWeekday = t("weekdayShort." + weekday);

      let hasPriceError = false;
      let amount = undefined;
      if (line.amount !== null && !isNaN(line.amount)) {
        // It's already a number, no need to parse
        amount = parseFloat(line.amount).toFixed(2);
      } else {
        hasPriceError = true;
      }

      let currency = line.currency?.name;

      if (!hasPriceError) {
        const previousAmount = totalAmount[currency];
        if (previousAmount) {
          // parse to float
          const previousAmountFloat = parseFloat(previousAmount);
          totalAmount[currency] = previousAmountFloat + parseFloat(amount);
        } else {
          totalAmount[currency] = parseFloat(amount);
        }
      }

      lines.push({
        id: line.id,
        timestamp: timestamp,
        weekday: localizedWeekday,
        amount: amount,
        currency: currency,
        substitute: line.substitute,
        club: {
          name: clubName,
          type: line.club.type.name,
          meetings: meetings,
        },
        status: line.status.name,
        type: line.type.name,
        lesson: line.lesson,
        hasPriceError: hasPriceError,
        date: date, // for sorting
      });
    });

    // order lines by timestamp ASC
    lines.sort((a, b) => {
      return a.date - b.date;
    });

    // add total invoicing
    setTotalInvoicing(totalAmount);

    // set open invoicing periods
    if (invoicingLinesData.openPeriods) {
      // console.log("openPeriods");
      // console.log(invoicingLinesData.openPeriods);

      if (invoicingLinesData.openPeriods.length > 0) {
        const orderedPeriods = invoicingLinesData.openPeriods.sort((a, b) =>
          dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1
        );
        setOpenIncoivingPeriods(orderedPeriods);
      }
    }
    // console.log(lines);

    setInvoicingLines(lines);

    hasInvoicingLines(lines.length > 0);
  };

  const handleMoveToInvoicingPeriod = async () => {
    setIsSavingMovePeriod(true);
    console.log("MOVE TO");
    console.log(selectedInvoicingPeriod);

    const body = {
      periodId: selectedInvoicingPeriod,
      invoicingLineIds: invoicingLines.map((line) => line.id),
    };

    try {
      const response = await setPeriodToLines(body);
      console.log(response);
      console.log("REFETCH>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      setSelectedInvoicingPeriod(""); // clear the selection
      refetch(); // refetch myself
      // refetch parent data

      refetchInvoicingPeriods().then((result) => {
        // Handle the refetch result if needed
        console.log("Refetch result:", result);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSavingMovePeriod(false);
    }
  };

  const handleSelectPeriod = (event) => {
    setSelectedInvoicingPeriod(event.target.value);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = openIncoivingPeriods.filter((period) =>
      period.startDate.toLowerCase().includes(inputValue)
    );
    setFilteredPeriods(filtered);
  };

  return (
    <div>
      {invoicingLinesLoading && <CircularProgress />}
      {invoicingLinesError && <ErrorAlert txt={invoicingLinesError} />}
      {!invoicingLinesLoading && geduId && (
        <>
          <List key={"invoicingLines" + geduId}>
            {/* <List subheader={<ListSubheader>{t("Header Text")}</ListSubheader>}> */}
            {invoicingLines.map((line) => (
              <React.Fragment key={line.id}>
                <ListItem key={"line" + line.id}>
                  <ListItemText
                    primary={`${line.weekday} ${line.timestamp} ${
                      isAdmin(appUserRoles) ? " [" + line.id + "]" : ""
                    }`}
                    secondary={`${
                      line.substitute ? t("invoicingLines.substitute") : ""
                    }`}
                  />
                  {!line.hasPriceError && (
                    <ListItemText
                      primary={`${line.amount} ${t("code." + line.currency)}`}
                    />
                  )}
                  {line.hasPriceError && (
                    <>
                      <ErrorIcon sx={{ mr: 1, color: "#F55B9A" }} />
                      <ListItemText
                        primary={`${t("invoicingLines.priceError")}`}
                        sx={{ color: "#F55B9A" }}
                      />
                    </>
                  )}
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={`${t("muniReport.openClub")}: ${line.club.name}`}
                    placement="top"
                  >
                    <Button
                      variant=""
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(`/club/?id=${line.club.id}`, "_blank");
                      }}
                      sx={{ ml: 0 }} // Adjust margin as needed
                    >
                      <OpenIcon />
                    </Button>
                  </Tooltip>
                  <ListItemText
                    primary={`${line.club.name}`}
                    secondary={`${t("code." + line.club.type)} - ${
                      line.club.meetings
                    }`}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography>
              {t("invoicingLines.moveLinesToPeriodHeader")}
            </Typography>

            <TextField
              label={t("invoicingLines.openPeriodsLabel")}
              select
              fullWidth
              value={selectedInvoicingPeriod}
              onChange={handleSelectPeriod}
              onInput={handleInputChange}
              sx={{ mt: 2 }}
            >
              {openIncoivingPeriods.map((period) => (
                <MenuItem key={period.id} value={period.id}>
                  {`${formatDate(
                    period.startDate,
                    "DD.MM.YYYY"
                  )} - ${formatDate(period.endDate, "DD.MM.YYYY")}`}
                </MenuItem>
              ))}
            </TextField>
            <Button
              disabled={!selectedInvoicingPeriod}
              variant="contained"
              onClick={handleMoveToInvoicingPeriod}
            >
              {isSavingMovePeriod ? (
                <CircularProgress size={24} /> // Show CircularProgress when isSavingStatus is true
              ) : (
                t("invoicingLines.selectInvoicingPeriodButtonText")
              )}
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};

export default InvoicingLinesNoPeriod;
