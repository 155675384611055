import React, { useEffect, useState } from "react";
import "./club.css";
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

export default function ClubStatus(params) {
  const { t } = useTranslation();
  const minimumWidth = params.minWidth ?? "120px";
  const clubStatus = params.status;

  const statusMappings = {
    // CLUB_STATUS
    CLUB_STATUS_ONGOING: {
      color: "white",
      backgroundColor: "#6AC66B", // green-ish
    },
    CLUB_STATUS_PLANNED: {
      color: "white",
      backgroundColor: "#4DB3F5", // blue-ish
    },
    CLUB_STATUS_ENDED: {
      color: "white",
      backgroundColor: "#F55B9A", // red-ish
    },
    CLUB_STATUS_ONHOLD: {
      color: "white",
      backgroundColor: "#FD700D", // yellow-ish
    },
    CLUB_STATUS_ARCHIVED: {
      color: "white",
      backgroundColor: "#9E9E9E", // grey-ish
    },

    // LESSON_STATUS
    LESSON_STATUS_DONE: {
      color: "white",
      backgroundColor: "#6AC66B", // green-ish
    },
    LESSON_STATUS_CANCELLEDBYMUNICIPALITY: {
      color: "white",
      backgroundColor: "#F55B9A", // red-ish darker
    },
    LESSON_STATUS_CANCELLEDBYSOG: {
      color: "white",
      backgroundColor: "#FD700D", // seconray orange darker
    },
    LESSON_STATUS_CANCELLED: {
      color: "white",
      backgroundColor: "#FA7FA3", // red-ish lighter
    },
    LESSON_STATUS_RESCHEDULED: {
      color: "black",
      backgroundColor: "#EBEBEB", // light grey w black text
    },
    LESSON_STATUS_UNDONE: {
      color: "white",
      backgroundColor: "#9E9E9E", // darker grey
    },
    LESSON_STATUS_HOLIDAY: {
      color: "white",
      backgroundColor: "#8F00E2", // purple
    },
  };

  return (
    <div
      style={{
        display: "inline-flex",
        verticalAlign: "bottom",
        marginTop: "0.8em",
      }}
    >
      {clubStatus && (
        <div style={{ minWidth: minimumWidth }}>
          <Paper
            sx={{
              minWidth: 120,
              verticalAlign: "middle",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 14,
                minHeight: "3.5em",
                paddingTop: "1em",
              }}
              color={
                statusMappings[clubStatus]
                  ? statusMappings[clubStatus].color
                  : "white"
              }
              backgroundColor={
                statusMappings[clubStatus]
                  ? statusMappings[clubStatus].backgroundColor
                  : "#121212"
              }
            >
              {t("code." + clubStatus)}
            </Typography>
          </Paper>
        </div>
      )}
    </div>
  );
}
