import React, { useState, useEffect } from "react";
import { Button, FormControlLabel, Modal, TextField } from "@mui/material";
import { FormGroup } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { setUserRoles } from "../../Hooks/users";

import ErrorAlert from "../../errorAlert";
import { useQuery, useMutation } from "react-query";
import { getCodeDomainsByNames } from "../../Hooks/codes";

import { useTranslation, Trans } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function SetUserRoles({ handleWindowClose, selectedUserData }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(setUserRoles);
  const [rolesString, setRolesString] = useState([]); // for Users list UI
  const [roles, setRoles] = useState([]); // for User roles selection modal

  const codeDomainNames = ["USER_ROLE"];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  useEffect(() => {
    storeUserRoles(selectedUserData.roles);
  }, []);

  const handleOpen = async () => {
    await storeUserRoles(selectedUserData.roles);
    setOpen(true);
  };

  const storeUserRoles = async (roles) => {
    // get shown text
    if (roles) {
      const theRoles = [];
      let count = 0;
      roles.map((role) => {
        if (count == 0) {
          theRoles.push(t("code." + role));
        } else if (count == 1) {
          theRoles.push("...");
        }
        count++;
      });
      setRolesString(theRoles);
    }

    // states for checkboxes
    const roleStatus = [];
    if (dataCodeDomains) {
      dataCodeDomains[0].codes.map((code) => {
        roleStatus.push({
          name: code.name,
          checked: roles && roles.includes(code.name),
        });
      });
    }
    setRoles(roleStatus);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userroles = [];
    roles.map((role) => {
      if (role.checked) {
        userroles.push(role.name);
      }
    });
    // console.log(userroles);
    // console.log(selectedUserData.id);
    mutate(
      {
        id: selectedUserData.id,
        body: userroles,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          selectedUserData.roles = e.roles; // set roles to current user
          handleWindowClose(selectedUserData.roles);
          storeUserRoles(selectedUserData.roles);

          handleClose();
        },
      }
    );
  };

  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={0} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Box mr={1}>{rolesString}</Box>
        <Button
          variant="outlined"
          color="secondary"
          // startIcon={<AddIcon />}
          sx={{ height: 25, m: 0 }}
          onClick={handleOpen}
        >
          ...
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {selectedUserData && (
            <div>
              <h1>
                {selectedUserData.firstName} {selectedUserData.lastName}
              </h1>
              <p>{t("admin.users.setRoles_info")}</p>
              <FormGroup
                id="roleSelector"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {roles.map((role, index) => (
                  <FormControlLabel
                    key={index}
                    display="flex"
                    control={
                      <Checkbox
                        onChange={() => {
                          const newRolesArray = [...roles];
                          newRolesArray[index].checked =
                            !newRolesArray[index].checked;
                          setRoles(newRolesArray);
                        }}
                        display="flex"
                        id={role.name}
                        checked={role.checked ? true : false}
                      ></Checkbox>
                    }
                    label={t("code." + role.name)}
                    sx={{ m: 0, width: "30%" }}
                  />
                ))}
              </FormGroup>

              <Button
                variant="contained"
                color="primary"
                sx={{ left: "35%", marginTop: "1rem" }}
                type="submit"
              >
                {t("admin.users.setRoles_SubmitButtonText")}
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
