import React, {useEffect} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";


export default function Store() {
  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      sogyellow: {
        main: '#faa901',
        contrastText: "#fff"
      },
      sogpurple: {
        main: '#8f00e2',
        contrastText: "#fff"
      },
    },
  });


  const tiers = [
    {
      title: 'Once a week',
      price: '59',
      priceId: 'price_1LL4jvCD5Q5ECgrcflM39Sxi',
      description: [
        '1 club per week',
        'Minecraft clubs',
        'Switch clubs anytime',
        'Cancel anytime',
      ],
      buttonText: 'Order now',
      buttonVariant: 'contained',
      buttonColor: "sogpurple",
    },
    {
      title: 'Twice a week',
      price: '99',
      priceId: 'price_1LL4kJCD5Q5ECgrcEAHrEbdb',
      description: [
        '2 clubs per week',
        'Minecraft and esports clubs',
        'Switch clubs anytime',
        'Cancel anytime',
      ],
      buttonText: 'Order now',
      buttonVariant: 'contained',
      buttonColor: "sogpurple",
    },
    {
      title: 'All inclusive',
      price: '169',
      priceId: 'price_1LL4koCD5Q5ECgrcmverEFCG',
      description: [
        '4 clubs per week',
        'All clubs',
        'Switch clubs anytime',
        'Cancel anytime',
      ],
      buttonText: 'Order now',
      buttonVariant: 'contained',
      buttonColor: "sogpurple",
    },
  ];

  const handleClick = async (e, priceId) => {
      e.preventDefault();

      try {
        const res = await axios.post('http://localhost:8080/api/v2/payments/create-checkout-session', {
          priceId: priceId,
        }, { withCredentials: true });

        const data = res.data.data;

        window.location.href = data.stripeUrl;
      } catch (error) {
        if (error.response.status === 401) return navigate('../logout', { replace: true });
        alert(error.message);
      }
  };

  const manageSubscriptions = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post('http://localhost:8080/api/v2/payments/create-customer-portal-session', {}, { withCredentials: true });

      const data = res.data.data;

      window.location.href = data.stripeUrl;
    } catch (error) {
      if (error.response.status === 401) return navigate('../logout', { replace: true });
      alert(error.message);
    }
};

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Container maxWidth="md" component="main">
        <Button sx={{ mt: 3 }} variant='contained' color='sogpurple' onClick={(e) => manageSubscriptions(e)}>
            Manage subscriptions
        </Button>
        <Grid container spacing={5} alignItems="center" justifyContent="center" style={{ minHeight: '90vh' }}>
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{ backgroundColor: '#faa901'}}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color={tier.buttonColor} onClick={(e) => handleClick(e, tier.priceId)}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}