import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import { getClubs, deleteClub, editClub } from "../../Hooks/clubs";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import AddClubs from "./addClubs";
import AddEditMeetingTimes from "./addEditMeetingTimes";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, MenuItem, TextField, Select } from "@mui/material";
import { getCodeDomainsByNames } from "../../Hooks/codes";
import { TranslatedDatePicker } from "../../Util/translatedDatePicker";

import OpenIcon from "@mui/icons-material/OpenInNew";

import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function ClubList() {
  const { t } = useTranslation();

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isUpdating, setIsUpdating] = React.useState("");
  // const {
  //   data: dataCodes,
  //   error: errorCodes,
  //   isLoading: isLoadingCodes,
  // } = useQuery(["codes"], getCodes);

  // The names of the needed CodeDomains
  const codeDomainNames = [
    "CLUB_TYPE",
    "CLUB_CONTENT_TYPE",
    "CLUB_STATUS",
    "CLUB_ATTANDANCE_TYPE",
    "CLUB_LANGUAGE",
  ];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  const [attandanceTypes, setAttandanceTypes] = useState([]);
  const [statusCodes, setStatus] = useState([]);
  const [clubTypeCodes, setClubTypes] = useState([]);
  const [clubContentTypeCodes, setClubContentTypes] = useState([]);
  const [clubLanguageCodes, setClubLanguageCodes] = useState([]);
  const columns = [
    //{ field: 'id', headerName: 'ID', flex:1,   },
    {
      // field: "link",
      // headerName: t("admin.clubs.tableHeader_Link"),
      flex: 1,
      maxWidth: 50,
      editable: false,
      renderCell: (params) => {
        let idValue = params.row.id;
        return (
          <OpenIcon
            size={20}
            // className="action-icon"
            onClick={() => {
              window.open(`../club/?id=${idValue}`, "_blank").focus();
            }}
            sx={{ ml: 0, color: "#3a71de" }}
          />
        );
      },
    },
    {
      field: "sku",
      headerName: t("admin.clubs.tableHeader_SKU"),
      maxWidth: 150,
      flex: 1,
      editable: true,
    },
    {
      field: "name",
      headerName: t("admin.clubs.tableHeader_Name"),
      flex: 1,
      editable: true,
    },
    {
      field: "status",
      headerName: t("admin.clubs.tableHeader_Status"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        let extra = params.row.status ? params.row.status.id : "";
        return (
          <Select
            id="select-status"
            size="small"
            sx={{ m: 1, width: "100%" }}
            defaultValue={extra}
            onChange={(e) => editStatus(params.row.id, e.target.value)}
          >
            {statusCodes.length > 0 &&
              statusCodes.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    {
      field: "clubtype",
      headerName: t("admin.clubs.tableHeader_clubtype"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        let extra = params.row.type ? params.row.type.id : "";
        return (
          <Select
            id="select-status"
            size="small"
            sx={{ m: 1, width: "100%" }}
            defaultValue={extra}
            onChange={(e) => editClubType(params.row.id, e.target.value)}
          >
            {clubTypeCodes.length > 0 &&
              clubTypeCodes.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    {
      field: "clubcontenttype",
      headerName: t("admin.clubs.tableHeader_clubcontenttype"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        let extra = params.row.contentType ? params.row.contentType.id : "";
        return (
          <Select
            id="contenttype-select-status"
            size="small"
            sx={{ m: 1, width: "100%" }}
            defaultValue={extra}
            onChange={(e) => editClubContentType(params.row.id, e.target.value)}
          >
            {clubContentTypeCodes.length > 0 &&
              clubContentTypeCodes.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    {
      field: "attendance",
      headerName: t("admin.clubs.tableHeader_Attendance"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        let extra = params.row.attendance ? params.row.attendance.id : "";
        return (
          <TextField
            id="select-attendance"
            select
            size="small"
            sx={{ m: 1, width: "100%" }}
            onChange={(e) => editAttendance(params.row.id, e.target.value)}
            defaultValue={extra}
          >
            {attandanceTypes.length > 0 &&
              attandanceTypes.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}{" "}
                </MenuItem>
              ))}
          </TextField>
        );
      },
    },
    {
      field: "languageCode",
      headerName: t("admin.clubs.tableHeader_Language"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        let extra = params.row.languageCode ? params.row.languageCode.id : "";
        return (
          <Select
            id="clublanguage-select-status"
            size="small"
            sx={{ m: 1, width: "100%" }}
            defaultValue={extra}
            onChange={(e) => editClubLanguage(params.row.id, e.target.value)}
          >
            {clubLanguageCodes.length > 0 &&
              clubLanguageCodes.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    // {
    //   field: "language",
    //   headerName: t("admin.clubs.tableHeader_Language"),
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "meetings",
      headerName: t("admin.clubs.tableHeader_MeetingTimes"),
      minWidth: 250,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <AddEditMeetingTimes
            handleWindowClose={doRefresh}
            selectedClubData={params.row}
          />
        );
      },
      valueGetter: (params) => params.row.meetings,
    },
    {
      field: "registration",
      headerName: t("admin.clubs.tableHeader_RegistrationEnabled"),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const updatesThis = params.row.id + "registration" == isUpdating;
        return (
          <div>
            {updatesThis && <CircularProgress />}
            {!updatesThis && (
              <Checkbox
                {...label}
                checked={params.row.registration}
                onChange={(e) =>
                  changeField(
                    "registration",
                    params.row.id,
                    !params.row.registration
                  )
                }
              />
            )}
          </div>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start date",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const updatesThis = params.row.id + "startDate" == isUpdating;
        return (
          <div>
            {updatesThis && <CircularProgress />}
            {!updatesThis && (
              <TranslatedDatePicker
                label={t("admin.clubs.tableHeader_StartDate")}
                value={params.row.startDate}
                onChange={(newValue) =>
                  changeField("startDate", params.row.id, newValue)
                }
              />
            )}
          </div>
        );
      },
    },
    // { field: "school", headerName: "School", flex: 1, editable: true },
  ];
  const { data, error, isLoading, refetch } = useQuery(["clubs"], getClubs);
  const { mutate } = useMutation(deleteClub);
  const { mutate: editClubInfo } = useMutation(editClub);

  useEffect(() => {
    if (data) {
      let newData = [];
      data.forEach((e) => {
        const startDate = e.startDate ? new Date(e.startDate) : undefined;
        const endDate = e.endDate ? new Date(e.endDate) : undefined;

        newData.push({
          attendance: e.attendance,
          entity: e.entity,
          id: e.id,
          language: e.language,
          languageCode: e.languageCode,
          meetings: e.meetings,
          name: e.name,
          owner: e.owner,
          sku: e.sku,
          status: e.status,
          type: e.type,
          contentType: e.contentType,
          country: e.country,
          municipality: e.municipality,
          school: e.school,
          registration: e.registration,
          startDate: startDate,
          endDate: endDate,
        });
      });
      console.log(newData);
      setRows(newData);
    }
    //console.log("HERE");
    //if (dataCodes) createCodes();

    if (dataCodeDomains) createCodeDomains();
  }, [data, dataCodeDomains]);

  const editAttendance = (id, value) => {
    editClubInfo(
      { id: id, field: "attendance", value: value },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };
  const editMeetingTime = (id, value) => {
    editClubInfo(
      { id: id, field: "clubMeetingTime", value: value },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };
  const editStatus = (id, value) => {
    editClubInfo(
      {
        id: id,
        field: "status",
        value: value,
      },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };
  const editClubType = (id, value) => {
    editClubInfo(
      { id: id, field: "type", value: value },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };

  const editClubContentType = (id, value) => {
    editClubInfo(
      { id: id, field: "contentType", value: value },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };

  const editClubLanguage = (id, value) => {
    editClubInfo(
      { id: id, field: "languageCode", value: value },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };

  const changeField = (fieldName, id, value) => {
    console.log(id + fieldName);
    setIsUpdating(id + fieldName);

    const sentData = { id: id, field: fieldName, value: value };

    console.log(sentData);

    editClubInfo(sentData, {
      onSuccess: (e) => {
        console.log(e);

        const rowsCopy = [...rows];
        var updatedRow = rowsCopy.filter(function (el) {
          return el.id === id;
        });
        if (updatedRow && updatedRow.length > 0) {
          const therow = updatedRow[0];
          console.log(therow);

          if (fieldName == "startDate" || fieldName == "endDate") {
            therow[fieldName] = new Date(e.data.club[fieldName]);
          } else {
            therow[fieldName] = e.data.club[fieldName]; // this needs to be updated based on item type
          }
        }
        console.log(rowsCopy);

        setRows(rowsCopy);
        setIsUpdating("");
      },
    });
  };

  const createCodeDomains = () => {
    //console.log(dataCodeDomains);

    let attandanceTypeCodes = [];
    let statusCodes = [];
    let clubTypeCodes = [];
    let clubContentTypeCodes = [];
    const clubLanguageCodes = [];

    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name == "CLUB_STATUS") {
        codeDomain.codes.forEach((code) => {
          statusCodes.push({
            value: code.id,
            label: t("code." + code.name),
          });
        });
      } else if (codeDomain.name == "CLUB_ATTANDANCE_TYPE") {
        codeDomain.codes.forEach((code) => {
          attandanceTypeCodes.push({
            value: code.id,
            label: t("code." + code.name),
          });
        });
      } else if (codeDomain.name == "CLUB_TYPE") {
        codeDomain.codes.forEach((code) => {
          clubTypeCodes.push({
            value: code.id,
            label: t("code." + code.name),
          });
        });
      } else if (codeDomain.name == "CLUB_CONTENT_TYPE") {
        codeDomain.codes.forEach((code) => {
          clubContentTypeCodes.push({
            value: code.id,
            label: t("code." + code.name),
          });
        });
      } else if (codeDomain.name == "CLUB_LANGUAGE") {
        codeDomain.codes.forEach((code) => {
          clubLanguageCodes.push({
            value: code.id,
            label: t("code." + code.name),
          });
        });
      }
    });

    setAttandanceTypes(attandanceTypeCodes);
    setStatus(statusCodes);
    setClubTypes(clubTypeCodes);
    setClubContentTypes(clubContentTypeCodes);
    setClubLanguageCodes(clubLanguageCodes);
  };

  const processRowUpdate = (e, i) => {
    console.log("processRowUpdate");
    console.log(e);

    const sentData = { id: e.id, field: e.field, value: e.value };

    editClubInfo(sentData, {
      onSuccess: (e) => {
        console.log(e);
      },
    });
  };
  const handleNewClub = (club) => {
    refetch();
  };

  const doRefresh = (clubMeetingTimes) => {
    //console.log("doRefresh");
    //console.log(clubMeetingTimes);
    refetch();
  };

  const deleteRows = () => {
    selectionModel.forEach((club) => {
      mutate(club, {
        onSuccess: (e) => {
          console.log(e);
        },
      });
    });
    window.location.reload();
  };
  return (
    <ContainerBox>
      <div style={{ width: "100%" }}>
        {(error || errorCodeDomains) && (
          <ErrorAlert txt={error || errorCodeDomains} />
        )}
        {(isLoading || isLoadingCodeDomains) && <CircularProgress />}
        {
          <AddClubs
            handleNewClub={handleNewClub}
            statusCodes={statusCodes}
            clubTypeCodes={clubTypeCodes}
            clubContentTypeCodes={clubContentTypeCodes}
            attendanceCodes={attandanceTypes}
            clubLanguageCodes={clubLanguageCodes}
            DeleteButton={
              <Button
                variant="contained"
                onClick={deleteRows}
                color="secondary"
                startIcon={<DeleteForeverIcon />}
                disabled={disableBtn}
                sx={{ height: 40 }}
              >
                Delete Rows
              </Button>
            }
          />
        }
        {rows.length > 0 && (
          <>
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              onCellEditCommit={processRowUpdate}
            />
          </>
        )}
      </div>
    </ContainerBox>
  );
}
