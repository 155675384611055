import { useState, forwardRef } from "react";
import { useMutation, useQuery } from "react-query";

import { CircularProgress, Box, Typography, Paper, Tooltip, LinearProgress, Modal, Stack, Button, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";
import i18n from "../i18n";

import { ReactComponent as TheLogo } from "../Static/img/logo_black.svg";
import { getGamerProfile, unlockAchievementForProfile, claimAchievements } from "../Hooks/profile";
import { useParams } from "react-router-dom";

const defaultUser = {
  username: undefined,
  profile: {
    harmony: 0,
    glow: 0,
    valor: 0,
    commonSense: 0,
  },
  newAchievements: [],
};

export default function GamerProfile(params) {
  const userId = useParams().id ?? "@me";
  const isAdmin = params.appAuthInfo?.appUserRoles?.includes("USER_ROLE_ADMIN") ?? false;

  const [achievementNotificationsPending, setAchievementNotificationsPending] = useState([]);
  const [levelUpNotificationsPending, setLevelUpNotificationsPending] = useState([]);
  const { t } = useTranslation();
  const lang = i18n.language.slice(0, 2);

  const {
    data: user,
    isLoading: isLoadingProfile,
    refetch,
  } = useQuery(["profile", userId], async () => getGamerProfile(userId), {
    onSuccess: (data) => {
      setAchievementNotificationsPending((achNotifications) =>
        achNotifications.concat(data.achievementNotifications),
      );
      setLevelUpNotificationsPending((lvlUpNotifications) =>
        lvlUpNotifications.concat(
          data.newLevelUps.map((name) => ({
            title: name,
            level: Math.floor(Math.sqrt(data.profile[name])),
          })),
        ),
      );
    },
    placeholderData: defaultUser,
  });

  const { mutate } = useMutation(unlockAchievementForProfile, {
    onSuccess: refetch,
  });

  const onUnlockAchievementForProfile = (userId, achievementId) => {
    mutate({ userId, achievementId });
  };

  const onClaimAchievements = async () => {
    await claimAchievements(userId, achievementNotificationsPending);
    setAchievementNotificationsPending([]);
  };

  const getLevelAndProgress = (x) => {
    const level = Math.floor(Math.sqrt(x));
    const current = x - level * level;
    const target = (level + 1) * (level + 1) - level * level;
    return { level, current, target };
  };

  const totalYty =
    user.profile.harmony +
    user.profile.glow +
    user.profile.valor +
    user.profile.commonSense;
  const totalLevel = getLevelAndProgress(totalYty);
  const harmony = getLevelAndProgress(user.profile.harmony);
  const glow = getLevelAndProgress(user.profile.glow);
  const valor = getLevelAndProgress(user.profile.valor);
  const commonSense = getLevelAndProgress(user.profile.commonSense);

  const { profile } = user;
  const newAchievements = (user.achievements ?? []).filter((achievement) =>
    achievementNotificationsPending.includes(achievement.achievementId),
  );

  return (
    <Stack alignItems="center">
      <Typography variant="h2">{t("home.profile.title")}</Typography>
      <Stack direction="row" spacing={3} useFlexGap flexWrap="wrap">
        <LevelDisplay size={88} name={t("home.profile.level")} progress={totalLevel} />
        <ProfileNameAndPicture name={user.username} avatar={user.avatar} discordRoles={user.discordRoles} />
        <LevelsGrid harmony={harmony} glow={glow} valor={valor} commonSense={commonSense} />
      </Stack>
      <RadarChart4 loading={isLoadingProfile} u={Math.sqrt(profile.commonSense)} r={Math.sqrt(profile.valor)} d={Math.sqrt(profile.harmony)} l={Math.sqrt(profile.glow)}/>
      <Typography variant="h4">{t("home.profile.achievements.title")}</Typography>
      <AchievementGrid onUnlockAchievementForProfile={onUnlockAchievementForProfile} userId={userId} lang={lang} user={user.profile} achievements={user.achievements} isAdmin={isAdmin}/>
      <Modal
        open={newAchievements.length > 0}
        onClose={onClaimAchievements}
        disableScrollLock
      >
        <AchievementNotification lang={lang} pendingAchievements={newAchievements}/>
      </Modal>
      <Modal 
        open={levelUpNotificationsPending.length > 0 && newAchievements.length === 0}
        onClose={() => setLevelUpNotificationsPending([])}
        disableScrollLock
      >
        <LevelUpNotification t={t} levelUps={levelUpNotificationsPending}/>
      </Modal>
    </Stack>
  );
}

const LevelsGrid = ({ harmony, glow, valor, commonSense }) => {
  const { t } = useTranslation();
  return (
    <Grid container justify="center" align="center" width={280}>
      <Grid item xs={6}>
        <LevelDisplay size={44} name={t("home.profile.harmony")} progress={harmony} />
      </Grid>
      <Grid item xs={6}>
        <LevelDisplay size={44} name={t("home.profile.glow")} progress={glow} />
      </Grid>
      <Grid item xs={6}>
        <LevelDisplay size={44} name={t("home.profile.valor")} progress={valor} />
      </Grid>
      <Grid item xs={6}>
        <LevelDisplay size={44} name={t("home.profile.commonSense")} progress={commonSense} />
      </Grid>
    </Grid>
  );
}

const ProfileNameAndPicture = ({ name, avatar, discordRoles }) => (
  <Paper elevation={10} sx={{ borderRadius: 7, width: 320, height: 272.8, m: "5px", p: 4 }}>
    <Stack sx={{ alignItems: "center" }}>
      {name === undefined ? (
        <Skeleton
          variant="rounded"
          sx={{ display: "inline-block", borderRadius: "10px" }}
          width={128}
          height={128}
        />
      ) : avatar ? (
        <img
          src={avatar}
          width={128}
          height={128}
          style={{ borderRadius: 10 }}
        />
      ) : (
        <TheLogo />
      )}
      <Typography variant="h3">{name ?? "Loading..."}</Typography>
      {discordRoles && discordRoles.map((role, i) => <Typography key={i}>{role}</Typography>)}
    </Stack>
  </Paper>
);

const AchievementProgress = styled(LinearProgress)(() => ({
  height: 12,
  width: 200,
  marginBottom: 10,
  marginTop: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#cccccc66",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#ffffff",
  },
}));

const AchievementCard = ({ achievement, lang, isAdmin, onUnlockAchievementForProfile, userId }) => {
  const { t } = useTranslation();

  const { translations, progressValue, progressTarget } = achievement;
  const title = translations[lang]?.title;
  const description = translations[lang]?.description;
  const hasProgress = progressValue !== undefined && progressTarget !== undefined;
  const card = (
    <Paper elevation={5} sx={{ backgroundColor: achievement.isUnlocked ? "#1AB061" : "#EBEBEB", borderRadius: 5, margin: 1.3, p: 2, width: 260, height: 315, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Stack sx={{ alignItems: "center" }}>
        {achievement.image ? <img src={achievement.image} width={200} height={124}/> : <TheLogo />}
        {hasProgress && !achievement.isUnlocked && <AchievementProgress variant="determinate" value={100 * progressValue / progressTarget} />}
        <Typography variant="h5" m={0}>{title}</Typography>
        <Typography m={0}>{description}{/*🥰💪😎✨⚡️*/}</Typography>
        <Box sx={{ width: "220px", display: "flex", gap: "5px 20px", justifyContent: "space-evenly", flexWrap: "wrap", marginTop: "auto" }}>
          {achievement.rewards.map((reward, i) => (<Typography key={i} m={0}>{t(`code.${reward.rewardVar}`)} +{reward.amount}</Typography>))}
        </Box>
      </Stack>
      {isAdmin && achievement.isManual && !achievement.isUnlocked && <Button variant="outlined" onClick={() => onUnlockAchievementForProfile(userId, achievement.achievementId)} >{t("home.profile.unlockAchievementForUser")}</Button> }
    </Paper>
  );

  return hasProgress
    ? <Tooltip title={<Typography align="center">{progressValue} / {progressTarget}</Typography>}>{card}</Tooltip> 
    : card;
};

const AchievementGrid = ({ achievements, lang, isAdmin, onUnlockAchievementForProfile, userId }) => (
  <Grid container justifyContent="center">
    {achievements
      ? achievements.map((achievement) => {
          return (
            <AchievementCard
              key={achievement.achievementId}
              achievement={achievement}
              lang={lang}
              isAdmin={isAdmin}
              onUnlockAchievementForProfile={onUnlockAchievementForProfile}
              userId={userId}
            />
          );
        })
      : [1, 2, 3, 4].map((i) => (
          <Paper key={i} elevation={5} sx={{ backgroundColor: "#EBEBEB", borderRadius: 5, margin: 1.3, p: 2, width: 260, height: 300 }}>
            <Stack sx={{ alignItems: "center" }}>
              <Skeleton sx={{ display: "inline-block" }} variant="rounded" width={200} height={124}><TheLogo /></Skeleton>
              <Typography variant="h5" m={0}><Skeleton width={200}/></Typography>
              <Typography m={0}><Skeleton width={200} /></Typography>
            </Stack>
          </Paper>
      ))}
  </Grid>
);

const AchievementNotification = forwardRef((props, _ref) => {
  const { t } = useTranslation();

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const pendingAchievements = props.pendingAchievements;
  const [index, setIndex] = useState(0);
  const shownAchievement = pendingAchievements[index];

  return (pendingAchievements.length !== 0 &&
    <Box sx={style}>
      <Typography variant="h3">{t("home.profile.newAchievement")}</Typography>
      <AchievementCard 
        achievement={shownAchievement}
        lang={props.lang}
      />
      {
        pendingAchievements.length > 1 &&
        <Stack>
          <Stack direction="row" justifyContent="center">
            {Array(pendingAchievements.length).fill(0).map((_, i) => i === index ? <CircleIcon key={i} sx={{ fontSize: 10 }}/> : <CircleOutlinedIcon key={i} sx={{ fontSize: 10 }}/>)}
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Button fullWidth disabled={index === 0} onClick={() => { setIndex(index - 1) }}><ArrowLeftIcon sx={{ fontSize: 40 }} /></Button>
            <Button fullWidth disabled={index === pendingAchievements.length - 1} onClick={() => { setIndex(index + 1) }}><ArrowRightIcon sx={{ fontSize: 40 }} /></Button>
          </Stack>
        </Stack>
      }
    </Box>
  );
});

const LevelDisplay = ({ size, name, progress }) => (
  <Tooltip title={<Typography align="center">{progress.current} / {progress.target}</Typography>}>
    <Paper elevation={7} sx = {{ pt: `${0.8 * size}px`, width: `${2.9 * size}px`, height: `${3.1 * size}px`, borderRadius: "20px", backgroundColor: "#8F00E2", color: "#FFFFFF", margin: "5px" }}>
      <Stack sx={{ alignItems: "center" }}>
        <Typography fontSize={`${size}px`} sx={{ lineHeight: `${size}px` }}>{progress.level}</Typography>
        <Box sx={{ color: "grey.500", transform: `translateY(-${1.5 * size}px) rotate(-120deg)`, lineHeight: 0, pointerEvents: "none" }}>
          <CircularProgress size={2 * size} variant="determinate" value={200 / 3} color="inherit" />
        </Box>
        <Box sx={{ transform: `translateY(-${3.5 * size}px) rotate(-120deg)`, lineHeight: 0, pointerEvents: "none" }}>
          <CircularProgress size={2 * size} variant="determinate" value={(progress.current / progress.target) * (200 / 3)} color="inherit" />
        </Box>
        <Typography fontSize={`${0.5 * size}px`} lineHeight={`${0.55 * size}px`} mt={`-${3.9 * size}px`} mb={`${0.1 * size}px`}>{name}</Typography>
      </Stack>
    </Paper>
  </Tooltip>
);

const RadarChart4 = ({ loading, u, d, l, r }) => {
  const { t } = useTranslation();

  let factor = 76 / Math.max(u, d, l, r);
  if (!Number.isFinite(factor)) {
    factor = 0;
  }
  const cu = -(factor * u + 10);
  const cd =  (factor * d + 10);
  const cl = -(factor * l + 10);
  const cr =  (factor * r + 10);

  return (
    <svg viewBox="-160 -125 320 250" width="320" height="250" xmlns="http://www.w3.org/2000/svg">
      <circle r="90" cx="0" cy="0" fill="transparent" stroke="black" strokeWidth="2" />
      <circle r="60" cx="0" cy="0" fill="transparent" stroke="black" />
      <circle r="30" cx="0" cy="0" fill="transparent" stroke="black" />
      <circle r="2"  cx="0" cy="0" fill="black"       stroke="black" />
      <line x1="0"   x2="0"  y1="-90" y2="90" strokeWidth="1" stroke="black" />
      <line x1="-90" x2="90" y1="0"   y2="0"  strokeWidth="1" stroke="black" />
      {!loading && <polygon points={`0 ${cu} ${cr} 0 0 ${cd} ${cl} 0`} stroke="#1AB061" fill="#6AC66B77" strokeWidth="3" strokeLinejoin="round" />}
      <text x="0"   y="-97" alignmentBaseline="baseline" textAnchor="middle">{t("home.profile.commonSense")}</text>
      <text x="-95" y="0"   alignmentBaseline="middle"   textAnchor="end">{t("home.profile.glow")}</text>
      <text x="95"  y="0"   alignmentBaseline="middle"   textAnchor="start">{t("home.profile.valor")}</text>
      <text x="0"   y="97"  alignmentBaseline="hanging"  textAnchor="middle">{t("home.profile.harmony")}</text>
    </svg>
  );
}

const LevelUpNotification = forwardRef((props, _ref) => {
  const { t } = useTranslation();

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const [index, setIndex] = useState(0);
  const shownLevelUp = props.levelUps[index];

  return (
    props.levelUps.length !== 0 && (
      <Box sx={style}>
        <Typography variant="h3">{t("home.profile.levelUp")}</Typography>
        <LevelDisplay
          size={80}
          name={props.t(`home.profile.${shownLevelUp.title}`)}
          progress={{ level: shownLevelUp.level, current: 0, target: 1 }}
        />
        {props.levelUps.length > 1 && (
          <Stack>
            <Stack direction="row" justifyContent="center">
              {Array(props.levelUps.length)
                .fill(0)
                .map((_, i) =>
                  i === index ? (
                    <CircleIcon key={i} sx={{ fontSize: 10 }} />
                  ) : (
                    <CircleOutlinedIcon key={i} sx={{ fontSize: 10 }} />
                  ),
                )}
            </Stack>
            <Stack direction="row" justifyContent="center">
              <Button fullWidth disabled={index === 0} onClick={() => { setIndex(index - 1) }}>
                <ArrowLeftIcon sx={{ fontSize: 40 }} />
              </Button>
              <Button fullWidth disabled={index === props.levelUps.length - 1} onClick={() => { setIndex(index + 1) }}>
                <ArrowRightIcon sx={{ fontSize: 40 }} />
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    )
  );
});
