import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useQuery } from "react-query";

import { getClubInstructions } from "../Hooks/clubs";

import { useTranslation } from "react-i18next";
import { login } from "../Hooks/auth";

export const ClubInstructionsSelect = ({
  originalClubInstruction,
  saveSelectedClubInstructions,
  label,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedClubInstruction, setSelectedClubInstruction] = useState(null);
  const clubInstructions = useQuery({
    queryKey: ["clubInstructions"],
    queryFn: getClubInstructions,
  });

  useEffect(() => {
    // console.log("ClubInstructionsSelect -  on effect");
    // console.log(originalClubInstruction);
    if (originalClubInstruction)
      setSelectedClubInstruction({
        id: originalClubInstruction.id,
        name: originalClubInstruction.name,
      });
  }, [originalClubInstruction]);

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {label ?? t("clubView.chooseClubInstructions")}
      </Button>
      <Dialog open={isOpen} disableScrollLock>
        <DialogTitle>{t("clubView.chooseClubInstructions")}</DialogTitle>
        <DialogContent>
          <Autocomplete
            value={selectedClubInstruction}
            disableClearable
            onChange={(_event, newValue) =>
              setSelectedClubInstruction(newValue)
            }
            // inputValue={inputValue}
            // onInputChange={(_event, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            options={clubInstructions.data ?? []}
            sx={{ width: 300 }}
            isOptionEqualToValue={(a, b) => a?.id === b?.id}
            getOptionLabel={(clubInstruction) =>
              clubInstruction !== null ? `${clubInstruction.name}` : ""
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>
            {t("general.dialog.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              // console.log("saveSelectedClubInstructions");
              // console.log(selectedClubInstruction);
              saveSelectedClubInstructions(selectedClubInstruction);
              setIsOpen(false);
            }}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
