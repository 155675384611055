import React, { useEffect, useMemo, useState, Fragment } from "react";
import "./club.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";

import Link from "@mui/material/Link";
import OpenIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import ContainerBox from "../Container";
import { editClub, getClubByID, sendWelcomeEmails } from "../Hooks/clubs";

import { createLessonsForClub } from "../Hooks/lessons";

import ErrorAlert from "../errorAlert";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { prepareTime } from "../Util/clubHelper";
import { GeduSelect } from "../Util/geduSelect";
import { ClubInstructionsSelect } from "../Util/clubInstructionsSelect";
import { prepareClubName } from "../Util/clubHelper";

import { isAdmin } from "../Util/authHelper";
import { CustomTextFieldWithSave } from "../Util/customTextFieldWithSave";
import CustomMultiTextFieldWithSave from "../Util/customMultiTextFieldWithSave";
import ClubFeeEditor from "../Util/clubFeeEditor";
import { TranslatedDatePicker } from "../Util/translatedDatePicker";
import ClubGeneralInfoEditor from "../Util/clubGeneralInfoEditor";
import ClubTypeEditor from "../Util/clubTypeEditor";
import AddEditMeetingTimes from "../Admin/Clubs/addEditMeetingTimes";

export default function ClubData(params) {
  const { t } = useTranslation();
  const clubId = params.clubId;
  const { data, error, isLoading, refetch } = useQuery(["clubId", clubId], () =>
    getClubByID(clubId)
  );

  const [welcomeEmailStatus, setWelcomeEmailStatus] = React.useState("");
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);

  const [bulkLessonCreationStatus, setBulkLessonCreationStatus] =
    React.useState("");

  const queryClient = useQueryClient();

  const { mutate: mutateClub } = useMutation({
    mutationFn: editClub,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["clubId", clubId] })
      // refetch();
    },
  });

  const { mutate: sendWelcomeFeathers } = useMutation({
    mutationFn: sendWelcomeEmails,
    onSuccess: async (e) => {
      console.log(e);
      setWelcomeEmailStatus(e.message);
      await params.refetch(); // parent refetch
    },
  });

  const {
    mutate: bulkCreateLessonsForClub,
    isLoading: isLoadingBulkCreateLessons,
    error: bulkCreateLessonsError,
  } = useMutation({
    mutationFn: createLessonsForClub,
    onSuccess: async (e) => {
      console.log(e);
      setBulkLessonCreationStatus(e.message);
      await params.refetch(); // parent refetch
    },
    onError: (error) => {
      console.error(error);
      // Handle the error here, and it will be available in bulkCreateLessonsError
    },
  });

  function formatDateTime(date, format = "YYYY-MM-DD hh:mm") {
    if (!date) return undefined;

    const pad2 = (n) => n.toString().padStart(2, "0");

    const map = {
      YYYY: date.getFullYear(),
      MM: pad2(date.getMonth() + 1),
      DD: pad2(date.getDate()),
      hh: pad2(date.getHours()),
      mm: pad2(date.getMinutes()),
      ss: pad2(date.getSeconds()),
    };

    return Object.entries(map).reduce(
      (prev, entry) => prev.replace(...entry),
      format
    );
  }

  const appUserRoles = params.appAuthInfo.appUserRoles;

  function fmt(date, format = "YYYY-MM-DD hh:mm") {
    if (!date) return undefined;

    const pad2 = (n) => n.toString().padStart(2, "0");

    const map = {
      YYYY: date.getFullYear(),
      MM: pad2(date.getMonth() + 1),
      DD: pad2(date.getDate()),
      hh: pad2(date.getHours()),
      mm: pad2(date.getMinutes()),
      ss: pad2(date.getSeconds()),
    };

    return Object.entries(map).reduce(
      (prev, entry) => prev.replace(...entry),
      format
    );
  }

  const prepareData = () => {
    console.log("ClubdData.prepareData");
    console.log(data);

    let text = prepareClubName(data, t);

    const data1 = [];
    const generalInfo = {
      header: t("clubData.generalInfoHeader"),
      data: [
        {
          key: t("clubData.status"),
          value: t("code." + data.status.name),
        },
        {
          key: t("clubData.clubId"),
          value: data.id,
        },
        {
          key: t("clubData.clubName"),
          value: text,
        },
        {
          key: t("clubData.sku"),
          value: data.sku,
        },
        {
          key: t("clubData.language"),
          value: t("code." + data.languageCode.name),
        },
        {
          key: t("clubData.discordRoleName"),
          value: data.discordRoleName,
        },
      ],
    };

    if (data.type.name === "CLUB_TYPE_MUNICIPALITY") {
      generalInfo.data.push({
        key: t("clubData.registrationEnabled"),
        value: data.registration
          ? t("clubData.registrationActive")
          : t("clubData.registrationInactive"),
      });
    }

    if (data.discordGuildName) {
      generalInfo.data.push({
        key: t("clubData.discordGuildName"),
        value: data.discordGuildName,
      });
    }

    if (data.groups.length > 0) {
      generalInfo.data.push({
        key: t("clubData.groupId"),
        value: data.groups[0].id,
      });
    }

    if (isAdmin(appUserRoles)) {
      generalInfo.data.push({
        key: t("clubData.edit"),
        value: (
          <ClubGeneralInfoEditor
            data={{
              status: data.status,
              name: data.name,
              sku: data.sku,
              language: data.languageCode,
              registration: data.registration,
              discordRoleId: data.discordRoleId ?? "",
              discordGuildId: data.discordGuildId ?? "",
            }}
            isMuni={data.type.name === "CLUB_TYPE_MUNICIPALITY"}
            onSave={(newValue) => {
              let fields = [
                { field: "name", value: newValue.name },
                { field: "sku", value: newValue.sku },
                { field: "registration", value: newValue.registration },
              ];

              if (newValue?.language?.id)
                fields.push({ field: "languageCode", value: newValue.language.id });
              
              if (newValue?.status?.id)
                fields.push({ field: "status", value: newValue.status.id });

              if (newValue.discordRoleId != data.discordRoleId
                || newValue.discordGuildId != data.discordGuildId) {
                fields.push({ field: "discordRoleId", value: newValue.discordRoleId });
                fields.push({ field: "discordGuildId", value: newValue.discordRoleId ? newValue.discordGuildId : null });
              }

              mutateClub({ id: clubId, fields });
            }}
          />
        ),
      });
    }
    data1.push(generalInfo);

    const ownerInfo = {
      header: t("clubData.ownerHeader"),
    };
    const ownerInfoData = [];
    if (data.owner && data.owner.email) {
      ownerInfoData.push(
        {
          key: t("clubData.ownerName"),
          value: data.owner.firstName + " " + data.owner.lastName,
        },
        {
          key: t("clubData.ownerEmail"),
          value: data.owner.email,
        },
        {
          key: t("clubData.ownerPhoneNumber"),
          value: data.owner.phoneNumber,
        },
        {
          key: t("clubData.ownerUsername"),
          value: data.owner.username,
        }
      );
      if (isAdmin(appUserRoles)) {
        ownerInfoData.push({
          key: t("clubData.changeOwner"),
          gadget: (
            <GeduSelect
              defaultGedu={data?.owner}
              saveSelectedGedu={(newGedu) => {
                mutateClub({ id: clubId, field: "ownerId", value: newGedu.id });
              }}
            />
          ),
        });
      }
    } else {
      ownerInfoData.push(
        {
          key: t("clubData.ownerName"),
          value: undefined,
        },
        {
          key: t("clubData.ownerEmail"),
          value: undefined,
        },
        {
          key: t("clubData.ownerUsername"),
          value: undefined,
        }
      );
      if (isAdmin(appUserRoles)) {
        ownerInfoData.push({
          key: t("clubData.changeOwner"),
          gadget: (
            <GeduSelect
              defaultGedu={data?.owner}
              saveSelectedGedu={(newGedu) => {
                mutateClub({ id: clubId, field: "ownerId", value: newGedu.id });
              }}
            />
          ),
        });
      }
    }

    ownerInfo["data"] = ownerInfoData;
    data1.push(ownerInfo);

    const clubTypesInfo = {
      header: t("clubData.clubTypsHeader"),
    };
    const clubTypesInfoData = [];
    // if (data.status) {
    //   clubTypesInfoData.push({
    //     key: t("clubData.status"),
    //     value: t("code." + data.status.name),
    //   });
    // }
    if (data.attendance) {
      clubTypesInfoData.push({
        key: t("clubData.attendance"),
        value: t("code." + data.attendance.name),
      });
    }
    if (data.type) {
      clubTypesInfoData.push({
        key: t("clubData.type"),
        value: t("code." + data.type.name),
      });
    }
    if (data.contentType) {
      clubTypesInfoData.push({
        key: t("clubData.contentType"),
        value: t("code." + data.contentType.name),
      });
    }
    if (isAdmin(appUserRoles)) {
      clubTypesInfoData.push({
        key: t("clubData.changeType"),
        gadget: (
          <ClubTypeEditor
            data={{
              attendance: data.attendance,
              clubType: data.type,
              contentType: data.contentType,
            }}
            onSave={(newValue) => {
              let fields = [];
              if (newValue?.attendance?.id)
                fields.push({ field: "attendance", value: newValue.attendance.id });
              if (newValue?.clubType?.id)
                fields.push({ field: "type", value: newValue.clubType.id });
              if (newValue?.contentType?.id)
                fields.push({ field: "contentType", value: newValue.contentType.id });
              mutateClub({ id: clubId, fields });
            }}
          />
        ),
      });
    }
    clubTypesInfo["data"] = clubTypesInfoData;
    data1.push(clubTypesInfo);

    const meetingTimesInfo = {
      header: t("clubData.meetingTimesHeader"),
    };
    const meeetingTimesInfoData = [];
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    // console.log(data.meetings);
    if (data.meetings) {
      data.meetings.map((meeting, i) =>
        meeetingTimesInfoData.push({
          key: t("weekday." + meeting.weekday),
          value:
            prepareTime(meeting.startTime) + "-" + prepareTime(meeting.endTime),
        })
      );
    }

    if (isAdmin(appUserRoles)) {
      meeetingTimesInfoData.push({
        key: t("clubData.editMeetings"),
        gadget: (
          <AddEditMeetingTimes
            handleWindowClose={() => {
              mutateClub({
                id: clubId,
              });
            }}
            selectedClubData={data}
            showMeetings={false}
          />
        ),
      });
    }

    meetingTimesInfo["data"] = meeetingTimesInfoData;
    data1.push(meetingTimesInfo);

    if (isAdmin(appUserRoles)) {
      const clubFeesInfo = {
        header: t("clubData.clubFeesHeader"),
        data: [],
      };

      //console.log(data?.fees);

      const currencyText = data?.fees?.currency?.name
        ? t("code." + data?.fees?.currency.name)
        : "";

      let mainGeduFee = undefined;
      if (data?.fees?.mainGeduFee)
        mainGeduFee = parseFloat(data.fees.mainGeduFee).toFixed(2);
      let groupGeduFee = undefined;
      if (data?.fees?.groupGeduFee)
        groupGeduFee = parseFloat(data.fees.groupGeduFee).toFixed(2);
      let assistantGeduFee = undefined;
      if (data?.fees?.assistantGeduFee)
        assistantGeduFee = parseFloat(data.fees.assistantGeduFee).toFixed(2);
      let municipalityFee = undefined;
      if (data?.fees?.municipalityFee)
        municipalityFee = parseFloat(data.fees.municipalityFee).toFixed(2);

      const clubFeeData = {
        currency: data?.fees?.currency?.id,
        mainGeduFee: mainGeduFee,
        groupGeduFee: groupGeduFee,
        assistantGeduFee: assistantGeduFee,
        municipalityFee: municipalityFee,
        currencies: data?.currencies,
      };

      clubFeesInfo.data.push({
        key: t("clubData.mainGeduFee"),
        value: mainGeduFee ? mainGeduFee + " " + currencyText : "-",
      });
      clubFeesInfo.data.push({
        key: t("clubData.groupGeduFee"),
        value: groupGeduFee ? groupGeduFee + " " + currencyText : "-",
      });
      clubFeesInfo.data.push({
        key: t("clubData.assistantGeduFee"),
        value: assistantGeduFee ? assistantGeduFee + " " + currencyText : "-",
      });
      clubFeesInfo.data.push({
        key: t("clubData.municipalityFee"),
        value: municipalityFee ? municipalityFee + " " + currencyText : "-",
      });

      if (isAdmin(appUserRoles)) {
        clubFeesInfo.data.push({
          key: t("clubData.changeOwner"),
          value: (
            <ClubFeeEditor
              data={clubFeeData}
              onSave={(newValue) => {
                mutateClub({ id: clubId, field: "fees", value: newValue });
              }}
            />
          ),
        });
      }

      data1.push(clubFeesInfo);
    }

    if (!isAdmin(appUserRoles) && data.gedufee) {
      const currencyText = data.gedufee.currency?.name
        ? t("code." + data.gedufee.currency?.name)
        : "";

      const geduClubFeesInfo = {
        header: t("clubData.geduClubFeesHeader"),
        data: [],
      };

      const roleText = t("clubData.geduClubFeeRole." + data.gedufee.type);

      geduClubFeesInfo.data.push({
        key: t("clubData.geduClubFeeTitle"),
        value: data.gedufee.fee ? data.gedufee.fee + " " + currencyText : "-",
        info: t("clubData.geduClubFeeInfo", { role: roleText }),
        note: t("clubData.geduClubFeeNote"),
      });

      if (geduClubFeesInfo.data.length > 0) data1.push(geduClubFeesInfo);
    }

    // period = (startDate and possible endDate), edit for Admins
    // note only for MUNICIPALITY clubs

    const period = {
      header: t("clubData.periodHeader"),
    };
    const periodData = [];
    if (data.type?.name === "CLUB_TYPE_MUNICIPALITY") {
      let startDate = undefined;
      if (data?.startDate) {
        // console.log("data.startDate");
        // console.log(data.startDate);
        startDate = new Date(data.startDate);
        startDate.setHours(12);
        startDate.setMinutes(0);
        // console.log(startDate);
        // setStartDate(startDate);
      }
      let endDate = undefined;
      if (data?.endDate) {
        endDate = new Date(data.endDate);
        startDate.setHours(12);
        startDate.setMinutes(0);
        setEndDate(endDate);
      }
      if (isAdmin(appUserRoles)) {
        periodData.push({
          key: t("clubData.startDate"),
          gadget: (
            <div style={{ margin: "0.5em" }}>
              <TranslatedDatePicker
                label={t("clubData.startDate")}
                value={startDate}
                onChange={(newValue) => {
                  let newStartDate = new Date(newValue);
                  newStartDate.setHours(12);
                  newStartDate.setMinutes(0);

                  mutateClub({
                    id: clubId,
                    field: "startDate",
                    value: newStartDate,
                  });
                }}
              />
            </div>
          ),
        });
        periodData.push({
          key: t("clubData.endDate"),
          gadget: (
            <div style={{ margin: "0.5em" }}>
              <TranslatedDatePicker
                label={t("clubData.endDate")}
                value={endDate}
                onChange={(newValue) => {
                  let newEndDate = new Date(newValue);
                  newEndDate.setHours(12);
                  newEndDate.setMinutes(0);
                  mutateClub({
                    id: clubId,
                    field: "endDate",
                    value: newEndDate,
                  });
                }}
              />
            </div>
          ),
        });

        periodData.push({
          key: t("clubData.bulkLessonCreationStatus"),
          value: bulkLessonCreationStatus,
        });

        periodData.push({
          key: "",
          gadget: (
            <div style={{ margin: "0.5em" }}>
              <Button
                variant="outlined"
                sx={{ m: 1 }}
                onClick={() => {
                  setBulkLessonCreationStatus(t("clubData.bulkCreateStarted"));
                  bulkCreateLessonsForClub(clubId);
                }}
                disabled={isLoadingBulkCreateLessons}
              >
                {t("clubData.bulkCreateLessons")}
              </Button>
            </div>
          ),
        });
      } else {
        periodData.push({
          key: t("clubData.startDate"),
          value: formatDateTime(startDate, "DD.MM.YYYY"),
        });
        periodData.push({
          key: t("clubData.endDate"),
          value: formatDateTime(endDate, "DD.MM.YYYY"),
        });
      }
      period["data"] = periodData;
      data1.push(period);
    }

    const additionalInfo = {
      header: t("clubData.additionalInfoHeader"),
    };

    const additionalInfoData = [];
    if (isAdmin(appUserRoles)) {
      additionalInfoData.push({
        key: "",
        gadget: (
          <CustomTextFieldWithSave
            initialValue={data?.additionalInformation}
            onSave={(newValue) => {
              mutateClub({
                id: clubId,
                field: "additionalInformation",
                value: newValue,
              });
            }}
          />
        ),
      });
    } else {
      additionalInfoData.push({
        key: "",
        value: data?.additionalInformation,
      });
    }

    additionalInfo["data"] = additionalInfoData;
    data1.push(additionalInfo);

    // club material
    const clubMaterial = {
      header: t("clubData.clubMaterialHeader"),
    };

    const clubMaterialData = [];
    clubMaterialData.push({
      key: "",
      value: (
        <>
          <Link href={data?.clubMaterial} target="_blank" rel="noreferrer">
            {data?.clubMaterial}
            <OpenIcon
              sx={{
                verticalAlign: "middle",
                width: "18px",
                height: "18px",
                marginLeft: "0.15em",
              }}
            />
          </Link>
          <br />
          <Button
            variant="outlined"
            //size="small"
            onClick={(event) => {
              event.stopPropagation();
              copyToClipboard(data?.clubMaterial);
            }}
            sx={{ mt: "0.5em", color: "#3a71de", width: "150px" }} // Adjust margin as needed
          >
            <ContentCopyIcon
              sx={{
                mr: "0.25em",
                color: "#3a71de",
                width: "18px",
                height: "18px",
              }}
            />{" "}
            {t("clubData.copyToClipboard")}
          </Button>
        </>
      ),
    });
    if (isAdmin(appUserRoles)) {
      clubMaterialData.push({
        key: "",
        gadget: (
          <CustomTextFieldWithSave
            initialValue={data?.clubMaterial}
            onSave={(newValue) => {
              mutateClub({
                id: clubId,
                field: "clubMaterial",
                value: newValue,
              });
            }}
          />
        ),
      });
    }

    clubMaterial["data"] = clubMaterialData;
    data1.push(clubMaterial);

    // club instructions
    const instructions = {
      header: t("clubData.clubInstructionsHeader"),
    };
    const instructionsData = [];

    if (data.clubInstruction) {
      instructionsData.push(
        {
          key: t("clubData.clubInstructionsName"),
          value: data.clubInstruction.name,
        },
        {
          key: t("clubData.clubInstructionsIntroduction"),
          value: data.clubInstruction.introduction,
        },
        {
          key: t("clubData.clubInstructionsInstructions"),
          value: data.clubInstruction.instructions,
        }
      );
      if (isAdmin(appUserRoles)) {
        instructionsData.push({
          key: t("clubData.changeClubInstructions"),
          value: (
            <ClubInstructionsSelect
              originalClubInstruction={data.clubInstruction}
              saveSelectedClubInstructions={(newInstructions) => {
                mutateClub({
                  id: clubId,
                  field: "clubInstructionId",
                  value: newInstructions.id,
                });
              }}
            />
          ),
        });
      }
    } else {
      instructionsData.push(
        {
          key: t("clubData.clubInstructionsName"),
          value: undefined,
        },
        {
          key: t("clubData.clubInstructionsIntroduction"),
          value: undefined,
        },
        {
          key: t("clubData.clubInstructionsInstructions"),
          value: undefined,
        }
      );
      if (isAdmin(appUserRoles)) {
        instructionsData.push({
          key: t("clubData.changeClubInstructions"),
          gadget: (
            <ClubInstructionsSelect
              originalClubInstruction={data.clubInstruction}
              saveSelectedClubInstructions={(newInstructions) => {
                mutateClub({
                  id: clubId,
                  field: "clubInstructionId",
                  value: newInstructions.id,
                });
              }}
            />
          ),
        });
      }
    }

    instructions["data"] = instructionsData;
    data1.push(instructions);

    // >>>>>>>>>>>>>>>>>>>>>
    // municipality additional info
    // check if user is admin and club is municipality
    if (data.type?.name === "CLUB_TYPE_MUNICIPALITY") {
      const municipalityInfo = {
        header: t("clubData.municipalityAdditionalDataHeader"),
      };
      const municipalityInfoData = [];

      const initialData = {};

      // minParticipantCount: "Minimiosallistujamäärä",
      initialData["minParticipantCount"] = {
        label: t("municipality.metadata.minParticipantCount"),
        value: data.metadata.minParticipantCount,
      };

      // // paymentTerm: "Maksuehto",
      // initialData["paymentTerm"] = {
      //   label: t("municipality.metadata.paymentTerm"),
      //   value: data.metadata.paymentTerm,
      // };

      // // paymentPeriod: "Maksujakso",
      // initialData["paymentPeriod"] = {
      //   label: t("municipality.metadata.paymentPeriod"),
      //   value: data.metadata.paymentPeriod,
      // };
      // // notes: "Muistiinpanot",
      // initialData["notes"] = {
      //   label: t("municipality.metadata.notes"),
      //   value: data.metadata.notes,
      //   rows: 4,
      // };
      if (isAdmin(appUserRoles))
        municipalityInfoData.push({
          key: "",
          gadget: (
            <CustomMultiTextFieldWithSave
              data={initialData}
              onSave={(newValue) => {
                const newMetaData = {};
                Object.keys(newValue).map((key) => {
                  newMetaData[key] = newValue[key].value;
                });
                mutateClub({
                  id: clubId,
                  field: "metadata",
                  value: newMetaData,
                });
              }}
            />
          ),
        });
      else {
        municipalityInfoData.push({
          key: t("municipality.metadata.minParticipantCount"),
          value: data.metadata.minParticipantCount,
        });
      }

      municipalityInfo["data"] = municipalityInfoData;
      data1.push(municipalityInfo);
    }

    // <<<<<<<<<<<<<<<<<<<<<

    if (isAdmin(appUserRoles) && data.type?.name === "CLUB_TYPE_MUNICIPALITY") {
      const welcomeLetters = {
        header: t("clubData.clubWelcomeEmailHeader"),
      };
      const welcomeLettersData = [];
      welcomeLettersData.push({
        key: t("clubData.clubWelcomeEmailStatus"),
        value: welcomeEmailStatus,
      });
      welcomeLettersData.push({
        key: "",
        value: (
          <Button
            variant="outlined"
            sx={{ m: 1 }}
            onClick={() => {
              sendWelcomeFeathers(clubId);
            }}
          >
            {t("clubData.clubSendWelcomeEmailsButtonText")}
          </Button>
        ),
      });

      welcomeLetters["data"] = welcomeLettersData;
      data1.push(welcomeLetters);
    }

    // console.log(data1);
    return data1;
    // setClubData(data);
  };

  const cardData = useMemo(() => {
    // console.log("useEffect on ClubData " + clubId);
    // console.log(data);
    if (data) {
      return prepareData();
    }
    return [];
    // eslint-disable-next-line
  }, [data, t]);

  const copyToClipboard = (text) => {
    // Create a temporary input element and set its value to the text to copy
    const tempInput = document.createElement("input");
    tempInput.value = text;

    // Append the input to the DOM
    document.body.appendChild(tempInput);

    // Select the text in the input
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      {error && <ErrorAlert txt={error} />}
      {bulkCreateLessonsError && <ErrorAlert txt={bulkCreateLessonsError} />}
      {isLoading && <CircularProgress />}
      {cardData.map((row, index) => (
        <Card
          sx={{
            display: "inline-block",
            mx: "0.5em",
            my: "0.5em",
            minWidth: 300,
            maxWidth: 500,
            textAlign: "left",
          }}
          key={"clubDataCard" + clubId + index}
        >
          <CardContent key={"clubDataCardContent." + clubId + index}>
            {row.header && (
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                key={"clubDataTypo1." + clubId + index}
              >
                {row.header}
              </Typography>
            )}
            {row.data &&
              row.data.map((row, index) => (
                <Fragment key={"clubDataTypo2." + clubId + index}>
                  <Typography key={"clubDataTypo2." + clubId + index}>
                    {row.key && <strong>{row.key}: </strong>}
                    {row.value}
                  </Typography>
                  {row.gadget}
                  <Typography key={"clubDataTypo3." + clubId + index}>
                    {row.info ? row.info : ""}
                  </Typography>
                  <Typography key={"clubDataTypo4." + clubId + index}>
                    {row.note ? row.note : ""}
                  </Typography>
                </Fragment>
              ))}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
