import axios from "axios";
import { API } from "./API";

export const getLessonsForGroup = async (id) => {
  const { data } = await axios.get(`${API}/lessons/${id}`, {
    withCredentials: true,
  });

  return data.data.lessons;
};

export const getLessonsEntriesForLesson = async (id) => {
  const { data } = await axios.get(`${API}/lessons/entries/${id}`, {
    withCredentials: true,
  });

  return data.data.lessons;
};

export const updateLessonsEntriesForLesson = async (body) => {
  const { data } = await axios.put(
    `${API}/lessons/entries/${body.id}`,
    body.fields,
    {
      withCredentials: true,
    }
  );

  return data.data;
};
export const createLesson = async (body) => {
  const { data } = await axios.post(`${API}/lessons/create`, body, {
    withCredentials: true,
  });
  return data;
};

export const createLessonsForClub = async (clubId) => {
  const body = {
    clubId: clubId,
  };

  const { data } = await axios.post(`${API}/lessons/createLessons`, body, {
    withCredentials: true,
  });
  return data;
};

export const editLesson = async (body) => {
  if (!body.id || !body.fields) return undefined;
  const { data } = await axios.put(`${API}/lessons/${body.id}`, body.fields, {
    withCredentials: true,
  });
  return data;
};

export const deleteLesson = async (id) => {
  const { data } = await axios.delete(`${API}/lessons/${id}`, {
    withCredentials: true,
  });
  return data;
};
