import React from "react";
import { Alert, Typography, Box } from "@mui/material";

import { useTranslation } from "react-i18next";

const Summary = ({
  formData,
  selectedClub,
  registrationData,
  answers,
  registrationErrorMsg,
}) => {
  const { t } = useTranslation();

  function isRegistered() {
    return (
      registrationData?.code === "SUCCESS_PARTICIPATION_CREATED" &&
      !isRegistrationInError()
    );
  }

  function isWaitingList() {
    return isRegistered() && registrationData?.waitingList;
  }

  function isRegistrationInError() {
    return registrationErrorMsg ? true : false;
  }

  const SuccessMessage = () => (
    <Alert
      severity="success"
      sx={{ padding: "1rem" }}
      //sx={{ backgroundColor: "green" }}
    >
      <Typography variant="body1" sx={{ color: "darkgreen", m: 0.5 }}>
        {t("muniRegister.summary.success")}
      </Typography>
    </Alert>
  );

  const WarningMessage = () => (
    <Alert
      severity="warning"
      //sx={{ backgroundColor: "orange" }}
    >
      <Typography variant="body1" sx={{ color: "black" }}>
        {t("muniRegister.summary.waitingList")}
      </Typography>
    </Alert>
  );

  const ErrorMessage = () => (
    <Alert
      severity="error"
      //sx={{ backgroundColor: "orange" }}
    >
      <Typography variant="body1" sx={{ color: "black" }}>
        {t("muniRegister.summary.error.title")}
      </Typography>
      <Typography variant="body1" sx={{ color: "black" }}>
        {registrationErrorMsg
          ? t("muniRegister.summary.error." + registrationErrorMsg)
          : ""}
      </Typography>
    </Alert>
  );

  return (
    <Box elevation={3} sx={{ padding: "2rem" }}>
      <Typography variant="h5" component="h2" gutterBottom>
        {t("muniRegister.summary.title")}
      </Typography>
      <Box sx={{ padding: "1rem" }}>
        {isRegistered() && !isWaitingList() && <SuccessMessage />}
        {isRegistered() && isWaitingList() && <WarningMessage />}
        {isRegistrationInError(registrationErrorMsg) && <ErrorMessage />}
      </Box>
      <Typography variant="body1" gutterBottom>
        <strong>{t("muniRegister.summary.parent")}:</strong>{" "}
        {formData.parentFirstName} {formData.parentLastName}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>{t("muniRegister.summary.parentContact")}:</strong>{" "}
        {formData.parentEmail} {formData.parentPhone}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>{t("muniRegister.summary.child")}:</strong>{" "}
        {formData.childFirstName} {formData.childLastName}
      </Typography>
      {/* <Typography variant="body1" gutterBottom>
        <strong>Child's date of birth:</strong> {formData.childBirthDate}
      </Typography> */}
      <Typography variant="body1" gutterBottom>
        <strong>{t("muniRegister.summary.club")}:</strong>{" "}
        {selectedClub.municipality}
        {" - "}
        {selectedClub.school ? selectedClub.school + " - " : ""}
        {selectedClub.name}
      </Typography>{" "}
      {/* custom field answers not shown here */}
    </Box>
  );
};

export default Summary;
