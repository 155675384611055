import React, { useEffect, useState } from "react";
import "./club.css";
import { useMutation, useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import { editGroup, setGroupGedu } from "../Hooks/groups";

import { GeduSelect } from "../Util/geduSelect";
import { useTranslation } from "react-i18next";

import {
  Typography,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { getCodeDomainsByNames } from "../Hooks/codes";
import { getGedus } from "../Hooks/gedus";
import { isAdmin } from "../Util/authHelper";

export default function GroupInfo({ club, group, refetch, appAuthInfo }) {
  const { t } = useTranslation();
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [savingName, setSavingName] = useState(false);
  const [showGeduButtons, setShowGeduButtons] = useState(false);

  const [selectedGedu, setSelectedGedu] = useState(undefined);

  const { mutate: mutateGroupMainGedu } = useMutation({
    mutationFn: (params) =>
      setGroupGedu({ ...params, type: "ASSIGNMENT_TYPE_GROUP_MAIN_GEDU" }),
    onSuccess: () => {
      refetch();
    },
  });

  const { mutate: mutateAssistantGedu } = useMutation({
    mutationFn: (params) =>
      setGroupGedu({ ...params, type: "ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU" }),
    onSuccess: () => {
      refetch();
    },
  });

  const { data: dataAssignmentTypes } = useQuery(
    ["codes", ["ASSIGNMENT_TYPE"]],
    () => getCodeDomainsByNames(["ASSIGNMENT_TYPE"])
  );

  // const gedus = useQuery({
  //   queryKey: ["gedus"],
  //   queryFn: getGedus,
  // });

  const appUserRoles = appAuthInfo.appUserRoles;

  //   const [clubData, setClubData] = useState({});
  //   const clubId = params.clubId;
  //   const { data, error, isLoading } = useQuery(["clubId", clubId], () =>
  //     getClubByID(clubId)
  //   );

  //   useEffect(() => {
  //     // console.log("useEffect on ClubData " + clubId);
  //     if (data) {
  //       prepareData();
  //     }
  //     // eslint-disable-next-line
  //   }, [data]);

  //   const prepareData = async () => {
  //     // console.log("prepareData");
  //     // console.log(data);
  //     setClubData(data);
  //   };

  // refetch();

  useEffect(() => {
    if (group) {
      if (
        "GROUP_TYPE_DEFAULT" === group.type?.name ||
        "GROUP_TYPE_REGULAR" === group.type?.name
      ) {
        setShowGeduButtons(true);
      }
    }
  }, [group]);

  const saveNewName = async () => {
    setSavingName(true);
    await editGroup(group.id, { name: editedName });
    await refetch();
    setSavingName(false);
    setEditNameOpen(false);
  };

  const getGroupMainGedu = () => {
    let mainGedu = (group?.assignments ?? []).find(
      (assignment) => assignment.type === groupMainGeduCode?.id
    )?.user;
    if (!mainGedu) {
      // set the owner of the club to main gedu
      mainGedu = club.owner;
      if (mainGedu)
        // exists, set fromOwner flag to true
        mainGedu.fromOwner = true;
    }
    return mainGedu;
  };

  const groupMainGeduCode = ((dataAssignmentTypes ?? [[]])[0].codes ?? []).find(
    (code) => code.name === "ASSIGNMENT_TYPE_GROUP_MAIN_GEDU"
  );
  const groupMainGedu = getGroupMainGedu();

  const groupAssistantGeduCode = (
    (dataAssignmentTypes ?? [[]])[0].codes ?? []
  ).find((code) => code.name === "ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU");

  const groupAssistantGedu = (group?.assignments ?? []).find(
    (assignment) => assignment.type === groupAssistantGeduCode?.id
  )?.user;

  const isMainGeduFromOwner = () => {
    if (!groupMainGedu) return false;

    if (groupMainGedu.fromOwner) return true;

    return false;
  };

  return (
    <Card sx={{ m: 1, p: 2, display: showGeduButtons ? "block" : "none" }}>
      {groupMainGedu && (
        <Typography>
          {" "}
          {t("clubView.groupInfo.groupGedu")}:{" "}
          {`${groupMainGedu.firstName} ${groupMainGedu.lastName}`}{" "}
          {groupMainGedu.fromOwner ? t("clubView.groupInfo.fromOwner") : ""}
        </Typography>
      )}
      {groupAssistantGedu && (
        <Typography>
          {" "}
          {t("clubView.groupInfo.assistantGedu")}:{" "}
          {`${groupAssistantGedu.firstName} ${groupAssistantGedu.lastName}`}{" "}
        </Typography>
      )}
      {isAdmin(appUserRoles) && showGeduButtons && (
        <Button
          variant="outlined"
          sx={{ m: 1 }}
          onClick={() => {
            setEditedName(group.name);
            setEditNameOpen(true);
          }}
        >
          {t("clubView.groupInfo.editName")}
        </Button>
      )}
      {isAdmin(appUserRoles) && showGeduButtons && isMainGeduFromOwner() && (
        <GeduSelect
          defaultGedu={groupMainGedu}
          saveSelectedGedu={(newGedu) =>
            mutateGroupMainGedu({ groupId: group.id, geduUserId: newGedu.id })
          }
          label={t("clubView.groupInfo.chooseGroupGedu")}
        />
      )}
      {isAdmin(appUserRoles) && showGeduButtons && !isMainGeduFromOwner() && (
        <Button
          onClick={() => mutateGroupMainGedu({ groupId: group.id })}
          variant="outlined"
        >
          {t("clubView.groupInfo.removeGroupGedu")}
        </Button>
      )}
      {isAdmin(appUserRoles) && showGeduButtons && !groupAssistantGedu && (
        <GeduSelect
          defaultGedu={groupAssistantGedu}
          saveSelectedGedu={(newGedu) =>
            mutateAssistantGedu({ groupId: group.id, geduUserId: newGedu.id })
          }
          label={t("clubView.groupInfo.chooseAssistantGedu")}
        />
      )}
      {isAdmin(appUserRoles) && showGeduButtons && groupAssistantGedu && (
        <Button
          onClick={() => mutateAssistantGedu({ groupId: group.id })}
          variant="outlined"
        >
          {t("clubView.groupInfo.removeAssistantGedu")}
        </Button>
      )}
      {isAdmin(appUserRoles) && (
        <Typography sx={{ fontSize: "0.8rem", color: "text.secondary" }}>
          {t("clubView.groupInfo.groupId")}: {group.id}
        </Typography>
      )}
      {/* Edit group name dialog */}
      <Dialog open={editNameOpen} maxWidth="sm" fullWidth disableScrollLock>
        <DialogTitle>Muokkaa ryhmän nimeä</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Uusi nimi"
            fullWidth
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditNameOpen(false)}>
            {t("general.dialog.cancel")}
          </Button>
          <Button
            variant="outlined"
            onClick={saveNewName}
            endIcon={savingName ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
