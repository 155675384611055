import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const translationsEn = {
  general: {
    dialog: {
      yes: "Yes",
      no: "No",
      ok: "Ok",
      cancel: "Cancel",
      save: "Save",
    },
    update: "Update",
    delete: "Delete",
    cancel: "Cancel",
    close: "Close",
    add: "Add",
    create: "Create",
    remove: "Remove",
  },
  home: {
    welcomeTitle: "Welcome to",
    welcome: "The home of game education",
    clientVersion: "Client version:",
    serverVersion: "Server version:",
    profile: {
      title: "Your profile",
      level: "LEVEL",
      harmony: "HARMONY",
      glow: "GLOW",
      valor: "VALOR",
      commonSense: "COMMON SENSE",
      levelUp: "Level Up!",
      newAchievement: "New Achievement!",
      unlockAchievementForUser: "Unlock for user",
      achievements: {
        title: "Achievements:",
      },
    },
  },
  slotSelection: {
    newPsw: {
      infoTxt:
        "You dont have an account in this platform, please create a new one by just adding a password:",
      modal: {
        label: "New Password",
        submit: "Save Password",
      },
    },
    steps: {
      nextBtn: "Next",
      backBtn: "Back",
      finishBtn: "Finish",
      firstStep: {
        name: "Select Gamer",
        sayHi: "Hello {{firstName}} 👋",
        welcomeText: "Welcome to School of Gaming!",
        infoPurchase: "You have purchase",
        infoGamers: "First we need to know who will be attending the lessons.",
        addGamerInfo:
          "If you have a gamer added to Gamers Arena, you can select it or create a new one:",
        modalNewGamer: {
          firstName: "Child's First Name",
          lastName: "Child's Last Name",
          birthDate: "Child's Birthday",
          consentAge: "Consent age agreement",
          createGamerBtn: "Create Gamer",
          editGamerBtn: "Edit Gamer",
        },
      },
      secondStep: {
        name: "Select Slot",
        infoSlots1: "You can select",
        infoSlots2: "lesson",
        infoSlots3: "from our lesson schedule",
        weekDays: {
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday",
        },
      },
      thirdStep: {
        name: "Confirmation",
        confirmText: "Confirm data is correct",
        gamerTitle: "Gamer",
        slotTitle: "Slots",
      },
      fourthStep: {
        success: {
          title: "All steps completed - you have finished 🎉",
          p: "All the information will be send to your email address as a reminder.",
          p2: "You can modify your lesson selection and other customer information by logging into Gamers’s Arena.",
        },
        error: {
          tryAgain: "Please try again",
          goBackBtn: "Go Back",
        },
      },
    },
  },
  muniRegister: {
    steps: {
      participant: "Basic information",
      selectClub: "Hobby",
      additionalQuestions: "Additional questions",
      confirmRegistration: "Confirmation",
    },
    participant: {
      parentInfoHeader: "Parent's information",
      parentFirstName: "Parent's first name",
      parentLastName: "Parent's last name",
      parentPhone: "Parent's phone number",
      parentEmail: "Parent's email",
      childInfoHeader: "Child's information",
      childFirstName: "Child's first name",
      childLastName: "Child's last name",
      childDateOfBirth: "Child's date of birth",
      infoValidPhone:
        "Please enter a valid phone number with the country code.",
      infoValidEmail: "Please enter a valid email address.",
    },
    clubSelect: {
      municipalitySelect: "Municipality",
      schoolSelect: "School",
      clubSelect: "Hobby club",
    },
    additionalInfo: {
      additionalInfo: "Other information",
    },
    summary: {
      title: "Registration summary",
      parent: "Parent",
      parentContact: "Parent's contact information",
      child: "Child",
      club: "Selected club",
      success:
        "Thank you for registration! You will be contacted through the email before the clubs starts. Welcome!",
      waitingList:
        "You have been added to the waiting list of the club. We will inform you through email if there will be seats available.",
    },
    next: "Next",
    back: "Back",
    confirm: "Register",
  },

  discord: {
    connecting: "Connecting to Discord...",
    success: "Discord has been connected. Continue on Discord.",
    failure: "Open the link from the email and try again.",
    clickjack: "Open the link from the email and try again.",
    authorizationCancelled: "Authorization canceled.",
    missingToken: "The link you used is missing the token.",
    noStateError:
      "Error occured while preparing the connection. Please try again.\nIf the error persists, try using a different browser.",
    invalidToken: "The link you used is invalid.",
    tokenExpired: "The link you used has expired.",
    unknownError: "Something went wrong. Please try again.",
    discordConnectSuccess:
      "Discord has been connected successfully. Open Discord to attend lessons.",
  },
  navbar: {
    home: "Home",
    clubCalendar: "Club Calendar",
    activities: "Gamer's Gym",
    geduInvoicing: "Invoicing",
    geduInvoicing4Admin: "Gedu-invoicing",
    reports: "Reports",
    search: "Search",
    adminTitle: "Admin",
    reportMenu: {
      muniInvoicing: "Muni Invoicing",
      muniInvoicingSummary: "Muni Summary",
      trusterData: "Truster Invoicing",
      geduInvoicingStatus: "Gedu Invoicing Status",
    },
    userSearch: "User Search",
    searchMenu: {
      geduSearch: "Gedu Search",
      gamerSearch: "Gamer Search",
      parentSearch: "Parent Search",
      geduAvailability: "Substitute Search",
      clubSearch: "Club Search",
    },
    adminMenu: {
      achievements: "Achievements",
      clubs: "Clubs",
      codes: "Codes",
      users: "Users",
      municipalities: "Municipalities",
      activities: "Gamer's Gym",
      clubInstuctions: "Club instructions",
    },
    logout: "Logout",
    languageSelectorLabel: "Language",
  },
  clubCalendar: {
    tableHeader_Id: "Id",
    tableHeader_SKU: "SKU",
    tableHeader_Name: "Name",
    tableHeader_Status: "Status",
    tableHeader_clubtype: "Type",
    tableHeader_clubcontenttype: "Content",
    tableHeader_clubparticipantCount: "Participants",
    tableHeader_Attendance: "Attendance",
    tableHeader_Language: "Language",
    tableHeader_Weekday: "Weekday",
    tableHeader_MeetingTimes: "Meeting times",
    tableHeader_RegistrationEnabled: "Registration",
    tableHeader_Owner: "Club gedu",
    tableHeader_Time: "Time",
    tableHeader_StartTime: "Start time",
    tableHeader_EndTime: "End time",
    tableHeader_Country: "Country",
    tableHeader_StartDate: "Start date",

    filter_byStatusPlaceholder: "Filter by status...",
    filter_byTypePlaceholder: "Filter by type...",
    filter_byMunicipalityPlaceholder: "Filter by municipality...",
    filter_byGedu: "Filter by gedu...",
    filter_byAttendanceTypePlaceholder: "Filter by attendance type...",
  },
  clubView: {
    group: "Group",
    chooseGedu: "Choose gedu",
    chooseClubInstructions: "Choose club instructions",
    addGroup: "Add group",
    addCancelledGroup: "Add dropouts group",
    defaultGroupName: "Default group",
    cancelledGroupName: "Dropouts",
    waitingListGroupName: "Waiting list",
    regularGroupName: "New group",
    clubData: "Club info",
    lessonData: "Lessons",
    participantData: "Participants",

    chooseClubInstructions: "Choose club instructions",

    groupInfo: {
      editName: "Edit group name",
      chooseGroupGedu: "Choose group gedu",
      chooseAssistantGedu: "Choose assistant gedu",
      groupGedu: "Group gedu",
      fromOwner: "(from club gedu)",
      assistantGedu: "Assistant gedu",
      removeGroupGedu: "Remove group gedu",
      removeAssistantGedu: "Remove assistant gedu",
      groupId: "Group id",
    },
  },
  clubData: {
    currency: "Currency",
    generalInfoHeader: "General info",
    clubName: "Club name",
    sku: "SKU",
    language: "Language",
    ownerHeader: "Club gedu",
    ownerName: "Name",
    ownerEmail: "Email",
    ownerPhoneNumber: "Phone number",
    ownerUsername: "Username",
    changeOwner: "Change",
    clubTypsHeader: "Club type information",
    status: "Status",
    attendance: "Attendance",
    type: "Type",
    contentType: "Content",
    meetingTimesHeader: "Meeting times",
    clubId: "Club id",
    groupId: "Default group id",
    discordRoleName: "Discord role",
    discordGuildName: "Discord guild (server)",
    clubFeesHeader: "Club fees",
    mainGeduFee: "Club gedu fee",
    groupGeduFee: "Group gedu fee",
    assistantGeduFee: "Assistant gedu fee",
    municipalityFee: "Municipality payment",
    municipality: "Municipality",

    geduClubFeesHeader: "Clubfee for gedu",
    geduClubFeeTitle: "Clubfee",
    geduClubFeeInfo: "Based on role: {{role}}",
    geduClubFeeNote:
      "If you notice any missing fee information, please contact staff!",

    geduClubFeeRole: {
      ASSISTANT: "Assistant",
      GROUPMAIN: "Group gedu",
      OWNER: "Club gedu",
      undefined: "Not defined",
      NO_TYPE: "No role given",
    },

    additionalInfoHeader: "Additional info (visible in welcome letter)",
    clubMaterialHeader: "Link to club material (Google Drive)",
    copyToClipboard: "Copy",
    clubInstructionsHeader: "Club instructions",
    clubInstructionsName: "Name",
    clubInstructionsIntroduction: "Introduction",
    clubInstructionsInstructions: "Instructions",
    changeClubInstructions: "Change",

    clubSendWelcomeEmailsButtonText: "Send welcome emails",
    clubWelcomeEmailHeader: "Welcome emails",
    clubWelcomeEmailStatus: "Status",

    municipalityAdditionalDataHeader:
      "Municipality club additional information",

    periodHeader: "Club period",
    startDate: "Start date",
    endDate: "End date",
    bulkCreateLessons: "Create lessons for period",
    bulkLessonCreationStatus: "Status",
    bulkCreateStarted: "Creating lessons ...",
    registrationEnabled: "Registration state",
    registrationActive: "Active",
    registrationInactive: "Inactive",
    editMeetings: "Edit meetings",
    edit: "Edit",
    changeType: "Change club type",
    clubGeneralInfoEditor: "Edit general information",
    clubTypeEditor: "Edit club type",
  },
  clubLessons: {
    addNewLessonCardHeader: "Add new lesson",
    create: "Create",
    update: "Update",
    createNewLessonTitle: "Create new lesson",
    editLessonTitle: "Edit lesson",
    updateLessonTitle: "Update lesson",
    selectDate: "Select date:",
    selectHour: "Select starting time:",
    selectLessonStatus: "Lesson status:",
    notesLabel: "Lesson report",
    editParticipants: "Participants...",
    editLesson: "Edit...",
    removeLessonConfirmText: "Are you sure you want to delete lesson:",
    lessonEntriesTitle: "Register participants",
    lessonEntriesTitleNoEdit: "Participants",
    selectedCount: "Selected",
    lessonEntriesSave: "Save",
    lessonEntryRewards: "Lesson Rewards",
    addNewParticipantCardHeader: "Add new participant",
    reward: "Reward",
    amount: "Amount",
    gedu: "Gedu",
    assistantGedu: "Assistant gedu",
    substituteGedu: "Substitute gedu",
    substituteAssistantGedu: "Substitute assistant gedu",
    lessonRewardGoodBehavior: "Extra reward for lesson: Good Behavior",
    chooseSubstituteGedu: "Choose substitute gedu",
    chooseSubstituteAssistant: "Choose substitute assistant gedu",
    removeSubstituteGedu: "Remove substitute gedu",
    removeSubstituteAssistant: "Remove substitute assistant gedu",
    muniInvoicingStatus: "Invoicing",
  },
  participants: {
    gamerName: "Gamer",
    gamerDiscordNick: "Discord",
    parentName: "Parent",
    waitingList: "On waiting list",
    subscriptionStatus: "Subscription",
    birthDate: "Birthdate",
    gender: "Gender",
    registrationDate: "Registration",
    copyAllEmails: "Copy emails",
    copyAllEmailsCopied: "All email of the group copied!",
    welcomeEmailStatus: "welcome email",
    additionalInformation: "Information",
    notes: "Notes",
    noLongerInGroup: "No longer in group",
    addNewParticipant: {
      title: "Add new participant",
      description:
        "Insert the subscription id and fetch the existing data. After that fill in the missing gamer data and add the new participant.",
      gamerFound:
        "Subscription already has a gamer attached, cannot add participant",
      subscriptionId: "Subscription id",
      fetchButton: "Fetch",
      parentFirstName: "Parent's first name",
      parentLastName: "Parent's last name",
      email: "Email",
      gamerFirstName: "Gamer's first name",
      gamerLastName: "Gamer's last name",
      birthDate: "Gamer's date of birth",
      discordId: "Discord id (Parent's if child under 13)",
      SUBSCRIPTION_NOT_FOUND: "Subscription not found",
      INTERNAL_SERVER_ERROR: "General error. Try Again!",
    },
    changeGamerClub: {
      title: "Change club",
      description: "You can change the club of the participant.",
      selectNewClubPlaceholder: "Select new club",
      errorFetchingOtherClubsData: "Error fetching clubs!",
      changeButtonText: "Change",
    },
    changeGamerGroup: {
      title: "Change group",
      description: "You can change the group of the participant.",
      selectNewClubPlaceholder: "Select new group",
      errorFetchingOtherClubsData: "Error fetching groups!",
      changeButtonText: "Change",
    },
    changeClubButtonText: "Change club...",
    changeGroupButtonText: "Change group...",
  },
  participantRegistration: {
    title: "Municipality participant registration",
    gamerGender: "Gender",
    gamerBirthdate: "Birthdate",
    gamerFirstName: "First name",
    gamerLastName: "Last name",
    parentFirstName: "Parent's first name",
    parentLastName: "Parent's last name",
    parentEmail: "Parent's email",
    parentPhoneNumber: "Parent's phone number",
  },

  clubs: {
    FI_MINE_MON_1: "Minecraft Megabuilders (Mon)",
    FI_TECH_MON_1: "Technology Server Secrets",
    FI_MINE_TUE_1: "Minecraft Basics (Bedrock)",
    FI_ESPT_TUE_1: "Esports Team (Tue)",
    FI_TECH_TUE_1: "Technology Coding basics",
    FI_TECH_WED_1: "Technology Game Design",
    FI_MINE_WED_1: "Minecraft Survivors",
    FI_ESPT_THU_1: "Esports Team (Thu)",
    FI_MINE_THU_1: "Minecraft Megabuilders (Thu)",
    FI_MINE_FRI_1: "Minecraft Creative Building (Fri)",
    FI_TECH_FRI_1: "Technology Roblox (Fri)",
    FI_ESPT_FRI_1: "Beginner Esports",
    UN_MINE_SAT_1: "Minecraft in English",
    FI_MINE_SAT_2: "Minecraft Creative building (Sat)",
    FI_TECH_SAT_1: "Technology Roblox (Sat)",
    FI_GILD_SAT_1: "Farming Simulator Guild",
    FI_HENGARI: "Hangaround club",
    TEST_CLUB: "Niina's own test club",
    TEST_CLUB2: "Test club 2",
    TEST_CLUB3: "Test club 3",
    "FI-MINE-FRI-2": "Minecraft teenager special",
    FI_ROCL_FRI_1: "Rocket Leagues Guild",
    FI_OLOH_MON_1: "Monday's living room (Finnish)",
    FI_OLOH_TUE_1: "Tuesday's living room (Finnish)",
    FI_OLOH_WED_1: "Wednesday's living room (Finnish)",
    FI_OLOH_THU_1: "Thursday's living room (Finnish)",
    FI_OLOH_FRI_1: "Friday's living room (Finnish)",
    FI_OLOH_FRI_2: "Friday's second living room (Finnish)",
    FI_OLOH_SAT_1: "Saturday's living room (Finnish)",

    SIN_SINGAPORE_XCLWORLDACADEMY_EJE_MA_1: "Singapore XCL World Academy",
    IT_TURIN_INTERNATIONALSCHOOL_OMA_MA_1: "Turin International School",

    FI_OW_SUM24_THU_1: "Esports (Overwatch) Thursday",
    FI_OW_SUM24_TUE_1: "Esports (Overwatch) Tuesday",
    FI_OLOH_SUM24_FRI_1: "Friday's living room",
    FI_OLOH_SUM24_THU_1: "Summer living room Thursday",
    FI_OLOH_SUM24_WED_1: "Summer living room Wednesday",
    FI_OLOH_SUM24_TUE_1: "Summer living room Tuesday",
    FI_OLOH_SUM24_MON_1: "Summer living room Monday",
    FI_FORT_SUM24_THU_1: "Esports (Fortnite) Thursday",
    FI_MINE_SUM24_THU_2: "Minecraft 13-16 Thursday",
    FI_MINE_SUM24_THU_1: "Minecraft 7-10 Spelheim Thursday",
    FI_MINE_SUM24_WED_1: "Minecraft 9-12 Spelheim Wednesday",
    FI_TECH_SUM24_WED_2: "Roblox 9-12 Wednesday",
    FI_TECH_SUM24_WED_1: "Game studio 8-10 Wednesday",
    FI_FORT_SUM24_TUE_1: "Esports (Fortnite) Tuesday",
    FI_MINE_SUM24_TUE_2: "Minecraft 9-12 Younglings Tuesday",
    FI_MINE_SUM24_TUE_1: "Minecraft 7-10 Youngsters Tuesday",
    FI_MINE_SUM24_MON_1: "Minecraft 11-15 Builders Monday",
    FI_TECH_SUM24_MON_1: "Level One 6-8 Monday",
    FI_RL_SUM24_THU_1: "Esports (Rocket League) Thursday",
    FI_PELI_THU_1: "Aikuisten pelikerho",
  },
  admin: {
    achievements: {
      title: "Achievements",
      achievementTitle: "Title",
      achievementDescription: "Description",
      achievementUnlockCondition: "Unlock condition",
      achievementImage: "Image",
      editAchievement: "Edit Achievement",
      createNewAchievement: "Create New Achievement",
    },
    clubs: {
      tableHeader_Id: "Id",
      tableHeader_SKU: "SKU",
      tableHeader_Name: "Name",
      tableHeader_Status: "Status",
      tableHeader_clubtype: "Type",
      tableHeader_clubcontenttype: "Content",
      tableHeader_clubparticipantCount: "Participants",
      tableHeader_Attendance: "Attendance",
      tableHeader_Language: "Language",
      tableHeader_Weekday: "Weekday",
      tableHeader_MeetingTimes: "Meeting times",
      tableHeader_RegistrationEnabled: "Registration",
      tableHeader_StartTime: "Start time",
      tableHeader_EndTime: "End time",
      tableHeader_Country: "Country",
      tableHeader_StartDate: "Start date",

      addClub_Name: "Name",
      addClub_SKU: "SKU",
      addClub_Status: "Status",
      addClub_Attendance: "Attendance",
      addClub_Language: "Language",
      addClub_maxParticipants: "Max number of participants",
      addClub_discordRoleId: "Discord role id",
      addClub_discordGuildId: "Discord server (guild) id (default: SOG Suomi)",
      addClub_Country: "Country",
      addClub_Type: "Type",
      addClub_StatusFirstEntry: "Select status...",
      addClub_TypeFirstEntry: "Select type...",
      addClub_ContentTypeFirstEntry: "Select content type...",
      addClub_AttandanceFirstEntry: "Select type...",
      addClub_CountryFirstEntry: "Select country...",
      addClub_LanguageFirstEntry: "Select language...",

      meetingTimes_info: "Select meeting times for the club",
      meetingTimes_startTime: "Start time",
      meetingTimes_endTime: "End time",
      meetingTimes_SubmitButtonText: "Save meeting times",

      addClubInstructions_Introduction: "Introduction",
      addClubInstructions_Instructions: "Instructions",

      addClubInstructions_Type: "Type",
      addClubInstructions_TypeFirstEntry: "Select type...",
    },
    users: {
      title: "Users",
      tableHeader_firstname: "First name",
      tableHeader_lastname: "Last name",
      tableHeader_username: "Username",
      tableHeader_email: "Email",
      tableHeader_roles: "Roles",
      tableHeader_gamers: "Gamers",

      deleteUserButton: "Remove selected users",
      addUserButton: "Add user...",
      gamersButton: "Gamers",

      gamers_info: "Gamers of selected user",
      gamers_invitationUrl: "Discord invitation link",

      addUser_FirstName: "First name",
      addUser_LastName: "Last name",
      addUser_Password: "Password",
      addUser_Email: "Email",
      addUser_Username: "Username",
      addUser_Role: "Role",
      addUser_Locale: "Language ID",
      addUser_discordId: "Discord ID",
      addUser_phonenumber: "Phone number",

      addUser_EmailAlreadyInUse: "Email already in use",
      addUser_UsernameAlreadyInUse: "Username already in use",

      addUser_RoleFirstEntry: "Select role...",
      addUser_SubmitButtonText: "Save User",

      setRoles_info: "Select user roles",
      setRoles_SubmitButtonText: "Save user roles",
    },
    activities: {
      tableHeader_Name: "Activity",
      tableHeader_Description: "Description",
      tableHeader_Image: "Image",

      addActivity_Name: "Activity Name",
      addActivity_Desctiption: "Description",
      addActivity_Image: "Select Image",
    },
    clubInstructions: {
      tableHeader_Id: "Id",
      tableHeader_Name: "Name",
      tableHeader_type: "Type",
      tableHeader_introduction: "Introduction",
      tableHeader_instructions: "Instructions",
    },
  },
  weekday: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    unscheduled: "No schedule",

    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
  },
  weekdayShort: {
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",

    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
  },

  codedomain: {
    CLUB_STATUS: "Club state",
    PARTICIPANT_STATUS: "Participant status",
    CLUB_ATTANDANCE_TYPE: "Club attendance type",
    CLUB_TYPE: "Club type",
    GROUP_TYPE: "Group type",
    GEDU_BILLING_METHOD: "Billing method",
  },
  code: {
    undefined: "Unknown code",
    CLUB_STATUS_ONGOING: "On-going",
    CLUB_STATUS_PLANNED: "Planned",
    CLUB_STATUS_ENDED: "Ended",
    CLUB_STATUS_ONHOLD: "On-hold",
    CLUB_STATUS_ARCHIVED: "Archived",

    CLUB_ATTENDANCE_ONSITE: "Onsite",
    CLUB_ATTENDANCE_UNKNOWN: "Unknown",
    CLUB_ATTENDANCE_REMOTE: "Remote",

    CLUB_TYPE_CONSUMER: "Consumer",
    CLUB_TYPE_COMMUNITY: "Community",
    CLUB_TYPE_MUNICIPALITY: "Municipality",
    CLUB_TYPE_INTERNATIONAL: "International",

    USER_ROLE_ADMIN: "Admin",
    USER_ROLE_GEDU: "Gedu",
    USER_ROLE_PARENT: "Parent",
    USER_ROLE_GAMER: "Gamer",
    USER_ROLE_EXGEDU: "ExGedu",

    CLUB_CONTENT_TYPE_ESPORT: "Esport",
    CLUB_CONTENT_TYPE_MINECRAFT: "Minecraft",
    CLUB_CONTENT_TYPE_TECH: "Technology",

    CLUB_CONTENT_TYPE_GAME_STUDIO: "Our game studio",
    CLUB_CONTENT_TYPE_EGANG: "Our eGang",
    CLUB_CONTENT_TYPE_GRAND_ADVENTURES: "Grand Adventures",
    CLUB_CONTENT_TYPE_SOGWOOD: "Sogwood",
    CLUB_CONTENT_TYPE_POKEMON_GO: "Pokemon Go",
    CLUB_CONTENT_TYPE_SERVER_MODERATOR: "Server moderator",

    CLUB_CONTENT_TYPE_INTERNATIONAL: "International (not in use)",
    CLUB_CONTENT_TYPE_GUILD: "Guild (not in use)",

    GROUP_TYPE_DEFAULT: "Default",
    GROUP_TYPE_REGULAR: "Regular",

    LESSON_STATUS_UNDONE: "Scheduled",
    LESSON_STATUS_DONE: "Done",
    LESSON_STATUS_CANCELLEDBYMUNICIPALITY: "Cancelled by municipality",
    LESSON_STATUS_CANCELLEDBYSOG: "Cancelled by SOG",
    LESSON_STATUS_CANCELLED: "Cancelled (Other reason)",
    LESSON_STATUS_HOLIDAY: "Holiday",
    LESSON_STATUS_RESCHEDULED: "Rescheduled",

    LESSON_ENTRY_STATUS_PRESENT: "Present",
    LESSON_ENTRY_STATUS_NOT_PRESENT: "Not present",

    USER_GENDER_FEMALE: "Girl",
    USER_GENDER_MALE: "Boy",
    USER_GENDER_UNDISCLOSED: "Undisclosed",

    GAMER_PROFILE_VARIABLE_HARMONY: "Harmony",
    GAMER_PROFILE_VARIABLE_GLOW: "Glow",
    GAMER_PROFILE_VARIABLE_VALOR: "Valor",
    GAMER_PROFILE_VARIABLE_COMMON_SENSE: "Common Sense",
    GAMER_PROFILE_VARIABLE_NUM_LESSONS_ATTENDED: "Number of lessons attended",
    GAMER_PROFILE_VARIABLE_GOOD_BEHAVIOR: "Good Behavior",
    GAMER_PROFILE_VARIABLE_GAMERS_GYM_EXERCISES_DONE:
      "Gamer's Gym exercises done",

    GAMER_PROFILE_VARIABLE_NUM_ESPORTS_LESSONS: "Number of esports lessons",
    GAMER_PROFILE_VARIABLE_NUM_TECH_LESSONS: "Number of tech lessons",
    GAMER_PROFILE_VARIABLE_NUM_MINECRAFT_LESSONS: "Number of Minecraft lessons",

    ASSIGNMENT_TYPE_GROUP_MAIN_GEDU: "Group main gedu",
    ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU: "Group assistant gedu",

    CURRENCY_EUR: "EUR",

    CLUB_INSTRUCTION_MUNICIPALITY_WELCOME: "Welcome letter (municipality)",
    CLUB_INSTRUCTION_CONSUMER_WELCOME: "Welcome letter (consumer)",

    INVOICING_STATUS_INVOICED: "Invoiced",
    INVOICING_STATUS_GEDU_APPROVED: "Waiting for approval",
    INVOICING_STATUS_MUNI_APPROVED: "Municipality approved",
    INVOICING_STATUS_APPROVED: "Approved",
    INVOICING_STATUS_REJECTED: "Rejected",
    INVOICING_STATUS_PAID: "Paid",
    INVOICING_STATUS_UNHANDLED: "Waiting to be ready",

    UNKNOWN_CODE: "Unknown",

    INVOICING_PERIOD_TYPE_STANDARD: "Standard",

    GEDU_INVOICING_TYPE_MAIN_GEDU: "Clubgedu",
    GEDU_INVOICING_TYPE_NORMAL_GEDU: "Groupgedu",
    GEDU_INVOICING_TYPE_ASSISTANT_GEDU: "Assistant",

    GEDU_BILLING_METHOD_NONE: "No invoicing",
    GEDU_BILLING_METHOD_INVOICE: "Invoice",
    GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE: "Truster collective",
    GEDU_BILLING_METHOD_STAFF: "Staff",
    GEDU_BILLING_METHOD_STAFF_HOUR: "Staff by hour",

    CLUB_LANGUAGE_FINNISH: "Finnish",
    CLUB_LANGUAGE_ENGLISH: "English",
    CLUB_LANGUAGE_SWEDISH: "Swedish",
    CLUB_LANGUAGE_SIGN_LANGUAGE: "Sign language",

    GEDU_QUALIFICATION_NEURODIVERSITY: "Neurodiversity",
    GEDU_QUALIFICATION_PEDAGOGICAL_EDUCATION: "Pedagogical education",
  },

  role: {
    USER_ROLE_GAMER: "Gamer",
    USER_ROLE_PARENT: "Parent",
    USER_ROLE_GEDU: "Gedu",
    USER_ROLE_ADMIN: "Admin",
    USER_ROLE_EXGEDU: "ExGedu",
  },

  subscription: {
    status: {
      active: "Active",
      non_renewing: "Pending cancellation",
      cancelled: "Cancelled",
      future: "Future",
      in_trial: "In trial",
      paused: "Paused",
      one_time: "One-time purchase",
      unknown: "Unknown",
    },
  },
  customField: {
    only_hobby_label: "Is this your child's only hobby?",
    value: {
      only_hobby_value_yes: "Yes",
      only_hobby_value_no: "No",
    },
  },
  customTextField: {
    title: "",
    save: "Save",
  },

  municipality: {
    metadata: {
      minParticipantCount: "Minimum participant count",
    },
  },
  clubFeeEditor: {
    openClubFeeEditor: "Edit fees",
    currencyLabel: "Currency",
    mainGeduFeeLabel: "Club gedu fee",
    groupGeduFeeLabel: "Group gedu fee",
    assistantGeduFeeLabel: "Assistant gedu fee",
    municipalityFeeLabel: "Municipality invoicing",
  },
  invoicingStatus: {
    title: "Open invoicing periods",
    adminTitle: "Gedu approved periods",
    approvedDate: "Ready",
    sogApprovedDate: "Approved",
    billingMethodNotSet: "Billing method missing!",
  },

  geduInvoicing: {
    currentMonth: "Current month",
    previousMonth: "Previous month",
    nextMonth: "Next month",
    startDate: "Start date",
    endDate: "End date",
    submit: "Submit",
    periodTitle: "Periods for",
    geduSelector: "Select gedu",
    periodId: "Period id",
    noPeriodHeader: "Items without period",
    invoicingMethodTitle: "Invoicing method",
    billingMethodNotSet: "Not set",
  },
  invoicingLines: {
    substitute: "Substitute",
    summarySubHeaderText: "Summary",
    errorSummarySubHeaderText: "Errors in period",
    errorSummaryDesciptionGedu:
      "Contact staff to fix the errors. You cannot finalize the invoicing before the errors are fixed.",
    errorSummaryDesciptionAdmin:
      "Most likely reason is that the club is missing price information, add missing prices and ask gedu to recalculate the period.",

    totalInvoicing: "Total",
    totalNumberOfLessons: "Total number of lessons",
    actionsSubHeaderText: "Toimenpiteet",
    geduApproval: "Ready",
    sogApproval: "Approve",
    sogReject: "Reject",
    invoiced: "Invoiced",
    priceError: "Price error",
    missmatchInWeekdays: "Missmatch in weekdays!",

    trusterGeduInvoicingInfo:
      "When participating Truster collective invoicing do not send us an invoice. Also there is no need to mark invoice period Invoiced. Everything is done automatically after invoicing period is approved.",

    CLUB_TYPE_CONSUMER: "Consumer clubs",
    CLUB_TYPE_COMMUNITY: "Community clubs",
    CLUB_TYPE_MUNICIPALITY: "Municipality clubs",
    CLUB_TYPE_INTERNATIONAL: "International clubs",

    selectInvoicingPeriodButtonText: "Move",
    moveLinesToPeriodHeader: "Move above lines to period:",
    openPeriodsLabel: "Open invoicing periods",
  },
  muniReport: {
    selectCountry: "Select country",
    selectMunicipality: "Select municipality",
    isEstimate: "Estimate",
    isError: "Error",
    openClub: "Open club",
    copyClubName: "Copy club name",
    lessonStatus: "Lesson status",
    municipalityName: "Name",
    municipalityContact: "Contact",
    paymentTerm: "Payment term",
    paymentTermEditHeader: "Payment term (only days, no text)",
    paymentTermDays: "days net",
    reference: "Reference",
    invoicingAdditiontalInfo: "Additional info",
    infoNotProvided: "Information not provided",
    municipalityTotalNumberOfClubs: "Total number of clubs",
    municipalityTotalInvoicing: "Total invoicing",
    InvoicingPerPaymentTerm: "Invoicing / payment term",
    NoPaymentTerm: "Not defined",
    resetInvoicingStatus: "Reset",
    resetInvoicingStatusAll: "Reset All",
    sogApprovalAll: "Approve All",
    invoicedAll: "Invoiced All",
    totalIncome: "Total income",
    includingVAT: "(VAT 24%)",
    income: "Income",
    totalCost: "Total cost",
    cost: "Cost",
    school: "School",
    numberOfClubs: "Number of clubs",
    numberOfMunicipalities: "Number of municipalities",
    costRate: "Cost %",
    numberOfLessons: "Number of lessons",

    editInvoicingInfoButtonText: "Edit invoicing info",
    editInvoicingInfoHeaderText: "Edit invoicing info",

    summaryStatus: {
      Estimated: "Estimated",
      Approved: "Approved",
      Invoiced: "Invoiced",
    },

    INVOICING_STATUS_UNHANDLED: "Waiting for approval",
    INVOICING_STATUS_REJECTED: "Rejected",
    INVOICING_STATUS_APPROVED: "Approved",
    INVOICING_STATUS_INVOICED: "Invoiced",
    INVOICING_STATUS_PAID: "Paid",
    LESSON_INVOICING_STATUS_NOT_SET: "No invoicing status set",
    LESSON_INVOICING_STATUS_IGNORED: "Ignored",
    undefined: "Unknown",
    MIXED: "Mixed",
    invoicingStatus: {
      short: {
        INVOICING_STATUS_UNHANDLED: "Waiting",
        INVOICING_STATUS_REJECTED: "Rejected",
        INVOICING_STATUS_APPROVED: "Approved",
        INVOICING_STATUS_INVOICED: "Invoiced",
        INVOICING_STATUS_PAID: "Paid",
        LESSON_INVOICING_STATUS_NOT_SET: "Not set",
        LESSON_INVOICING_STATUS_IGNORED: "Ignored",
        undefined: "Unknown",
        MIXED: "Mixed",
      },
    },
  },
  trusterReport: {
    submit: "Get report",
    invocingEmail: "Invoicing email",
    periodTitle: "Period",
    otherStatuses: "Other statuses",
    geduTotal: "Total",
    geduTotalAllGedus: "Total (all gedus)",
    submitCSV: "Download CSV",
    submitJSON: "Download JSON",
    submitMarkApprovedPeriodInvoiced: "Mark periods invoiced",
  },
  geduInvoicingStatusReport: {
    title: "Gedu invoicing status",
    submit: "Get report",
    statusChanges: "Status changes",
    total: "Total",
    undefinedAmount: "error",
    totalIncludingVAT: "Total (VAT)",
  },
  geduSearch: {
    title: "Gedu search",
    searchButtonText: "Search",
    searchLabel:
      "Search by name, email, phonenumber, discord id, username or nick",
    searchHelpText:
      "Note! Works now even with discord user name or nick name!!!!",
    invoicingMethod: "Invoicing method",
    languages: "Languages",
    locations: "Locations",
    id: "Database id",
  },
  gamerSearch: {
    title: "Gamer search",
    searchButtonText: "Search",
    searchLabel:
      "Search by name, email, phonenumber, discord id, username or nick",
    searchHelpText:
      "Note! Works now even with discord user name or nick name!!!!",
    id: "Database id",
    subscriptionPlans: "Subscriptions",
    clubs: "Clubs",
    parents: "Parents",
    muniGamer: "Municipality gamer",
    consumerGamer: "Consumer gamer",
    gamerTypeLabel: "Gamer type",
    gamerTypeAll: "All",
    gamerTypeMuni: "Municipality",
    gamerTypeConsumer: "Consumer",
  },
  parentSearch: {
    title: "Parent search",
    searchButtonText: "Search",
    searchLabel:
      "Search by name, email, phonenumber, discord id, username or nick",
    searchHelpText:
      "Note! Works now even with discord user name or nick name!!!!",
    id: "Database id",
    gamers: "Gamers",
    subscriptions: "Subscriptions",
  },
  geduSubstituteSearch: {
    title: "Substitute search",
    findButtonText: "Find",
    searchHelpText:
      "Find potential substitutes who are free on given time and day",
    weekday: "Weekday",
    startTime: "Start time",
    endTime: "End time",

    languageSelectorPlaceholder: "Select language...",
    skillSelectorPlaceholder: "Select skill...",
    qualificationSelectorPlaceholder: "Select qualification...",
    locationSelectorPlaceholder: "Select location...",
    attandanceTypeSelectorPlaceholder: "Select attendance type...",
  },
  geduDetails: {
    roles: "Roles",
    lastLogin: "Last login",
    lastLoginNever: "Never",
    inactive: "Inactive",
    active: "Active",

    criminalRecordChecked: "Criminal record checked",
    criminalRecordNotChecked: "Not checked",
    setCriminalRecordChecked: "Set checked",
    updateCriminalRecordChecked: "Update checked",

    deactivateButtonText: "Deactivate",
    activateButtonText: "Activate",

    userId: "User id",
    title: "Game Educator details",
    invoicingDataTitle: "Invoice data",
    invoicingMethod: "Invoicing method",
    invoicingMethodNotSelected: "Not selected",
    invoicingEmail: "Invoicing email",

    filteringDataTitle: "Filtering data",
    languages: "Languages",
    skills: "Content skills",
    qualifications: "Other qualifications",
    locations: "Locations",
    attandanceTypes: "Attendance types",

    webFlowTitle: "WebFlow",
    clubsTitle: "Clubs",
    groupName: "Group",
    discordIdButtonText: "Discord Id",
    discordIdLabel: "Discord Id",
    setDiscordId: "Game Educators Discord Id",
    role: "Role",
    clubId: "Club id",
    webflow: {
      isActive: "Is active gedu",
      description: "Public description",
      imageLink: "Public image link",
      email: "Email",
      isExperienceArchitect: "Is experience architect",
      discord: "Discord",
      name: "Name",
      id: "Webflow Collection Id",
      updateStatus: "Update status",
      updateSuccess: "Webflow data updated successfully",
    },
    webflowInformationEdit: {
      setWebflowDataButtonText: "Set Webflow data",
      createWebflowDataButtonText: "Create Webflow data",
      title: "Webflow information",
      description:
        "Set Webflow (sog.gg) information for gedu. When saved this data will be pushed to Webflow and is publicky available. Note: description is written in English and the site will handle the translation.",
      descriptionLabel: "Public gedu description (always in English)",
      imageLinkLabel: "Public image link",
      isExperienceArchitectLabel: "Experience architect",

      saveButtonText: "Save and push to Webflow",
    },

    clubRole: {
      owner: "Club gedu",
      ASSIGNMENT_TYPE_GROUP_MAIN_GEDU: "Group gedu",
      ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU: "Assistant",
    },
    GeduInvoicingInfoEdit: {
      setInvoicingData: "Set invoicing data",
      title: "Invoicing data",
      description: "Set invoicing data for gedu",
      invoicingEmailLabel: "Invoicing email",
    },
    GeduFilteringDataEdit: {
      title: "Filtering data",
      description:
        "Set filtering data for gedu including languages, cities, skills etc. Information is used to filter gedus in gedu search",
      languageSelectorPlaceholder: "Select languages...",
      skillSelectorPlaceholder: "Select content skills...",
      qualificationSelectorPlaceholder: "Select qualifications...",
      locationSelectorPlaceholder: "Select locations...",
      attandanceTypeSelectorPlaceholder: "Select attendance types...",
      setFilteringDataButton: "Set filtering data",
      setNewValuesButton: "Set new values",
    },
  },
  gamerDetails: {
    roles: "Roles",
    lastLogin: "Last login",
    lastLoginNever: "Never",
    inactive: "Inactive",
    active: "Active",

    userId: "User id",
    title: "Gamer details",

    discordIdButtonText: "Discord Id",
    discordIdLabel: "Discord Id",
    setDiscordId: "Gamer Discord id",
    birthdate: "Birthdate",
    metadata: "Metadata",
    subscriptionData: {
      title: "Subscriptions",
      planId: "ChargebeeSubscriptionPlanId",
      subscriptionId: "ChargebeeSubscriptionId",
      chargebeeSubscriptionId: "Subscription id",
      customerName: "Customer name",
      customerEmail: "Customer email",
      chargebeeCustomerId: "Chargebee user id",
      nextBillingAt: "Next billing",
      planMetadata: "Subscription metadata",
      status: "Status",

      statusActive: "Active",
      statusCancelled: "Cancelled",
      statusInTrial: "In trial",
      statusNonRenewing: "Non renewing",
      statusOneTime: "One-time purchase",
      statusPaused: "Paused",
      statusFuture: "Future",
    },
    participationData: {
      title: "Clubs",
      clubId: "Club id",
      groupId: "Group id",
    },
    parentData: {
      title: "Parents",
      parentId: "User id",
    },
    discordData: {
      title: "Discord",
      roles: "Roles",
      cooldown: "Time Out",
      noCooldown: "None",
      joinedAt: "Joined at",
      serverNick: "Server nickname",
      bannedReason: "BAN",
    },
  },
  parentDetails: {
    roles: "Roles",
    lastLogin: "Last login",
    lastLoginNever: "Never",
    inactive: "Inactive",
    active: "Active",

    userId: "User id",
    title: "Parent details",

    discordIdButtonText: "Discord Id",
    discordIdLabel: "Discord Id",
    setDiscordId: "Parent Discord id",
    birthdate: "Birthdate",
    metadata: "Metadata",
    subscriptionData: {
      title: "Subscriptions",
      planId: "ChargebeeSubscriptionPlanId",
      subscriptionId: "ChargebeeSubscriptionId",
      chargebeeSubscriptionId: "Subscription id",
      customerName: "Customer name",
      customerEmail: "Customer email",
      chargebeeCustomerId: "Chargebee user id",
      nextBillingAt: "Next billing",
      planMetadata: "Subscription metadata",
      plans: "Subscription plans",
      noGamerForPlan: "No gamer selected",
    },
    gamerData: {
      title: "Gamers",
      gamerId: "User id",
      discordNick: "Discord nick",
    },
  },
  userSearch: {
    title: "User search",
    parent: "Parent",
    gamer: "Gamer",
    mainGedu: "Main gedu",
    searchLabel: "",
    searchHelpText:
      "Search using whole first name, last name, Discord user, email or phone number",
    searchButtonText: "Search",
    userTypeAll: "All",
    userTypeParent: "Parent",
    userTypeGamer: "Gamer",
    userTypeLabel: "Choose user type",
  },
  clubSearch: {
    title: "Club search",
    searchLabel: "",
    searchHelpText: "Search using club name, id, sku or Discord role id",
    searchButtonText: "Search",
    numResult: "result",
    numResults: "results",
    filterClubTypePlaceholder: "Filter by club type...",
    filterContentTypePlaceholder: "Filter by content type...",
    filterAttendanceTypePlaceholder: "Filter by attendance...",
    filterMainGeduPlaceholder: "Filter by main gedu...",
    filterMuniPlaceholder: "Filter by municipality...",
    filterLanguagePlaceholder: "Filter by language...",
    filterStatusPlaceholder: "Filter by status...",
    filterMeetingDaysPlaceholder: "Filter by meeting days...",
  },
  betaNotification: {
    header: "BETA",
    description:
      "This is a beta version of the feature application - there may be issues.",
  },
};

const translationsFi = {
  general: {
    dialog: {
      yes: "Kyllä",
      no: "Ei",
      ok: "Ok",
      cancel: "Peruuta",
      close: "Sulje",
      save: "Tallenna",
    },
    update: "Päivitä",
    delete: "Poista",
    cancel: "Peruuta",
    add: "Lisää",
    create: "Luo",
    remove: "Poista",
  },
  home: {
    welcomeTitle: "Tervetuloa mukaan!",
    welcome: "Pelikasvatuksen koti",
    clientVersion: "Versionumero:",
    serverVersion: "Palvelimen versio:",
    profile: {
      title: "Profiili",
      level: "TASO",
      harmony: "HARMONIA",
      glow: "HEHKU",
      valor: "KANTTI",
      commonSense: "ARKIJÄRKI",
      levelUp: "Uusi Taso!",
      newAchievement: "Uusi saavutus!",
      unlockAchievementForUser: "Avaa käyttäjälle",
      achievements: {
        title: "Saavutukset:",
      },
    },
  },
  slotSelection: {
    newPsw: {
      infoTxt:
        "Ennen kuin voit aloittaa oppituntien valinnan, tulee sinun luoda tili Gamers' Arenaan. Gamers' Arena on School of Gamingin oma palvelu, jossa voit hoitaa kaikki tiliisi liittyvät asiat. Luo siis tili lisäämällä salasana alla olevaan kenttään ja jatka matkaa oppituntivalintaan painamalla Tallenna salasana -nappia.",
      modal: {
        label: "Uusi salasana",
        submit: "Tallenna salasana",
      },
    },
    steps: {
      nextBtn: "Seuraava",
      backBtn: "Takaisin",
      finishBtn: "Valmis",
      firstStep: {
        name: "Gamerin tiedot",
        sayHi: "Moi {{firstName}} 👋",
        welcomeText: "Tervetuloa School of Gamingiin!",
        infoPurchase:
          "Ostamasi lukukausimaksun tyyppi määrittelee kuinka monta oppituntia voit valita lukujärjestyksestämme. Lukukausimaksullasi voit valita:",
        infoPurchase_pre:
          "Ostamasi lukukausimaksu sisältää yhden paikan oppitunnilla: ",
        infoGamers:
          "Vain yksi gameri voi käyttää yhtä lukukausimaksua. Kenelle siis osoitamme oikeuden käyttää tätä lukukausimaksua? Lisää gameri klikkaamalla Uusi gameri -painiketta.",
        addGamerInfo:
          "Jos olet jo aiemmin lisännyt gamerin Gamers' Arenaan, voit valita hänet alla olevasta listasta. Mikäli haluamaasi gameria ei löydy listasta, voit lisätä uuden gamerin.",
        addNewGamerButtonTitle: "Lisää uusi",
        modalNewGamer: {
          title: "Gamerin tiedot",
          firstName: "Lapsen etunimi",
          lastName: "Lapsen sukunimi",
          birthDate: "Lapsen syntymäpäivä",
          consentAge:
            "Sallin 3 vuoden ikärajajouston peleissä, joita lapsi pelaa kotona.",
          createGamerBtn: "Luo uusi gameri",
          editGamerBtn: "Muokkaa gameria",
        },
      },
      secondStep: {
        name: "Oppituntivalinta",
        infoSlots1: "Voit valita",
        infoSlots2_one: "{{count}} oppitunnin",
        infoSlots2_other: "{{count}} oppituntia",
        infoSlots3: "lukujärjestyksestä.",
        selectionStatus: "Valintoja tehty:",
        weekDays: {
          monday: "Maanantai",
          tuesday: "Tiistai",
          wednesday: "Keskiviikko",
          thursday: "Torstai",
          friday: "Perjantai",
          saturday: "Lauantai",
          sunday: "Sunnuntai",
        },
      },
      thirdStep: {
        name: "Vahvistus",
        confirmText:
          "Viimeistelläksesi oppituntivalintasi, tarkastathan, että alla olevat tiedot vastaavat toiveitasi.",
        confirmText2:
          "Mikäli tiedot ovat oikein, klikkaa Valmis-painiketta. Älä sulje ikkunaa ennen vahvistustekstiä. Pienet kätyrimme pistävät monta mutteria koneistoon valintojesi perusteella, joten vahvistuksen saaminen voi kestää muutaman hetken.",

        gamerTitle: "Valittu gameri",
        slotTitle: "Valitut oppitunnit",
        confirmText_pre:
          "Viimeistelläksesi rekisteröitymisen, tarkastathan, että alla olevat gamerin tiedot ovat oikein.",
        confirmText2_pre:
          "Mikäli näin on, klikkaa Valmis-painiketta. Älä sulje ikkunaa ennen vahvistustekstiä. Pienet kätyrimme pistävät monta mutteria koneistoon valintojesi perusteella, joten vahvistuksen saaminen voi kestää muutaman hetken.",
        slotTitle_pre: "Ostettu oppitunti",
      },
      fourthStep: {
        success: {
          title:
            "Oppituntivalinta on mennyt läpi onnistuneesti - gamerisi on rekisteröity valituille tunneille 🎉",
          p: "Lähetämme vielä sinulle vahvistusviestin sähköpostiisi, josta löydät oppituntien ajankohdat ja namiskan, joka maagisesti vie sinut Discordiimme ja antaa tilillesi pääsyoikeuden oppituntien luokkahuoneisiin. Nyt voit sulkea tämän ikkunan.",
          p2: "Tervetuloa School of Gamingiin!",
          title_pre: "Gamerisi on rekisteröity valituille tunneille 🎉",
        },
        error: {
          tryAgain: "Ole hyvä ja yritä uudelleen.",
          goBackBtn: "Palaa takaisin",
        },
      },
    },
  },
  muniRegister: {
    steps: {
      participant: "Perustiedot",
      selectClub: "Harrastus",
      additionalQuestions: "Lisätiedot",
      confirmRegistration: "Vahvistus",
    },
    participant: {
      parentInfoHeader: "Vanhemman tiedot",
      parentFirstName: "Vanhemman etunimi",
      parentLastName: "Vanhemman sukunimi",
      parentPhone:
        "Vanhemman puhelinnumero, josta saamme lapsen huoltajan kiinni tarvittaessa",
      parentEmail:
        "Vanhemman sähköpostiosoite, johon voimme lähettää kerhokuulumisia ja -infoa",
      childInfoHeader: "Lapsen tiedot",
      childFirstName: "Lapsen etunimi",
      childLastName: "Lapsen sukunimi",
      childDateOfBirth: "Lapsen syntymäaika",
      infoValidPhone:
        "Syötä maakoodi ja puhelinnumero muodossa +358 40 1234567",
      infoValidEmail: "Syötä sähköpostiosoite oikeassa muodossa!",
      consentLabel:
        "Hyväksyn tietojeni tallentamisen ja käsittelyn. Minulle saa lähettää sähköpostia kerhoja koskien.",
      privacyPolicyLinkText: "Tietosuojaseloste",
    },
    clubSelect: {
      municipalitySelect: "Kunta, jossa lapsesi käy koulua",
      schoolSelect: "Valitse koulu, jossa harrastusryhmä kokoontuu",
      clubSelect: "Valitse harrastus, johon lapsesi osallistuu",
    },
    additionalQuestions: {
      title: "Lisätiedot",
      description:
        "Keräämme anonymisoitua dataa Harrastamisen Suomen malliin -osallistujista kuntien pyynnöstä. Voit myös laittaa lisätietoa lapsestasi ja hänen erityistarpeistaan. Nämä tiedot välitämme kerhon pelikasvattajalle.",
      gender: "Lapsen sukupuoli",
      impactTitle: "Vaikuttavuus",
      impactDescription:
        "Vaikuttavuus kysymyksillä seuraamme miten harrastaminen vaikuttaa lapsen hyvinvointiin...",
      otherInforTitle: "Muuta",
      additionalInfo: "Muuta tietoa",
    },
    summary: {
      title: "Yhteenveto",
      parent: "Vanhempi",
      parentContact: "Vanhemman yhteystiedot",
      child: "Lapsi",
      club: "Valittu harrastus",
      success:
        "Kiitos ilmoittautumisesta! Teihin ollaan yhteydessä sähköpostitse ennen kerhon käynnistymistä. Tervetuloa mukaan!",
      waitingList:
        "Sinut on lisätty valitsemasi kerhon odotuslistalle. Tarkkaile sähköpostiasi, ilmoitamme kun paikkoja vapautuu.",
      error: {
        title: "Ilmoittautuminen epäonnistui",
        ERROR_NAME_ALREADY_EXISTS: "Nimi on jo käytössä",
        ERROR_INVALID_INPUT: "Virheellinen syöte, tarkista syöttämäsi tiedot",
        ERROR_INTERNAL_SERVER_ERROR:
          "Yleinen virhe, yritä hetken päästä uudelleen",
        ERROR_UNKNOWN_ERROR:
          "Tunnistamaton virhe, yritä hetken päästä uudelleen",
        ERROR_INVALID_EMAIL:
          "Emme pystyneet lähettämään sähköpostia. Tarkista, että antamasi sähköpostiosoite on oikein.",
      },
    },
    next: "Seuraava",
    back: "Takaisin",
    confirm: "Rekisteröidy",
  },

  discord: {
    connecting: "Yhdistetään Discordiin...",
    success: "Yhdistetty Discordiin, jatka siellä.",
    successlinkText: "Jatka SOG:n Discordiin",
    failure: "Jotain meni pieleen, avaa linkki uudestaan sähköpostista.",
    clickjack: "Ei onnistunut, avaa linkki uudestaan sähköpostista.",
    authorizationCancelled: "Yhdistäminen peruutettu.",
    missingToken: "Käyttämästäsi linkistä puuttuu tunniste.",
    noStateError:
      "Yhteyden muodostaminen ei onnistu. Yritä uudestaan.\nJos ongelma jatkuu, kokeile yhdistämistä eri selaimella.",
    invalidToken: "Käyttämäsi linkki on virheellinen.",
    tokenExpired: "Käyttämäsi linkki on vanhentunut.",
    unknownError: "Jotain meni pieleen, ole hyvä ja yritä uudestaan.",
    discordConnectSuccess:
      "Discord on kytketty onnistuneesti tunnukseesi. Voit liittyä oppitunneille Discordissa.",
  },
  navbar: {
    home: "Etusivu",
    clubCalendar: "Kerhokalenteri",
    activities: "Gamer's Gym",
    geduInvoicing: "Laskutus",
    geduInvoicing4Admin: "Gedulaskutus",
    reports: "Raportit",
    search: "Haku",
    adminTitle: "Hallinta",
    reportMenu: {
      muniInvoicing: "Kuntalaskutus",
      muniInvoicingSummary: "Kuntayhteenveto",
      trusterData: "Truster-laskutus",
      geduInvoicingStatus: "Gedu laskutusstatus",
    },
    userSearch: "Käyttäjähaku",
    searchMenu: {
      geduSearch: "Geduhaku",
      gamerSearch: "Pelaajahaku",
      parentSearch: "Vanhempien haku",
      geduAvailability: "Tuuraajahaku",
      clubSearch: "Kerhohaku",
    },
    adminMenu: {
      achievements: "Saavutukset",
      clubs: "Kerhot",
      codes: "Koodisto",
      users: "Käyttäjät",
      municipalities: "Kunnat",
      activities: "Gamer's Gym",
      clubInstuctions: "Kerho-ohjeet",
    },
    logout: "Kirjaudu ulos",
    languageSelectorLabel: "Kieli",
  },
  clubCalendar: {
    tableHeader_Id: "Id",
    tableHeader_SKU: "Tunniste",
    tableHeader_Name: "Nimi",
    tableHeader_Status: "Tila",
    tableHeader_clubtype: "Tyyppi",
    tableHeader_clubcontenttype: "Sisältö",
    tableHeader_clubparticipantCount: "Osallistujat",
    tableHeader_Attendance: "Osallistuminen",
    tableHeader_Language: "Kieli",
    tableHeader_Weekday: "Viikonpäivä",
    tableHeader_MeetingTimes: "Kokoontumiset",
    tableHeader_RegistrationEnabled: "Ilmoittautuminen",
    tableHeader_Owner: "Kerhogedu",
    tableHeader_Time: "Aika",
    tableHeader_StartTime: "Aloitusaika",
    tableHeader_EndTime: "Päättymisaika",
    tableHeader_Country: "Maa",
    tableHeader_StartDate: "Alkamispäivä",

    filter_byStatusPlaceholder: "Suodata tilan mukaan...",
    filter_byTypePlaceholder: "Suodata tyypin mukaan...",
    filter_byMunicipalityPlaceholder: "Suodata kunnan mukaan...",
    filter_byGedu: "Suodata gedun mukaan...",
    filter_byAttendanceTypePlaceholder: "Suodata tapaamisten mukaan...",
  },
  clubView: {
    group: "Ryhmä",
    chooseGedu: "Valitse gedu",
    chooseClubInstructions: "Choose kerho-ohjeet",
    addGroup: "Lisää ryhmä",
    addCancelledGroup: "Lisää peruutettujen ryhmä",
    defaultGroupName: "Oletusryhmä",
    cancelledGroupName: "Peruneet",
    waitingListGroupName: "Jonossa",
    regularGroupName: "Uusi ryhmä",
    clubData: "Oppitunnin tiedot",
    lessonData: "Kokoontumiset",
    participantData: "Osallistujat",
    chooseClubInstructions: "Valitse kerho-ohjeet",
    groupInfo: {
      editName: "Muokkaa ryhmän nimeä",
      chooseGroupGedu: "Valitse ryhmän gedu",
      chooseAssistantGedu: "Valitse apulaisgedu",
      groupGedu: "Ryhmän gedu",
      fromOwner: "(kerhogedu)",
      assistantGedu: "Apulaisgedu",
      removeGroupGedu: "Poista ryhmän gedu",
      removeAssistantGedu: "Poista apulaisgedu",
      groupId: "Ryhmän id",
    },
  },
  clubData: {
    currency: "Valuutta",
    generalInfoHeader: "Yleiset tiedot",
    clubName: "Kerhon nimi",
    sku: "SKU",
    language: "Kieli",
    ownerHeader: "Kerhogedu",
    ownerName: "Nimi",
    ownerEmail: "Sähköposti",
    ownerPhoneNumber: "Puhelinnumero",
    ownerUsername: "Käyttäjänimi",
    changeOwner: "Vaihda",
    clubTypsHeader: "Kerhon tyyppitiedot",
    status: "Status",
    attendance: "Osallistuminen",
    type: "Tyyppi",
    contentType: "Sisältö",
    meetingTimesHeader: "Kokoontumiset",
    clubId: "Kerhon id",
    groupId: "Oletusryhmän id",
    discordRoleName: "Discord rooli",
    discordGuildName: "Discord serveri",
    clubFeesHeader: "Kerhon hinnoittelu",
    mainGeduFee: "Kerhogedu",
    groupGeduFee: "Ryhmägedu",
    assistantGeduFee: "Apulaisgedu",
    municipalityFee: "Kuntalaskutus",
    municipality: "Kunta",

    geduClubFeesHeader: "Kerhopalkkio",
    geduClubFeeTitle: "Palkkio",
    geduClubFeeInfo: "Perustuu rooliin: {{role}}",
    geduClubFeeNote:
      "Jos havaitset puuttuvia palkkioita, ota yhteyttä henkilökuntaan!",
    geduClubFeeRole: {
      ASSISTANT: "Assari",
      GROUPMAIN: "Ryhmägedu",
      OWNER: "Kerhogedu",
      undefined: "Määrittelemätön",
      NO_TYPE: "Ei roolia",
    },

    additionalInfoHeader: "Lisätiedot (näkyy kerhokirjeessä)",
    clubMaterialHeader: "Linkki kerhomateriaaliin (Google Drive)",
    copyToClipboard: "Kopioi",
    clubInstructionsHeader: "Kerho-ohjeet",
    clubInstructionsName: "Nimi",
    clubInstructionsIntroduction: "Johdanto",
    clubInstructionsInstructions: "Ohjeet",
    changeClubInstructions: "Vaihda",

    clubSendWelcomeEmailsButtonText: "Lähetä kerhokirjeet",
    clubWelcomeEmailHeader: "Kerhokirje",
    clubWelcomeEmailStatus: "Tila",

    municipalityAdditionalDataHeader: "Kuntakerhon lisätiedot",

    periodHeader: "Harrastuksen kausi",
    startDate: "Alkamispäivä",
    endDate: "Päättymispäivä",

    bulkCreateLessons: "Luo kokoontumiset kaudelle",
    bulkLessonCreationStatus: "Status",
    bulkCreateStarted: "Luodaan kokoontumisia...",
    registrationEnabled: "Ilmoittautuminen",
    registrationActive: "Aktiivinen",
    registrationInactive: "Ei aktiivinen",
    editMeetings: "Muokkaa tapaamisia",
    edit: "Muokkaa",
    changeType: "Vaihda kerhon tyyppiä",
    clubGeneralInfoEditor: "Muokkaa yleistietoja",
    clubTypeEditor: "Muokkaa kerhon tyyppiä",
  },
  clubLessons: {
    addNewLessonCardHeader: "Lisää uusi kokoontuminen",
    create: "Luo",
    update: "Päivitä",
    createNewLessonTitle: "Luo uusi kokoontuminen",
    editLessonTitle: "Muokkaa kokoontumista",
    updateLessonTitle: "Päivitä kokoontuminen",
    selectDate: "Valitse päivämäärä:",
    selectHour: "Valitse alkamisaika:",
    selectLessonStatus: "Kokoontumisen tila:",
    notesLabel: "Kerhoraportti",
    editParticipants: "Osallistujat...",
    editLesson: "Muokkaa...",
    removeLessonConfirmText: "Oletko varma että haluat poistaa kokoontumisen:",
    lessonEntriesTitle: "Kirjaa osallistujat",
    lessonEntriesTitleNoEdit: "Osallistujat",
    selectedCount: "Valittuna",
    lessonEntriesSave: "Tallenna",
    lessonEntryRewards: "Tuntimerkinnät",
    addNewParticipantCardHeader: "Lisää uusi osallistuja",
    reward: "Palkinto",
    amount: "Määrä",
    gedu: "Gedu",
    assistantGedu: "Apulaisgedu",
    substituteGedu: "Gedun sijainen",
    substituteAssistantGedu: "Apulaisgedun sijainen",
    lessonRewardGoodBehavior: "Tuntimerkintä: hyvä käyttäytyminen",
    chooseSubstituteGedu: "Valitse gedun sijainen",
    chooseSubstituteAssistant: "Valitse apulaisgedun sijainen",
    removeSubstituteGedu: "Poista gedun sijainen",
    removeSubstituteAssistant: "Poista apulaisgedun sijainen",
    muniInvoicingStatus: "Laskutus:",
  },
  participants: {
    gamerName: "Gamer",
    gamerDiscordNick: "Discord",
    parentName: "Vanhempi",
    waitingList: "Jonossa",
    subscriptionStatus: "Tilaus",
    birthDate: "Syntymäaika",
    gender: "Sukupuoli",
    registrationDate: "Ilmoittautuminen",
    copyAllEmails: "Kopioi sähköpostit",
    copyAllEmailsCopied: "Kaikki ryhmän sähköpostit kopioitu",
    welcomeEmailStatus: "Kerhokirje",
    notes: "Muistiinpanot",
    noLongerInGroup: "Ei enää ryhmässä",

    additionalInformation: "Lisätiedot",
    addNewParticipant: {
      title: "Lisää uusi osallistuja",
      description:
        "Kirjoita tilauksen id ja hae olemassa oleva data ja sen jälkeen tarkista ja täytä puuttuvat gamerin tiedot ennen osallistujan lisäämistä.",
      gamerFound: "Tilauksella on jo gameri, osallistujaa ei voi lisätä",
      subscriptionId: "Tilauksen id",
      fetchButton: "Hae",
      parentFirstName: "Vahemman etunimi",
      parentLastName: "Vanhemman sukunimi",
      email: "Sähköposti",
      gamerFirstName: "Gamerin etunimi",
      gamerLastName: "Gamerin sukunimi",
      birthDate: "Gamerin syntymäaika",
      discordId: "Discord id (vanhemman, jos lapsi alle 13-v)",
      SUBSCRIPTION_NOT_FOUND: "Tilausta ei löydy",
      INTERNAL_SERVER_ERROR: "Yleinen virhe, yritä uudestaan!",
    },
    changeGamerClub: {
      title: "Vaihda kerhoa",
      description: "Voit vaihtaa osallistujan kerhoa.",
      selectNewClubPlaceholder: "Valitse uusi kerho",
      errorFetchingOtherClubsData: "Virhe kerhojen haussa!",
      changeButtonText: "Vaihda",
    },
    changeGamerGroup: {
      title: "Vaihda ryhmää",
      description: "Voit vaihtaa osallistujan ryhmää.",
      selectNewClubPlaceholder: "Valitse uusi kerho",
      errorFetchingOtherClubsData: "Virhe kerhojen haussa!",
      changeButtonText: "Vaihda",
    },
    changeClubButtonText: "Vaihda kerhoa...",
    changeGroupButtonText: "Vaihda ryhmää...",
  },
  participantRegistration: {
    title: "Kuntakerhon osallistujan ilmoittaminen",
    gamerGender: "Sukupuoli",
    gamerBirthdate: "Syntymäaika",
    gamerFirstName: "Gamerin etunimi",
    gamerLastName: "Gamerin sukunimi",
    parentFirstName: "Vanhemman etunimi",
    parentLastName: "Vanhemman sukunimi",
    parentEmail: "Vanhemman sähköposti",
    parentPhoneNumber: "Vanhemman puhelinnumero",
  },
  clubs: {
    FI_MINE_MON_1: "Minecraft Megarakentaja (ma)",
    FI_TECH_MON_1: "Teknologia Palvelimen salaisuudet",
    FI_MINE_TUE_1: "Minecraftin perusteet (Bedrock)",
    FI_ESPT_TUE_1: "Esports-joukkue (ti)",
    FI_TECH_TUE_1: "Teknologia Koodauksen perusteet",
    FI_TECH_WED_1: "Teknologia pelisuunnittelu",
    FI_MINE_WED_1: "Minecraft Selviytyjät",
    FI_ESPT_THU_1: "Esports-joukkue (to)",
    FI_MINE_THU_1: "Minecraft Megarakentaja (to)",
    FI_MINE_FRI_1: "Minecraft - Luova rakennus (pe)",
    FI_TECH_FRI_1: "Teknologia Roblox (pe)",
    FI_ESPT_FRI_1: "Aloitteleva Esports",
    UN_MINE_SAT_1: "Minecraft englanniksi",
    FI_MINE_SAT_2: "Minecraft Luova rakennus (la)",
    FI_TECH_SAT_1: "Teknologia Roblox (la)",
    FI_GILD_SAT_1: "Farming Simulator kilta",
    FI_HENGARI: "Hengari",
    TEST_CLUB: "Testikerho 1",
    TEST_CLUB2: "Testikerho 2",
    "FI-MINE-FRI-2": "Teini Special",
    FI_ROCL_FRI_1: "Rocket League kilta",
    FI_OLOH_MON_1: "Maanantain olohuone",
    FI_OLOH_TUE_1: "Tiistain olohuone",
    FI_OLOH_WED_1: "Keskiviikon olohuone",
    FI_OLOH_THU_1: "Torstain olohuone",
    FI_OLOH_FRI_1: "Perjantain olohuone",
    FI_OLOH_FRI_2: "Perjantain toinen olohuone",
    FI_OLOH_SAT_1: "Lauantain olohuone",

    SIN_SINGAPORE_XCLWORLDACADEMY_EJE_MA_1: "Singapore XCL World Academy",
    IT_TURIN_INTERNATIONALSCHOOL_OMA_MA_1: "Turin International School",

    FI_OW_SUM24_THU_1: "Esports (Overwatch) tortai",
    FI_OW_SUM24_TUE_1: "Esports (Overwatch) tiistai",
    FI_OLOH_SUM24_FRI_1: "Perjantaipuuhat",
    FI_OLOH_SUM24_THU_1: "Kesäolkkari torstai",
    FI_OLOH_SUM24_WED_1: "Kesäolkkari keskiviikko",
    FI_OLOH_SUM24_TUE_1: "Kesäolkkari tiistai",
    FI_OLOH_SUM24_MON_1: "Kesäolkkari maanantai",
    FI_FORT_SUM24_THU_1: "Esports (Fortnite) torstai",
    FI_MINE_SUM24_THU_2: "Minecraft 13-16 torstai",
    FI_MINE_SUM24_THU_1: "Minecraft 7-10 Spelheim torstai",
    FI_MINE_SUM24_WED_1: "Minecraft 9-12 Spelheim keskiviikko",
    FI_TECH_SUM24_WED_2: "Roblox 9-12 keskiviikko",
    FI_TECH_SUM24_WED_1: "Pelistudio 8-10 keskiviikko",
    FI_FORT_SUM24_TUE_1: "Esports (Fortnite) tiistai",
    FI_MINE_SUM24_TUE_2: "Minecraft 9-12 Laamalaakso tiistai",
    FI_MINE_SUM24_TUE_1: "Minecraft 7-10 Laamalaakso tiistai",
    FI_MINE_SUM24_MON_1: "Minecraft 11-15 Korrennotko maanantai",
    FI_TECH_SUM24_MON_1: "Pelikerho 6-8 maanantai",
    FI_RL_SUM24_THU_1: "Esports (Rocket League) torstai",
    FI_PELI_THU_1: "Aikuisten pelikerho",
  },
  admin: {
    achievements: {
      title: "Saavutukset",
      achievementTitle: "Nimi",
      achievementDescription: "Kuvaus",
      achievementUnlockCondition: "Avausehto",
      achievementImage: "Kuva",
      editAchievement: "Muokkaa saavutusta",
      createNewAchievement: "Luo uusi saavutus",
    },
    clubs: {
      tableHeader_Id: "Id",
      tableHeader_SKU: "Tunniste",
      tableHeader_Name: "Nimi",
      tableHeader_Status: "Tila",
      tableHeader_clubtype: "Tyyppi",
      tableHeader_clubcontenttype: "Sisältö",
      tableHeader_clubparticipantCount: "Osallistujat",
      tableHeader_Attendance: "Osallistuminen",
      tableHeader_Language: "Kieli",
      tableHeader_Weekday: "Viikonpäivä",
      tableHeader_MeetingTimes: "Kokoontumiset",
      tableHeader_RegistrationEnabled: "Ilmoittautuminen",
      tableHeader_StartTime: "Aloitusaika",
      tableHeader_EndTime: "Päättymisaika",
      tableHeader_Country: "Maa",
      tableHeader_StartDate: "Alkamispäivä",

      addClub_Name: "Nimi",
      addClub_SKU: "Tunniste",
      addClub_Status: "Tila",
      addClub_Attendance: "Osallistuminen",
      addClub_Language: "Kieli",
      addClub_maxParticipants: "Maksimiosallistujamäärä",
      addClub_discordRoleId: "Discord rooli-id",
      addClub_discordGuildId: "Discord server (guild) id (vakio: SOG Suomi)",
      addClub_Country: "Maa",
      addClub_Type: "Tyyppi",
      addClub_ContentType: "Sisältö",
      addClub_StatusFirstEntry: "Valitse tila...",
      addClub_TypeFirstEntry: "Valitse tyyppi...",
      addClub_ContentTypeFirstEntry: "Valitse sisältötyyppi...",
      addClub_AttandanceFirstEntry: "Valitse tyyppi...",
      addClub_CountryFirstEntry: "Valitse maa...",
      addClub_LanguageFirstEntry: "Valitse kieli...",

      meetingTimes_info: "Valitse kerhon kookoontumisajat",
      meetingTimes_startTime: "Aloitus",
      meetingTimes_endTime: "Lopetus",
      meetingTimes_SubmitButtonText: "Tallenna kokoontumisajat",

      addClubInstructions_Introduction: "Johdanto",
      addClubInstructions_Instructions: "Ohjeet",

      addClubInstructions_Type: "Tyyppi",
      addClubInstructions_TypeFirstEntry: "Valitse tyyppi...",
    },
    users: {
      title: "Käyttäjät",
      tableHeader_firstname: "Etunimi",
      tableHeader_lastname: "Sukunimi",
      tableHeader_username: "Tunnus",
      tableHeader_email: "Sähköposti",
      tableHeader_roles: "Roolit",
      tableHeader_gamers: "Gamers",

      deleteUserButton: "Poista valitut",
      addUserButton: "Lisää...",
      gamersButton: "Gamers",

      gamers_info: "Valitun käyttäjän gamerit",
      gamers_invitationUrl: "Discord kutsulinkki",

      addUser_FirstName: "Etunimi",
      addUser_LastName: "Sukunimi",
      addUser_Email: "Sähköposti",
      addUser_Password: "Salasana",
      addUser_Username: "Käyttäjätunnus",
      addUser_Role: "Rooli",
      addUser_Locale: "Kielen tunniste",
      addUser_discordId: "Discord Id",
      addUser_phonenumber: "Puhelinnumero",

      addUser_EmailAlreadyInUse: "Sähköpostiosoite on jo käytössä",
      addUser_UsernameAlreadyInUse: "Käyttäjätunnus on jo käytössä",

      addUser_RoleFirstEntry: "Valitse rooli...",
      addUser_SubmitButtonText: "Tallenna käyttäjä",

      setRoles_info: "Valitse käyttäjän roolit",
      setRoles_SubmitButtonText: "Tallenna roolit",
    },
    activities: {
      tableHeader_Name: "Aktiviteetti",
      tableHeader_Description: "Kuvaus",
      tableHeader_Image: "Kuva",

      addActivity_Name: "Aktiviteetin nimi",
      addActivity_Description: "Kuvaus",
      addActivity_Image: "Valitse Kuva",
    },
    clubInstructions: {
      tableHeader_Id: "Id",
      tableHeader_Name: "Nimi",
      tableHeader_type: "Tyyppi",
      tableHeader_introduction: "Intro",
      tableHeader_instructions: "Ohjeet",
    },
  },
  weekday: {
    monday: "Maanantai",
    tuesday: "Tiistai",
    wednesday: "Keskiviikko",
    thursday: "Torstai",
    friday: "Perjantai",
    saturday: "Lauantai",
    sunday: "Sunnuntai",
    unscheduled: "Ei aikataulua",

    mon: "Maanantai",
    tue: "Tiistai",
    wed: "Keskiviikko",
    thu: "Torstai",
    fri: "Perjantai",
    sat: "Lauantai",
    sun: "Sunnuntai",
  },
  weekdayShort: {
    monday: "ma",
    tuesday: "ti",
    wednesday: "ke",
    thursday: "to",
    friday: "pe",
    saturday: "la",
    sunday: "su",

    mon: "ma",
    tue: "ti",
    wed: "ke",
    thu: "to",
    fri: "pe",
    sat: "la",
    sun: "su",
  },

  codedomain: {
    CLUB_STATUS: "Kerhon tila",
    PARTICIPANT_STATUS: "Osallistujan status",
    CLUB_ATTANDANCE_TYPE: "Kerhon osallistumistyyppi",
    CLUB_TYPE: "Kerhon tyyppi",
    CLUB_CONTENT_TYPE: "Kerhon sisältö",
    GROUP_TYPE: "Ryhmän tyyppi",
    GEDU_BILLING_METHOD: "Laskutustapa",
  },
  code: {
    undefined: "Tunnistamaton koodi",
    CLUB_STATUS_ONGOING: "Käynnissä",
    CLUB_STATUS_PLANNED: "Suunnitteilla",
    CLUB_STATUS_ENDED: "Päättynyt",
    CLUB_STATUS_ONHOLD: "Tauolla",
    CLUB_STATUS_ARCHIVED: "Arkistoitu",

    CLUB_ATTENDANCE_ONSITE: "Paikan päällä",
    CLUB_ATTENDANCE_UNKNOWN: "Tuntematon",
    CLUB_ATTENDANCE_REMOTE: "Etänä",

    CLUB_TYPE_CONSUMER: "Kuluttaja",
    CLUB_TYPE_COMMUNITY: "Yhteisö",
    CLUB_TYPE_MUNICIPALITY: "Kunta",
    CLUB_TYPE_INTERNATIONAL: "Kansainvälinen",

    USER_ROLE_ADMIN: "Admin",
    USER_ROLE_GEDU: "Gedu",
    USER_ROLE_PARENT: "Vanhempi",
    USER_ROLE_GAMER: "Gamer",
    USER_ROLE_EXGEDU: "Ex-gedu",

    CLUB_CONTENT_TYPE_ESPORT: "Esport",
    CLUB_CONTENT_TYPE_MINECRAFT: "Minecraft",
    CLUB_CONTENT_TYPE_TECH: "Teknologia",

    CLUB_CONTENT_TYPE_GAME_STUDIO: "Oma pelistudio",
    CLUB_CONTENT_TYPE_EGANG: "Meidän E-jengi",
    CLUB_CONTENT_TYPE_GRAND_ADVENTURES: "Suuret seikkailut",
    CLUB_CONTENT_TYPE_SOGWOOD: "Sogwood",
    CLUB_CONTENT_TYPE_POKEMON_GO: "Pokemon Go ja pihapelit",
    CLUB_CONTENT_TYPE_SERVER_MODERATOR: "Server moderaattori",

    CLUB_CONTENT_TYPE_INTERNATIONAL: "Kansainvälinen (ei käytössä)",
    CLUB_CONTENT_TYPE_GUILD: "Kilta (ei käytössä)",

    GROUP_TYPE_DEFAULT: "Oletus",
    GROUP_TYPE_REGULAR: "Perusryhmä",

    LESSON_STATUS_UNDONE: "Suunniteltu",
    LESSON_STATUS_DONE: "Pidetty",
    LESSON_STATUS_CANCELLEDBYMUNICIPALITY: "Peruttu (Kunta)",
    LESSON_STATUS_CANCELLEDBYSOG: "Peruttu (SOG)",
    LESSON_STATUS_CANCELLED: "Peruttu (Muu syy)",
    LESSON_STATUS_HOLIDAY: "Loma",
    LESSON_STATUS_RESCHEDULED: "Uudelleen aikataulutettu",

    LESSON_ENTRY_STATUS_PRESENT: "Paikalla",
    LESSON_ENTRY_STATUS_NOT_PRESENT: "Poissa",

    USER_GENDER_FEMALE: "Tyttö",
    USER_GENDER_MALE: "Poika",
    USER_GENDER_UNDISCLOSED: "En halua kertoa",

    GAMER_PROFILE_VARIABLE_HARMONY: "Harmonia",
    GAMER_PROFILE_VARIABLE_GLOW: "Hehku",
    GAMER_PROFILE_VARIABLE_VALOR: "Kantti",
    GAMER_PROFILE_VARIABLE_COMMON_SENSE: "Arkijärki",
    GAMER_PROFILE_VARIABLE_NUM_LESSONS_ATTENDED:
      "Suoritettujen oppituntien määrä",
    GAMER_PROFILE_VARIABLE_GOOD_BEHAVIOR: "Hyvä käyttäytyminen",
    GAMER_PROFILE_VARIABLE_GAMERS_GYM_EXERCISES_DONE:
      "Taukoliikuntaharjoitteita suoritettu",

    GAMER_PROFILE_VARIABLE_NUM_ESPORTS_LESSONS: "Esports oppituntien määrä",
    GAMER_PROFILE_VARIABLE_NUM_TECH_LESSONS: "Teknologia oppituntien määrä",
    GAMER_PROFILE_VARIABLE_NUM_MINECRAFT_LESSONS: "Minecraft oppituntien määrä",

    ASSIGNMENT_TYPE_GROUP_MAIN_GEDU: "Group main gedu",
    ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU: "Group assistant gedu",

    CURRENCY_EUR: "EUR",

    CLUB_INSTRUCTION_MUNICIPALITY_WELCOME: "Kerhokirje (kunta)",
    CLUB_INSTRUCTION_CONSUMER_WELCOME: "Kerhokirje (kuluttaja)",

    INVOICING_STATUS_INVOICED: "Laskutettu",
    INVOICING_STATUS_GEDU_APPROVED: "Valmis, odottaa hyväksyntää",
    INVOICING_STATUS_MUNI_APPROVED: "Kunnan hyväksymä",
    INVOICING_STATUS_APPROVED: "Hyväksytty",
    INVOICING_STATUS_REJECTED: "Hylätty",
    INVOICING_STATUS_PAID: "Maksettu",
    INVOICING_STATUS_UNHANDLED: "Odottaa valmistumista",

    UNKNOWN_CODE: "Tuntematon",

    INVOICING_PERIOD_TYPE_STANDARD: "Standardi",

    GEDU_INVOICING_TYPE_MAIN_GEDU: "Kerhogedu",
    GEDU_INVOICING_TYPE_NORMAL_GEDU: "Ryhmägedu",
    GEDU_INVOICING_TYPE_ASSISTANT_GEDU: "Assari",

    GEDU_BILLING_METHOD_NONE: "Ei laskutusta",
    GEDU_BILLING_METHOD_INVOICE: "Laskutus",
    GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE: "Truster yhteislaskutus",
    GEDU_BILLING_METHOD_STAFF: "Henkilöstö",
    GEDU_BILLING_METHOD_STAFF_HOUR: "Henkilöstö (tunti)",

    CLUB_LANGUAGE_FINNISH: "Suomi",
    CLUB_LANGUAGE_ENGLISH: "Englanti",
    CLUB_LANGUAGE_SWEDISH: "Ruotsi",
    CLUB_LANGUAGE_SIGN_LANGUAGE: "Viittomakieli",

    GEDU_QUALIFICATION_NEURODIVERSITY: "Neurokirjo",
    GEDU_QUALIFICATION_PEDAGOGICAL_EDUCATION: "Pedagoginen koulutus",
  },
  role: {
    USER_ROLE_GAMER: "Gamer",
    USER_ROLE_PARENT: "Vanhempi",
    USER_ROLE_GEDU: "Gedu",
    USER_ROLE_ADMIN: "Admin",
    USER_ROLE_EXGEDU: "Ex-gedu",
  },
  subscription: {
    status: {
      active: "Aktiivinen",
      non_renewing: "Päättymässä",
      cancelled: "Peruutettu",
      future: "Tuleva",
      in_trial: "Koekäytössä",
      paused: "Tauolla",
      one_time: "Kertaostos",
      unknown: "Tuntematon",
    },
  },
  customField: {
    only_hobby_label: "Onko tämä lapsen ainoa harrastus?",
    value: {
      only_hobby_value_yes: "Kyllä",
      only_hobby_value_no: "Ei",
    },
  },
  customTextField: {
    title: "",
    save: "Tallenna",
  },

  municipality: {
    metadata: {
      minParticipantCount: "Minimiosallistujamäärä",
    },
  },
  clubFeeEditor: {
    openClubFeeEditor: "Muuta hinnoittelua",
    currencyLabel: "Valuutta",
    mainGeduFeeLabel: "Kerhogedun palkkio",
    groupGeduFeeLabel: "Ryhmägedun palkkio",
    assistantGeduFeeLabel: "Apulaisgedun palkkio",
    municipalityFeeLabel: "Kuntalaskutus",
  },
  invoicingStatus: {
    title: "Avoimet laskutuskaudet",
    adminTitle: "Gedujen valmiit kaudet",
    approvedDate: "Valmis",
    sogApprovedDate: "Hyväksytty",
    billingMethodNotSet: "Laskutustapaa ei ole asetettu!",
  },
  geduInvoicing: {
    currentMonth: "Nykyinen kuukausi",
    previousMonth: "Edellinen kuukausi",
    nextMonth: "Seuraava kuukausi",
    startDate: "Alkamispäivä",
    endDate: "Päättymispäivä",
    periodTitle: "Laskutuskaudet",
    geduSelector: "Valitse gedu",
    periodId: "Jakson tunnus",
    noPeriodHeader: "Rivit ilman jaksoa",
    invoicingMethodTitle: "Laskutustapa",
    billingMethodNotSet: "Ei asetettu",
  },
  invoicingLines: {
    summarySubHeaderText: "Yhteenveto",
    errorSummarySubHeaderText: "Jakson riveillä on virheitä!",
    errorSummaryDesciptionGedu:
      "Ole yhteydessä henkilökuntaan virheiden korjaamiseksi. Et voi laskuttaa ennenkuin virheet on korjattu.",
    errorSummaryDesciptionAdmin:
      "Todennäköisin syy virheille on kerholta puuttuvat hintatiedot. Lisää puuttuvat tiedot ja pyydä gedua tarkistamaan uudestan",
    substitute: "Tuuraus",
    totalInvoicing: "Yhteensä",
    totalNumberOfLessons: "Kokoontumisten määrä",

    actionsSubHeaderText: "Toimenpiteet",
    geduApproval: "Valmis",
    sogApproval: "Hyväksy",
    sogReject: "Hylkää",
    invoiced: "Laskutettu",
    priceError: "Hintavirhe",
    missmatchInWeekdays: "Viikonpäivä ei täsmää kerhoon!",

    trusterGeduInvoicingInfo:
      "Truster yhteislaskutuksessa älä lähetä meille laskua. Sinun ei myöskään tarvitse merkitä laskutuskautta laskutetuksi. Molemmat tehdään tehdään automaattisesti, sen jälkeen kun lasku on hyväksytty.",

    CLUB_TYPE_CONSUMER: "Kuluttajakerhot",
    CLUB_TYPE_COMMUNITY: "Yhteisökerhot",
    CLUB_TYPE_MUNICIPALITY: "Kuntakerhot",
    CLUB_TYPE_INTERNATIONAL: "Kansainväliset kerhot",

    selectInvoicingPeriodButtonText: "Siirrä",
    moveLinesToPeriodHeader: "Siirrä ylläolevat rivit laskutuskaudelle:",
    openPeriodsLabel: "Avoimet laskutuskaudet",
  },
  muniReport: {
    selectCountry: "Valitse maa",
    selectMunicipality: "Valitse kunta",
    isEstimate: "Arvio",
    isError: "Virhe",
    openClub: "Avaa kerho",
    copyClubName: "Kopioi kerhon nimi",
    lessonStatus: "Kokoontumisen tila",
    municipalityName: "Nimi",
    municipalityContact: "Kontakti",
    paymentTerm: "Maksuehto",
    paymentTermEditHeader: "Maksuehto (numerona, ei tekstiä)",
    paymentTermDays: "päivää netto",
    reference: "Viitteenne",
    invoicingAdditiontalInfo: "Laskutuksen lisätietoja",
    infoNotProvided: "Tietoa ei ole annettu",
    municipalityTotalNumberOfClubs: "Kerhojen määrä",
    municipalityTotalInvoicing: "Laskutus yhteensä",
    InvoicingPerPaymentTerm: "Laskutus / maksuehto",
    NoPaymentTerm: "Määrittelemätön",
    INVOICING_STATUS_UNHANDLED: "Odottaa valmistumista",
    INVOICING_STATUS_REJECTED: "Hylätty",
    INVOICING_STATUS_APPROVED: "Hyväksytty",
    INVOICING_STATUS_INVOICED: "Laskutettu",
    INVOICING_STATUS_PAID: "Maksettu",
    LESSON_INVOICING_STATUS_NOT_SET: "Laskutuksen tilaa ei asetettu",
    LESSON_INVOICING_STATUS_IGNORED: "Ohitetaan",
    undefined: "Tuntematon",
    MIXED: "Sekalainen",
    resetInvoicingStatus: "Resetoi",
    resetInvoicingStatusAll: "Resetoi kaikki",
    sogApprovalAll: "Hyväksy kaikki",
    invoicedAll: "Laskutettu kaikki",
    totalIncome: "Tulot yhteensä",
    includingVAT: "(ALV 24%)",
    income: "Tulot",
    totalCost: "Kulut yhteensä",
    cost: "Kulut",
    school: "Koulu",
    numberOfClubs: "Kerhojen määrä",
    numberOfMunicipalities: "Kuntien määrä",
    costRate: "Kulu-%",
    numberOfLessons: "Kokoontumiset",

    editInvoicingInfoButtonText: "Muokkaa laskutustietoja",
    editInvoicingInfoHeaderText: "Muokkaa laskutustietoja",

    summaryStatus: {
      Estimated: "Arvio",
      Approved: "Hyväksytty",
      Invoiced: "Laskutettu",
    },

    invoicingStatus: {
      short: {
        INVOICING_STATUS_UNHANDLED: "Odottaa",
        INVOICING_STATUS_REJECTED: "Hylätty",
        INVOICING_STATUS_APPROVED: "Hyväksytty",
        INVOICING_STATUS_INVOICED: "Laskutettu",
        INVOICING_STATUS_PAID: "Maksettu",
        LESSON_INVOICING_STATUS_NOT_SET: "Ei asetettu",
        LESSON_INVOICING_STATUS_IGNORED: "Ohitetaan",
        undefined: "Tuntematon",
        MIXED: "Sekalainen",
      },
    },
  },
  trusterReport: {
    submit: "Hae raportti",
    invocingEmail: "Laskutussähköposti",
    periodTitle: "Jakso",
    otherStatuses: "Muut tilat",
    geduTotal: "Yhteensä",
    geduTotalAllGedus: "Yhteensä (kaikki gedut)",
    submitCSV: "Lataa CSV",
    submitJSON: "Lataa JSON",
    submitMarkApprovedPeriodInvoiced: "Merkitse kaudet laskutetuiksi",
  },
  geduInvoicingStatusReport: {
    title: "Gedu laskutus status",
    submit: "Hae raportti",
    statusChanges: "Tilamuutokset",
    total: "Yhteensä",
    undefinedAmount: "virhe",
    totalIncludingVAT: "Yhteensä (ALV)",
  },
  geduSearch: {
    title: "Pelikasvattajahaku",
    searchButtonText: "Hae",
    searchLabel:
      "Etsi nimellä, sähköpostilla, puhelinnumerolla, discord id:llä, käyttäjänimellä tai nickillä",
    searchHelpText:
      "Huom! Toimii nyt myös discord user namella tai nickillä!!!",
    invoicingMethod: "Laskutustapa",
    languages: "Kielet",
    locations: "Paikkakunnat",
    id: "Tietokanta-id",
  },
  gamerSearch: {
    title: "Pelaajahaku",
    searchButtonText: "Hae",
    searchLabel:
      "Etsi nimellä, sähköpostilla, puhelinnumerolla, discord id:llä, käyttäjänimellä tai nickillä",
    searchHelpText:
      "Huom! Toimii nyt myös discord user namella tai nickillä!!!",
    id: "Tietokanta-id",
    subscriptionPlans: "Tilaukset",
    clubs: "Kerhot",
    parents: "Vanhemmat",
    muniGamer: "Kunta-gamer",
    consumerGamer: "Kuluttaja-gamer",
    gamerTypeLabel: "Pelaajan tyyppi",
    gamerTypeAll: "Kaikki",
    gamerTypeMuni: "Kunta",
    gamerTypeConsumer: "Kuluttaja",
  },
  parentSearch: {
    title: "Vanhempien haku",
    searchButtonText: "Hae",
    searchLabel:
      "Etsi nimellä, sähköpostilla, puhelinnumerolla, discord id:llä, käyttäjänimellä tai nickillä",
    searchHelpText:
      "Huom! Toimii nyt myös discord user namella tai nickillä!!!",
    id: "Tietokanta-id",
    gamers: "Pelaajat",
    subscriptions: "Tilaukset",
  },
  geduSubstituteSearch: {
    title: "Tuuraajan haku",
    findButtonText: "Etsi",
    searchHelpText:
      "Etsi mahdolliset tuuraajat, jotka ovat vapaana annettuna ajankohtana",
    weekday: "Päivä",
    startTime: "Aloitus",
    endTime: "Lopetus",

    languageSelectorPlaceholder: "Valitse kieli...",
    skillSelectorPlaceholder: "Valitse sisältötaito...",
    qualificationSelectorPlaceholder: "Valitse muu osaaminen...",
    locationSelectorPlaceholder: "Valitse paikkakunta...",
    attandanceTypeSelectorPlaceholder: "Valitse osallistumistapa...",
  },
  geduDetails: {
    roles: "Roolit",
    lastLogin: "Viimeisin kirjautuminen",
    lastLoginNever: "Ei kirjautumisia",
    inactive: "Ei aktiivinen",
    active: "Aktiivinen",

    criminalRecordChecked: "Rikosrekisteri tarkastettu",
    criminalRecordNotChecked: "Ei tarkastettu",
    setCriminalRecordChecked: "Merkitse tarkastetuksi",
    updateCriminalRecordChecked: "Päivitä tarkastus",

    deactivateButtonText: "Deaktivoi",
    activateButtonText: "Aktivoi",

    userId: "Käyttäjän tunniste",
    title: "Pelikasvattajan tiedot",
    invoicingDataTitle: "Laskutustiedot",
    invoicingMethod: "Laskutustapa",
    invoicingMethodNotSelected: "Ei valittu",
    invoicingEmail: "Laskutussähköposti",

    filteringDataTitle: "Suodatustiedot",
    languages: "Kielet",
    skills: "Sisältötaidot",
    qualifications: "Muut osaamiset",
    locations: "Paikkakunnat",
    attandanceTypes: "Osallistumistavat",

    webFlowTitle: "WebFlow",
    clubsTitle: "Kerhot",
    groupName: "Ryhmä",
    discordIdButtonText: "Discord Id",
    discordIdLabel: "Discord Id",
    setDiscordId: "Pelikasvattajan discord id",
    role: "Rooli",
    clubId: "Kerhon tunniste",
    webflow: {
      isActive: "Onko aktiivinen",
      description: "Julkinen kuvaus",
      imageLink: "Julkinen kuvalinkki",
      email: "Sähköposti",
      isExperienceArchitect: "Onko elämysarkkitehti",
      discord: "Discord",
      name: "Nimi",
      id: "Webflow Collection tunnus",
      updateStatus: "Päivityksen tila",
      updateSuccess: "Webflow tiedot päivitetty onnistuneesti",
    },
    webflowInformationEdit: {
      setWebflowDataButtonText: "Aseta Webflow-tiedot",
      createWebflowDataButtonText: "Luo Webflow-tiedot",
      title: "Webflow teidot",
      description:
        "Aseta pelikasvattajan tiedot Webflowhun (sog.gg). Kun tiedot on tallennettu, ne päivitetään Webflowhun ja ovat julkisesti saatavilla. Huom! Kuvaus on kirjoitetaan englanniksi ja sivusto hoitaa kääntämisen.",
      descriptionLabel: "Julkinen kuvaus (aina englanniksi)",
      imageLinkLabel: "Julkinen kuvalinkki",
      isExperienceArchitectLabel: "Elämysarkkitehti",

      saveButtonText: "Tallenna ja vie Webflowhun",
    },

    clubRole: {
      owner: "Kerhogedu",
      ASSIGNMENT_TYPE_GROUP_MAIN_GEDU: "Ryhmägedu",
      ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU: "Assari",
    },
    GeduInvoicingInfoEdit: {
      setInvoicingData: "Aseta laskutustiedot",
      title: "Laskutustiedot",
      description: "Aseta pelikasvattajan laskutustiedot",
      invoicingEmailLabel: "Laskutussähköposti",
    },
    GeduFilteringDataEdit: {
      title: "Suodatustiedot",
      description:
        "Aseta pelikasvattajan suodatustiedot kuten kielet, paikkakunnat, taidot jne. Tietoja käytetään kun gedujen hakutietoina",
      languageSelectorPlaceholder: "Valitse kielet...",
      skillSelectorPlaceholder: "Valitse sisältötaidot...",
      qualificationSelectorPlaceholder: "Valitse osaamiset...",
      locationSelectorPlaceholder: "Valitse paikkakunnat...",
      attandanceTypeSelectorPlaceholder: "Valitse osallistumistavat...",
      setFilteringDataButton: "Aseta suodatustiedot",
      setNewValuesButton: "Aseta uudet tiedot",
    },
  },
  gamerDetails: {
    roles: "Roolit",
    lastLogin: "Viimeisin kirjautuminen",
    lastLoginNever: "Ei kirjautumisia",
    inactive: "Ei aktiivinen",
    active: "Aktiivinen",

    userId: "Käyttäjän tunniste",
    title: "Pelaajan tiedot",

    discordIdButtonText: "Discord Id",
    discordIdLabel: "Discord Id",
    setDiscordId: "Pelaajan Discord id",
    birthdate: "Syntymäpäivä",
    metadata: "Metadata",
    subscriptionData: {
      title: "Tilaukset",
      planId: "ChargebeeSubscriptionPlanId",
      subscriptionId: "ChargebeeSubscriptionId",
      chargebeeSubscriptionId: "Tilaus-id",
      customerName: "Asiakkaan nimi",
      customerEmail: "Asiakkaan sähköposti",
      chargebeeCustomerId: "Chargebee-asiakas-id",
      nextBillingAt: "Seuraava laskutus",
      planMetadata: "Tilauksen metadata",
      status: "Tila",

      statusActive: "Aktiivinen",
      statusCancelled: "Lopetettu",
      statusInTrial: "Kokeilu",
      statusNonRenewing: "Uusiutumaton",
      statusOneTime: "Kertaosto",
      statusPaused: "Tauolla",
      statusFuture: "Tuleva",
    },
    participationData: {
      title: "Pelaajan kerhot",
      clubId: "Kerhon id",
      groupId: "Ryhmän id",
    },
    parentData: {
      title: "Vanhemmat",
      parentId: "Käyttäjän id",
    },
    discordData: {
      title: "Discord",
      roles: "Roolit",
      cooldown: "Jäähy",
      noCooldown: "Ei ole",
      joinedAt: "Liittynyt",
      bannedReason: "BAN",
      serverNick: "Nimi serverillä",
    },
  },
  parentDetails: {
    roles: "Roolit",
    lastLogin: "Viimeisin kirjautuminen",
    lastLoginNever: "Ei kirjautumisia",
    inactive: "Ei aktiivinen",
    active: "Aktiivinen",

    userId: "Käyttäjän tunniste",
    title: "Vanhemman tiedot",

    discordIdButtonText: "Discord Id",
    discordIdLabel: "Discord Id",
    setDiscordId: "Vanhemman Discord id",
    birthdate: "Syntymäpäivä",
    metadata: "Metadata",
    subscriptionData: {
      // plans: "",
      // noGamerForPlan: "",
      title: "Tilaukset",
      planId: "ChargebeeSubscriptionPlanId",
      subscriptionId: "ChargebeeSubscriptionId",
      chargebeeSubscriptionId: "Tilaus-id",
      customerName: "Asiakkaan nimi",
      customerEmail: "Asiakkaan sähköposti",
      chargebeeCustomerId: "Chargebee-asiakas-id",
      nextBillingAt: "Seuraava laskutus",
      planMetadata: "Tilauksen metadata",
      plans: "Tilauksen tuotteet",
      noGamerForPlan: "Ei valittua pelaajaa",
    },
    gamerData: {
      title: "Pelaajien tiedot",
      gamerId: "Käyttäjän id",
      discordNick: "Discord-käyttäjä",
    },
  },
  userSearch: {
    title: "Hae käyttäjiä",
    parent: "Vanhempi",
    gamer: "Pelaaja",
    mainGedu: "Päägedu",
    searchLabel: "",
    searchHelpText:
      "Käytä haussa koko etunimeä, sukunimeä, Discord-käyttäjää, sähköpostiosoitetta tai puhelinnumeroa",
    searchButtonText: "Hae",
    userTypeAll: "Kaikki",
    userTypeParent: "Vanhempi",
    userTypeGamer: "Pelaaja",
    userTypeLabel: "Valitse käyttäjän tyyppi",
  },
  clubSearch: {
    title: "Kerhohaku",
    searchLabel: "",
    searchHelpText:
      "Hae kerhon nimellä, id:llä, SKU:lla tai Discord-roolin id:llä",
    searchButtonText: "Hae",
    numResult: "tulos",
    numResults: "tulosta",
    filterClubTypePlaceholder: "Suodata kerhon tyypin mukaan...",
    filterContentTypePlaceholder: "Suodata sisällön mukaan...",
    filterAttendanceTypePlaceholder: "Suodata osallitumistavan mukaan...",
    filterMainGeduPlaceholder: "Suodata päägedun mukaan...",
    filterMuniPlaceholder: "Suodata kunnan mukaan...",
    filterLanguagePlaceholder: "Suodata kielen mukaan...",
    filterStatusPlaceholder: "Suodata kerhon tilan mukaan...",
    filterMeetingDaysPlaceholder: "Suodata tapaamispäivien mukaan...",
  },
  betaNotification: {
    header: "BETA",
    description: "Tämä on beta-versio, joten odotettavissa on puutteita.",
  },
};

const translationsEs = {
  // here we will place our translations...
  slotSelection: {
    newPsw: {
      infoTxt:
        "No tiene una cuenta en esta plataforma, porfavor crea una introduciendo una contraseña:",
      modal: {
        label: "Nueva Contraseña",
        submit: "Guardar Contraseña",
      },
    },
    steps: {
      nextBtn: "Siguiente",
      backBtn: "Atrás",
      finishBtn: "Finalizar",
      firstStep: {
        name: "Escoger Gamer",
        sayHi: "Hola {{firstName}}👋",
        welcomeText: "¡Bienvenido a School of Gaming!",
        infoPurchase: "Has comprado",
        infoGamers: "Primero debemos saber quien recibirá las clases.",
        addGamerInfo:
          "Si ya tienes un gamer dentro de Gamers Arena, puedes seleccionarlo aquí o crear uno nuevo:",
        modalNewGamer: {
          firstName: "Nombre",
          lastName: "Apellido",
          birthDate: "Cumpleaños",
          consentAge: "Consentimiento de edad",
          createGamerBtn: "Crear Gamer",
          editGamerBtn: "Editar Gamer",
        },
      },
      secondStep: {
        name: "Seleccion de Clase",
        infoSlots1: "Puedes escoger",
        infoSlots2: "clase",
        infoSlots3: "de nuestro calendario de clases",
        weekDays: {
          monday: "Lunes",
          tuesday: "Martes",
          wednesday: "Miércoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",
          sunday: "Domingo",
        },
      },
      thirdStep: {
        name: "Confirmación",
        confirmText: "Confirma que los datos sean correctos",
        gamerTitle: "Gamer",
        slotTitle: "Clases",
      },
      fourthStep: {
        success: {
          title: "Todos los pasos han sido completados - ya has terminado 🎉",
          p: "Toda la información será enviada a tu email como recordatorio.",
          p2: "Puedes modificar las clases elegidas y tu información personal entrando en el portal de Gamers’s Arena.",
        },
        error: {
          tryAgain: "Porfavor, inténtalo de nuevo",
          goBackBtn: "Retroceder",
        },
      },
    },
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    //lng: 'fi',
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: { translation: translationsEn },
      fi: { translation: translationsFi },
      es: { translation: translationsEs },
    },
  });

export default i18n;
