import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import {
  Container,
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Link,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";
import BetaNotification from "../Util/BetaNotification";

import { userSearchDb } from "../Hooks/search"; // Adjust the path accordingly
import "../App.css";
import { useTranslation } from "react-i18next";
import { getStatusSpan } from "./common";

const UserSearch = () => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [customerType, setCustomerType] = useState("all");
  const [activeSearchQuery, setActiveSearchQuery] = useState(undefined);

  const processData = (data) => {
    const users = data.map((user) =>
      user.userType === "parent"
        ? {
            name: user.lastName + ", " + user.firstName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            clubs: user.clubs,
            userType: user.userType,
            subscriptions: user.subscriptions,
          }
        : {
            name: user.lastName + ", " + user.firstName,
            // email: user.email,
            // phoneNumber: user.phoneNumber,
            discordUserId: user.discordUserId,
            discordUserName: user.discordUserName,
            avatar: user.discordAvatar ?? "",
            clubs: user.clubs,
            parents: user.parents,
            userType: user.userType,
            subscriptions: user.subscriptions,
          }
    );
    // sort by name
    users.sort((a, b) => a.name.localeCompare(b.name));
    return users;
  };

  const {
    data: userData,
    isLoading,
    error,
  } = useQuery(
    ["userSearch", activeSearchQuery?.query, activeSearchQuery?.userType],
    () => userSearchDb(activeSearchQuery).then(processData),
    { enabled: activeSearchQuery !== undefined }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setActiveSearchQuery({ query: searchQuery, userType: customerType });
  };

  return (
    <Container maxWidth="md">
      <BetaNotification />
      <Typography variant="h4" gutterBottom>
        {t("userSearch.title")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" alignItems="baseline" gap={1}>
          <Stack direction="column" alignItems="flex-start" gap={0}>
            <TextField
              label={t("userSearch.searchLabel")}
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginBottom: 2 }}
              helperText={t("userSearch.searchHelpText")}
            />
          </Stack>
          <FormControl sx={{ m: 1 }}>
            <Select
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}
            >
              <MenuItem value={"all"}>{t("userSearch.userTypeAll")}</MenuItem>
              <MenuItem value={"gamer"}>
                {t("userSearch.userTypeGamer")}
              </MenuItem>
              <MenuItem value={"parent"}>
                {t("userSearch.userTypeParent")}
              </MenuItem>
            </Select>
            <FormHelperText id="user-type-label">
              {t("userSearch.userTypeLabel")}
            </FormHelperText>
          </FormControl>
          <Button variant="contained" type="submit">
            {t("userSearch.searchButtonText")}
          </Button>
        </Stack>
      </form>
      {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
      {error && <Typography color="error">{error.message}</Typography>}
      {userData && (
        <div>
          {userData.map((result, index) => (
            <Card key={result.name} sx={{ marginTop: 2 }}>
              <CardContent>
                <Stack direction="row" alignItems="center" gap={2}>
                  {result.avatar && (
                    <CardMedia
                      component="img"
                      className="user-icon"
                      image={result.avatar}
                      alt="Avatar"
                    />
                  )}
                  {!result.avatar && <NoAvatarIcon className="user-icon" />}
                  {(result.userType === "muniGamer" ||
                    result.userType === "consumerGamer") && (
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      // gap={0}
                    >
                      <Typography variant="h5">{result.name}</Typography>
                      <Typography variant="body1">
                        {
                          {
                            muniGamer: t("gamerSearch.muniGamer"),
                            consumerGamer: t("gamerSearch.consumerGamer"),
                          }[result.userType]
                        }
                      </Typography>
                      {result.discordUserName && (
                        <Typography variant="body1">
                          Discord: {result.discordUserName}
                        </Typography>
                      )}
                      {/* <Typography variant="body1">
                        {result.email}
                        {result.phoneNumber ? ` - ${result.phoneNumber}` : ``}
                      </Typography> */}
                      {result.subscriptions.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.subscriptionPlans")}:</Typography>
                      }
                      {result.subscriptions.map((plan, index) => 
                        <Typography key={plan.name + index}>({getStatusSpan(plan.status)}) {plan.name}</Typography>
                      )}
                      {result.clubs.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.clubs")}:</Typography>
                      }
                      {result.clubs.map((club) => club.clubId
                        ? <Link href={"./club/?id=" + club.clubId} key={club.clubId}>{club.clubName} ({club.groupName})</Link>
                        : <Typography key={club.clubName + club.groupName}>{club.clubName} ({club.groupName})</Typography>
                      )}
                      {result.parents.length > 0 && (
                        <Typography variant="h6">
                          {t("gamerSearch.parents")}:
                        </Typography>
                      )}
                      {result.parents.map((parent) => (
                        <Typography key={parent.email}>
                          {parent.lastName}, {parent.firstName} ({parent.email},{" "}
                          {parent.phoneNumber})
                        </Typography>
                      ))}
                      {/* Add more fields as needed */}
                    </Stack>
                  )}
                  {result.userType === "parent" && (
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      textAlign="left"
                      // gap={0}
                    >
                      <Typography variant="h5">{result.name}</Typography>
                      <Typography variant="body1">
                        {t("userSearch.parent")}
                      </Typography>
                      {/* {result.discordUserName && <Typography variant="body1">
                        Discord: {result.discordUserName}
                      </Typography>} */}
                      <Typography variant="body1">
                        {result.email}
                        {result.phoneNumber ? ` - ${result.phoneNumber}` : ``}
                      </Typography>
                      {result.subscriptions && result.subscriptions.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.subscriptionPlans")}:</Typography>
                      }
                      {(result.subscriptions ?? []).map((subscription, index) => 
                        <Typography key={index}>({getStatusSpan(subscription.status)}) {subscription.planNames.join(", ")}</Typography>
                      )}
                      {result.clubs && result.clubs.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.clubs")}:</Typography>
                      }
                      {(result.clubs ?? []).map((club) => club.clubId
                        ? (
                          <Fragment key={club.clubId}>
                            <Typography><Link href={"./club/?id=" + club.clubId}>{club.clubName}</Link> ({club.groupName})</Typography>
                            {club.gamer && <Typography variant="body2">
                              {t("userSearch.gamer")}: {club.gamer.lastName + ", " + club.gamer.firstName}
                            </Typography>}
                            {club.mainGedu && <Typography variant="body2">
                              {t("userSearch.mainGedu")}: {club.mainGedu.lastName + ", " + club.mainGedu.firstName}
                            </Typography>}
                          </Fragment>
                        ) : (
                          <Fragment key={club.clubId}>
                            <Typography>{club.clubName}</Typography>
                            {club.mainGedu && (
                              <Typography variant="body2">
                                {" "}
                                {t("userSearch.mainGedu")}:{" "}
                                {club.mainGedu.lastName +
                                  ", " +
                                  club.mainGedu.firstName}
                              </Typography>
                            )}
                          </Fragment>
                        )
                      )}
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </Container>
  );
};

export default UserSearch;
