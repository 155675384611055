import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import TextField from "@mui/material/TextField";

import FloatInputField from "./floatInputField";

import { useTranslation } from "react-i18next";

export const MuniInvoicingInfoEditor = ({ data, onSave }) => {
  const { t } = useTranslation();

  const [editedData, setEditedData] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const deepCopy = (obj) => {
    // Check if obj is an object
    if (typeof obj !== "object" || obj === null) {
      return obj; // Return primitive values or null as is
    }

    // Create a new object or array
    const copy = Array.isArray(obj) ? [] : {};

    // Iterate over each key in the object
    for (const key in obj) {
      // Recursively copy nested objects or arrays
      copy[key] = deepCopy(obj[key]);
    }

    return copy;
  };

  useEffect(() => {
    // console.log("USE EFFECT DATA");
    // console.log(data);
    // console.log(editedData);

    if (data) {
      // Check for changes when data or editedData changes
      const changes = Object.keys(data).some(
        (key) => editedData[key]?.value !== data[key]?.value
      );

      setHasChanges(changes);

      const updatedData2 = deepCopy(data);
      setEditedData(updatedData2);

      Object.keys(editedData).forEach((key) => {
        if (!editedData[key].value) {
          editedData[key].value = ""; // emtpy string
        }
      });
    }
  }, [data]);

  useEffect(() => {
    // console.log("USE EFFECT EDITED DATA");
    // console.log(data);
    // console.log(editedData);

    if (data && editedData) {
      // Check for changes when data or editedData changes
      const changes = Object.keys(data).some(
        (key) => editedData[key]?.value !== data[key]?.value
      );

      setHasChanges(changes);
    }
  }, [editedData]);

  const handleValueChange = (key, newValue) => {
    // console.log("HANDLE VALUE CHANGE");
    // console.log(data);
    // console.log(editedData);
    // console.log(key + ": " + newValue);
    // console.log(newValue);

    const updatedData = {
      ...editedData,
    };

    updatedData[key].value = newValue;

    // console.log("UPDATED DATA");
    // console.log(updatedData);

    setEditedData(updatedData);
  };

  const handleSave = () => {
    onSave(editedData);
    setHasChanges(false);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("muniReport.editInvoicingInfoButtonText")}
      </Button>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>{t("muniReport.editInvoicingInfoHeaderText")}</DialogTitle>
        {/* <DialogContent>
          {Object.keys(editedData).map((key) => {
            <TextField
              label={editedData[key].label}
              variant="outlined"
              fullWidth
              value={editedData[key].value}
              onChange={(event) => handleValueChange(key, event.target.value)}
              sx={{ marginBottom: 2, marginTop: 0.5 }}
            />;
          })}
        </DialogContent> */}

        <DialogContent>
          {Object.keys(editedData).map((key) => (
            <TextField
              key={key} // Add a unique key for each TextField
              label={editedData[key].label}
              variant="outlined"
              fullWidth
              value={editedData[key].value}
              onChange={(event) => handleValueChange(key, event.target.value)}
              sx={{ marginBottom: 2, marginTop: 0.5 }}
            />
          ))}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            {t("general.close")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!hasChanges}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
