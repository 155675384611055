import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ErrorAlert from "../errorAlert";

import Alert from "@mui/material/Alert";

import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";

import AcUnitIcon from "@mui/icons-material/AcUnit";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import SleddingIcon from "@mui/icons-material/Sledding";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import SkateboardingIcon from "@mui/icons-material/Skateboarding";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import OpenIcon from "@mui/icons-material/OpenInNew";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";

import "./club.css";
import {
  getSubscriptionData,
  manualSubscriptionMapping,
  getActiveClubs,
  moveParticipant,
} from "../Hooks/clubs";
import { setUserNote, setUserWelcomeLetterStatus } from "../Hooks/users";
import { addParticipationManual } from "../Hooks/municipality";
import { getGroup, getGroupWithActiveParticipantsOnly } from "../Hooks/groups";
import {
  isAdmin,
  isGedu,
  isParent,
  isGamer,
  isActiveStatus,
} from "../Util/authHelper";
import {
  prepareClubName,
  weekdays,
  formatDate,
  prepareGroupName,
} from "../Util/clubHelper";

import MunicipalityParticipantRegistrationDialog from "./municipalityParticipantRegistrationDialog.js";

import { useTranslation } from "react-i18next";
import { add, set } from "date-fns";

export default function ClubParticipants(params) {
  const { t } = useTranslation();
  const [cardData, setCardData] = useState(undefined);
  const [openAddParticipant, setOpenAddParticipant] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentFistName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [gamerFirstName, setGamerFirstName] = useState("");
  const [gamerLastName, setGamerLastName] = useState("");
  const [discordId, setDiscordId] = useState("");
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [gamerFound, setGamerFound] = useState(false);
  const [gamerNotes, setGamerNotes] = useState({});
  const [gamerWelcomeEmailSent, setGamerWelcomeEmailSent] = useState({});
  const [gamerWelcomeEmailSaving, setGamerWelcomeEmailSaving] = useState("");
  const [gamerNotesOriginal, setGamerNotesOriginal] = useState({});
  const [changeClubGamer, setChangeClubGamer] = useState({});
  const [changeClubOpen, setChangeClubOpen] = useState(false);
  const [changeGroupGamer, setChangeGroupGamer] = useState({});
  const [changeGroupOpen, setChangeGroupOpen] = useState(false);
  const [addMunicipalityParticipantOpen, setAddMunicipalityParticipantOpen] =
    useState(false);
  const [isMunicipalityClub, setIsMunicipalityClub] = useState(false);
  //const [participations, setParticipations] = useState({});
  const [errorSavingParticipation, setErrorSavingParticipation] =
    useState(undefined);
  const [errorChangingGamersClub, setErrorChangingGamersClub] =
    useState(undefined);
  const appUserRoles = params.appAuthInfo.appUserRoles;
  const waitingList = params.waitingList;
  const [infoAlertText, setInfoAlertText] = useState("");

  const locked = isAdmin(appUserRoles) ? false : true;

  const {
    data: subscriptionData,
    error: errorFetchingSubscriptionData,
    isLoading: isLoadingSubsriptionData,
    refetch: refetchSubscriptionData,
  } = useQuery(
    ["subscriptionId", subscriptionId],
    () => getSubscriptionData(subscriptionId),
    {
      enabled: false,
    }
  );
  const { mutate, error } = useMutation(manualSubscriptionMapping);

  const { mutate: addMunicipalityParticipation } = useMutation({
    mutationFn: addParticipationManual,
    onSuccess: params.refetch,
  });
  const { mutate: setNote } = useMutation(setUserNote);
  const { mutate: setWelcomeLetterStatus } = useMutation(
    setUserWelcomeLetterStatus
  );
  const { mutate: moveGamer } = useMutation({
    mutationFn: moveParticipant,
    onSuccess: params.refetch,
  });

  let errorTxt = undefined;

  const { data: groupData, isLoading } = useQuery(
    [
      "getGroup",
      params.groupId,
      params.clubData.discordGuildId,
      params.updateGeneration,
    ],
    () =>
      getGroupWithActiveParticipantsOnly(
        params.groupId,
        params.clubData.discordGuildId
      ),
    {
      staleTime: 10_000,
    }
  );

  useEffect(() => {
    prepareData();

    // TODO: remove this whole useEffect
    // eslint-disable-next-line
  }, [groupData]);

  const getDefaultIconState = () => {
    const iconStatesPerMonth = [6, 1, 1, 2, 4, 3, 5, 3, 4, 2, 2, 6];
    const today = new Date();

    let month = today.getMonth();
    return iconStatesPerMonth[month];
  };

  const getFields = (gamer) => {
    const fields = {
      id: gamer.id,
      firstName: gamer.firstName,
      lastName: gamer.lastName,
      discordUserName: gamer.discordUserName,
      participantNotes: gamer.participantNotes,
      //gamerRoles: participation.gamer.roles,
      waitingList: gamer.waitingList,
      welcomeLetterSent: gamer.welcomeEmailSent,
      //welcomeEmailSentTimestamp: gamer.welcomeEmailSent?.timestamp,
      additionalInformation: gamer.additionalInformation,
    };

    if (gamer.discordRoleNames) {
      let roleString = "";
      let first = true;
      for (const roleName of gamer.discordRoleNames) {
        if (!first) roleString += ", ";
        roleString += roleName;
        first = false;
      }
      fields.gamerRoles = roleString;
    }

    if (gamer.avatar) {
      fields["iconState"] = 0; // has avatar
      fields["avatar"] = gamer.avatar;
    } else {
      if (gamer.discordAvatar) {
        fields["iconState"] = 0;
        fields["avatar"] = gamer.discordAvatar;
      } else {
        fields["iconState"] = getDefaultIconState();
      }
    }

    if (gamer.parents) {
      const parents = [];
      for (const parent of gamer.parents) {
        const parentData = {
          id: parent.id,
          firstName: parent.firstName,
          lastName: parent.lastName,
          email: parent.email,
          phone: parent.phone,
        };
        parents.push(parentData);
      }
      fields["parents"] = parents;
    }

    return fields;
  };

  const prepareData = () => {
    const club = params.clubData;
    const group = groupData;
    const notes = {};
    const welcomeEmailSent = {};
    const data1 = [];
    if (group?.participations) {
      for (const participation of group.participations) {
        if (participation.gamer && isActiveStatus(participation.status)) {
          // only if have gamer info

          // filter out waiting list
          if (participation.gamer.waitingList && !waitingList) continue;
          if (!participation.gamer.waitingList && waitingList) continue;

          const gamer = getFields(participation.gamer);
          gamer.status = participation.status;
          notes[gamer.id] = gamer.participantNotes;
          welcomeEmailSent[gamer.id] = gamer.welcomeLetterSent;

          if (isAdmin(appUserRoles)) {
            if (participation.gamer.birthdate) {
              gamer.birthdate = participation.gamer.birthdate;
            }
          }

          if (isAdmin(appUserRoles)) {
            if (participation.gamer.gender) {
              gamer.gender = t("code." + participation.gamer.gender);
            }
          }

          // participantGamer createdAt
          if (participation.participantGamer) {
            gamer.municipalityGamer = true;
            if (participation.participantGamer.createdAt) {
              gamer.createdAt = participation.participantGamer.createdAt;
            }
          } else {
            gamer.municipalityGamer = false;
          }

          data1.push(gamer);
        }
      }
    }

    if (club?.type?.name === "CLUB_TYPE_MUNICIPALITY") {
      setIsMunicipalityClub(true);
      // sort by createdAt
      console.log("sort by createdAt");
      data1.sort((a, b) => {
        if (!a.createdAt) return 1;
        if (!b.createdAt) return -1;
        return (a.createdAt > b.createdAt) - (a.createdAt < b.createdAt);
      });
    } else {
      // sort by discord nick
      console.log("sort by discord nick");
      data1.sort((a, b) => {
        if (!a.discordUserName) return 1;
        const nameA = a.discordUserName ? a.discordUserName.toLowerCase() : ""; // ignore upper and lowercase
        const nameB = b.discordUserName ? b.discordUserName.toLowerCase() : ""; // ignore upper and lowercase
        return (nameA > nameB) - (nameA < nameB);
      });
    }
    setCardData(data1);
    setGamerNotes(notes);
    setGamerNotesOriginal(notes); // store originals for reset
    setGamerWelcomeEmailSent(welcomeEmailSent);
    if (params.onParticipantCountChange && !isLoading)
      params.onParticipantCountChange(data1.length);
  };

  const resetFieldValues = () => {
    setParentEmail("");
    setParentFirstName("");
    setParentLastName("");
    setGamerFirstName("");
    setGamerLastName("");
    setDiscordId("");
    setGamerFound(false);
    setAddButtonDisabled(true);
    setErrorSavingParticipation(undefined);
  };

  const handleAddParticipantOpen = async () => {
    setOpenAddParticipant(true);
  };
  const handleAddParticipantClose = async () => {
    resetFieldValues();
    setOpenAddParticipant(false);
  };
  const handleAddParticipant = async () => {
    const groupId = params.groupId;

    mutate(
      {
        chargebeeSubscriptionId: subscriptionId,
        groupIds: groupId,
        gamer: {
          firstName: gamerFirstName,
          lastName: gamerLastName,
          discordId: discordId,
        },
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleAddParticipantClose();
        },
        onError: (e) => {
          errorTxt = e;
          console.error(e);
          setErrorSavingParticipation(e);
        },
      }
    );
  };

  const handleAddMunicipalityParticipant = async (participantInfoReceived) => {
    /*
			parentFirstName: { type: 'string', minLength: 1, required: true },
			parentLastName: { type: 'string', minLength: 1, required: true },
			locale: { type: 'string', minLength: 1, required: true },
			parentEmail: { type: 'string', minLength: 1, required: true },
			parentPhoneNumber: { type: 'string', minLength: 1, required: true },
			gamerFirstName: { type: 'string', minLength: 1, required: true },
			gamerLastName: { type: 'string', minLength: 1, required: true },
			gamerBirthdate: { type: 'string', format: 'date', required: true },
			gamerGender: { type: 'string', minLength: 1, required: true },
			consent: { type: 'boolean', required: true },

			clubId: { type: 'string', minLength: 1, maxLength: 20, pattern: '^\\d+$', required: true },
*/
    const participantInfo = { ...participantInfoReceived };

    // format the date to YYYY-MM-DD
    var date = new Date(participantInfo.gamerBirthdate);
    participantInfo.gamerBirthdate = formatDate(date, "YYYY-MM-DD");

    participantInfo.clubId = params.clubData.id;
    participantInfo.consent = true; // manual participants have given the consent one way or another

    // locale is code now
    //participantInfo.locale = params.clubData.language;

    if (params.clubData.languageCode?.name === "LANGUAGE_CODE_ENGLISH") {
      participantInfo.locale = "en";
    } else if (params.clubData.languageCode?.name === "LANGUAGE_CODE_SWEDISH") {
      participantInfo.locale = "sv";
    } else {
      participantInfo.locale = "fi";
    }

    addMunicipalityParticipation(
      {
        ...participantInfo,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleAddMunicipalityParticipantClose();
        },
        onError: (e) => {
          errorTxt = e;
          console.error(e);
          setErrorSavingParticipation(e);
        },
      }
    );
  };

  const fetchWithSubscriptionId = async () => {
    console.log("fetchWithSubscriptionId");
    console.log(subscriptionId);

    if (subscriptionId) {
      resetFieldValues(); // reset old values
      const subscriptionData = await refetchSubscriptionData(subscriptionId);
      if (errorFetchingSubscriptionData) {
        console.log(errorFetchingSubscriptionData.response.data.code);
      } else {
        console.log(subscriptionData);

        setGamerFound(subscriptionData.data?.gamerFound ? true : false);

        if (!subscriptionData.data?.gamerFound) {
          setAddButtonDisabled(false);
        }

        if (subscriptionData.data?.customer) {
          setParentEmail(subscriptionData.data.customer.email);
          setParentFirstName(subscriptionData.data.customer.firstName);
          setParentLastName(subscriptionData.data.customer.lastName);
        }
        if (subscriptionData.data?.gamer) {
          setGamerFirstName(subscriptionData.data.gamer.firstName);
          setGamerLastName(subscriptionData.data.gamer.lastName);
          setDiscordId(subscriptionData.data.gamer.discordId);
        }
      }
    }
  };

  const handleWelcomLetterStatus = async (e, gamerId, status) => {
    e.preventDefault();

    setGamerWelcomeEmailSaving(gamerId);
    // do actual save to API
    setWelcomeLetterStatus(
      {
        id: gamerId,
        body: {
          status: status,
        },
      },
      {
        onSuccess: (e) => {
          // set the UI state
          const newData = { ...gamerWelcomeEmailSent };
          console.log(newData);
          newData[gamerId] = status;
          setGamerWelcomeEmailSent(newData);
          setGamerWelcomeEmailSaving("");
        },
        onError: (e) => {
          errorTxt = e;
          console.error(e);
          setGamerWelcomeEmailSaving("");
        },
      }
    );
  };

  const storeParticipantNote = async (gamerId, notes) => {
    const notesCopy = { ...gamerNotes };
    notesCopy[gamerId] = notes;
    setGamerNotes(notesCopy);
  };

  const saveParticipantNote = async (gamerId) => {
    console.log("Do save: " + gamerId + "-->" + gamerNotes[gamerId]);

    // do save to API
    setNote(
      {
        id: gamerId,
        body: {
          note: gamerNotes[gamerId],
        },
      },
      {
        onSuccess: (e) => {
          const notesCopy = { ...gamerNotes };
          notesCopy[gamerId] = gamerNotes[gamerId];
          setGamerNotesOriginal(notesCopy);
          console.log("success");
        },
        onError: (e) => {
          errorTxt = e;
          console.error(e);
        },
      }
    );
  };

  const resetParticipantNote = async (gamerId) => {
    const notesCopy = { ...gamerNotes };
    notesCopy[gamerId] = gamerNotesOriginal[gamerId];
    setGamerNotes(notesCopy);
  };

  const handleChangeClubOpen = async (gamer) => {
    setChangeClubGamer(gamer);
    setChangeClubOpen(true);
  };
  const handleChangeGroupOpen = async (gamer) => {
    setChangeGroupGamer(gamer);
    setChangeGroupOpen(true);
  };
  // const handleChangeClubClose = async () => {
  //   setChangeClubGamer({});
  //   setChangeClubButtonDisabled(true);
  //   setNewClubForGamer([currentClubForGamer]);

  //   setChangeClubOpen(false);
  // };

  // const newClubForGamerSelected = async (data) => {
  //   if (data.value !== clubId) setChangeClubButtonDisabled(false);
  //   else setChangeClubButtonDisabled(true);
  //   setNewClubForGamer(data);
  // };

  // const handleChangeClub = async () => {
  //   setChangeClubOpen(false);

  //   // call API
  //   const oldGroupId = params.group.id;
  //   const newClubId = newClubForGamer?.value;

  //   moveGamer(
  //     {
  //       gamerId: changeClubGamer?.id,
  //       oldGroupId: oldGroupId,
  //       newClubId: newClubId,
  //     },
  //     {
  //       onSuccess: (e) => {
  //         console.log(e);

  //         // update participant list
  //         const newCardData = [...cardData];
  //         const foundIndex = newCardData.findIndex(
  //           (card) => card.id === changeClubGamer?.id
  //         );

  //         console.log("INDEX = " + foundIndex);

  //         console.log("BEFORE");
  //         console.log(newCardData);
  //         newCardData.splice(foundIndex, 1);
  //         console.log("AFTER");
  //         console.log(newCardData);

  //         setCardData([...newCardData]);

  //         if (params.onParticipantCountChange)
  //           params.onParticipantCountChange(newCardData.length);

  //         handleChangeClubClose();
  //       },
  //       onError: (e) => {
  //         errorTxt = e;
  //         console.error(e);
  //         setErrorChangingGamersClub(e);
  //       },
  //     }
  //   );
  // };

  const copyAllEmails = () => {
    const allEmails = [];
    cardData.map((card) =>
      card.parents.map((parent) => {
        allEmails.push(parent.email);

        console.log(parent.email);
      })
    );
    const toCopy = allEmails.map((row) => row).join("\n");

    console.log(toCopy);

    // Create a temporary textarea to copy all emails
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = toCopy;
    document.body.appendChild(tempTextarea);

    // Select all email text and copy it to the clipboard
    tempTextarea.select();
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(tempTextarea);

    // Optionally, provide user feedback (e.g., a tooltip or a message)
    setInfoAlertText(t("participants.copyAllEmailsCopied"));
  };

  return (
    <div>
      {errorTxt && <ErrorAlert txt={errorTxt} />}
      {infoAlertText && (
        <Alert
          severity="info"
          onClose={() => {
            setInfoAlertText("");
          }}
        >
          {infoAlertText}
        </Alert>
      )}
      {/* Add participant dialog */}
      <MunicipalityParticipantRegistrationDialog
        open={addMunicipalityParticipantOpen}
        onClose={() => setAddMunicipalityParticipantOpen(false)}
        clubId={params.clubData.id}
        onSave={handleAddMunicipalityParticipant}
      />
      <Dialog
        onClose={handleAddParticipantClose}
        open={openAddParticipant}
        disableScrollLock
      >
        <DialogTitle>{t("participants.addNewParticipant.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("participants.addNewParticipant.description")}{" "}
          </DialogContentText>
          {gamerFound && (
            <DialogContentText sx={{ color: "red" }}>
              {t("participants.addNewParticipant.gamerFound")}{" "}
            </DialogContentText>
          )}
          {errorFetchingSubscriptionData && (
            <DialogContentText sx={{ color: "red" }}>
              {t(
                "participants.addNewParticipant." +
                  errorFetchingSubscriptionData.response.data.code
              )}
            </DialogContentText>
          )}
          {errorSavingParticipation && (
            <DialogContentText sx={{ color: "red" }}>
              {t("participants.addNewParticipant." + errorSavingParticipation)}
            </DialogContentText>
          )}

          <TextField
            autoFocus
            margin="dense"
            id="subscriptionIdField"
            label={t("participants.addNewParticipant.subscriptionId")}
            type="text"
            fullWidth
            value={subscriptionId}
            variant="standard"
            onChange={(e) => setSubscriptionId(e.target.value)}
          />
          <Button id="fetchButton" onClick={fetchWithSubscriptionId}>
            {t("participants.addNewParticipant.fetchButton")}
          </Button>
          {isLoadingSubsriptionData && <CircularProgress />}
          <TextField
            margin="dense"
            id="parentEmailField"
            label={t("participants.addNewParticipant.email")}
            type="email"
            fullWidth
            value={parentEmail}
            disabled
            variant="standard"
            onChange={(e) => setParentEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="parentFirstNameField"
            label={t("participants.addNewParticipant.parentFirstName")}
            type="text"
            fullWidth
            value={parentFistName}
            disabled
            variant="standard"
            onChange={(e) => setParentFirstName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="parentLastNameField"
            label={t("participants.addNewParticipant.parentLastName")}
            type="text"
            fullWidth
            value={parentLastName}
            disabled
            variant="standard"
            onChange={(e) => setParentLastName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="gamerFirstNameField"
            label={t("participants.addNewParticipant.gamerFirstName")}
            type="text"
            fullWidth
            value={gamerFirstName}
            variant="standard"
            onChange={(e) => setGamerFirstName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="gamerLastNameField"
            label={t("participants.addNewParticipant.gamerLastName")}
            type="text"
            fullWidth
            value={gamerLastName}
            variant="standard"
            onChange={(e) => setGamerLastName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="discordIdField"
            label={t("participants.addNewParticipant.discordId")}
            type="text"
            fullWidth
            value={discordId}
            variant="standard"
            onChange={(e) => setDiscordId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddParticipantClose}>Cancel</Button>
          <Button disabled={addButtonDisabled} onClick={handleAddParticipant}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ClubGroupSelect
        selectedGamer={changeClubGamer}
        isOpen={changeClubOpen}
        setIsOpen={setChangeClubOpen}
        curGroupId={undefined}
        moveParticipant={(newGroupId) =>
          moveGamer({
            gamerId: changeClubGamer?.id,
            oldGroupId: params.groupId,
            newGroupId: newGroupId,
          })
        }
      />
      <GroupSelect
        selectedGamer={changeGroupGamer}
        isOpen={changeGroupOpen}
        setIsOpen={setChangeGroupOpen}
        curGroupId={undefined}
        groups={params.groups}
        moveParticipant={(newGroupId) =>
          moveGamer({
            gamerId: changeGroupGamer?.id,
            oldGroupId: params.groupId,
            newGroupId: newGroupId,
          })
        }
      />
      <Box>
        {cardData?.length > 0 && (
          <Button
            variant="contained"
            onClick={copyAllEmails}
            sx={{ marginTop: "1em", marginBottom: "1em" }}
          >
            {t("participants.copyAllEmails")}
          </Button>
        )}
      </Box>
      {isLoading && <CircularProgress />}
      <Box sx={{ textAlign: "left" }} key="clubParticipantsBox">
        {cardData &&
          cardData.map((card, index) => (
            <Card
              sx={{ display: "block", marginBottom: 2, minWidth: 320 }}
              key={"clubParticipantCard" + index}
            >
              <Box sx={{ display: "flex" }}>
                <Stack
                  key={"participantBox" + index}
                  direction="column"
                  alignItems="center"
                  gap={1}
                >
                  {/* <div className="gamer-card-content"> */}
                  {card.iconState === 0 && (
                    <CardMedia
                      component="img"
                      className="gamer-icon"
                      image={card.avatar}
                      alt="Avatar"
                      key={"clubParticipantsCardMedia1." + index}
                    />
                  )}
                  {card.iconState === 1 && (
                    <SleddingIcon className="gamer-icon" />
                  )}
                  {card.iconState === 2 && (
                    <EmojiPeopleIcon className="gamer-icon" />
                  )}
                  {card.iconState === 3 && (
                    <KitesurfingIcon className="gamer-icon" />
                  )}
                  {card.iconState === 4 && (
                    <SkateboardingIcon className="gamer-icon" />
                  )}
                  {card.iconState === 5 && (
                    <WbSunnyIcon className="gamer-icon" />
                  )}
                  {card.iconState === 6 && (
                    <AcUnitIcon className="gamer-icon" />
                  )}

                  <Stack
                    key={"participantStack_" + index}
                    direction="row"
                    alignItems="center"
                    gap={0.1}
                    mr={2}
                  >
                    {gamerWelcomeEmailSaving === card.id && (
                      <CircularProgress />
                    )}
                    {gamerWelcomeEmailSaving !== card.id && (
                      <Box>
                        <Checkbox
                          disabled={locked}
                          key={`participant_welcomeEmailSent_${index}`}
                          checked={gamerWelcomeEmailSent[card.id]}
                          onChange={(e) =>
                            handleWelcomLetterStatus(
                              e,
                              card.id,
                              e.target.checked
                            )
                          }
                        />
                        <Typography sx={{ fontSize: 12 }} color="text.primary">
                          {t("participants.welcomeEmailStatus")}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Stack>

                {/* </div> */}
                <Box
                  sx={{ display: "flex", flexDirection: "column" }}
                  key={"clubParticipantsBox1." + index}
                >
                  {/* <CardContent sx={{ flex: "1 0 auto", pd: "0px" }}> */}
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontStyle: "italic" }}
                    key={"clubParticipantsStatus2." + index}
                  >
                    {card.municipalityGamer
                      ? t("participants.registrationDate")
                      : t("participants.subscriptionStatus")}
                    {": "}
                    {card.municipalityGamer
                      ? formatDate(
                          new Date(card.createdAt),
                          "DD.MM.YYYY hh:mm:ss"
                        )
                      : card.status
                      ? t("subscription.status." + card.status)
                      : t("subscription.status.unknown")}
                  </Typography>
                  <Typography
                    // <Link
                    // href={`/profile/${card.id}`}
                    // component="div"
                    variant="h6"
                    key={"clubParticipantsTypo1." + index}
                  >
                    {card.firstName + " " + card.lastName}
                  </Typography>
                  {/* </Link> */}
                  {card.birthdate && isAdmin(appUserRoles) && (
                    <>
                      <Typography
                        key={"clubParticipantsTypo5." + index}
                        sx={{ pt: 1, fontSize: 10, mt: -1 }}
                        variant="body2"
                      >
                        {t("participants.birthDate")}: {card.birthdate}
                      </Typography>
                      <Typography
                        key={"clubParticipantsTypo6." + index}
                        sx={{ pt: 1, fontSize: 10, mt: -1 }}
                        variant="body2"
                      >
                        {t("participants.gender")}: {card.gender}
                      </Typography>
                    </>
                  )}
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontStyle: "italic" }}
                    key={"clubParticipantsTypo2." + index}
                  >
                    {card.discordUserName} ({card.gamerRoles})
                  </Typography>
                  {card.parents && card.parents.length > 0 && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="gamer-card-parent"
                      key={"clubParticipantsTypo3." + index}
                      sx={{ pt: 2 }}
                    >
                      {card.parents.map((parent) => (
                        <Typography key={"clubParticipantsTypo4." + index}>
                          {t("participants.parentName")}: {parent.firstName}{" "}
                          {parent.lastName} {parent.email} {parent.phone}
                        </Typography>
                      ))}
                    </Typography>
                  )}
                  {card.additionalInformation && (
                    <Typography key={"clubParticipantsTypo6." + index}>
                      {t("participants.additionalInformation")}:{" "}
                      {card.additionalInformation}
                    </Typography>
                  )}
                  {card.waitingList && (
                    <Typography key={"clubParticipantsTypo5." + index}>
                      {t("participants.waitingList")}
                    </Typography>
                  )}
                  {/* </CardContent> */}
                </Box>
                {isAdmin(appUserRoles) && (
                  <Box display="flex" alignItems="center">
                    <IconButton
                      onClick={() => {
                        window
                          .open(`../gamerDetails/?id=${card.id}`, "_blank")
                          .focus();
                      }}
                    >
                      <OpenIcon className="action-icon" />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <CardActions sx={{ paddingTop: "0px" }}>
                <Box
                  sx={{
                    minWidth: "300px",
                    width: "400px",
                    fontSize: 14,
                    display: "flex",
                  }}
                >
                  <TextField
                    margin="dense"
                    id={"participantNotes" + index}
                    label={t("participants.notes")}
                    type="text"
                    fullWidth
                    value={gamerNotes[card.id] ? gamerNotes[card.id] : ""}
                    variant="standard"
                    onChange={(e) =>
                      storeParticipantNote(card.id, e.target.value)
                    }
                  />
                  {gamerNotes[card.id] !== gamerNotesOriginal[card.id] && (
                    <Button
                      size="small"
                      onClick={() => saveParticipantNote(card.id)}
                      sx={{ minWidth: "48px", color: "#6ac66b" }}
                    >
                      <CheckIcon />
                    </Button>
                  )}
                  {gamerNotes[card.id] !== gamerNotesOriginal[card.id] && (
                    <Button
                      size="small"
                      onClick={() => resetParticipantNote(card.id)}
                      sx={{ minWidth: "48px", color: "#ff0000" }}
                    >
                      <CancelIcon />
                    </Button>
                  )}
                </Box>
                {isAdmin(appUserRoles) && (
                  <Box
                    sx={{ display: "flex", flexDirection: "column" }}
                    key={"clubParticipantsBox2." + index}
                  >
                    <Button onClick={() => handleChangeClubOpen(card)}>
                      {t("participants.changeClubButtonText")}
                    </Button>
                  </Box>
                )}
                {isAdmin(appUserRoles) && (
                  <Box
                    sx={{ display: "flex", flexDirection: "column" }}
                    key={"clubParticipantsBox3." + index}
                  >
                    <Button onClick={() => handleChangeGroupOpen(card)}>
                      {t("participants.changeGroupButtonText")}
                    </Button>
                  </Box>
                )}
              </CardActions>
            </Card>
          ))}

        {isAdmin(appUserRoles) && params.showAddParticipantButton && (
          <Card
            key="addNewPartipantCard"
            sx={{
              display: "inline-block",
              mx: "0.5em",
              my: "0.5em",
              minWidth: 330,
              maxWidth: 500,
              textAlign: "left",
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {t("clubLessons.addNewParticipantCardHeader")}
              </Typography>
            </CardContent>
            {isMunicipalityClub && (
              <CardActions sx={{ alignContent: "center" }}>
                {cardData && (
                  <Button
                    size="small"
                    // disabled for now
                    onClick={() => setAddMunicipalityParticipantOpen(true)}
                  >
                    <AddCircleIcon className="add-participant-icon" />
                  </Button>
                )}
              </CardActions>
            )}
            {!isMunicipalityClub && (
              <CardActions sx={{ alignContent: "center" }}>
                {cardData && (
                  <Button
                    size="small"
                    onClick={() => handleAddParticipantOpen()}
                  >
                    <AddCircleIcon className="add-participant-icon" />
                  </Button>
                )}
              </CardActions>
            )}
          </Card>
        )}
      </Box>
    </div>
  );
}

const ClubGroupSelect = ({
  curGroupId,
  moveParticipant,
  selectedGamer,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [clubInputValue, setClubInputValue] = useState("");
  const [groupInputValue, setGroupInputValue] = useState("");

  const {
    data: activeClubsData,
    // error: errorFetchingOtherClubsData,
    // isLoading: isLoadingOtherClubsData,
    // refetch: refetchOtherClubsData,
  } = useQuery(["activeClubs"], getActiveClubs);

  const clubs = [];

  if (activeClubsData && activeClubsData.length > 0) {
    for (const club of activeClubsData) {
      let pre = "";
      let clubSorter = "";
      if (club.meetings && club.meetings.length > 0) {
        const meetings = club.meetings;
        meetings.sort(function (a, b) {
          const aValue = weekdays.indexOf(a.weekday);
          const bValue = weekdays.indexOf(b.weekday);

          return aValue - bValue;
        });
        for (const meeting of meetings) {
          pre += t("weekdayShort." + meeting.weekday);
          clubSorter +=
            weekdays.indexOf(meeting.weekday) + ":" + meeting.startTime;
        }
      } else {
        clubSorter = "9999999"; // if no schedule put to last on the list
      }
      let clubFullName = prepareClubName(club, t);

      const clubItem = {
        id: club.id,
        label: pre ? pre + ": " + clubFullName : clubFullName,
        sorter: clubSorter,
        groups: club.groups,
      };
      clubs.push(clubItem);
    }

    clubs.sort(function (a, b) {
      if (a.sorter < b.sorter) return -1;
      else if (a.sorter > b.sorter) return 1;
      return 0;
    });
  }

  const handleClose = () => {
    setSelectedClub(null);
    setSelectedGroup(null);
    setClubInputValue("");
    setGroupInputValue("");
    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: {
          minHeight: "50%",
          maxHeight: "50%",
        },
      }}
      disableScrollLock
    >
      <DialogTitle>{t("participants.changeGamerClub.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("participants.changeGamerClub.description")}
        </DialogContentText>
        {/* {errorFetchingOtherClubsData && (
          <DialogContentText sx={{ color: "red" }}>
            {t("participants.changeGamerClub.errorFetchingOtherGroupsData")}
          </DialogContentText>
        )} */}
        {/* {errorChangingGamersClub && (
          <DialogContentText sx={{ color: "red" }}>
            {t("participants.changeGamerClub.errorChangingGamersClub") +
              " " +
              errorChangingGamersClub}
          </DialogContentText>
        )} */}
        <DialogContentText>
          {selectedGamer.firstName} {selectedGamer.lastName}
        </DialogContentText>
        <DialogContentText>{selectedGamer.discordUserName}</DialogContentText>
        <DialogContentText>
          <br />
        </DialogContentText>
        {/* <DialogContentText>
        {t("participants.changeGamerClub.selectClubDescription")}
      </DialogContentText> */}
        {/* {isLoadingOtherClubsData && <CircularProgress />} */}
        <Autocomplete
          value={selectedClub}
          disableClearable
          onChange={(_event, newValue) => {
            setSelectedGroup(null);
            setGroupInputValue("");
            setSelectedClub(newValue);
          }}
          inputValue={clubInputValue}
          onInputChange={(_event, newInputValue) => {
            setClubInputValue(newInputValue);
          }}
          options={clubs ?? []}
          sx={{ width: 300 }}
          getOptionLabel={(club) => club?.label ?? ""}
          renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {selectedClub && (
          <Autocomplete
            value={selectedGroup}
            // disableClearable
            onChange={(_event, newValue) => {
              setSelectedGroup(newValue);
            }}
            inputValue={groupInputValue}
            onInputChange={(_event, newInputValue) => {
              setGroupInputValue(newInputValue);
            }}
            options={selectedClub.groups ?? []}
            sx={{ width: 300 }}
            getOptionLabel={(group) => prepareGroupName(group, t) ?? ""}
            renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("general.dialog.cancel")}</Button>
        <Button
          variant="contained"
          disabled={selectedGroup?.id === curGroupId}
          onClick={async () => {
            await moveParticipant(selectedGroup.id);
            handleClose();
          }}
        >
          {t("participants.changeGamerClub.changeButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GroupSelect = ({
  curGroupId,
  groups,
  moveParticipant,
  selectedGamer,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const [selectedGroup, setSelectedGroup] = useState(null);

  const [groupInputValue, setGroupInputValue] = useState("");

  const handleClose = () => {
    setSelectedGroup(null);
    setGroupInputValue("");
    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: {
          minHeight: "50%",
          maxHeight: "50%",
        },
      }}
      disableScrollLock
    >
      <DialogTitle>{t("participants.changeGamerGroup.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("participants.changeGamerGroup.description")}
        </DialogContentText>
        {/* {errorFetchingOtherClubsData && (
          <DialogContentText sx={{ color: "red" }}>
            {t("participants.changeGamerGroup.errorFetchingOtherGroupsData")}
          </DialogContentText>
        )} */}
        {/* {errorChangingGamersClub && (
          <DialogContentText sx={{ color: "red" }}>
            {t("participants.changeGamerGroup.errorChangingGamersClub") +
              " " +
              errorChangingGamersClub}
          </DialogContentText>
        )} */}
        <DialogContentText>
          {selectedGamer.firstName} {selectedGamer.lastName}
        </DialogContentText>
        <DialogContentText>{selectedGamer.discordUserName}</DialogContentText>
        <DialogContentText>
          <br />
        </DialogContentText>
        {/* <DialogContentText>
        {t("participants.changeGamerClub.selectClubDescription")}
      </DialogContentText> */}
        {/* {isLoadingOtherClubsData && <CircularProgress />} */}
        <Autocomplete
          value={selectedGroup}
          // disableClearable
          onChange={(_event, newValue) => {
            setSelectedGroup(newValue);
          }}
          inputValue={groupInputValue}
          onInputChange={(_event, newInputValue) => {
            setGroupInputValue(newInputValue);
          }}
          options={groups ?? []}
          sx={{ width: 300 }}
          getOptionLabel={(group) => prepareGroupName(group, t) ?? ""}
          renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("general.dialog.cancel")}</Button>
        <Button
          variant="contained"
          disabled={selectedGroup?.id === curGroupId}
          onClick={async () => {
            await moveParticipant(selectedGroup.id);
            handleClose();
          }}
        >
          {t("participants.changeGamerGroup.changeButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
