import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "./navbar.css";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import Groups2Icon from '@mui/icons-material/Groups2';
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LogoutIcon from "@mui/icons-material/Logout";
import CodeIcon from "@mui/icons-material/Code";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SecurityIcon from "@mui/icons-material/Security";
import ClubIcon from "@mui/icons-material/AllInclusive";
import MenuIcon from "@mui/icons-material/Menu";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import TrusterIcon from "@mui/icons-material/MotionPhotosAuto";
import InvoicingStatusIcon from "@mui/icons-material/BeachAccess";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PublicIcon from "@mui/icons-material/Public";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FaceIcon from "@mui/icons-material/Face";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import RequestQuote from "@mui/icons-material/RequestQuote";

import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";
import axios from "axios";
import { API } from "../Hooks/API";

// import {ReactComponent as flag_fi} from '../Static/img/fi.svg';
// import {ReactComponent as flag_gb} from '../Static/img/gb.svg';
// import {ReactComponent as flag_es} from '../Static/img/es.svg';

import flag_fi from "../Static/img/fi.svg";
import flag_gb from "../Static/img/gb.svg";
import flag_es from "../Static/img/es.svg";
import { MenuItem } from "@mui/material";
import { setUserRoles } from "../Hooks/users";

export default function Navbar(params) {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [openAdmin, setOpenAdmin] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth > 1100 ? 240 : 60);
  const [authValid, setAuthValid] = React.useState(false);
  const [appUserRoles, setAppUserRoles] = React.useState([]);
  const handleClickCollapseMenu = (rootName) => {
    if (rootName === "report") setOpenReport(!openReport);
    else if (rootName === "search") setOpenSearch(!openSearch);
    else setOpenAdmin(!openAdmin);
  };

  useEffect(() => {
    try {
      setAuthValid(params.appAuthInfo.authValid);
      setAppUserRoles(params.appAuthInfo.appUserRoles);
    } catch (error) {
      window.location.href = "../login";
    }

    window.addEventListener(
      "resize",
      function (event) {
        setWidth(window.innerWidth > 1100 ? 240 : 60);
      },
      true
    );
  }, []);

  const hasAdminRole = () => {
    return appUserRoles.includes("USER_ROLE_ADMIN");
  };

  const isAllowed = (requiredRoles) => {
    if (!requiredRoles) return true;
    if (requiredRoles.length === 0) return true;

    let returnValue = false;

    //    console.log(appUserRoles);

    requiredRoles.map((requiredRole) => {
      // console.log(requiredRole);
      // console.log(appUserRoles.includes(requiredRole));
      if (appUserRoles.includes(requiredRole)) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const openBurger = () => {
    setWidth(240);
    document.getElementById("left-burger").style.display = "inline-block";
    document.getElementById("icon-burger").style.display = "none";
  };
  const closeBurger = () => {
    setWidth(60);
    document.getElementById("left-burger").style.display = "none";
    document.getElementById("icon-burger").style.display = "inline-block";
  };

  const changeLanguage = (event) => {
    console.log(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const languages = {
    en: { nativeName: "English", icon: flag_gb },
    fi: { nativeName: "Suomi", icon: flag_fi },
    es: { nativeName: "España", icon: flag_es },
  };

  const links = [
    {
      txt: "navbar.home",
      url: "/",
      icon: <HomeIcon color="white" />,
      collapse: false,
      requiredRoles: [],
    },
    {
      txt: "navbar.clubCalendar",
      url: "/club-calendar",
      icon: <EventNoteIcon color="white" />,
      requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
      collapse: false,
    },
    {
      txt: "navbar.activities",
      url: "/activity-generator",
      icon: <SportsScoreIcon color="white" />,
      requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
      collapse: false,
    },
    {
      txt: "navbar.geduInvoicing",
      txtAdmin: "navbar.geduInvoicing4Admin", // different text for admins
      url: "/geduinvoicing",
      icon: <CurrencyExchangeIcon color="white" />,
      collapse: false,
      requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
    },

    // {
    //   txt: "navbar.userSearch",
    //   url: "/search-user",
    //   icon: <SearchIcon color="white" />,
    //   collapse: false,
    //   requiredRoles: ["USER_ROLE_GEDU", "USER_ROLE_ADMIN"],
    // },
    {
      txt: "navbar.search",
      url: "",
      icon: <SearchIcon color="white" />,
      collapse: openSearch ? <ExpandLess /> : <ExpandMore />,
      requiredRoles: ["USER_ROLE_ADMIN"],
      collapseMenuName: "search",
      collapseRender: (
        <Collapse in={openSearch} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/search-gedu")}
            >
              <ListItemIcon>
                <FaceIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.searchMenu.geduSearch")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/search-substitute")}
            >
              <ListItemIcon>
                <FindReplaceIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.searchMenu.geduAvailability")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/search-gamer")}
            >
              <ListItemIcon>
                <SportsEsportsIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.searchMenu.gamerSearch")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/search-parent")}
            >
              <ListItemIcon>
                <FamilyRestroomIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.searchMenu.parentSearch")} />
            </ListItemButton>
          </List>
        </Collapse>
      ),
    },
    {
      txt: "navbar.reports",
      url: "",
      icon: <AssessmentIcon color="white" />,
      collapse: openReport ? <ExpandLess /> : <ExpandMore />,
      requiredRoles: ["USER_ROLE_ADMIN"],
      collapseMenuName: "report",
      collapseRender: (
        <Collapse in={openReport} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/report-muni")}
            >
              <ListItemIcon>
                <LocalAtmIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.reportMenu.muniInvoicing")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/report-muni-summary")}
            >
              <ListItemIcon>
                <RequestQuote color="white" />
              </ListItemIcon>
              <ListItemText
                primary={t("navbar.reportMenu.muniInvoicingSummary")}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/report-truster")}
            >
              <ListItemIcon>
                <TrusterIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.reportMenu.trusterData")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                (window.location.href = "/report-gedu-invoicing-status")
              }
            >
              <ListItemIcon>
                <InvoicingStatusIcon color="white" />
              </ListItemIcon>
              <ListItemText
                primary={t("navbar.reportMenu.geduInvoicingStatus")}
              />
            </ListItemButton>
          </List>
        </Collapse>
      ),
    },
    {
      txt: "navbar.adminTitle",
      url: "",
      icon: <SecurityIcon color="white" />,
      collapse: openAdmin ? <ExpandLess /> : <ExpandMore />,
      requiredRoles: ["USER_ROLE_ADMIN"],
      collapseMenuName: "admin",
      collapseRender: (
        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/achievements")}
            >
              <ListItemIcon>
                <EmojiEventsIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.achievements")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/clubs")}
            >
              <ListItemIcon>
                <ClubIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.clubs")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/search-club")}
            >
              <ListItemIcon>
                <Groups2Icon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.searchMenu.clubSearch")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/users")}
            >
              <ListItemIcon>
                <PeopleIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.users")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/codedomains")}
            >
              <ListItemIcon>
                <CodeIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.codes")} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/municipalities")}
            >
              <ListItemIcon>
                <PublicIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.municipalities")} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/activities")}
            >
              <ListItemIcon>
                <SportsScoreIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.activities")} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => (window.location.href = "/admin/clubInstructions")}
            >
              <ListItemIcon>
                <SportsScoreIcon color="white" />
              </ListItemIcon>
              <ListItemText primary={t("navbar.adminMenu.clubInstuctions")} />
            </ListItemButton>
            {/* 
                    <ListItemButton sx={{ pl: 4 }} onClick={ ()=> window.location.href = "/admin/entities"}>
                        <ListItemIcon>
                            <AccountTreeIcon  color="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Entities" />
                    </ListItemButton>
                    */}
          </List>
        </Collapse>
      ),
    },
    {
      txt: "navbar.logout",
      url: "/logout",
      icon: <LogoutIcon color="white" />,
      collapse: false,
      requiredRoles: [],
    },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {links.map(
          (link, index) =>
            isAllowed(link.requiredRoles) && (
              <div key={index}>
                <ListItem key={link.url} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (!link.collapse) {
                        window.location.href = link.url;
                      } else {
                        handleClickCollapseMenu(link.collapseMenuName);
                      }
                    }}
                  >
                    <ListItemIcon>{link.icon}</ListItemIcon>

                    {width > 200 && (
                      <ListItemText
                        primary={
                          hasAdminRole()
                            ? link.txtAdmin
                              ? t(link.txtAdmin)
                              : t(link.txt)
                            : t(link.txt)
                        }
                      />
                    )}
                    {width > 200 && link.collapse && link.collapse}
                  </ListItemButton>
                </ListItem>
                {link.collapse && link.collapseRender}
              </div>
            )
        )}
      </List>
      <div style={{ alignItems: "center" }}>
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: width > 200 ? 120 : 45,
            color: "white",
            borderColor: "white",
          }}
          size="small"
        >
          <InputLabel id="language-selected-label" sx={{ color: "white" }}>
            {t("navbar.languageSelectorLabel")}
          </InputLabel>

          <Select
            name="language"
            labelId="language-selected-label"
            label={t("navbar.languageSelectorLabel")}
            onChange={changeLanguage}
            value={i18n.resolvedLanguage}
            sx={{ color: "white", borderColor: "white", textAlign: "center" }}
            renderValue={(lang) =>
              width > 200 ? languages[lang].nativeName : lang
            }
            inputProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {Object.keys(languages).map((lng, index) => (
              <MenuItem key={index} value={lng}>
                {languages[lng].nativeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );

  return (
    <>
      <CssBaseline />
      {/*LEFT NAV */}
      <Box
        component="nav"
        sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <div id={"left-nav"}>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: width,
                textTransform: "uppercase",
              },
            }}
            open
          >
            <div className="hamburger-nav-left">
              <span id="icon-burger" onClick={openBurger}>
                {" "}
                <MenuIcon />
              </span>
              <span id="left-burger" onClick={closeBurger}>
                {" "}
                <KeyboardArrowLeftIcon />
              </span>
            </div>
            {drawer}
          </Drawer>
        </div>
      </Box>
    </>
  );
}
