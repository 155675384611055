import axios from "axios";
import { API } from "./API";

export const getCodes = async () => {
  const { data } = await axios.get(`${API}/codes`, { withCredentials: true });
  return data.data.codes;
};

export const createCode = async (body) => {
  const { data } = await axios.post(`${API}/codes/create`, body, {
    withCredentials: true,
  });
  return data;
};

export const editCodeField = async (body) => {
  const newBody = {};
  // loop through the body and copy fields to new object
  for (const [key, value] of Object.entries(body)) {
    if (key !== "id") {
      newBody[key] = value;
    }
  }

  const { data } = await axios.post(
    `${API}/codes/editCodeField/${body.id}`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data.data.codes;
};

export const deleteCode = async (id) => {
  const { data } = await axios.delete(`${API}/codes/${id}`, {
    withCredentials: true,
  });
  return data;
};

export const getCodeDomain = async (id) => {
  //  console.log("getCodeDomain " + id);
  const { data } = await axios.get(`${API}/codes/codedomain/${id}`, {
    withCredentials: true,
  });
  //  console.log(data);
  return data.data.codeDomain;
};

export const createCodeDomain = async (body) => {
  //  console.log(body);
  const { data } = await axios.post(`${API}/codes/createcodedomain`, body, {
    withCredentials: true,
  });
  return data;
};

export const getCodeDomains = async () => {
  const { data } = await axios.get(`${API}/codes/codedomains`, {
    withCredentials: true,
  });
  return data.data.codeDomains;
};

export const getCodeDomainsByNames = async (body) => {
  //  console.log(body);
  const { data } = await axios.post(`${API}/codes/codedomainsbynames`, body, {
    withCredentials: true,
  });

  //  console.log(data.data.codeDomains);

  return data.data.codeDomains;
};

export const editCodeDomainField = async (id, body) => {
  const { data } = await axios.post(
    `${API}/codes/editcodedomainfield/${id}`,
    body,
    { withCredentials: true }
  );
  return data.data.codes;
};

export const deleteCodeDomain = async (id) => {
  const { data } = await axios.delete(`${API}/codes/codedomain/${id}`, {
    withCredentials: true,
  });
  return data;
};
