import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Snackbar,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import OpenIcon from "@mui/icons-material/OpenInNew";
import TrusterIcon from "@mui/icons-material/Title";
import NoneIcon from "@mui/icons-material/DoDisturb";
import StaffIcon from "@mui/icons-material/Person";
import InvoiceIcon from "@mui/icons-material/Receipt";
import NotSetIcon from "@mui/icons-material/HelpOutline";

import { Alert } from "@mui/material"; // Import the Alert component from MUI
import { invoicingStatus } from "../Hooks/report";
import { formatDate } from "./clubHelper";
import InvoicingStatus from "./InvoicingStatus";

import { useTranslation } from "react-i18next";
import { login } from "../Hooks/auth";

const InvoicingStatusComponent = (params) => {
  const { t } = useTranslation();

  const [invoicingGeduStatusData, setInvoicingGeduStatusData] = useState([]);
  const [invoicingAdminStatusData, setInvoicingAdminStatusData] = useState([]);
  //  const [loading, setLoading] = useState(true); // New loading state
  //   const [error, setError] = useState(null);

  const isGedu = params.isGedu ? params.isGedu : false;
  const isAdmin = params.isAdmin ? params.isAdmin : false;

  const fetchData = async () => {
    const result = await invoicingStatus();
    return result;
  };

  const {
    data: invoicingStatusData,
    isLoading,
    error,
  } = useQuery("invoicingStatus", fetchData);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const result = await invoicingStatus(); // Replace with your actual hook

  //         if (isGedu) {
  //           let statusData = result.geduReport;
  //           if (statusData) {
  //             // sort by startDate
  //             statusData.sort((a, b) => {
  //               if (a.startDate > b.startDate) {
  //                 return -1;
  //               }
  //               if (a.startDate < b.startDate) {
  //                 return 1;
  //               }
  //               return 0;
  //             });
  //           }
  //           setInvoicingStatusData(statusData);
  //         }
  //         if (isAdmin) {
  //           let adminData = await prepareAdminData(result.adminReport);

  //           console.log(adminData);

  //           setInvoicingAdminStatusData(adminData);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching invoicing status:", error);
  //         setError("An error occurred while fetching data."); // Set the error state
  //       } finally {
  //         setLoading(false); // Set loading to false when the operation completes
  //       }
  //     };

  //     fetchData();
  //   }, []);

  useEffect(() => {
    if (!invoicingStatusData) return;

    console.log(invoicingStatusData);
    try {
      if (isGedu) {
        let statusData = invoicingStatusData.geduReport;
        if (statusData) {
          // sort by startDate
          statusData.sort((a, b) => {
            if (a.startDate > b.startDate) {
              return -1;
            }
            if (a.startDate < b.startDate) {
              return 1;
            }
            return 0;
          });
        }
        setInvoicingGeduStatusData(statusData);
      }
      if (isAdmin) {
        prepareAdminData(invoicingStatusData.adminReport);
      }
    } catch (error) {
      console.error("Error fetching invoicing status:", error);
    }
  }, [invoicingStatusData]);

  const prepareAdminData = async (data) => {
    // if not data, return empty array
    if (!data || (data && data.length === 0)) {
      return [];
    }

    // loog through array of users and sort periods by startDate
    for (const user of data) {
      const sortedPeriods = user.periods;
      // sort periods by startDate
      sortedPeriods.sort((a, b) => {
        if (a.startDate > b.startDate) {
          return -1;
        }
        if (a.startDate < b.startDate) {
          return 1;
        }
        return 0;
      });
      user.periods = sortedPeriods;

      // billing methods:
      // GEDU_BILLING_METHOD_NONE
      // GEDU_BILLING_METHOD_INVOICE
      // GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE
      // GEDU_BILLING_METHOD_STAFF
      // GEDU_BILLING_METHOD_STAFF_HOUR

      if (user.billingMethod) {
        // if billingMethod is TRUSTER, raise the flag
        user.infoText = t("code." + user.billingMethod.name);
        if (
          user.billingMethod.name === "GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE"
        ) {
          user.isTruster = true;
        } else if (
          user.billingMethod.name === "GEDU_BILLING_METHOD_STAFF" ||
          user.billingMethod.name === "GEDU_BILLING_METHOD_STAFF_HOUR"
        ) {
          user.isStaff = true;
        } else if (user.billingMethod.name === "GEDU_BILLING_METHOD_INVOICE") {
          user.isInvoice = true;
        } else {
          user.noBillingMethod = true;
        }
      } else {
        user.billingMethodNotSet = true;
        user.infoText = t("invoicingStatus.billingMethodNotSet");
      }

      user.name = user.lastName + ", " + user.firstName;
    }

    // sort users by Name
    data.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    setInvoicingAdminStatusData(data);
  };

  return (
    <Box key="BOXBOX" sx={{ marginTop: 3 }}>
      {isLoading && <CircularProgress key="circularProgres" />}{" "}
      {/* Show CircularProgress while loading */}
      {error && (
        <Alert
          key="alertBOXBOX"
          severity="error"
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      {isGedu && invoicingGeduStatusData.length > 0 && (
        <Card key={"invoicingStatus"} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="body1">
              {`${t("invoicingStatus.title")}`}
            </Typography>{" "}
            <List key={"invoicingStatusList"} sx={{ margin: "auto" }}>
              {invoicingGeduStatusData.map((statusItem, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${formatDate(
                      statusItem.startDate,
                      "DD.MM.YYYY"
                    )} - ${formatDate(statusItem.endDate, "DD.MM.YYYY")}`}
                  />
                  <InvoicingStatus status={statusItem.status?.name} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
      {isAdmin && (
        <Card key={"invoicingAdminStatus"} sx={{ marginBottom: 2 }}>
          <CardContent key={"invoicingAdminStatusContent"}>
            <Typography key="adminheader" variant="body1">
              {`${t("invoicingStatus.adminTitle")}`}
            </Typography>{" "}
            <List key={"invoicingAdminStatusList"} sx={{ margin: "auto" }}>
              {invoicingAdminStatusData.map((user, index) => (
                <div key={"divdiv" + index}>
                  {user.periods.map((period, index2) => (
                    <ListItem key={"index0" + index + index2}>
                      <OpenIcon
                        sx={{ mr: "1em" }}
                        onClick={() => {
                          window
                            .open(`geduinvoicing/?id=${user.id}`, "_blank")
                            .focus();
                        }}
                      />

                      <Tooltip
                        title={user.infoText}
                        key={"tooltip" + index + index2}
                        placement="top"
                      >
                        {user.isTruster && (
                          <TrusterIcon
                            sx={{ mr: "1em", background: "#F76C6C" }}
                          />
                        )}
                        {user.isStaff && <StaffIcon sx={{ mr: "1em" }} />}
                        {user.isInvoice && <InvoiceIcon sx={{ mr: "1em" }} />}
                        {user.noBillingMethod && (
                          <NoneIcon sx={{ mr: "1em" }} />
                        )}
                        {user.billingMethodNotSet && (
                          <NotSetIcon sx={{ mr: "1em" }} />
                        )}
                      </Tooltip>

                      <ListItemText
                        key={"index1" + index + index2}
                        primary={`${user.name + " [" + user.discordNick + "]"}`}
                        secondary={`${t(
                          "invoicingStatus.approvedDate"
                        )} ${formatDate(
                          new Date(period.geduApproved?.timestamp)
                        )}`}
                      />

                      <ListItemText
                        key={"index2" + index + index2}
                        primary={`${formatDate(
                          period.startDate,
                          "DD.MM.YYYY"
                        )} - ${formatDate(period.endDate, "DD.MM.YYYY")}`}
                      />

                      <InvoicingStatus
                        key={"status" + index + index2}
                        status={period.status?.name}
                      />
                    </ListItem>
                  ))}
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default InvoicingStatusComponent;
