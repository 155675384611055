import React, { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { createActivity } from "../../Hooks/activities";

import ErrorAlert from "../../errorAlert";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery, useMutation } from "react-query";

import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function AddActivity({
  handleNewActivity,
  DeleteButton,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(createActivity);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    console.log("handleSubmit");
    console.log(selectedFile);


    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('name', document.getElementById('activity-name').value);
      formData.append('description', document.getElementById('activity-description').value);

      mutate(formData, {
        onSuccess: () => {
          handleNewActivity(); // Manually trigger the refetch or update the query data here.
          handleClose();
        },
      });
    }
  };


  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ height: 40, mr: 1 }}
          onClick={handleOpen}
        >
          Add Activity
        </Button>
        {DeleteButton}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {
            <div>
              <TextField
                required
                id="activity-name"
                label={t("admin.activities.addActivity_Name")}
                sx={{ m: 1, width: "100%" }}
              />
              <TextField
                required
                id="activity-description"
                label={t("admin.activities.addActivity_Description")}
                sx={{ m: 1, width: "100%" }}
              />
              <div className="imgUpload">
                <Button
                  label={t("admin.activities.addActivity_Image")}
                >
                  <input
                    type="file"
                    accept="image/*"
                    id="activity-image"
                    name="image"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>

              <Button
                variant="contained"
                color="primary"
                sx={{ left: "35%", marginTop: "1rem" }}
                type="submit"
              >
                Save Activity{" "}
              </Button>
            </div>
          }
        </Box>
      </Modal>
    </div>
  );
}
