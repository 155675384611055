import React, { useEffect, useState } from "react";
import "./clubCalendar.css";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import ClubCalendarTable from "./clubCalendarTable";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import ContainerBox from "../Container";
import { getActiveClubs } from "../Hooks/clubs";
import ErrorAlert from "../errorAlert";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { isAdmin, isGedu } from "../Util/authHelper";

import { useTranslation } from "react-i18next";
import { weeksToDays } from "date-fns";

export default function ClubCalendar(params) {
  const { t } = useTranslation();
  let id = "";
  const week = ["mon", "tue", "wed", "thu", "fri", "sat", "sun", "unscheduled"];
  const [weekdays, setWeekdays] = React.useState([]);
  const [statusList, setStatusList] = React.useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState();
  const [typeList, setTypeList] = React.useState([]);
  const [selectedTypes, setSelectedTypes] = useState();
  const [municipalityList, setMunicipalityList] = React.useState([]);
  const [selectedMunicipalities, setSelectedTMunicipalities] = useState();
  const [geduList, setGeduList] = React.useState([]);
  const [selectedGedus, setSelectedGedus] = useState();
  const [attendanceTypeList, setAttendanceTypeList] = React.useState([]);
  const [selectedAttendanceTypes, setSelectedAttendanceTypes] = useState();

  const { data, error, isLoading } = useQuery(["activeClubs"], getActiveClubs);

  useEffect(() => {
    //console.log("useEffect on clubCalendar");
    if (data) {
      prepareData(true);
    }
    // eslint-disable-next-line
  }, [
    data,
    selectedStatuses,
    selectedTypes,
    selectedMunicipalities,
    selectedGedus,
    selectedAttendanceTypes,
  ]);

  const prepareData = async (collectFilterData = false) => {
    console.log(data);
    // console.log(filters);

    var list = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
      unscheduled: [],
    };

    let statuses = [];
    let statusKeys = [];
    let types = [];
    let typeKeys = [];
    let municipalities = [];
    let municipalityKeys = [];
    let geduList = [];
    let geduKeys = [];
    let attendanceTypes = [];
    let attendanceTypeKeys = [];

    //Do a loop in data and assing to each day
    data.map((club) => {
      //console.log(club.meetings);
      if (club.meetings && club.meetings.length > 0) {
        club.meetings.map((meeting) => {
          const filtered = filterClubs(club);
          if (!filtered && meeting.weekday) {
            list[meeting.weekday].push(club);
          }
          // else {
          //   list.unscheduled.push(club);
          // }

          collectDataForFilters(
            collectFilterData,
            club,
            statusKeys,
            statuses,
            typeKeys,
            types,
            municipalityKeys,
            municipalities,
            geduKeys,
            geduList,
            attendanceTypes,
            attendanceTypeKeys
          );
        });
      } else {
        const filtered = filterClubs(club);
        if (!filtered) list.unscheduled.push(club);
        collectDataForFilters(
          collectFilterData,
          club,
          statusKeys,
          statuses,
          typeKeys,
          types,
          municipalityKeys,
          municipalities,
          geduKeys,
          geduList,
          attendanceTypes,
          attendanceTypeKeys
        );
      }
    });

    if (collectFilterData) {
      if (statuses.length > 0) {
        statuses.sort((a, b) => a.label.localeCompare(b.label));
      }
      if (types.length > 0) {
        types.sort((a, b) => a.label.localeCompare(b.label));
      }
      if (municipalities.length > 0) {
        municipalities.sort((a, b) => a.label.localeCompare(b.label));
      }
      if (geduList.length > 0) {
        geduList.sort((a, b) => a.label.localeCompare(b.label));
      }
      if (attendanceTypes.length > 0) {
        attendanceTypes.sort((a, b) => a.label.localeCompare(b.label));
      }

      setStatusList(statuses);
      setTypeList(types);
      setMunicipalityList(municipalities);
      setGeduList(geduList);
      setAttendanceTypeList(attendanceTypes);
    }

    //console.log(list);
    setWeekdays(list);
  };

  function collectDataForFilters(
    collectFilterData,
    club,
    statusKeys,
    statuses,
    typeKeys,
    types,
    municipalityKeys,
    municipalities,
    geduKeys,
    geduList,
    attendanceTypes,
    attendanceTypeKeys
  ) {
    if (collectFilterData)
      if (club.status && !statusKeys.includes(club.status.name)) {
        statuses.push({
          value: club.status.name,
          label: t("code." + club.status.name),
        });
        statusKeys.push(club.status.name);
      }
    if (club.type && !typeKeys.includes(club.type.name)) {
      types.push({
        value: club.type.name,
        label: t("code." + club.type.name),
      });
      typeKeys.push(club.type.name);
    }
    if (club.municipality && !municipalityKeys.includes(club.municipality)) {
      municipalities.push({
        value: club.municipality,
        label: club.municipality,
      });
      municipalityKeys.push(club.municipality);
    }
    if (club.owner && !geduKeys.includes(club.owner.id)) {
      geduList.push({
        value: club.owner.id,
        label: club.owner.firstName + " " + club.owner.lastName,
      });
      geduKeys.push(club.owner.id);
    }
    if (collectFilterData)
      if (club.attendance && !attendanceTypeKeys.includes(club.attendance.name)) {
        attendanceTypes.push({
          value: club.attendance.name,
          label: t("code." + club.attendance.name),
        });
        attendanceTypeKeys.push(club.attendance.name);
      }
  }

  function filterClubs(club) {
    let filtered = false;
    if (
      selectedStatuses && selectedStatuses.length > 0
        ? !selectedStatuses.some((r) => club.status?.name.includes(r.value))
        : false
    ) {
      filtered = true;
    }
    if (
      selectedTypes && selectedTypes.length > 0
        ? !selectedTypes.some((r) => club.type?.name.includes(r.value))
        : false
    ) {
      filtered = true;
    }
    if (
      selectedMunicipalities && selectedMunicipalities.length > 0
        ? !selectedMunicipalities.some((r) =>
            club.municipality?.includes(r.value)
          )
        : false
    ) {
      filtered = true;
    }

    if (
      selectedGedus && selectedGedus.length > 0
        ? !selectedGedus.some((r) => club.owner?.id.includes(r.value))
        : false
    ) {
      filtered = true;
    }
    if (
      selectedAttendanceTypes && selectedAttendanceTypes.length > 0
        ? !selectedAttendanceTypes.some((r) => club.attendance?.name.includes(r.value))
        : false
    ) {
      filtered = true;
    }

    return filtered;
  }

  function handleSelectStatus(data) {
    setSelectedStatuses(data);
  }

  function handleSelectType(data) {
    setSelectedTypes(data);
  }

  function handleSelectMunicipality(data) {
    setSelectedTMunicipalities(data);
  }

  function handleSelectGedu(data) {
    setSelectedGedus(data);
  }

  function handleSelectAttendanceType(data) {
    setSelectedAttendanceTypes(data);
  }

  return (
    <ContainerBox>
      {/* Accordion */}
      {error && <ErrorAlert txt={error} />}
      {isLoading && <CircularProgress />}
      {data && (
        <div id="FilterDiv" style={{ marginBottom: 10 }}>
          {isAdmin(params.appAuthInfo.appUserRoles) && (
            <div
              style={{
                marginRight: "0.25em",
                marginBottom: "0.25em",
                maxWidth: 300,
                display: "inline-flex",
              }}
            >
              <Select
                name="geduFilter"
                options={geduList}
                className="basic-multi-select"
                placeholder={t("clubCalendar.filter_byGedu")}
                value={selectedGedus}
                onChange={handleSelectGedu}
                isSearchable={true}
                isMulti
              />
            </div>
          )}
          <div
            style={{
              marginRight: "0.25em",
              maxWidth: 300,
              marginBottom: "0.25em",
              display: "inline-flex",
            }}
          >
            <Select
              name="typeFilter"
              options={typeList}
              className="basic-multi-select"
              //classNamePrefix="Filter by Status"
              placeholder={t("clubCalendar.filter_byTypePlaceholder")}
              value={selectedTypes}
              onChange={handleSelectType}
              isSearchable={true}
              isMulti
            />
          </div>
          <div
            style={{
              marginRight: "0.25em",
              marginBottom: "0.25em",
              maxWidth: 300,
              display: "inline-flex",
            }}
          >
            <Select
              name="statusFilter"
              options={statusList}
              className="basic-multi-select"
              placeholder={t("clubCalendar.filter_byStatusPlaceholder")}
              value={selectedStatuses}
              onChange={handleSelectStatus}
              isSearchable={true}
              isMulti
            />
          </div>
          <div
            style={{
              marginRight: "0.25em",
              marginBottom: "0.25em",
              maxWidth: 300,
              display: "inline-flex",
            }}
          >
            <Select
              name="municipalityFilter"
              options={municipalityList}
              className="basic-multi-select"
              placeholder={t("clubCalendar.filter_byMunicipalityPlaceholder")}
              value={selectedMunicipalities}
              onChange={handleSelectMunicipality}
              isSearchable={true}
              isMulti
            />
          </div>
          <div
            style={{
              marginRight: "0.25em",
              marginBottom: "0.25em",
              maxWidth: 300,
              display: "inline-flex",
            }}
          >
            <Select
              name="attendanceTypeFilter"
              options={attendanceTypeList}
              className="basic-multi-select"
              placeholder={t("clubCalendar.filter_byAttendanceTypePlaceholder")}
              value={selectedAttendanceTypes}
              onChange={handleSelectAttendanceType}
              isSearchable={true}
              isMulti
            />
          </div>
        </div>
      )}
      {
        // <div className="search">
        //   <TextField
        //     id="outlined-basic"
        //     variant="outlined"
        //     fullWidth
        //     size="small"
        //     InputProps={{
        //       startAdornment: (
        //         <InputAdornment position="start">
        //           <SearchIcon />
        //         </InputAdornment>
        //       ),
        //     }}
        //     onChange={searchFilter}
        //   />
        // </div>
      }

      {data &&
        week.map((day) => (
          <MuiAccordion key={day}>
            <MuiAccordionSummary
              aria-controls="panel1d-content"
              id={day + "panel1d-header"}
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              {weekdays[day] && (
                <Typography>
                  {t("weekday." + day)} <small>({weekdays[day].length})</small>
                </Typography>
              )}
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              {/* Table of each day */}
              <ClubCalendarTable day={day} clubs={weekdays[day]} />
            </MuiAccordionDetails>
          </MuiAccordion>
        ))}
    </ContainerBox>
  );
}
