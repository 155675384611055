import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import {
  getCodeDomains,
  deleteCodeDomain,
  editCodeDomainField,
} from "../../Hooks/codes";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import AddCodeDomains from "./addCodeDomains";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";

export default function CodeDomainList() {
  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            showCodes(params.row.id);
          }}
        >
          Codes [{params.row.codes.length ? params.row.codes.length : "0"}] ...
        </Button>
      </strong>
    );
  };

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const columns = [
    //{ field: 'id', headerName: 'ID', flex: 1, editable: false },
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "action",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];
  const { data, error, isLoading } = useQuery(["codeDomains"], getCodeDomains);
  const { mutate } = useMutation(deleteCodeDomain);
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  useEffect(() => {
    getCodeDomains();
    if (data) setRows(data);
  }, [data]);

  const processRowUpdate = (e, i) => {
    //console.log(e.field + ": " + e.value);
    try {
      setIsDataLoading(true);

      var bodystr = '{ "' + e.field + '": "' + e.value + '"}';
      var body = JSON.parse(bodystr);

      console.log(body);

      var response = editCodeDomainField(e.id, body);

      console.log(response);
    } catch (editerror) {
      //error = editerror;
      console.log(editerror);
    }
    setIsDataLoading(false);
  };
  const handleNewCodeDomain = (domain) => {
    setRows((rows) => [...rows, domain]);
  };
  const deleteRows = () => {
    selectionModel.forEach((codedomain) => {
      mutate(codedomain, {
        onSuccess: (e) => {
          console.log(e);
        },
      });
    });
    window.location.reload();
  };

  const showCodes = (codeDomainid) => {
    console.log(codeDomainid);
    window.open(`./codes?codedomainid=${codeDomainid}`, "_blank").focus();
  };

  return (
    <ContainerBox>
      <h1>Code domains</h1>
      <div style={{ width: "100%" }}>
        {error && <ErrorAlert txt={error} />}
        {(isLoading || isDataLoading) && <CircularProgress />}
        {
          // rows.length > 0 &&
          <>
            <AddCodeDomains
              handleNewCodeDomain={handleNewCodeDomain}
              DeleteButton={
                <Button
                  variant="contained"
                  onClick={deleteRows}
                  color="secondary"
                  startIcon={<DeleteForeverIcon />}
                  disabled={disableBtn}
                  sx={{ height: 40 }}
                >
                  Delete selected
                </Button>
              }
            />
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                if (newSelectionModel)
                  setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[]}
              rows={rows}
              columns={columns}
              onCellEditCommit={processRowUpdate}
            />
          </>
        }
      </div>
    </ContainerBox>
  );
}
