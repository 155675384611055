import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContainerBox from "../Container";
import ErrorAlert from "../errorAlert";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import { TranslatedDatePicker } from "../Util/translatedDatePicker";
import TrusterReportGedus from "./TrusterReportGedus";

import { isAdmin, isGedu, isParent, isGamer } from "../Util/authHelper";
import { formatDate } from "../Util/clubHelper";

import { getTrusterGeduDataForPeriod } from "../Hooks/report";

import { useTranslation } from "react-i18next";

export default function MuniReportMainView(params) {
  const { t } = useTranslation();

  const [isLoadingInvoicingData, setIsLoadingInvoicingData] = useState(false);

  const [periodType, setPeriodType] = useState("previousMonth");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [yearList, setYearList] = React.useState([]);
  const [selectedYears, setSelectedYears] = useState();

  const [gedus, setGedus] = useState([]);

  const appUserRoles = params?.appAuthInfo?.appUserRoles;

  useEffect(() => {
    // Set the default dates for current month
    const { startDate, endDate } = getPreviousMonth();
    console.log(startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const getCurrentMonth = () => {
    // create a struct with startDate and endDate for current month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const getPreviousMonth = () => {
    // create a struct with startDate and endDate for previous month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const handleButtonClick = (period) => {
    setPeriodType(period);

    if (period === "currentMonth") {
      const { startDate, endDate } = getCurrentMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "previousMonth") {
      const { startDate, endDate } = getPreviousMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "nextMonth") {
      const { startDate, endDate } = getNextMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "custom") {
      // don't change the dates
    }
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const fetchGedus = async () => {
    setIsLoadingInvoicingData(true);
    const body = {
      startDate: formatDate(startDate, "YYYY-MM-DD"),
      endDate: formatDate(endDate, "YYYY-MM-DD"),
    };

    console.log(body);
    const response = await getTrusterGeduDataForPeriod(body);

    console.log(response);

    const gedus = response.data.gedus;

    // sort gedus by lastName, firstName
    gedus.sort((a, b) =>
      a.lastName + a.firstName > b.lastName + b.firstName ? 1 : -1
    );

    setGedus(gedus);

    setIsLoadingInvoicingData(false);
  };

  const handleSubmit = async () => {
    await fetchGedus();
  };

  return (
    <>
      {/* Accordion */}
      {true && ( // TODO: correct check
        // <div id="FilterDiv" style={{ marginBottom: 10 }}>
        <Box>
          <Stack direction="row" spacing={2} sx={{ display: "block", m: 2.5 }}>
            <Button
              variant={
                periodType === "previousMonth" ? "contained" : "outlined"
              }
              onClick={() => handleButtonClick("previousMonth")}
            >
              {t("geduInvoicing.previousMonth")}
            </Button>
            <Button
              variant={periodType === "currentMonth" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("currentMonth")}
            >
              {t("geduInvoicing.currentMonth")}
            </Button>
            {/* <Button
              variant={periodType === "nextMonth" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("nextMonth")}
            >
              {t("geduInvoicing.nextMonth")}
            </Button> */}
          </Stack>
          <Stack direction="row" spacing={2} sx={{ display: "block", m: 1 }}>
            <TranslatedDatePicker
              fullWidth
              label={t("geduInvoicing.startDate")}
              value={startDate}
              onChange={(newValue) => handleStartDateChange(newValue)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TranslatedDatePicker
              fullWidth
              type="date"
              label={t("geduInvoicing.endDate")}
              value={endDate}
              onChange={(newValue) => handleEndDateChange(newValue)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            >
              {t("trusterReport.submit")}
            </Button>
          </Stack>
        </Box>
        // </div>
      )}
      {isLoadingInvoicingData && <CircularProgress sx={{ m: 3 }} />}
      {!isLoadingInvoicingData && gedus && gedus.length > 0 && (
        <TrusterReportGedus
          gedus={gedus}
          refetch={fetchGedus}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
}
