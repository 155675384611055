import axios from "axios";
import { API } from "./API";

export const getGeduInvoicingLines = async (geduId, startDate, endDate) => {
  const body = {};
  if (startDate && endDate) {
    body.startDate = startDate;
    body.endDate = endDate;
  }

  const { data } = await axios.post(
    `${API}/reports/gedu/${geduId}/invoicinglines`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getGeduInvoicingLinesByPeriod = async (periodId) => {
  //console.log("getGeduInvoicingLinesByPeriod" + periodId);

  const { data } = await axios.get(
    `${API}/reports/period/${periodId}/invoicinglines`,
    {
      withCredentials: true,
    }
  );

  //  console.log(data);
  return data.data;
};

export const getGeduInvoicingLineWithoutPeriod = async (geduId) => {
  const { data } = await axios.get(
    `${API}/reports/gedu/${geduId}/invoicinglineswithoutperiod`,
    {
      withCredentials: true,
    }
  );
  return data.data;
};

export const setPeriodStatusFoo = async (body) => {
  const periodId = body.periodId;
  console.log("setPeriodStatus" + periodId);

  const newBody = {
    geduId: body.geduId,
    status: body.status,
    notes: body.notes,
  };

  const { data } = await axios.post(
    `${API}/reports/period/${periodId}/setperiodstatus`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data.data;
};

export const invoicingGedus = async (startDate, endDate) => {
  console.log("Invoicing Gedus");
  const body = {};
  if (startDate && endDate) {
    body.startDate = startDate;
    body.endDate = endDate;
  }

  console.log(body);

  const { data } = await axios.post(`${API}/reports/invoicinggedus`, body, {
    withCredentials: true,
  });

  console.log(data);

  return data.data;
};

export const invoicingStatus = async () => {
  const { data } = await axios.get(`${API}/reports/invoicingstatus`, {
    withCredentials: true,
  });

  console.log(data);

  return data.data;
};

export const getGeduInvoicingPeriods = async (geduId) => {
  const { data } = await axios.get(`${API}/reports/gedu/${geduId}/periods`, {
    withCredentials: true,
  });
  return data.data;
};

export const getGeduInvoicingPeriodsBetweenDates = async (body) => {
  const { data } = await axios.post(`${API}/reports/gedus/invoicing`, body, {
    withCredentials: true,
  });
  return data;
};

export const getMuniClubsBetweenDates = async (body) => {
  const municipalityId = body.municipalityId;

  const newBody = {};
  if (body.startDate && body.endDate) {
    newBody.startDate = body.startDate;
    newBody.endDate = body.endDate;
  }

  const { data } = await axios.post(
    `${API}/reports/muni/${municipalityId}/clubs`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data;
};

// call new API
export const getMuniSummaryClubsBetweenDates = async (body) => {
  const countryId = body.countryId;

  const newBody = {};
  if (body.startDate && body.endDate) {
    newBody.startDate = body.startDate;
    newBody.endDate = body.endDate;
  }

  const { data } = await axios.post(
    `${API}/reports/country/${countryId}/summary`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const setMuniInvoicingStatuses = async (body) => {
  const { data } = await axios.post(
    `${API}/reports/muni/setmuniinvoicingstatuses`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getTrusterGeduDataForPeriod = async (body) => {
  const newBody = {};
  if (body.startDate && body.endDate) {
    newBody.startDate = body.startDate;
    newBody.endDate = body.endDate;
  }

  const { data } = await axios.post(
    `${API}/reports/truster/gedudataforperiod`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getTrusterGeduDataForPeriodJson = async (body) => {
  const newBody = {};
  if (body.startDate && body.endDate) {
    newBody.startDate = body.startDate;
    newBody.endDate = body.endDate;
    newBody.periodIds = body.periodIds;
  }

  const { data } = await axios.post(
    `${API}/reports/truster/jsondataforperiod`,
    newBody,
    {
      withCredentials: true,
    }
  );
  console.log("getTrusterGeduDataForPeriodJson");
  console.log(data);
  return data;
};

export const getTrusterGeduDataForPeriodCsv = async (body) => {
  const newBody = {};
  if (body.startDate && body.endDate) {
    newBody.startDate = body.startDate;
    newBody.endDate = body.endDate;
  }

  const { data } = await axios.post(
    `${API}/reports/truster/csvdataforperiod`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const setPeriodStatusInvoiced = async (body) => {
  const { data } = await axios.post(
    `${API}/reports/setperiodstatusinvoiced`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const setPeriodToLines = async (body) => {
  const { data } = await axios.post(`${API}/reports/setperiodtolines`, body, {
    withCredentials: true,
  });
  return data;
};
