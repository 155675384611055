import { Fragment } from "react";

import { useQuery } from "react-query";

import {
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";

import OpenIcon from "@mui/icons-material/OpenInNew";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";

import ContainerBox from "../Container";

import { getParentDetails } from "../Hooks/users";

import { useTranslation } from "react-i18next";
import ErrorAlert from "../errorAlert";
import { getStatusIcon, getStatusTypography } from "./common";

export default function ParentDetails() {
  const { t } = useTranslation();
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const parentId = urlParams.get("id");

  const { data: parentData, error, isLoading } = useQuery(
    ["parentId", parentId],
    () => getParentDetails(parentId)
  );

  return (
    <ContainerBox>
      <Typography variant="h4">{t("parentDetails.title")}</Typography>
      {error && <ErrorAlert txt={error}/>}
      {isLoading && <CircularProgress />}
      {parentData?.id && (
        <>
          <Card key={parentId} sx={{ marginTop: 2 }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  {parentData.discordAvatar && (
                    <CardMedia
                      component="img"
                      className="user-icon"
                      image={parentData.discordAvatar}
                      alt="Avatar"
                    />
                  )}
                  {!parentData.discordAvatar && <NoAvatarIcon className="user-icon" />}
                  {/* <GeneralTextFieldEditor
                    title={t("parentDetails.setDiscordId")}
                    data={{
                      discordUserId: {
                        value: parentData.discordUserId
                          ? parentData.discordUserId
                          : "",
                        label: t("parentDetails.discordIdLabel"),
                      },
                    }}
                    buttonText={t("parentDetails.discordIdButtonText")}
                    onSave={(data) => {
                      console.log("TODO");
                      // updateUserData(data);
                    }}
                  /> */}
                </Stack>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap={1}
                >
                  <Typography variant="h6">
                    {parentData.lastName + ", " + parentData.firstName}
                  </Typography>
                  <Typography variant="body1">
                    {t("parentDetails.userId")}: {parentData.id}
                  </Typography>
                  {parentData.discordUserName && <Typography variant="body1">
                    {parentData.discordUserName
                      ? `${parentData.discordUserName}`
                      : ``}
                    {parentData.discordUserId
                      ? " [" + parentData.discordUserId + "]"
                      : ``}
                  </Typography>}
                  {/* <Typography variant="body1">
                    {t("parentDetails.birthdate")}: {parentData.birthdate}
                  </Typography> */}
                  <Typography variant="body1">
                    {parentData.email}
                    {parentData.phoneNumber ? ` - ${parentData.phoneNumber}` : ``}
                  </Typography>
                  <Typography variant="body1">
                    {t("parentDetails.roles")}: {parentData.roles}
                  </Typography>
                  <Typography variant="body1">
                    {t("parentDetails.metadata")}: {JSON.stringify(parentData.metadata)}
                  </Typography>
                  {/* Add more fields as needed */}
                  <Typography variant="body1">
                    {t("parentDetails.lastLogin")}: {parentData.lastLogin}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("parentDetails.subscriptionData.title")} ({parentData.subscriptions.length})</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {parentData.subscriptions.map((subscription, index) => <Fragment key={"frag" + subscription.id}>
                  {index !== 0 && <Divider key={"divider" + subscription.id} />}
                  <ListItem key={subscription.id} alignItems="flex-start" sx={{ flexDirection: "column" }}>
                    {/* <ListItemText
                      primary={`${plan.chargebeePlan.name} | ${plan.chargebeePlan.chargebeeId} | ${plan.chargebeeSubscription.status}`}
                      />
                      <ListItemText
                      secondary={`${t("parentDetails.subscriptionData.planId")}: ${plan.id}`}
                      /> */}
                    <ListItemText
                      primary={`${t("parentDetails.subscriptionData.chargebeeSubscriptionId")}: ${subscription.chargebeeId}`}
                    />
                    <Stack direction="row" alignItems="center">
                      <ListItemText primary={t("gamerDetails.subscriptionData.status") + ": "}/>
                      {getStatusIcon(subscription.status)}
                      {getStatusTypography(subscription.status, t)}
                    </Stack>
                    <ListItemText
                      secondary={`${t("parentDetails.subscriptionData.subscriptionId")}: ${subscription.id}`}
                    />
                    {/* <ListItemText 
                      secondary={`${t("parentDetails.subscriptionData.customerName")}: ${subscription.customerLastName + ", " + subscription.customerFirstName}`}
                    />
                    <ListItemText 
                      secondary={`${t("parentDetails.subscriptionData.customerEmail")}: ${subscription.email}`}
                    /> */}
                    <ListItemText 
                      secondary={`${t("parentDetails.subscriptionData.chargebeeCustomerId")}: ${subscription.chargebeeCustomerId}`}
                    />
                    {subscription.nextBillingAt && <ListItemText 
                      secondary={`${t("parentDetails.subscriptionData.nextBillingAt")}: ${subscription.nextBillingAt}`}
                    />}
                    {subscription.chargebeeSubscriptionPlans.length > 0 && <Typography variant="h7">{t("parentDetails.subscriptionData.plans")}:</Typography>}
                    {subscription.chargebeeSubscriptionPlans.map((plan, index) => (
                      <Typography variant="body2" key={plan.id}>
                        {index !== 0 ? " | " : ""}{plan.chargebeePlan.name} [{plan.chargebeePlan.chargebeeId}] | {plan.gamer ? <Link href={`../gamerDetails/?id=${plan.gamerId}`}>{plan.gamer.lastName + ", " + plan.gamer.firstName} [{plan.gamerId}]</Link> : t("parentDetails.subscriptionData.noGamerForPlan")}
                      </Typography>
                    ))}
                    {/* {plan.chargebeePlan.metadata && <ListItemText 
                      secondary={`${t("parentDetails.subscriptionData.planMetadata")}: ${JSON.stringify(plan.chargebeePlan.metadata)}`}
                    />} */}
                  </ListItem>
                </Fragment>)}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>
          {/* <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("parentDetails.participationData.title")} ({parentData.participations.length})</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {parentData.participations.map((participation, index) => <Fragment key={participation.id}>
                  {index !== 0 && <Divider />}
                  <ListItem key={participation.id}>
                    <IconButton
                      sx={{ ml: "-1em" }}
                      onClick={() => {
                        window
                          .open(`../club/?id=${participation.group.clubId}`, "_blank")
                          .focus();
                        }}
                    >
                      <OpenIcon className="action-icon"/>
                    </IconButton>
                    <Stack 
                      direction="column"
                      alignItems="flex-start"
                    >
                      <ListItemText
                        primary={`${participation.group.club.name} | ${participation.group.name} | [${(participation.active ? t("parentDetails.active") : t("parentDetails.inactive") )}]`}
                      />
                      <ListItemText 
                        secondary={`${t("parentDetails.participationData.clubId")}: ${participation.group.clubId}`}
                      />
                    </Stack>
                  </ListItem>
                </Fragment>)}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion> */}
          <MuiAccordion>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("parentDetails.gamerData.title")} ({parentData.gamers.length})</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <List>
                {parentData.gamers.map((gamer, index) => <Fragment key={gamer.id}>
                  {index !== 0 && <Divider />}
                  <ListItem key={gamer.id}>
                    <IconButton
                      sx={{ ml: "-1em" }}
                      onClick={() => {
                        window
                          .open(`../gamerDetails/?id=${gamer.id}`, "_blank")
                          .focus();
                        }}
                    >
                      <OpenIcon className="action-icon"/>
                    </IconButton>
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                    >
                      <ListItemText
                        primary={`${gamer.lastName + ", " + gamer.firstName}`}
                      />
                      <ListItemText 
                        secondary={`${t("parentDetails.gamerData.discordNick")}: ${gamer.discordNick} [${gamer.discordUserId}]`}
                      />
                      <ListItemText 
                        secondary={`${t("parentDetails.gamerData.gamerId")}: ${gamer.id}`}
                      />
                    </Stack>
                  </ListItem>
                </Fragment>)}
              </List>
            </MuiAccordionDetails>
          </MuiAccordion>
        </>
      )}
    </ContainerBox>
  );
}
