import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  getRegistrationMunicipalities,
  getRegistrationSchools,
  getRegistrationClubsForMunicipality,
  getRegistrationClubsForSchool,
} from "../Hooks/municipalityRegistration";

import { useTranslation } from "react-i18next";
import { prepareTime } from "../Util/clubHelper";

const SelectClub = ({
  countryId,
  selectedMunicipalityId,
  selectedSchoolId,
  selectedClubId,
  onMunicipalityChange,
  onSchoolChange,
  onClubChange,
}) => {
  const { t } = useTranslation();

  const [municipalities, setMunicipalities] = useState([]);
  const [schools, setSchools] = useState([]);
  const [clubs, setClubs] = useState([]);

  const [loadingMunicipalities, setLoadingMunicipalities] = useState(true);
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [loadingClubs, setLoadingClubs] = useState(false);

  const fetchMunicipalities = async () => {
    try {
      const response = await getRegistrationMunicipalities(countryId);
      // console.log(response);

      const municipalities = response.municipalities;

      municipalities.sort(function (a, b) {
        return (a.name > b.name) - (a.name < b.name);
      });

      setMunicipalities(municipalities);
      setLoadingMunicipalities(false);
      return municipalities;
    } catch (error) {
      console.log("Error fetching municipalities:", error);
    }
    return [];
  };

  useEffect(() => {
    fetchMunicipalities();
  }, []);

  useEffect(() => {
    const fetchSchools = async () => {
      setLoadingSchools(true);
      try {
        const response = await getRegistrationSchools(selectedMunicipalityId);
        const schools = response.schools;

        schools.sort(function (a, b) {
          return (a.name > b.name) - (a.name < b.name);
        });

        // console.log(response);
        setSchools(schools);
        setLoadingSchools(false);
      } catch (error) {
        console.log("Error fetching schools:", error);
      }
    };

    if (selectedMunicipalityId) {
      fetchSchools();
    } else {
      setSchools([]);
    }
  }, [selectedMunicipalityId]);

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        setLoadingClubs(true);

        const selectedMunicipality = await getSelectedMunicipality(
          selectedMunicipalityId
        );

        if (
          selectedSchoolId &&
          selectedMunicipality &&
          selectedMunicipality.hasSchools
        ) {
          const response = await getRegistrationClubsForSchool(
            selectedSchoolId
          );
          // console.log(response);
          const clubData = await prepareClubData(response.clubs);
          setClubs(clubData);
        } else if (
          selectedMunicipalityId &&
          selectedMunicipality &&
          !selectedMunicipality.hasSchools
        ) {
          const response = await getRegistrationClubsForMunicipality(
            selectedMunicipalityId
          );
          // console.log(response);
          const clubData = await prepareClubData(response.clubs);
          setClubs(clubData);
        } else {
          setClubs([]);
        }
        setLoadingClubs(false);
      } catch (error) {
        console.log("Error fetching clubs:", error);
      }
    };

    fetchClubs();
  }, [selectedMunicipalityId, selectedSchoolId]);

  const prepareClubData = async (clubs) => {
    const clubData = [];
    for (const club of clubs) {
      club.full = false;
      club.color = "black";
      let clubText = club.name;
      if (club.meetings && club.meetings.length > 0) {
        const moreThanOne = club.meetings.length > 1;

        if (!moreThanOne) {
          clubText +=
            " " +
            t("weekdayShort." + club.meetings[0].weekday) +
            " " +
            prepareTime(club.meetings[0].startTime) +
            "-" +
            prepareTime(club.meetings[0].endTime) +
            " ";
        } else {
          for (let i = 0; i < club.meetings.length; i++) {
            const meeting = club.meetings[i];
            clubText +=
              " " +
              t("weekdayShort." + meeting.weekday) +
              " " +
              prepareTime(meeting.startTime) +
              "-" +
              prepareTime(meeting.endTime) +
              " ";

            if (i + i < club.meetings.length) {
              clubText += ", ";
            }
          }
        }
      } else {
        clubText += " ";
      }

      const maxParticipants = club.maxParticipants ? club.maxParticipants : 0;

      clubText += "(" + club.participants + "/" + maxParticipants + ")";

      if (!(club.maxParticipants - club.participants > 0)) {
        clubText += " Täynnä, liity odotuslistalle";
        club.full = true;
        club.color = "red";
      }
      club.text = clubText;
      clubData.push(club);
    }

    clubData.sort(function (a, b) {
      return (a.text > b.text) - (a.text < b.text);
    });

    return clubData;
  };

  const selectMunicipality = async (municipalityId) => {
    const selectedMunicipality = await getSelectedMunicipality(municipalityId);
    onClubChange({}); // reset club selection
    onSchoolChange({}); // reset school selection
    onMunicipalityChange(selectedMunicipality);
  };

  const selectSchool = async (schoolId) => {
    if (schools) {
      const selectedSchool = Object.values(schools).find(
        (school) => school.id === schoolId
      );
      onClubChange({}); // reset club selection
      onSchoolChange(selectedSchool);
    }
  };

  const selectClub = async (clubId) => {
    if (clubs) {
      const selectedClub = Object.values(clubs).find(
        (club) => club.id === clubId
      );
      onClubChange(selectedClub);
    }
  };

  const getSelectedMunicipality = async (municipalityId) => {
    // console.log("getSelectedMunicipality: " + municipalityId);
    // console.log(municipalities);

    if (!municipalityId) return undefined;

    let fetched = municipalities;
    if (municipalities && municipalities.length === 0) {
      fetched = await fetchMunicipalities();
    }

    if (fetched && fetched.length > 0) {
      const selectedMunicipality = Object.values(fetched).find(
        (municipality) => municipality.id === municipalityId
      );
      // console.log(selectedMunicipality);
      // if (selectedMunicipality) console.log(selectedMunicipality?.name);
      return selectedMunicipality;
    }
    return undefined;
  };

  return (
    <div>
      <Box sx={{ margin: "1em 0", width: "100%" }}>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {loadingMunicipalities && <CircularProgress size={20} />}
          {!loadingMunicipalities && (
            <TextField
              sx={{ textAlign: "left", width: "100%" }}
              id="municipality-select-textfield"
              select
              fullWidth
              label={t("muniRegister.clubSelect.municipalitySelect")}
              value={selectedMunicipalityId}
              onChange={(event) => selectMunicipality(event.target.value)}
            >
              {municipalities.map((municipality) => (
                <MenuItem key={municipality.id} value={municipality.id}>
                  {municipality.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </FormControl>
      </Box>

      {schools && schools.length > 0 && (
        <Box sx={{ margin: "1em 0" }}>
          <FormControl
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {loadingSchools && <CircularProgress size={20} />}
            {!loadingSchools && (
              <TextField
                sx={{ textAlign: "left", width: "100%" }}
                id="school-select-textfield"
                select
                label={t("muniRegister.clubSelect.schoolSelect")}
                value={selectedSchoolId}
                onChange={(event) => selectSchool(event.target.value)}
                disabled={loadingSchools || loadingClubs}
              >
                {/* <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClub_TypeFirstEntry")}{" "}
                </MenuItem> */}
                {schools.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </FormControl>
        </Box>
      )}

      {clubs && clubs.length > 0 && (
        <Box sx={{ margin: "1em 0" }}>
          <FormControl
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {loadingClubs && <CircularProgress size={20} />}
            {!loadingClubs && (
              <TextField
                sx={{ textAlign: "left", width: "100%" }}
                id="club-select-textfield"
                select
                label={t("muniRegister.clubSelect.clubSelect")}
                value={selectedClubId}
                onChange={(event) => selectClub(event.target.value)}
                disabled={loadingClubs}
              >
                {/* <MenuItem key={-1} disabled value={-1}>
              {t("admin.clubs.addClub_TypeFirstEntry")}{" "}
            </MenuItem> */}
                {clubs.map((club) => (
                  <MenuItem
                    sx={{ color: club.color }}
                    key={club.id}
                    value={club.id}
                  >
                    {club.text}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </FormControl>
        </Box>
      )}
    </div>
  );
};

export default SelectClub;
