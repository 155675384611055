import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import CustomFieldSelect from "../CustomFields/CustomFieldSelect";
import CustomFieldTextField from "../CustomFields/CustomFieldTextField";

import {
  Box,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";

import { getCodeDomainsByNames } from "../Hooks/codes";

import { useTranslation } from "react-i18next";
import { login } from "../Hooks/auth";

const AdditionalQuestions = ({
  selectedClub,
  answers,
  onAnswerChange,
  onRequiredFieldStatusChanged,
}) => {
  const { t } = useTranslation();
  const [userGenderCodes, setUserGenderCodes] = React.useState([]);
  const [selectedGender, setSelectedGender] = React.useState("");
  const [additionalInformationField, setAdditionalInformationField] =
    React.useState("");
  const [clubCustomFields, setClubCustomFields] = React.useState([]);
  const [clubCustomImpactFields, setClubCustomImpactFields] = React.useState(
    []
  );
  const [fieldAnswers, setFieldAnswers] = React.useState({});

  // The names of the needed CodeDomains
  const codeDomainNames = ["USER_GENDER"];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  useEffect(() => {
    if (dataCodeDomains) {
      createCodeDomains();
    }
    // eslint-disable-next-line
  }, [dataCodeDomains]);

  useEffect(() => {
    if (selectedClub) getCustomFields();
  }, [selectedClub]);

  useEffect(() => {
    console.log("additionalInformation.useEffer.answers");
    console.log(answers);
    if (answers) {
      if (answers["GENDER"]) {
        setSelectedGender(answers["GENDER"]);
      }

      if (answers["ADDITIONAL_INFORMATION"]) {
        setAdditionalInformationField(answers["ADDITIONAL_INFORMATION"]);
      }

      const localAnswers = {};
      for (const key in answers) {
        console.log(key);
        if (key !== "GENDER" && key !== "ADDITIONAL_INFORMATION") {
          // skip gender && additionalInformation
          localAnswers[key] = answers[key];
        }
      }

      console.log(localAnswers);
      setFieldAnswers(localAnswers);
    }
  }, [answers]);

  useEffect(() => {
    console.log("additionalInformation.useEffect.fieldAnswers");
    console.log(fieldAnswers);
    if (fieldAnswers) {
      checkRequiredFieldStatus();
    }
  }, [fieldAnswers]);

  const createCodeDomains = () => {
    // console.log("DOMAINS:");
    // console.log(dataCodeDomains);

    let userGendersRaw = [];

    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name === "USER_GENDER") {
        codeDomain.codes.forEach((code) => {
          userGendersRaw.push(createCode(code));
        });
      }
    });

    setUserGenderCodes(userGendersRaw);
  };

  const getCustomFields = () => {
    if (selectedClub.customFields) {
      console.log(selectedClub.customFields);

      const customFields = [];
      const customImpactFields = [];
      for (const customField of selectedClub.customFields) {
        const isLocalized = customField.isLocalized
          ? customField.isLocalized
          : false;

        const data = {
          id: customField.id,
          name: customField.name,
          type: customField.type.name,
          isImpactField: customField.isImpactField,
          isRequired: customField.isRequired,
          label: isLocalized
            ? t("customField." + customField.label)
            : customField.label,
          description: customField.description
            ? isLocalized
              ? t("customField." + customField.description)
              : customField.description
            : undefined,
          placeholder: customField.placeholder
            ? isLocalized
              ? t("customField." + customField.placeholder)
              : customField.placeholder
            : undefined,
        };

        switch (customField.type.name) {
          case "CUSTOM_FIELD_TYPE_SELECT":
            const values = [];
            for (const singleValue of customField.values) {
              const value = {
                id: singleValue.id,
                text: singleValue.isLocalized
                  ? t("customField.value." + singleValue.text)
                  : singleValue.text,
              };
              values.push(value);
            }
            data.values = values;
            break;
          default:
            break;
        }

        if (customField.isImpactField) {
          customImpactFields.push(data);
        } else {
          customFields.push(data);
        }
      }

      console.log(customFields);
      console.log(customImpactFields);

      setClubCustomFields(customFields);
      setClubCustomImpactFields(customImpactFields);
    }
  };

  const fieldValueChanged = (fieldId, selectedValue) => {
    console.log("fieldValueChanged " + fieldId + " " + selectedValue);

    if (fieldId === "GENDER") {
      setSelectedGender(selectedValue);
    } else if (fieldId === "ADDITIONAL_INFORMATION") {
      setAdditionalInformationField(selectedValue);
    } else {
      const originalsFieldAnswers = { ...fieldAnswers };
      originalsFieldAnswers[fieldId] = selectedValue;

      setFieldAnswers(originalsFieldAnswers);
    }

    const originalValues = { ...answers }; // must copy the object so that the state is updated
    originalValues[fieldId] = selectedValue;
    onAnswerChange(originalValues);

    checkRequiredFieldStatus();
  };

  const checkRequiredFieldStatus = () => {
    // go through all required fields and check if they are filled

    console.log("checkRequiredFieldStatus");
    console.log(fieldAnswers);

    let allRequiredFieldsFilled = true;
    for (const customField of clubCustomFields) {
      if (customField.isRequired) {
        console.log("CustomField.id = " + customField.id);
        console.log(fieldAnswers[customField.id]);
        if (!fieldAnswers[customField.id]) {
          allRequiredFieldsFilled = false;
        } else if (fieldAnswers[customField.id] === "") {
          allRequiredFieldsFilled = false;
        }
      }
    }

    if (selectedGender === "") {
      allRequiredFieldsFilled = false;
    }

    console.log("allRequiredFieldsFilled = " + allRequiredFieldsFilled);

    onRequiredFieldStatusChanged(allRequiredFieldsFilled);
  };

  const renderCustomFields = (isImpact) => {
    const fields = isImpact ? clubCustomImpactFields : clubCustomFields;
    return (
      <div key={"customFields" + isImpact}>
        {fields.length > 0 &&
          fields.map((field, i) => (
            <div key={"customField" + field.id}>{renderCustomField(field)}</div>
          ))}
      </div>
    );
  };

  const renderCustomField = (customField) => {
    switch (customField.type) {
      case "CUSTOM_FIELD_TYPE_SELECT":
        return (
          <Box sx={{ mt: "1em" }}>
            <CustomFieldSelect
              key={customField.id}
              customField={customField}
              selectedValue={fieldAnswers[customField.id]}
              onChange={fieldValueChanged}
            />
          </Box>
        );

      case "CUSTOM_FIELD_TYPE_TEXTFIELD":
        return (
          <Box sx={{ mt: "1em" }}>
            <CustomFieldTextField
              key={customField.id}
              customField={customField}
              selectedValue={fieldAnswers[customField.id]}
              onChange={fieldValueChanged}
              sx={{ mt: "1em" }}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  const createCode = (code) => {
    return {
      value: code.id,
      name: code.name,
      label: t("code." + code.name),
    };
  };

  const selectGender = (codeid) => {
    console.log(codeid);
    fieldValueChanged("GENDER", codeid);
  };

  return (
    <Box>
      <Box>
        <h2>{t("muniRegister.additionalQuestions.title")}</h2>
        <Typography variant="body1" sx={{ color: "black", mb: "2em" }}>
          {t("muniRegister.additionalQuestions.description")}
        </Typography>
        {isLoadingCodeDomains && <CircularProgress size={20} />}
        {!isLoadingCodeDomains && (
          <TextField
            sx={{ textAlign: "left", width: "100%" }}
            id="gender-select-textfield"
            select
            label={t("muniRegister.additionalQuestions.gender")}
            value={selectedGender}
            onChange={(event) => selectGender(event.target.value)}
            fullWidth
          >
            {userGenderCodes.map((code) => (
              <MenuItem key={code.value} value={code.value}>
                {code.label}
              </MenuItem>
            ))}
          </TextField>
        )}

        {clubCustomFields.length > 0 && <Box>{renderCustomFields(false)}</Box>}
      </Box>

      {clubCustomImpactFields.length > 0 && (
        <Box sx={{ mt: "2em" }}>
          <h2>{t("muniRegister.additionalQuestions.impactTitle")}</h2>
          <Typography variant="body1" sx={{ color: "black", mb: "2em" }}>
            {t("muniRegister.additionalQuestions.impactDescription")}
          </Typography>
          <div>{renderCustomFields(true)}</div>
        </Box>
      )}

      <Box sx={{ mt: "2em" }}>
        <h2>{t("muniRegister.additionalQuestions.otherInforTitle")}</h2>

        <TextField
          id="ADDITIONAL_INFORMATION"
          label={t("muniRegister.additionalQuestions.additionalInfo")}
          value={additionalInformationField}
          onChange={(e) =>
            fieldValueChanged("ADDITIONAL_INFORMATION", e.target.value)
          }
          required={false}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default AdditionalQuestions;
