import React, { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import { createUser, updateUser, setPassword } from "../../Hooks/users";
import { getCodeDomainsByNames } from "../../Hooks/codes";

import ErrorAlert from "../../errorAlert";
import MenuItem from "@mui/material/MenuItem";
import { useQuery, useMutation } from "react-query";

import { useTranslation } from "react-i18next";
import { addMonths } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function AddEditUser({
  handleNewUser,
  handleUserUpdate,
  DeleteButton,
  selectedUser,
  users,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate, errorCreate } = useMutation(createUser);
  const { mutate: mutateUpdate, errorUpdate } = useMutation(updateUser);
  const { mutate: mutateSetPw, errorSetPw } = useMutation(setPassword);

  const [emailError, setEmailError] = React.useState(false);
  const [usernameError, setUsernameError] = React.useState(false);
  const [emailHelperText, setEmailHelperText] = React.useState("");
  const [usernameHelperText, setUsernameHelperText] = React.useState("");

  const isAddMode = selectedUser ? false : true;

  const codeDomainNames = ["USER_ROLE"];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );
  const [roleCodes, setRoleCodes] = React.useState([]);

  const [selectedRole, setSelectedRole] = React.useState(-1);

  useEffect(() => {
    if (dataCodeDomains) handleCodeDomains(dataCodeDomains);
  }, [dataCodeDomains]);

  const handleCodeDomains = async (codeDomains) => {
    codeDomains.map((domain) => {
      if (domain.name === "USER_ROLE") {
        const roles = [];
        domain.codes.map((code) => {
          roles.push(code);
        });
        setRoleCodes(roles);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (usernameError || emailError) return;

    let locale = document.getElementById("user-locale").value;
    if (locale) locale = locale.toLowerCase();

    // const userdata = {
    //   firstName: document.getElementById("user-firstname").value,
    //   lastName: document.getElementById("user-lastname").value,
    //   email: document.getElementById("user-email").value,
    //   username: document.getElementById("user-username").value,
    //   password: document.getElementById("user-newpassword").value,
    //   locale: locale,
    //   roles: roles,
    // };

    //console.log(userdata);

    if (isAddMode) {
      const roles = [selectedRole];

      mutate(
        {
          firstName: document.getElementById("user-firstname").value,
          lastName: document.getElementById("user-lastname").value,
          email: document.getElementById("user-email").value
            ? document.getElementById("user-email").value
            : undefined,
          username: document.getElementById("user-username").value,
          password: document.getElementById("user-newpassword").value,
          discordUserId: document.getElementById("user-discordid").value
            ? document.getElementById("user-discordid").value
            : undefined,
          phoneNumber: document.getElementById("user-phonenumber").value
            ? document.getElementById("user-phonenumber").value
            : undefined,
          locale: locale,
          roles: roles,
        },
        {
          onSuccess: (e) => {
            console.log(e);
            handleNewUser(e);
            handleClose();
          },
        }
      );
    } else {
      mutateUpdate(
        {
          id: selectedUser.id,
          body: {
            firstName: document.getElementById("user-firstname").value,
            lastName: document.getElementById("user-lastname").value,
            email: document.getElementById("user-email").value
              ? document.getElementById("user-email").value
              : undefined,
            username: document.getElementById("user-username").value
              ? document.getElementById("user-username").value
              : undefined,
            discordUserId: document.getElementById("user-discordid").value
              ? document.getElementById("user-discordid").value
              : undefined,
            phoneNumber: document.getElementById("user-phonenumber").value
              ? document.getElementById("user-phonenumber").value
              : undefined,
            locale: locale,
            // roles are set separately with role selector
          },
        },
        {
          onSuccess: (e) => {
            console.log(e);

            selectedUser.firstName =
              document.getElementById("user-firstname").value;
            selectedUser.lastName =
              document.getElementById("user-lastname").value;
            selectedUser.email = document.getElementById("user-email").value;
            selectedUser.username =
              document.getElementById("user-username").value;
            selectedUser.locale = locale;

            selectedUser.discordUserId = document.getElementById(
              "user-discordid"
            ).value
              ? document.getElementById("user-discordid").value
              : "";

            selectedUser.phoneNumber = document.getElementById(
              "user-phonenumber"
            ).value
              ? document.getElementById("user-phonenumber").value
              : "";

            const pw = document.getElementById("user-newpassword").value;

            if (pw && pw.length > 0) {
              mutateSetPw(
                {
                  id: selectedUser.id,
                  body: { password: pw },
                },
                {
                  onSuccess: (ep) => {
                    console.log(ep);
                    handleUserUpdate(ep);
                    handleClose();
                  },
                }
              );
            } else {
              handleUserUpdate(e);
              handleClose();
            }
          },
        }
      );
    }
  };

  // https://stackoverflow.com/a/51540480
  const generatePassword = (
    length = 12,
    charset = "023456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz!?@#$%&"
  ) =>
    Array.from(crypto.getRandomValues(new Uint32Array(length)))
      .map((x) => charset[x % charset.length])
      .join("");

  const validateInput = (e) => {
    const input = e.target.value;

    if (e.target.id.includes("email")) {
      if (selectedUser?.email === input) return;

      if (users.some((el) => el.email === input)) {
        setEmailError(true);
        setEmailHelperText(t("admin.users.addUser_EmailAlreadyInUse"));
      } else {
        setEmailError(false);
        setEmailHelperText("");
      }
    }

    if (e.target.id.includes("username")) {
      if (selectedUser?.username === input) return;

      if (users.some((el) => el.username === input)) {
        setUsernameError(true);
        setUsernameHelperText(t("admin.users.addUser_UsernameAlreadyInUse"));
      } else {
        setUsernameError(false);
        setUsernameHelperText("");
      }
    }
  };

  const clearValidateErrors = (e) => {
    if (!e) {
      setEmailError(false);
      setEmailHelperText("");
      setUsernameError(false);
      setUsernameHelperText("");
      return;
    }

    if (e.target.id.includes("email") && emailError) {
      setEmailError(false);
      setEmailHelperText("");
      return;
    }

    if (e.target.id.includes("username") && usernameError) {
      setUsernameError(false);
      setUsernameHelperText("");
      return;
    }
  };

  return (
    <div>
      {errorCreate && <ErrorAlert txt={errorCreate} />}
      {errorUpdate && <ErrorAlert txt={errorUpdate} />}
      {errorSetPw && <ErrorAlert txt={errorSetPw} />}
      {isAddMode && (
        <Box
          m={1} //margin
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Button
            key="AddUserButton"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ height: 40, mr: 1 }}
            onClick={handleOpen}
          >
            {t("admin.users.addUserButton")}
          </Button>
          {DeleteButton}
        </Box>
      )}
      {!isAddMode && (
        <Button
          key="EditUserButton"
          variant="text"
          color="secondary"
          startIcon={<EditIcon />}
          sx={{ height: 25, m: 0 }}
          onClick={handleOpen}
        >
          {
            //t("admin.users.editUserButton")
          }
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {true && (
            <div>
              <TextField
                required
                id="user-firstname"
                label={t("admin.users.addUser_FirstName")}
                sx={{ m: 1, width: "100%" }}
                defaultValue={isAddMode ? "" : selectedUser.firstName}
              />
              <TextField
                required
                id="user-lastname"
                label={t("admin.users.addUser_LastName")}
                sx={{ m: 1, width: "100%" }}
                defaultValue={isAddMode ? "" : selectedUser.lastName}
              />
              <TextField
                required={isAddMode ? true : false}
                id="user-newpassword"
                label={t("admin.users.addUser_Password")}
                sx={{ m: 1, width: "100%" }}
                defaultValue={isAddMode ? generatePassword() : ""}
              />{" "}
              <TextField
                id="user-email"
                label={t("admin.users.addUser_Email")}
                sx={{ m: 1, width: "100%" }}
                onBlur={validateInput}
                onChange={clearValidateErrors}
                error={emailError}
                helperText={emailHelperText}
                defaultValue={isAddMode ? "" : selectedUser.email}
              />
              <TextField
                id="user-username"
                label={t("admin.users.addUser_Username")}
                sx={{ m: 1, width: "100%" }}
                onBlur={validateInput}
                onChange={clearValidateErrors}
                error={usernameError}
                helperText={usernameHelperText}
                defaultValue={isAddMode ? "" : selectedUser.username}
              />
              <TextField
                // required not required
                id="user-discordid"
                label={t("admin.users.addUser_discordId")}
                sx={{ m: 1, width: "100%" }}
                defaultValue={isAddMode ? "" : selectedUser.discordUserId}
              />
              <TextField
                id="user-phonenumber"
                label={t("admin.users.addUser_phonenumber")}
                sx={{ m: 1, width: "100%" }}
                defaultValue={isAddMode ? "" : selectedUser.phoneNumber}
              />
              {isAddMode && (
                <TextField
                  id="user-role"
                  required
                  //onBlur={validateInput}
                  helperText={usernameHelperText}
                  select
                  label={t("admin.users.addUser_Role")}
                  value={selectedRole}
                  sx={{ m: 1, width: "100%" }}
                  onChange={(e) => {
                    setSelectedRole(e.target.value);
                  }}
                  defaultValue={-1}
                >
                  <MenuItem key={-1} disabled value={-1}>
                    {t("admin.users.addUser_RoleFirstEntry")}
                  </MenuItem>
                  {roleCodes &&
                    roleCodes.map((code, i) => (
                      <MenuItem key={i} value={code.name}>
                        {t("code." + code.name)}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              <TextField
                required
                id="user-locale"
                label={t("admin.users.addUser_Locale")}
                sx={{ m: 1, width: "100%" }}
                defaultValue={isAddMode ? "" : selectedUser.locale}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ left: "50%", marginTop: "1rem" }}
                type="submit"
              >
                {t("admin.users.addUser_SubmitButtonText")}
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
