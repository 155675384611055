import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Container,
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Link,
  Select,
  IconButton,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";
import OpenIcon from "@mui/icons-material/OpenInNew";

import { gamerSearchDb } from "../Hooks/search"; // Adjust the path accordingly
import "../App.css";
import { useTranslation } from "react-i18next";

import { getStatusSpan } from "./common";

const GamerSearch = () => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [customerType, setCustomerType] = useState("all");
  const [activeSearchQuery, setActiveSearchQuery] = useState(undefined);

  const processData = (data) => {
    const gamers = data.map((gamer) => ({
      id: gamer.id,
      name: gamer.lastName + ", " + gamer.firstName,
      email: gamer.email,
      phoneNumber: gamer.phoneNumber,
      discordUserId: gamer.discordUserId,
      discordUserName: gamer.discordUserName,
      avatar: gamer.discordAvatar ? gamer.discordAvatar : "",
      subscriptionPlans: gamer.subscriptionPlans.map((plan) => ({
        id: plan.id,
        name: plan.chargebeePlan.name,
        chargebeeId: plan.chargebeeSubscription.chargebeeId,
        status: plan.chargebeeSubscription.status,
      })),
      clubs: gamer.clubs,
      parents: gamer.parents,
      isMuniGamer: gamer.metadata?.muniGamer ?? false,
    }));
    // sort by name
    gamers.sort((a, b) => a.name.localeCompare(b.name));
    return gamers;
  };

  const { data: gamerData, isLoading, error } = useQuery(
    ["gamerSearch", activeSearchQuery?.query, activeSearchQuery?.gamerType],
    () => gamerSearchDb(activeSearchQuery).then(processData),
    { enabled: activeSearchQuery !== undefined }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setActiveSearchQuery({ query: searchQuery, gamerType: customerType });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {t("gamerSearch.title")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="baseline"
          gap={1}
        >
          <Stack direction="column" alignItems="flex-start" gap={0}>
            <TextField
              label={t("gamerSearch.searchLabel")}
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginBottom: 2 }}
              helperText={t("gamerSearch.searchHelpText")}
            />
          </Stack>
          <FormControl sx={{ m: 1 }}>
            <Select
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}
            >
              <MenuItem value={"all"}>{t("gamerSearch.gamerTypeAll")}</MenuItem>
              <MenuItem value={"muni"}>{t("gamerSearch.gamerTypeMuni")}</MenuItem>
              <MenuItem value={"consumer"}>{t("gamerSearch.gamerTypeConsumer")}</MenuItem>
            </Select>
            <FormHelperText id="gamer-type-label">{t("gamerSearch.gamerTypeLabel")}</FormHelperText>
          </FormControl>
          <Button variant="contained" type="submit">
            {t("gamerSearch.searchButtonText")}
          </Button>
        </Stack>
      </form>

      {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
      {error && <Typography color="error">{error.message}</Typography>}

      {gamerData && (
        <div>
          {gamerData.map((result, index) => (
            <Card key={result.id} sx={{ marginTop: 2 }}>
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                  >
                    {result.avatar && (
                      <CardMedia
                        component="img"
                        className="user-icon"
                        image={result.avatar}
                        alt="Avatar"
                      />
                    )}
                    {!result.avatar && <NoAvatarIcon className="user-icon" />}

                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      textAlign="left"
                      // gap={0}
                    >
                      <Typography variant="h5">
                        {result.name}
                      </Typography>
                      <Typography variant="body1">
                        {result.isMuniGamer ? t("gamerSearch.muniGamer") : t("gamerSearch.consumerGamer")}
                      </Typography>
                      <Typography variant="body1">
                        {result.discordUserName
                          ? `${result.discordUserName}`
                          : ``}
                        {result.discordUserId
                          ? " [" + result.discordUserId + "]"
                          : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.email}
                        {result.phoneNumber ? ` - ${result.phoneNumber}` : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.id ? `${t("gamerSearch.id")}: ${result.id}` : ``}
                      </Typography>
                      {result.subscriptionPlans.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.subscriptionPlans")}:</Typography>
                      }
                      {result.subscriptionPlans.map((plan) => 
                        <Typography key={plan.id}>({getStatusSpan(plan.status, t)}) {plan.name} [{plan.chargebeeId}]</Typography>
                      )}
                      {result.clubs.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.clubs")}:</Typography>
                      }
                      {result.clubs.map((club) => 
                        <Link href={"./club/?id=" + club.clubId} key={club.participationId}>{club.clubName} ({club.groupName}) [{club.clubId}]</Link>
                      )}
                      {result.parents.length > 0 && 
                        <Typography variant="h6">{t("gamerSearch.parents")}:</Typography>
                      }
                      {result.parents.map((parent) => 
                        <Typography key={parent.id}><Link href={"./parent-details/?id=" + parent.id}>{parent.lastName}, {parent.firstName}</Link> ({parent.email}, {parent.phoneNumber})</Typography>
                      )}
                      {/* Add more fields as needed */}
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      window
                        .open(`./gamerDetails/?id=${result.id}`, "_blank")
                        .focus();
                        }}
                  >
                    <OpenIcon className="action-icon"/>
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </Container>
  );
};

export default GamerSearch;
