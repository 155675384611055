import React from "react";
import { TextField, MenuItem } from "@mui/material";

const CustomFieldTextField = ({ customField, selectedValue, onChange }) => {
  const { id, label, isRequired } = customField;

  return (
    <TextField
      id={id}
      label={label}
      value={selectedValue ? selectedValue : ""}
      onChange={(e) => onChange(id, e.target.value)}
      required={isRequired} // Set the 'required' attribute based on the 'isRequired' flag
      fullWidth
    ></TextField>
  );
};

export default CustomFieldTextField;
