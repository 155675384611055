import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { login } from "../Hooks/auth";
import { useMutation } from "react-query";
import ErrorAlert from "../errorAlert";
import { CircularProgress } from "@mui/material";

const theme = createTheme();

export default function Login() {
  const { mutate, error, isLoading } = useMutation(login);
  const [errorText, setErrorText] = React.useState(
    "We have problems with the server, please try again later"
  );
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate(
      {
        login: document.getElementById("login").value,
        password: document.getElementById("password").value,
      },
      {
        onSuccess: () => {
          window.location.href = "./";
        },
        onError: (e) => {
          try {
            setErrorText(error.response.data.message);
          } catch (error) {}
        },
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading && <CircularProgress />}
          {error && <ErrorAlert txt={errorText} />}
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Email or username"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
