import * as React from "react";
import axios from "axios";
import { API } from "../Hooks/API";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  React.useEffect(() => {
    logoutAndClearSession();
  });

  const logoutAndClearSession = async () => {
    try {
      await axios.delete(`${API}/auth/logout`, { withCredentials: true });
    } catch (error) {
      document.cookie =
        "sogga_key=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    localStorage.clear();
    await timeout(1000); // UX delay
    navigate("../login", { replace: true });
  };

  return (
    <>
      <h2>Logging out...</h2>
    </>
  );
}
