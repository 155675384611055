import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import {
  getClubInstructions,
  deleteClubInstructions,
  editClubInstructions,
} from "../../Hooks/clubs";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import AddClubInstruction from "./addClubInstruction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, MenuItem, TextField, Select } from "@mui/material";
import { getCodeDomainsByNames } from "../../Hooks/codes";

import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function ClubInstructionList() {
  const { t } = useTranslation();

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isUpdating, setIsUpdating] = React.useState("");
  // const {
  //   data: dataCodes,
  //   error: errorCodes,
  //   isLoading: isLoadingCodes,
  // } = useQuery(["codes"], getCodes);

  // The names of the needed CodeDomains
  const codeDomainNames = ["CLUB_INSTRUCTIONS_TYPE"];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  const [clubInstructionTypeCodes, setClubInstructionTypes] = useState([]);
  const columns = [
    //{ field: 'id', headerName: 'ID', flex:1,   },
    {
      field: "name",
      headerName: t("admin.clubInstructions.tableHeader_Name"),
      flex: 1,
      editable: true,
    },
    {
      field: "type",
      headerName: t("admin.clubInstructions.tableHeader_type"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        let extra = params.row.type ? params.row.type.id : "";
        return (
          <Select
            id="select-status"
            size="small"
            sx={{ m: 1, width: "100%" }}
            defaultValue={extra}
            onChange={(e) => editType(params.row.id, e.target.value)}
          >
            {clubInstructionTypeCodes.length > 0 &&
              clubInstructionTypeCodes.map((code, i) => (
                <MenuItem key={i} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    {
      field: "introduction",
      headerName: t("admin.clubInstructions.tableHeader_introduction"),
      flex: 1,
      editable: true,
    },
    {
      field: "instructions",
      headerName: t("admin.clubInstructions.tableHeader_instructions"),
      flex: 1,
      editable: true,
    },
  ];
  const { data, error, isLoading, refetch } = useQuery(
    ["clubInstructions"],
    getClubInstructions
  );
  const { mutate } = useMutation(deleteClubInstructions);
  const { mutate: editClubInstructionsData } =
    useMutation(editClubInstructions);

  useEffect(() => {
    if (data) {
      let newData = [];
      data.forEach((e) => {
        newData.push({
          id: e.id,
          name: e.name,
          owner: e.owner,
          introduction: e.introduction,
          instructions: e.instructions,
          type: e.type,
        });
      });
      console.log(newData);
      setRows(newData);
    }

    if (dataCodeDomains) createCodeDomains();
  }, [data, dataCodeDomains]);

  const editType = (id, value) => {
    editClubInstructionsData(
      { id: id, field: "type", value: value },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };

  const createCodeDomains = () => {
    //console.log(dataCodeDomains);

    let clubInstructionTypeCodes = [];

    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name == "CLUB_INSTRUCTIONS_TYPE") {
        codeDomain.codes.forEach((code) => {
          clubInstructionTypeCodes.push({
            value: code.id,
            label: t("code." + code.name),
          });
        });
      }
    });

    setClubInstructionTypes(clubInstructionTypeCodes);
  };

  const processRowUpdate = (e, i) => {
    console.log("processRowUpdate");
    console.log(e);
    editClubInstructionsData(e, {
      onSuccess: (e) => {
        console.log(e);
      },
    });
  };

  const doRefresh = () => {
    console.log("doRefresh");
    refetch();
  };

  const deleteRows = () => {
    selectionModel.forEach((club) => {
      mutate(club, {
        onSuccess: (e) => {
          console.log(e);
          doRefresh();
        },
      });
    });
  };

  return (
    <ContainerBox>
      <div style={{ width: "100%" }}>
        {(error || errorCodeDomains) && (
          <ErrorAlert txt={error || errorCodeDomains} />
        )}
        {(isLoading || isLoadingCodeDomains) && <CircularProgress />}
        {
          <AddClubInstruction
            handleNewClubInstructions={doRefresh}
            clubInstructionTypeCodes={clubInstructionTypeCodes}
            DeleteButton={
              <Button
                variant="contained"
                onClick={deleteRows}
                color="secondary"
                startIcon={<DeleteForeverIcon />}
                disabled={disableBtn}
                sx={{ height: 40 }}
              >
                Delete Rows
              </Button>
            }
          />
        }
        {rows.length > 0 && (
          <>
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              onCellEditCommit={processRowUpdate}
            />
          </>
        )}
      </div>
    </ContainerBox>
  );
}
