import axios from "axios";
import { API } from "./API";

export const geduSearchDb = async (query) => {
  const { data } = await axios.get(`${API}/search/gedu`, {
    params: { query }, // Add query as a parameter
    withCredentials: true,
  });

  return data.data;
};

export const gamerSearchDb = async (query) => {
  const { data } = await axios.get(`${API}/search/gamer`, {
    params: { query }, // Add query as a parameter
    withCredentials: true,
  });

  return data.data;
};

export const parentSearchDb = async (query) => {
  const { data } = await axios.get(`${API}/search/parent`, {
    params: { query }, // Add query as a parameter
    withCredentials: true,
  });

  return data.data;
};

export const userSearchDb = async (query) => {
  const { data } = await axios.get(`${API}/search/user`, {
    params: { query }, // Add query as a parameter
    withCredentials: true,
  });

  return data.data;
};

export const geduSubstituteSearch = async (body) => {
  const { data } = await axios.post(`${API}/search/gedusubstitute`, body, {
    withCredentials: true,
  });

  return data.data;
};

export const clubSearchDb = async (query) => {
  const { data } = await axios.get(`${API}/search/club`, {
    params: query,
    withCredentials: true,
  });

  return data.data;
};
