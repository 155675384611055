import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenIcon from "@mui/icons-material/OpenInNew";

import { formatDate } from "../Util/clubHelper";
import InvoicingStatus from "../Util/InvoicingStatus";

import {
  getTrusterGeduDataForPeriodJson,
  getTrusterGeduDataForPeriodCsv,
  setPeriodStatusInvoiced,
} from "../Hooks/report";

import { useTranslation } from "react-i18next";
import { use } from "i18next";
import { isAdmin } from "../Util/authHelper";

const TrusterReportGedus = (params) => {
  const { t } = useTranslation();

  const [gedus, setGedus] = useState([]);
  // invoicing periods by status
  const [approved, setApproved] = useState([]);
  const [invoiced, setInvoiced] = useState([]);
  const [other, setOther] = useState([]);
  const [isLoadingInvoicingData, setIsLoadingInvoicingData] = useState(false);
  const [containsApprovedPeriods, setContainsApprovedPeriods] = useState(false);
  const [totalApprovedAllGedus, setTotalApprovedAllGedus] = useState({});
  const [totalApprovedPerTypeAllGedus, setTotalApprovedPerTypeAllGedus] =
    useState({});

  useEffect(() => {
    console.log("useEffect on geduInvoicingMainView.gedus");

    if (params.gedus) prepareGedus(params.gedus);
  }, [params.gedus]);

  const prepareGedus = (gedus) => {
    // loop through gedus and collect periods by status for each gedu separately

    console.log("prepareGedus", gedus);

    let localcontainsApprovedPeriods = false;

    const totalApproved = {};
    const totalApprovedPerType = {}; // total approved per type for all gedus

    for (const gedu of gedus) {
      const approvedPeriods = [];
      const invoicedPeriods = [];
      const otherPeriods = [];

      const totalApprovedGedu = {};
      const totalApprovedGeduPerType = {};
      for (const period of gedu.periods) {
        // console.log("PERIOD STATUS  ", period.status?.name);
        // console.log("PERIOD STATUS CHANGED DATE: ", period[period.status.name]);

        // set status change info
        if (period.status?.name && period[period.status?.name] !== undefined) {
          period.statusChangeData = period[period.status.name];
        }

        if (period.status?.name === "INVOICING_STATUS_APPROVED") {
          approvedPeriods.push(period);
          localcontainsApprovedPeriods = true;

          // loop through approved periods and collect totals
          for (const [type, amount] of Object.entries(period.invoicingByType)) {
            // get amount as float
            const amountFloat = parseFloat(amount);
            const invoicingType = type.split(".")[0];
            const currency = type.split(".")[1];
            // handle total
            if (totalApprovedGedu[currency]) {
              // get previous value as float
              const previousAmountFloat = parseFloat(
                totalApprovedGedu[currency]
              );
              // add new amount to previous value
              totalApprovedGedu[currency] = previousAmountFloat + amountFloat;
            } else {
              totalApprovedGedu[currency] = amountFloat;
            }

            // handle total per type
            if (totalApprovedGeduPerType[invoicingType]) {
              // get previous value per currency
              const previousAmountPerCurrency =
                totalApprovedGeduPerType[invoicingType];

              if (previousAmountPerCurrency[currency]) {
                // get previous value as float
                const previousAmountFloat = parseFloat(
                  previousAmountPerCurrency[currency]
                );
                // add new amount to previous value
                previousAmountPerCurrency[currency] =
                  previousAmountFloat + amountFloat;
              } else {
                previousAmountPerCurrency[currency] = amountFloat;
              }
            } else {
              totalApprovedGeduPerType[invoicingType] = {};
              totalApprovedGeduPerType[invoicingType][currency] = amountFloat;
            }

            // add to total approved for all gedus
            if (totalApproved[currency]) {
              // get previous value as float
              const previousAmountFloat = parseFloat(totalApproved[currency]);
              // add new amount to previous value
              totalApproved[currency] = previousAmountFloat + amountFloat;
            } else {
              totalApproved[currency] = amountFloat;
            }

            // add to total approved per type for all gedus
            if (totalApprovedPerType[invoicingType]) {
              // get previous value per currency
              const previousAmountPerCurrency =
                totalApprovedPerType[invoicingType];

              if (previousAmountPerCurrency[currency]) {
                // get previous value as float
                const previousAmountFloat = parseFloat(
                  previousAmountPerCurrency[currency]
                );
                // add new amount to previous value
                previousAmountPerCurrency[currency] =
                  previousAmountFloat + amountFloat;
              } else {
                previousAmountPerCurrency[currency] = amountFloat;
              }
            } else {
              totalApprovedPerType[invoicingType] = {};
              totalApprovedPerType[invoicingType][currency] = amountFloat;
            }
          }
        } else if (period.status?.name === "INVOICING_STATUS_INVOICED") {
          invoicedPeriods.push(period);
        } else {
          otherPeriods.push(period);
        }
      }
      gedu.approvedPeriods = approvedPeriods;
      gedu.invoicedPeriods = invoicedPeriods;
      gedu.otherPeriods = otherPeriods;
      gedu.totalApproved = totalApprovedGedu;
      gedu.totalApprovedPerType = totalApprovedGeduPerType;
    }

    setGedus(gedus);

    setContainsApprovedPeriods(localcontainsApprovedPeriods);
    setTotalApprovedAllGedus(totalApproved);
    setTotalApprovedPerTypeAllGedus(totalApprovedPerType);
  };

  const fetchJson = async () => {
    setIsLoadingInvoicingData(true);

    const periodIds = [];
    for (const gedu of gedus) {
      for (const period of gedu.approvedPeriods) {
        periodIds.push(period.id);
      }
    }

    const body = {
      startDate: formatDate(params.startDate, "YYYY-MM-DD"),
      endDate: formatDate(params.endDate, "YYYY-MM-DD"),
      periodIds: periodIds,
    };
    const response = await getTrusterGeduDataForPeriodJson(body);

    console.log(response);

    setIsLoadingInvoicingData(false);

    return response;
  };

  const fetchCSV = async () => {
    setIsLoadingInvoicingData(true);
    const body = {
      startDate: formatDate(params.startDate, "YYYY-MM-DD"),
      endDate: formatDate(params.endDate, "YYYY-MM-DD"),
    };
    const response = await getTrusterGeduDataForPeriodCsv(body);

    console.log(response);
    setIsLoadingInvoicingData(false);

    return response.data.csv;
  };

  const handleSubmitJSON = async () => {
    try {
      const json = await fetchJson();

      const data = JSON.stringify(json, null, 2);
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/json" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading JSON:", error);
      setIsLoadingInvoicingData(false);
    }
  };

  const handleSubmitCSV = async () => {
    try {
      const csv = await fetchCSV();

      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      setIsLoadingInvoicingData(false);
    }
  };

  const markApprovedPeriodInvoiced = async () => {
    try {
      setIsLoadingInvoicingData(true);
      // loop through gedus and collect each period id from approvedPeriods
      const periodIds = [];
      for (const gedu of gedus) {
        for (const period of gedu.approvedPeriods) {
          periodIds.push(period.id);
        }
      }

      const body = {
        periodIds: periodIds,
      };
      const response = await setPeriodStatusInvoiced(body);

      console.log(response);

      params.refetch();

      setIsLoadingInvoicingData(false);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      setIsLoadingInvoicingData(false);
    }
  };

  return (
    <Box key={"RootBox"}>
      {gedus.length === 0 && <CircularProgress />}
      {gedus.length > 0 && (
        <Box key={"MainBoxBox"}>
          <MuiAccordion key={"accordion-approved"}>
            <MuiAccordionSummary
              aria-controls="panel1d-content"
              id={"panel1d-header"}
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("code.INVOICING_STATUS_APPROVED")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              {gedus.map(
                (gedu, index) =>
                  gedu.approvedPeriods.length > 0 && (
                    <Box
                      key={gedu.id + "_APPROVED"}
                      sx={{ textAlign: "left", ml: 1.5, mt: 1.5 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <OpenIcon
                          className="action-icon-no-margin"
                          onClick={() => {
                            window
                              .open(`./geduDetails/?id=${gedu.id}`, "_blank")
                              .focus();
                          }}
                          // sx={{ ml: 2 }}
                        />
                        <Stack direction="column" spacing={0}>
                          <Typography variant="body1">{`${gedu.firstName} ${gedu.lastName}`}</Typography>
                          <Typography variant="body2">{`${t(
                            "trusterReport.invocingEmail"
                          )}: ${
                            gedu.invoicingEmail ? gedu.invoicingEmail : ""
                          }`}</Typography>
                        </Stack>
                      </Stack>

                      <Divider />
                      <List
                        key={"invoicingStatusList_APPROVED_" + gedu.id}
                        sx={{ margin: "auto" }}
                      >
                        <ListItem key={"APPROVED_LIST_ITME" + gedu.id}>
                          {gedu.approvedPeriods.map((period, periodIndex) => (
                            <Box
                              key={"APPROVED_BOX_ITME" + gedu.id + periodIndex}
                            >
                              <Stack direction="row" spacing={2}>
                                <Stack direction="column" spacing={0}>
                                  <Typography>
                                    {t("trusterReport.periodTitle")}:{" "}
                                    {formatDate(
                                      new Date(period.startDate),
                                      "DD.MM.YYYY"
                                    )}
                                    {" - "}
                                    {formatDate(
                                      new Date(period.endDate),
                                      "DD.MM.YYYY"
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontSize: "0.8em" }}>
                                    [{period.id}]
                                  </Typography>
                                </Stack>
                                <InvoicingStatus
                                  key={
                                    "status_APPROVED_" + gedu.id + periodIndex
                                  }
                                  status={period.status?.name}
                                  statusChangeData={period.statusChangeData}
                                />
                              </Stack>
                              <List>
                                {Object.entries(period.invoicingByType).map(
                                  ([type, amount]) => (
                                    <ListItem
                                      key={"APPROVED_" + type + periodIndex}
                                    >
                                      <ListItemText
                                        primary={`${t(
                                          "code." + type.split(".")[0]
                                        )}: ${amount} ${t(
                                          "code." + type.split(".")[1]
                                        )}`}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                            </Box>
                          ))}
                        </ListItem>
                      </List>
                      <List>
                        {Object.entries(gedu.totalApproved).map(
                          ([currency, amount], currencyIndex) => (
                            <ListItem
                              key={
                                "APPROVED_TOTALS_" + currency + currencyIndex
                              }
                            >
                              <ListItemText
                                primary={`${t("trusterReport.geduTotal")} (${
                                  gedu.firstName
                                } ${gedu.lastName}): ${amount} ${t(
                                  "code." + currency
                                )}`}
                                secondary={
                                  <span
                                    key={"SPAN_" + currency + currencyIndex}
                                  >
                                    {/* Loop over totalApprovedPerType */}
                                    {Object.entries(
                                      gedu.totalApprovedPerType
                                    ).map(
                                      ([type, amountPerCurrency], typeIndex) =>
                                        // Check if the current currency has an entry in totalApprovedPerType
                                        amountPerCurrency[currency] !==
                                          undefined && (
                                          <span
                                            key={
                                              "SPAN2_" +
                                              currency +
                                              currencyIndex +
                                              typeIndex
                                            }
                                          >
                                            {t("trusterReport.geduTotal")} -{" "}
                                            {t("code." + type)}:{" "}
                                            {amountPerCurrency[currency]}
                                            {t("code." + currency)}
                                          </span>
                                        )
                                    )}
                                  </span>
                                }
                              />
                            </ListItem>
                          )
                        )}
                      </List>
                      <Divider />
                    </Box>
                  )
              )}

              <Stack
                direction="row"
                spacing={2}
                sx={{ display: "block", m: 1 }}
                key={"stack-approved-buttons"}
              >
                <List>
                  {Object.entries(totalApprovedAllGedus).map(
                    ([currency, amount], typeIndex) => (
                      <ListItem
                        key={"APPROVED_ALLGEDUS_TOTALS" + currency + typeIndex}
                      >
                        <ListItemText
                          primary={`${t(
                            "trusterReport.geduTotalAllGedus"
                          )}: ${amount} ${t("code." + currency)}`}
                          secondary={
                            <span key={"SPAN_" + currency + typeIndex}>
                              {/* Loop over totalApprovedPerType */}
                              {Object.entries(totalApprovedPerTypeAllGedus).map(
                                ([type, amountPerCurrency], typeIndex) =>
                                  // Check if the current currency has an entry in totalApprovedPerType
                                  amountPerCurrency[currency] !== undefined ? (
                                    <span
                                      key={
                                        "SPAN2_" + currency + typeIndex + type
                                      }
                                    >
                                      {t("trusterReport.geduTotalAllGedus")} -{" "}
                                      {t("code." + type)}:{" "}
                                      {amountPerCurrency[currency]}
                                      {t("code." + currency)}
                                    </span>
                                  ) : (
                                    ""
                                  )
                              )}
                            </span>
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>

                <Button
                  sx={{ m: 2, position: "relative" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitJSON}
                  key="DownloadJSON"
                  disabled={!containsApprovedPeriods}
                >
                  {isLoadingInvoicingData && (
                    <CircularProgress
                      style={{
                        color: "white",
                        position: "absolute", // Set position to absolute
                        top: "50%", // Position at the center vertically
                        left: "50%", // Position at the center horizontally
                        marginTop: "-10px", // Adjust based on half of the CircularProgress size
                        marginLeft: "-10px",
                      }}
                      size={20}
                      thickness={3}
                      mr={20}
                    />
                  )}
                  {t("trusterReport.submitJSON")}
                </Button>

                <Button
                  sx={{ m: 2, position: "relative" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitCSV}
                  key="DownloadCSV"
                  disabled={!containsApprovedPeriods}
                >
                  {isLoadingInvoicingData && (
                    <CircularProgress
                      style={{
                        color: "white",
                        position: "absolute", // Set position to absolute
                        top: "50%", // Position at the center vertically
                        left: "50%", // Position at the center horizontally
                        marginTop: "-10px", // Adjust based on half of the CircularProgress size
                        marginLeft: "-10px",
                      }}
                      size={20}
                      thickness={3}
                      mr={20}
                    />
                  )}
                  {t("trusterReport.submitCSV")}
                </Button>
                <Button
                  sx={{ m: 2, position: "relative" }}
                  variant="contained"
                  color="primary"
                  onClick={markApprovedPeriodInvoiced}
                  key="markApprovedPeriodInvoiced"
                  disabled={!containsApprovedPeriods}
                >
                  {isLoadingInvoicingData && (
                    <CircularProgress
                      style={{
                        color: "white",
                        position: "absolute", // Set position to absolute
                        top: "50%", // Position at the center vertically
                        left: "50%", // Position at the center horizontally
                        marginTop: "-10px", // Adjust based on half of the CircularProgress size
                        marginLeft: "-10px",
                      }}
                      size={20}
                      thickness={3}
                      mr={20}
                    />
                  )}
                  {t("trusterReport.submitMarkApprovedPeriodInvoiced")}
                </Button>
              </Stack>
            </MuiAccordionDetails>
          </MuiAccordion>
          <MuiAccordion key={"accordion-invoiced"}>
            <MuiAccordionSummary
              aria-controls={"panel2d-content"}
              id={"panel2d-header"}
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("code.INVOICING_STATUS_INVOICED")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              {gedus.map(
                (gedu, index) =>
                  gedu.invoicedPeriods.length > 0 && (
                    <Box
                      key={gedu.id + "_INVOICED" + index}
                      sx={{ textAlign: "left", ml: 1.5 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <OpenIcon
                          className="action-icon-no-margin"
                          onClick={() => {
                            window
                              .open(`./geduDetails/?id=${gedu.id}`, "_blank")
                              .focus();
                          }}
                          // sx={{ ml: 2 }}
                        />
                        <Stack direction="column" spacing={0}>
                          <Typography variant="body1">{`${gedu.firstName} ${gedu.lastName}`}</Typography>
                          <Typography variant="body2">{`${t(
                            "trusterReport.invocingEmail"
                          )}: ${
                            gedu.invoicingEmail ? gedu.invoicingEmail : ""
                          }`}</Typography>
                        </Stack>
                      </Stack>

                      <Divider />
                      <List
                        key={"invoicingStatusList_INVOICED_" + gedu.id}
                        sx={{ margin: "auto" }}
                      >
                        <ListItem key={"INVOICED_LIST_ITME" + gedu.id + index}>
                          {gedu.invoicedPeriods.map((period, periodIndex) => (
                            <Box
                              key={
                                "INVOICED_BOX" + gedu.id + index + periodIndex
                              }
                            >
                              <Stack direction="row" spacing={2}>
                                <Stack direction="column" spacing={0}>
                                  <Typography>
                                    {t("trusterReport.periodTitle")}:{" "}
                                    {formatDate(
                                      new Date(period.startDate),
                                      "DD.MM.YYYY"
                                    )}
                                    {" - "}
                                    {formatDate(
                                      new Date(period.endDate),
                                      "DD.MM.YYYY"
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontSize: "0.8em" }}>
                                    [{period.id}]
                                  </Typography>
                                </Stack>
                                <InvoicingStatus
                                  key={
                                    "status_INVOICED" + gedu.id + periodIndex
                                  }
                                  status={period.status?.name}
                                  statusChangeData={period.statusChangeData}
                                />
                              </Stack>
                              <List>
                                {Object.entries(period.invoicingByType).map(
                                  ([type, amount]) => (
                                    <ListItem
                                      key={
                                        "INVOICED_type_" + type + "_" + gedu.id
                                      }
                                    >
                                      <ListItemText
                                        primary={`${t(
                                          "code." + type.split(".")[0]
                                        )}: ${amount} ${t(
                                          "code." + type.split(".")[1]
                                        )}`}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                            </Box>
                          ))}
                        </ListItem>
                      </List>
                      <Divider sx={{ mb: 1 }} />
                    </Box>
                  )
              )}
            </MuiAccordionDetails>
          </MuiAccordion>
          <MuiAccordion key={"accordion-other"}>
            <MuiAccordionSummary
              aria-controls="panel3d-content_OTHER"
              id={"panel3d-header_OTHER"}
              expandIcon={<ExpandMoreIcon />}
              sx={{ background: "#f0f0f0" }}
            >
              <Typography>{t("trusterReport.otherStatuses")}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              {gedus.map(
                (gedu, index) =>
                  gedu.otherPeriods.length > 0 && (
                    <Box
                      key={"OTHER_" + gedu.id}
                      sx={{ textAlign: "left", ml: 1.5 }}
                    >
                      <Stack direction="row" spacing={2}>
                        <OpenIcon
                          className="action-icon-no-margin"
                          onClick={() => {
                            window
                              .open(`./geduDetails/?id=${gedu.id}`, "_blank")
                              .focus();
                          }}
                          // sx={{ ml: 2 }}
                        />
                        <Stack direction="column" spacing={0}>
                          <Typography variant="body1">{`${gedu.firstName} ${gedu.lastName}`}</Typography>
                          <Typography variant="body2">{`${t(
                            "trusterReport.invocingEmail"
                          )}: ${
                            gedu.invoicingEmail ? gedu.invoicingEmail : ""
                          }`}</Typography>
                        </Stack>
                      </Stack>

                      <Divider />
                      <List
                        key={"invoicingStatusList_OTHER_" + gedu.id}
                        sx={{ margin: "auto" }}
                      >
                        <ListItem key={"OTHER_LIST_ITME" + gedu.id}>
                          {gedu.otherPeriods.map((period, periodIndex) => (
                            <Box key={"OTHER_BOX_ITME" + gedu.id + periodIndex}>
                              <Stack direction="row" spacing={2}>
                                <Stack direction="column" spacing={0}>
                                  <Typography>
                                    {t("trusterReport.periodTitle")}:{" "}
                                    {formatDate(
                                      new Date(period.startDate),
                                      "DD.MM.YYYY"
                                    )}
                                    {" - "}
                                    {formatDate(
                                      new Date(period.endDate),
                                      "DD.MM.YYYY"
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontSize: "0.8em" }}>
                                    [{period.id}]
                                  </Typography>
                                </Stack>
                                <InvoicingStatus
                                  key={"status_OTHER_" + gedu.id + period.id}
                                  status={period.status?.name}
                                  statusChangeData={period.statusChangeData}
                                />
                              </Stack>
                              <List>
                                {Object.entries(period.invoicingByType).map(
                                  ([type, amount]) => (
                                    <ListItem key={"OTHER_" + type + gedu.id}>
                                      <ListItemText
                                        primary={`${t(
                                          "code." + type.split(".")[0]
                                        )}: ${amount} ${t(
                                          "code." + type.split(".")[1]
                                        )}`}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                            </Box>
                          ))}
                        </ListItem>
                      </List>
                      <Divider sx={{ mb: 1 }} />
                    </Box>
                  )
              )}
            </MuiAccordionDetails>
          </MuiAccordion>
        </Box>
      )}
    </Box>
  );
};

export default TrusterReportGedus;
