import React from "react";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenIcon from "@mui/icons-material/OpenInNew";
import Alert from "@mui/material/Alert";

import ReceiptIcon from "@mui/icons-material/Receipt";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import { prepareClubName } from "../Util/clubHelper";
import { formatDate, prepareTime } from "../Util/clubHelper";
import { setMuniInvoicingStatuses } from "../Hooks/report";
import { setInvoicingData } from "../Hooks/municipality";
import { MuniInvoicingInfoEditor } from "../Util/muniInvoicingInfoEditor";
import ErrorAlert from "../errorAlert";

import { useTranslation } from "react-i18next";
import { is } from "date-fns/locale";
import { use } from "i18next";
import { set } from "date-fns";

/*
    Data:
        "clubs": [
            {
                "name": "Animaatio",
                "id": "454192539356901376",
                "lessons": [
                    {
                        "id": "487278119586115584",
                        "date": "2023-09-06T10:30:26.000Z",
                        "status": {
                            "id": "413253253241778176",
                            "name": "LESSON_STATUS_UNDONE",
                            "domain": {}
                        },
                        "pricing": {
                            "amount": "75",
                            "currency": {
                                "id": "479635273974558720",
                                "name": "CURRENCY_EUR",
                                "domain": {
                                    "id": "479635204265226240",
                                    "name": "CURRENCY"
                                }
                            }
                        }
                    }
                ],
                "meetings": [
                    {
                        "id": "497096507875405824",
                        "startTime": "13:30:00",
                        "endTime": "14:30:00",
                        "weekday": "wed"
                    }
                ],
                "type": {
                    "id": "333214184844045312",
                    "name": "CLUB_TYPE_MUNICIPALITY",
                    "domain": {}
                },
                "school": null,
                "municipality": {
                    "id": "454192103363194880",
                    "name": "Tuusula",
                    "contact": "katja.sihvonen@tuusula.fi",
                    "isActive": true,
                    "registration": true,
                    "createdAt": "2023-06-07T07:57:15.923Z",
                    "updatedAt": "2023-08-19T10:52:46.167Z",
                    "countryId": "346995052465106944"
                }
            }
        ],


*/

const MuniReportClubs = ({
  clubs,
  refetch,
  municipality,
  municipalityData,
}) => {
  const { t } = useTranslation();
  const [clubData, setClubData] = useState([]);
  const [clubHeader, setClubHeader] = useState("");
  const [isSettingStatus, setIsSettingStatus] = useState(false);
  const [statusSettingError, setStatusSettingError] = useState(false);
  const [totalInvoicingForMunicipality, setTotalInvoicingForMunicipality] =
    useState(0.0);
  const [totalInvoicingCurrency, setTotalInvoicingCurrency] = useState("");
  const [totalNumberOfClubs, setTotalNumberOfClubs] = useState(0);
  const [municipalityIsEstimate, setMunicipalityIsEstimate] = useState(false);
  const [municipalityInvoicingData, setMunicipalityInvoicingData] = useState(
    {}
  );
  const [errorOnInvoicingDataMutate, setErrorOnInvoicingDataMutate] =
    useState("");

  useEffect(() => {
    if (clubs) {
      prepareClubData(clubs);
    }
  }, [clubs]);

  useEffect(() => {
    if (municipalityData) {
      prepareMunicipalityInvoicingData(municipalityData);
    }
  }, [municipalityData]);

  const { mutate: mutateInvoicingInfo } = useMutation({
    mutationFn: setInvoicingData,
    onSuccess: (response) => {
      // console.log("onSuccess>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log(response);
      //refetch(municipality);
      prepareMunicipalityInvoicingData(response.data.municipality);
    },
    onError: (error) => {
      console.error("An error occurred:", error);
      // Handle error, show error message, etc.
      setErrorOnInvoicingDataMutate(error);
    },
  });

  /*
INVOICING_STATUS_UNHANDLED
INVOICING_STATUS_REJECTED
INVOICING_STATUS_APPROVED
INVOICING_STATUS_INVOICED
INVOICING_STATUS_PAID
*/
  const invoicingStatusMappings = {
    INVOICING_STATUS_UNHANDLED: {
      color: "#3A71DE", // blue-ish
    },
    INVOICING_STATUS_APPROVED: {
      color: "#6AC66B", // green-ish
    },
    INVOICING_STATUS_REJECTED: {
      color: "#F55B9A", // red-ish darker
    },
    INVOICING_STATUS_INVOICED: {
      color: "#6AC66B", // green-ish
    },
    INVOICING_STATUS_PAID: {
      color: "#1AB061", // green-ish darker
    },
    LESSON_INVOICING_STATUS_IGNORED: {
      color: "#9E9E9E", //  grey darker
    },
  };

  const lessonStatusMappings = {
    // LESSON_STATUS
    LESSON_STATUS_DONE: {
      isEstimate: false,
      isCalculated: true,
      color: "#6AC66B", // green-ish
    },
    LESSON_STATUS_CANCELLEDBYMUNICIPALITY: {
      isEstimate: false,
      isCalculated: false,
      color: "#F55B9A", // red-ish darker
    },
    LESSON_STATUS_CANCELLEDBYSOG: {
      isEstimate: false,
      isCalculated: false,
      color: "#FD700D", // seconray orange darker
    },
    LESSON_STATUS_CANCELLED: {
      isEstimate: false,
      isCalculated: false,
      color: "#FA7FA3", // red-ish lighter
    },
    LESSON_STATUS_RESCHEDULED: {
      isEstimate: false,
      isCalculated: false,
      color: "#EBEBEB", // light grey w black text
    },
    LESSON_STATUS_UNDONE: {
      isEstimate: true,
      isCalculated: true,
      color: "#9E9E9E", // darker grey
    },
    LESSON_STATUS_HOLIDAY: {
      isEstimate: false,
      isCalculated: false,
      color: "#8F00E2", // purple
    },
  };

  const prepareMunicipalityInvoicingData = (municipalityData) => {
    const invoicingData = {};

    const contact = {
      value: municipalityData.contact,
      label: t("muniReport.municipalityContact"),
    };
    invoicingData.contact = contact;

    const paymentTerm = {
      value: municipalityData.invoicingInformation?.paymentTerm,
      label: t("muniReport.paymentTermEditHeader"),
    };
    invoicingData.paymentTerm = paymentTerm;

    const reference = {
      value: municipalityData.invoicingInformation?.reference,
      label: t("muniReport.reference"),
    };
    invoicingData.reference = reference;

    const additionalInfo = {
      value: municipalityData.invoicingInformation?.additionalInfo,
      label: t("muniReport.invoicingAdditiontalInfo"),
    };
    invoicingData.additionalInfo = additionalInfo;

    setMunicipalityInvoicingData(invoicingData);
  };

  const dayOrder = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };

  const prepareClubData = (clubs) => {
    const clubList = [];

    console.log("prepareClubData");
    console.log(municipality);
    console.log(clubs);

    let numberOfClubs = clubs.length;
    let totalMunicipalityInvoicing = 0.0;
    let totalInvoicingCurrency = "";
    let municipalityIsEstimate = false;

    // loop through the clubs
    clubs.map((club) => {
      let isEstimate = false;
      let isError = false;
      let totalAmount = parseFloat(0.0);
      let currency = undefined;
      let unitPrice = undefined;

      let invoicedLessonsCount = 0;
      const clubLessons = [];

      const meeetingTimesInfoData = [];
      const meetingDays = [];
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log(data.meetings);
      if (club.meetings) {
        club.meetings.map((meeting, i) => {
          meeetingTimesInfoData.push({
            key: t("weekday." + meeting.weekday),
            value:
              prepareTime(meeting.startTime) +
              "-" +
              prepareTime(meeting.endTime),
            sortValue: meeting.weekday,
          });
          meetingDays.push(meeting.weekday);
        });
      }

      // sort meeetingTimesInfoData by weekday
      meetingDays.sort((a, b) => dayOrder[a] - dayOrder[b]);
      // sort meeetingTimesInfoData by weekday
      meeetingTimesInfoData.sort(
        (a, b) => dayOrder[a.sortValue] - dayOrder[b.sortValue]
      );

      // construct a string from meetingTimesInfoData
      const meetingTimesInfo = meeetingTimesInfoData
        .map((item) => item.key + " " + item.value)
        .join(", ");

      // loop through the lessons
      club.lessons.map((lesson) => {
        let lessonError = false;
        let lessonEstimate = false;
        let lessonInvoicingStatus = undefined;
        let lessonPricingId = undefined;

        if (lessonStatusMappings[lesson.status.name] === undefined) {
          isError = true;
          lessonError = true;
        } else {
          if (lessonStatusMappings[lesson.status.name].isEstimate) {
            isEstimate = true;
            lessonEstimate = true;
          }

          if (lessonStatusMappings[lesson.status.name].isCalculated) {
            if (lesson.pricing?.amount !== undefined) {
              totalAmount += parseFloat(lesson.pricing?.amount);
              invoicedLessonsCount++;
            }
            if (lesson.pricing?.muniInvoicing) {
              console.log("muniInvoicing: " + lesson.pricing.status?.name);
              lessonInvoicingStatus = lesson.pricing.status.name;

              if (!lessonInvoicingStatus) {
                lessonInvoicingStatus = "LESSON_INVOICING_STATUS_NOT_SET";
              }
            } else {
              console.log("NO muniInvoicing: ");
              lessonInvoicingStatus = "LESSON_INVOICING_STATUS_NOT_SET";
            }
            lessonPricingId = lesson.pricing?.id;
          } else {
            lessonInvoicingStatus = "LESSON_INVOICING_STATUS_IGNORED";
          }
        }

        if (unitPrice === undefined) {
          unitPrice = lesson.pricing?.amount; // assuming same unit price for all lessons
        }
        if (currency === undefined) {
          currency = lesson.pricing?.currency?.name; // assuming same currency for all lessons
        }

        const lessonData = {
          id: lesson.id,
          pricingId: lessonPricingId,
          date: lesson.date,
          status: lesson.status.name,
          amount: lesson.pricing?.amount,
          currency: lesson.pricing?.currency?.name,
          isError: lessonError,
          isEstimate: lessonEstimate,
          invoicingStatus: lessonInvoicingStatus,
        };
        // get lesson gedu
        if (lesson.gedus) {
          const gedu = lesson.gedus["gedu"];
          const assistant = lesson.gedus["assistant"];

          if (gedu) {
            lessonData.gedu =
              gedu.firstName +
              " " +
              gedu.lastName +
              " [" +
              gedu.discordUserName +
              "]";
          }
          if (assistant) {
            lessonData.assistant =
              assistant.firstName +
              " " +
              assistant.lastName +
              " [" +
              gedu.discordUserName +
              "]";
          }
        }

        clubLessons.push(lessonData);
      });

      // collect club lesson statuses and ignore those marked not calulated
      let clubStatus = "INVOICING_STATUS_UNHANDLED";
      if (clubLessons.length > 0) {
        const clubLessonStatuses = [];
        for (const lesson of clubLessons) {
          if (lessonStatusMappings[lesson.status]) {
            if (lessonStatusMappings[lesson.status].isCalculated) {
              clubLessonStatuses.push(lesson.invoicingStatus); // note push invoicing status
            }
          }
        }

        const allStatusesSame = clubLessonStatuses.every(
          (theStatus, index, array) => theStatus === array[0]
        );

        if (allStatusesSame) {
          clubStatus = clubLessonStatuses[0];
        } else {
          clubStatus = "MIXED";
        }
      }

      // sort clubLessons by date
      clubLessons.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      const clubData = {
        name: prepareClubName(club),
        owner: club.owner,
        id: club.id,
        lessons: clubLessons,
        isEstimate: isEstimate,
        isError: isError,
        totalAmount: totalAmount,
        currency: currency,
        unitPrice: unitPrice,
        meetingTimesInfo: meetingTimesInfo,
        clubStatus: clubStatus,
        invoicedLessonsCount: invoicedLessonsCount,
        meetingDays,
      };

      if (isEstimate) {
        municipalityIsEstimate = true;
      }

      if (totalInvoicingCurrency === "") {
        totalInvoicingCurrency = currency;
      } else if (totalInvoicingCurrency !== currency) {
        // mixed currencies
        totalInvoicingCurrency = "MIXED";
      }

      totalMunicipalityInvoicing += totalAmount;

      clubList.push(clubData);
    });

    // sort clubList by weekday and then name
    // clubList.sort((a, b) => {
    //   const firstDayA =
    //     a.meetingDays.length > 0 ? dayOrder[a.meetingDays[0]] : Infinity;
    //   const firstDayB =
    //     b.meetingDays.length > 0 ? dayOrder[b.meetingDays[0]] : Infinity;

    //   return firstDayA - firstDayB;
    // });

    clubList.sort((a, b) => {
      const firstDayA =
        a.meetingDays.length > 0 ? dayOrder[a.meetingDays[0]] : Infinity;
      const firstDayB =
        b.meetingDays.length > 0 ? dayOrder[b.meetingDays[0]] : Infinity;

      // First, compare by meetingDays
      const firstComparison = firstDayA - firstDayB;

      // If meetingDays are the same, then compare by name
      if (firstComparison === 0) {
        const nameA = a.name.toLowerCase(); // Convert to lowercase for case-insensitive comparison
        const nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      }

      return firstComparison;
    });

    setClubData(clubList);

    setTotalInvoicingForMunicipality(totalMunicipalityInvoicing);
    setTotalInvoicingCurrency(totalInvoicingCurrency);
    setTotalNumberOfClubs(numberOfClubs);
    setMunicipalityIsEstimate(municipalityIsEstimate);
  };

  const getStatusColor = (status) => {
    if (lessonStatusMappings[status]) return lessonStatusMappings[status].color;

    return "#121212";
  };

  const getInvoicingStatusColor = (status) => {
    if (invoicingStatusMappings[status])
      return invoicingStatusMappings[status].color;

    return "#121212";
  };

  const handleReset = (club, all = false) => {
    if (!club && all) {
      console.log("handleReset ALL");
      const invoicingStatusDataAll = getInvoicingStatusDataForAll(
        "INVOICING_STATUS_UNHANDLED"
      );
      setStatusClubLessons(invoicingStatusDataAll);
    } else {
      console.log("handleReset");
      const invoicingStatusData = getInvoicingStatusData(
        club,
        "INVOICING_STATUS_UNHANDLED"
      );
      setStatusClubLessons(invoicingStatusData);
    }
  };

  const handleApproval = (club, all = false) => {
    if (!club && all) {
      console.log("handleReset ALL");
      const invoicingStatusDataAll = getInvoicingStatusDataForAll(
        "INVOICING_STATUS_APPROVED"
      );
      setStatusClubLessons(invoicingStatusDataAll);
    } else {
      console.log("handleApproval");
      const invoicingStatusData = getInvoicingStatusData(
        club,
        "INVOICING_STATUS_APPROVED"
      );
      setStatusClubLessons(invoicingStatusData);
    }
  };

  const handleInvoiced = (club, all = false) => {
    if (!club && all) {
      console.log("handleReset ALL");
      const invoicingStatusDataAll = getInvoicingStatusDataForAll(
        "INVOICING_STATUS_INVOICED"
      );
      setStatusClubLessons(invoicingStatusDataAll);
    } else {
      console.log("handleInvoiced");
      const invoicingStatusData = getInvoicingStatusData(
        club,
        "INVOICING_STATUS_INVOICED"
      );
      setStatusClubLessons(invoicingStatusData);
    }
  };

  const getInvoicingStatusDataForAll = (status) => {
    const invoicingStatusData = [];
    for (const club of clubData) {
      for (const lesson of club.lessons) {
        if (lesson.pricingId) {
          invoicingStatusData.push({
            id: lesson.pricingId,
            status: status,
          });
        }
      }
    }
    return invoicingStatusData;
  };

  const getInvoicingStatusData = (club, status) => {
    const invoicingStatusData = [];
    for (const lesson of club.lessons) {
      if (lesson.pricingId) {
        invoicingStatusData.push({
          id: lesson.pricingId,
          status: status,
        });
      }
    }
    return invoicingStatusData;
  };

  const setStatusClubLessons = async (invoicingStatusData) => {
    /*
API expects:
	const schema = {
		properties: {
			invoicingData: {
				type: 'array',
				required: true,
				items: {
					type: 'object',
					properties: {
						id: { type: 'string', required: true },
						status: { type: 'string', minLength: 1, required: true },
					},
				},
			},
		},
		additionalProperties: false,
	};
*/
    setIsSettingStatus(true);

    const body = {
      invoicingData: invoicingStatusData,
    };

    try {
      //setIsSavingStatus(true);
      //params.isLoading?.(true);
      const response = await setMuniInvoicingStatuses(body);
      console.log(response);

      if (response.data.success) {
        console.log("Calling refetch" + municipality);
        if (refetch) refetch(municipality);
        else console.log("refetch not set");
      }
    } catch (error) {
      console.log(error);
      setStatusSettingError(error);
    } finally {
      // setIsSavingStatus(false);
      // params.isLoading?.(false);
      setIsSettingStatus(false);
    }
  };

  const copyToClipboard = (text) => {
    // Create a temporary input element and set its value to the text to copy
    const tempInput = document.createElement("input");
    tempInput.value = text;

    // Append the input to the DOM
    document.body.appendChild(tempInput);

    // Select the text in the input
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
  };

  const openClub = (clubid) => {
    const newWindow = window.open(`/club/?id=${clubid}`, "_blank");
    //if (newWindow) newWindow.focus();
  };

  return (
    <div>
      {errorOnInvoicingDataMutate && errorOnInvoicingDataMutate !== "" && (
        <ErrorAlert txt={errorOnInvoicingDataMutate} />
      )}
      {municipalityData?.name && (
        <>
          <List>
            <ListItem key={"muni" + municipalityData.id}>
              <ListItemText
                primary={`${t("muniReport.municipalityContact")}`}
                secondary={`${
                  municipalityInvoicingData?.contact?.value
                    ? municipalityInvoicingData.contact.value
                    : ""
                }`}
              />
              <ListItemText
                primary={`${t("muniReport.paymentTerm")}`}
                secondary={`${
                  municipalityInvoicingData?.paymentTerm?.value
                    ? municipalityInvoicingData.paymentTerm.value +
                      " " +
                      t("muniReport.paymentTermDays")
                    : ""
                }`}
              />
              <ListItemText
                primary={`${t("muniReport.reference")}`}
                secondary={`${
                  municipalityInvoicingData?.reference?.value
                    ? municipalityInvoicingData?.reference.value
                    : ""
                }`}
              />
            </ListItem>
            <ListItem key={"muni2" + municipalityData.id}>
              <ListItemText
                primary={`${t("muniReport.invoicingAdditiontalInfo")}`}
                secondary={`${
                  municipalityInvoicingData?.additionalInfo?.value
                    ? municipalityInvoicingData?.additionalInfo.value
                    : ""
                }`}
              />
            </ListItem>
            <MuniInvoicingInfoEditor
              data={municipalityInvoicingData}
              onSave={(newValues) => {
                // console.log("onSave");
                // console.log(newValues);

                const updatedData = {};

                Object.keys(newValues).forEach((key) => {
                  updatedData[key] = newValues[key].value;
                });

                const data = {
                  id: municipalityData.id,
                  fields: updatedData,
                };

                mutateInvoicingInfo(data);
              }}
            />
          </List>
        </>
      )}
      {clubData.map((club) => (
        <Accordion key={club.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ background: "#f0f0f0" }}
          >
            <Tooltip
              title={`${t("muniReport.openClub")}: ${club.name}`}
              placement="top"
            >
              <Button
                variant=""
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  openClub(club.id);
                }}
                sx={{ ml: 0 }} // Adjust margin as needed
              >
                <OpenIcon />
              </Button>
            </Tooltip>
            <ListItem key={"header1" + club.id}>
              <ListItemText
                primary={club.name}
                secondary={
                  club.meetingTimesInfo +
                  " / " +
                  club.owner?.firstName +
                  " " +
                  club.owner?.lastName +
                  " [" +
                  club.owner?.discordUserName +
                  "]"
                }
              />
            </ListItem>
            {/* Copy button for club.name */}
            <Tooltip
              title={`${t("muniReport.copyClubName")}: ${club.name}`}
              placement="top"
            >
              <Button
                variant=""
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  copyToClipboard(club.name);
                }}
                sx={{ ml: 0 }} // Adjust margin as needed
              >
                <ContentCopyIcon />
              </Button>
            </Tooltip>
            <ListItem key={"header2" + club.id}>
              <ListItemText
                primary={`${club.invoicedLessonsCount} x ${parseFloat(
                  club.unitPrice
                ).toFixed(2)} ${t("code." + club.currency)}`}
                secondary={" "}
              />
            </ListItem>
            <ListItem
              key={"header3" + club.id}
              style={{
                color: club.isEstimate ? "#9E9E9E" : "black", // different color for estimate
              }}
            >
              <ListItemText
                secondaryTypographyProps={
                  club.isEstimate ? { color: "#9E9E9E" } : { color: "black" }
                }
                primary={`${parseFloat(club.totalAmount).toFixed(2)} ${t(
                  "code." + club.currency
                )}`}
                secondary={`${
                  club.isEstimate ? t("muniReport.isEstimate") : ""
                } ${club.isError ? t("muniReport.isError") : ""}`}
              />
            </ListItem>
            <ListItem key={"header4" + club.id}>
              <ListItemText
                primary={`${
                  club.clubStatus ? t("muniReport." + club.clubStatus) : ""
                }`}
              />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {club.lessons.map((lesson) => (
                <ListItem key={lesson.id}>
                  <Tooltip
                    title={`${t("code." + lesson.status)}`}
                    placement="top"
                  >
                    {/* Colored status indicator */}
                    <div
                      className="status-indicator"
                      style={{
                        display: "inline-block",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        marginRight: "12px" /* Adjust the margin as needed */,
                        backgroundColor: getStatusColor(lesson.status), // Replace with your logic
                      }}
                    ></div>
                  </Tooltip>
                  <ListItemText
                    primary={`${formatDate(
                      new Date(lesson.date),
                      "DD.MM.YYYY hh:mm"
                    )}`}
                    secondary={
                      <>
                        <div>{t("code." + lesson.status)}</div>
                        <div>{lesson.gedu ? lesson.gedu : ""}</div>
                      </>
                    }
                  />
                  <ListItemText
                    style={{
                      color: lesson.isEstimate ? "#9E9E9E" : "black", // different color for estimate
                    }}
                    secondaryTypographyProps={
                      lesson.isEstimate
                        ? { color: "#9E9E9E" }
                        : { color: "black" }
                    }
                    primary={`${parseFloat(lesson.amount).toFixed(2)} ${t(
                      "code." + lesson.currency
                    )}`}
                    secondary={`${
                      lesson.isEstimate ? t("muniReport.isEstimate") : ""
                    } ${lesson.isError ? t("muniReport.isError") : ""}`}
                  />
                  <Box
                    sx={{
                      color: getInvoicingStatusColor(lesson.invoicingStatus),
                    }}
                  >
                    {t(
                      "muniReport.invoicingStatus.short." +
                        lesson.invoicingStatus
                    )}
                    &nbsp;
                    <Tooltip
                      title={`${t("muniReport." + lesson.invoicingStatus)}`}
                      placement="top"
                    >
                      <Box
                        className="status-indicator"
                        style={{
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          marginRight: "12px" /* Adjust the margin as needed */,
                          backgroundColor: getInvoicingStatusColor(
                            lesson.invoicingStatus
                          ),
                        }}
                      ></Box>
                    </Tooltip>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ display: "block" }}
            >
              {isSettingStatus && <CircularProgress />}

              <Button
                size="small"
                sx={{
                  minWidth: "30px",
                  width: "145px",
                }}
                disabled={false}
                variant={"outlined"}
                onClick={() => {
                  handleReset(club);
                }}
              >
                <CancelIcon sx={{ mr: 1 }} />{" "}
                {t("muniReport.resetInvoicingStatus")}
              </Button>

              <Button
                size="small"
                sx={{
                  minWidth: "30px",
                  width: "145px",
                }}
                disabled={false}
                variant={"contained"}
                onClick={() => {
                  handleApproval(club);
                }}
              >
                <ThumbUpIcon sx={{ mr: 1 }} /> {t("invoicingLines.sogApproval")}
              </Button>

              <Button
                size="small"
                sx={{ minWidth: "30px", width: "145px", mr: 2 }}
                disabled={false}
                variant={"outlined"}
                onClick={() => {
                  handleInvoiced(club);
                }}
              >
                <ReceiptIcon sx={{ mr: 1 }} /> {t("invoicingLines.invoiced")}
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}

      {clubData && clubData.length > 0 && (
        <>
          <List>
            <ListItem key={"header2" + municipality.id}>
              <ListItemText
                primary={`${t(
                  "muniReport.municipalityTotalNumberOfClubs"
                )}: ${totalNumberOfClubs}`}
              />
              <ListItemText
                secondaryTypographyProps={
                  municipalityIsEstimate
                    ? { color: "#9E9E9E" }
                    : { color: "black" }
                }
                primary={`${t(
                  "muniReport.municipalityTotalInvoicing"
                )}: ${parseFloat(totalInvoicingForMunicipality).toFixed(2)} ${t(
                  "code." + totalInvoicingCurrency
                )}`}
                secondary={`${
                  municipalityIsEstimate ? t("muniReport.isEstimate") : ""
                }`}
              />
            </ListItem>
          </List>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ display: "block" }}
          >
            {isSettingStatus && <CircularProgress />}

            <Button
              size="small"
              sx={{
                minWidth: "30px",
                width: "145px",
              }}
              disabled={false}
              variant={"outlined"}
              onClick={() => {
                handleReset(undefined, true);
              }}
            >
              <CancelIcon sx={{ mr: 1 }} />{" "}
              {t("muniReport.resetInvoicingStatusAll")}
            </Button>

            <Button
              size="small"
              sx={{
                minWidth: "30px",
                width: "145px",
              }}
              disabled={false}
              variant={"contained"}
              onClick={() => {
                handleApproval(undefined, true);
              }}
            >
              <ThumbUpIcon sx={{ mr: 1 }} /> {t("muniReport.sogApprovalAll")}
            </Button>

            <Button
              size="small"
              sx={{ minWidth: "30px", width: "145px", mr: 2 }}
              disabled={false}
              variant={"outlined"}
              onClick={() => {
                handleInvoiced(undefined, true);
              }}
            >
              <ReceiptIcon sx={{ mr: 1 }} /> {t("muniReport.invoicedAll")}
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};

export default MuniReportClubs;
