import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";

import { getCodeDomainsByNames } from "../Hooks/codes";

export const RewardList = ({ rewards, setRewards }) => {
  const { t } = useTranslation();

  const {
    data: dataProfileVariables,
    isLoading: isLoadingProfileVariables
  } = useQuery({
    queryKey: ["codes", ["GAMER_PROFILE_VARIABLE"]], 
    queryFn: () => getCodeDomainsByNames(["GAMER_PROFILE_VARIABLE"]),
    refetchOnWindowFocus: false,
  });

  const rewardCodes = isLoadingProfileVariables ? [] : dataProfileVariables[0].codes.map(code => ({
    value: code.id,
    name: code.name,
    label: t("code." + code.name),
  }));

  const columns = [
    {
      field: "code",
      headerName: t("clubLessons.reward"),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          <Select
            error={(rewards.find((row) => row.id === params.id)?.code ?? "") === ""}
            displayEmpty={false}
            id="reward-type-select"
            size="small"
            sx={{ m: 1, width: "100%" }}
            value={isLoadingProfileVariables ? "" : rewards.find((row) => row.id === params.id)?.code ?? ""}
            onChange={(e) => {
              console.log(e, params);
              setRewards(rewards.map((row) => row.id === params.id ? ({ ...row, code: e.target.value }) : row ))
            }}
          >
            {rewardCodes.length > 0 &&
              rewardCodes.map((code) => (
                <MenuItem key={code.value} value={code.value}>
                  {code.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    {
      field: "amount",
      headerName: t("clubLessons.amount"),
      width: 150,
      type: "number",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("general.remove"),
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => ([
        <Button onClick={() => setRewards(rewards.filter(reward => reward.id !== id))}><DeleteIcon /></Button>
      ]),
    },
  ];
  
  const nextId = Math.max(...rewards.map(reward => reward.id), 0) + 1;
  
  const NoRowsOverlay = () => (
      <Typography variant="h2" sx={{ 
        mt: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', 
      }}>Rows</Typography>
  );
  
  return (<>
      <Button
        variant="contained"  
        onClick={() => {
          setRewards(rewards.concat({ id: nextId, code: "", amount: 10 }))
        }}
      >{t("general.add")}</Button>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
            display: "none"
          }
        }}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        disableSelectionOnClick
        autoHeight
        rowsPerPageOptions={[100]}
        rows={rewards.map(reward => ({ id: reward.id, code: reward.code, amount: reward.amount }))}
        columns={columns}
        hideFooter={rewards.length < 100}
        experimentalFeatures={{ newEditingApi:true }}
        processRowUpdate={(updatedRow, originalRow) => {
          setRewards(rewards.map((row) => row.id === originalRow.id ? updatedRow : row ));
          // console.log("UPDATED REWARDS:", updatedRow);
          return updatedRow;
        }}
        // onCellEditCommit={processRowUpdate}
      />
    </>);
};
  