import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useTranslation } from "react-i18next";
import FloatInputField from "./floatInputField"; // Import the FloatInputField component

const CustomMultiTextFieldWithSave = ({ data, onSave }) => {
  const { t } = useTranslation();
  const [editedData, setEditedData] = useState(data);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // Check for changes when data or editedData changes
    const changes = Object.keys(data).some(
      (key) => editedData[key].value !== data[key].value
    );
    setHasChanges(changes);
  }, [data, editedData]);

  const handleInputChange = (key, newValue) => {
    setEditedData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value: newValue,
      },
    }));
  };

  const handleSave = () => {
    onSave(editedData);
  };

  return (
    <div>
      {Object.keys(data).map((key) => (
        <div key={key}>
          {data[key].dataType === "float" ? ( // Check if it's a float field
            <FloatInputField
              label={data[key].label}
              initialValue={editedData[key].value}
              onChange={(newValue) => handleInputChange(key, newValue)}
            />
          ) : (
            <TextField
              label={data[key].label}
              variant="outlined"
              fullWidth
              multiline={data[key].rows ? true : false}
              rows={data[key].rows || 1}
              value={editedData[key].value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              sx={{ mt: 2, mb: 2 }}
            />
          )}
        </div>
      ))}
      <Button variant="contained" onClick={handleSave} disabled={!hasChanges}>
        {t("customTextField.save")}
      </Button>
    </div>
  );
};

export default CustomMultiTextFieldWithSave;
