import axios from "axios"
import { API } from "./API";

export const getEntities = async () => {
    const { data } = await axios.get(`${API}/entities`, { withCredentials: true });      
    return data.data
}

export const addChildEntity = async (body) => {
    const { data } = await axios.post(`${API}/entities/create`, body, { withCredentials: true });
    return data
}

export const deleteEntity = async (id) => {
    const { data } = await axios.delete(`${API}/entities/${id}`, { withCredentials: true });
    return data
}