export const isAdmin = (appUserRoles) => {
  // console.log(appUserRoles);
  // console.log(appUserRoles?.includes("USER_ROLE_ADMIN"));
  return appUserRoles?.includes("USER_ROLE_ADMIN");
};
export const isGedu = (appUserRoles) => {
  return appUserRoles?.includes("USER_ROLE_GEDU");
};
export const isParent = (appUserRoles) => {
  return appUserRoles?.includes("USER_ROLE_PARENT");
};
export const isGamer = (appUserRoles) => {
  return appUserRoles?.includes("USER_ROLE_GAMER");
};

const validSubscriptionStatuses = ["active", "non_renewing", "one_time", "in_trial"];
export const isActiveStatus = (status) => {
  if (!status) return true; // no status is considered active
  return validSubscriptionStatuses.includes(status);
};
