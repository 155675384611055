import React, { useEffect } from "react";
import clientVersion from "../version";
import "./home.css";
import ArenaLogo from "./arena_logo";
import GamerProfile from "./profile";
import { useQuery } from "react-query";
import { getServerVersion } from "../Hooks/version";
import CircularProgress from "@mui/material/CircularProgress";
import ContainerBox from "../Container";
import ErrorAlert from "../errorAlert";
import { isGamer, isGedu, isAdmin } from "../Util/authHelper";
import InvoicingStatusComponent from "../Util/InvoicingStatusComponent";

import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";

const lngs = {
  en: { nativeName: "English" },
  fi: { nativeName: "Suomi" },
  es: { nativeName: "España" },
};

export default function Home(params) {
  const showProfile = isGamer(params?.appAuthInfo?.appUserRoles);
  const isAdminUser = isAdmin(params?.appAuthInfo?.appUserRoles);
  const isGeduUser = isGedu(params?.appAuthInfo?.appUserRoles);
  const {
    data: serverVersion,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["version"],
    queryFn: getServerVersion,
    enabled: !showProfile,
  });

  const { t } = useTranslation();

  return showProfile ? (
    <ContainerBox>
      <GamerProfile />
    </ContainerBox>
  ) : (
    <ContainerBox>
      {false && isLoading && <CircularProgress />}
      {error && <ErrorAlert txt={error} />}
      <h1>{t("home.welcomeTitle")}</h1>
      <ArenaLogo />
      <div>
        <div>
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                marginRight: "5px",
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <br />
        <Trans components={{ 1: <strong /> }}>home.welcome</Trans>
      </div>
      <div>
        <br />
        {t("home.clientVersion")} {clientVersion}
        <br />
        {t("home.serverVersion")}{" "}
        {isLoading ? "" : error ? "n/a" : serverVersion.version}
        {/* {isAdminUser && (
          <span>
            <br />
            API URL: {URL}
          </span>
        )} */}
      </div>
      <div>
        {isGeduUser && <InvoicingStatusComponent isGedu={isGeduUser} />}
      </div>
      <div>
        {isAdminUser && <InvoicingStatusComponent isAdmin={isAdminUser} />}
      </div>
    </ContainerBox>
  );
}
