import axios from "axios";
import { API } from "./API";

export const getAchievements = async () => {
  const { data } = await axios.get(`${API}/achievements`, {
    withCredentials: true,
  });

  return data.data;
};

export const createAchievement = async (body) => {
  const { data } = await axios.post(`${API}/achievements/create`, body, {
    withCredentials: true,
  });

  return data.data;
};

export const deleteAchievement = async (id) => {
  const { data } = await axios.delete(`${API}/achievements/${id}`, {
    withCredentials: true,
  });
  
  return data.data;
};

export const editAchievement = async (body) => {
  const { data } = await axios.put(`${API}/achievements/${body.id}`, body, {
    withCredentials: true,
  });
  
  return data.data;
};
