import React, { useEffect } from "react";
import Box from "@mui/material/Box";

export default function ContainerBox(props) {
  const [width, setWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        setWidth(window.innerWidth);
      },
      true
    );
  }, []);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1.5,
        width: { sm: `calc(100% - ${width}px)` },
        height: "100vh",
        paddingBottom: "60px",
      }}
    >
      {/* <div>{width}</div> */}
      {props.children}
    </Box>
  );
}
