import React, {useEffect, useState} from 'react';
import { useMutation } from 'react-query';
import { getSlots } from '../Hooks/purchase';
import "./slotSelection.css"
import { Button, Modal, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import SlotSelection from './selectSlot';
import Purchase from './purchase';
import { Box } from "@mui/system";
import { useTranslation, Trans } from 'react-i18next';

export default function SlotSelectionPsw({token, newPswChange}) {

    const { t } = useTranslation();
    const [newPsw, setNewPsw] = useState("")
    const [state, setState] = React.useState({
        open: true,
        vertical: "top",
        horizontal: "center",
    });
    const { vertical, horizontal, open } = state;
    const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    sessionStorage.setItem("psw", document.getElementById("purchase-psw").value)  
    setNewPsw(document.getElementById("purchase-psw").value)
    newPswChange(document.getElementById("purchase-psw").value)
  }
    return(
        <>
            {
                
                newPsw === "" && sessionStorage.getItem("psw") === "-1" && (
                    <div>
                    {t("slotSelection.newPsw.infoTxt")}
                    <br></br>
                    <Box
                       
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            required
                            id="purchase-psw"
                            label={t("slotSelection.newPsw.modal.label")}
                            sx={{ m: 1, width: "20%", minWidth: "300px" }}
                            type="password"
                            inputProps={{ minLength: 8 }}
                        />
                        <br></br>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            
                        >
                            {t("slotSelection.newPsw.modal.submit")}
                        </Button>
                    </Box>
                    
                    </div>
                )
                }
                
            
        </>
    )

}