import React, { useEffect, useState } from "react";
import "./club.css";
import {
  useQuery,
  useMutation,
  QueryClient,
  useQueryClient,
} from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../errorAlert";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { isValid } from "date-fns";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import ClubStatus from "./clubStatus";
import ClubLessonParticipants from "./clubLessonParticipants";
import { RewardList } from "../Util/rewardList";
import { TranslatedDatePicker } from "../Util/translatedDatePicker";
//import ClubLessonParticipants2 from "./clubLessonParticipants2";

import {
  getLessonsForGroup,
  createLesson,
  editLesson,
  deleteLesson,
} from "../Hooks/lessons";
import { getCodeDomainsByNames } from "../Hooks/codes";

import { useTranslation } from "react-i18next";
import { GeduSelect } from "../Util/geduSelect";
import { isAdmin } from "../Util/authHelper";

export default function ClubLessons(params) {
  const { t } = useTranslation();
  const [openEditLesson, setOpenEditLesson] = React.useState(false);
  const [openAddLesson, setOpenAddLesson] = React.useState(false);
  const [selectedLesson, setSelectedLesson] = React.useState(undefined);
  const [lessonStatusCodes, setLessonStatusCodes] = React.useState([]);
  const [lessonEntryStatusCodes, setLessonEntryStatusCodes] = React.useState(
    []
  );
  const [lessonDate, setLessonDate] = React.useState({});
  const [lessonTime, setLessonTime] = React.useState("15:00");
  const [lessonStatus, setLessonStatus] = React.useState("");
  const [invoicingStatus, setInvoicingStatus] = React.useState("");
  const [lessonNotes, setLessonNotes] = React.useState(undefined);
  const [defaultLessonStatus, setDefaultLessonStatus] = React.useState("");
  const [updatingLessons, setUpdatingLessons] = React.useState(new Set());

  const [cardData, setCardData] = useState([]);
  const groupId = params.group.id;
  const clubData = params.clubData;
  const clubStartTime = undefined;
  const queryClient = useQueryClient();
  const { data, error, isLoading, refetch } = useQuery(
    ["groupId", groupId],
    () => getLessonsForGroup(groupId)
  );
  const { mutate, errorCreateLesson } = useMutation(createLesson);
  const { mutate: mutateEditLesson } = useMutation(editLesson);
  const { mutate: mutateDeleteLesson } = useMutation(deleteLesson);

  const [assignmentCodes, setAssignmentCodes] = React.useState([]);
  const [rewardCodes, setRewardCodes] = React.useState([]);
  const [lessonRewards, setLessonRewards] = React.useState(undefined);
  const [lessonSubstitutions, setLessonSubstitutions] = React.useState([]);
  const [lessonInvoicingGedus, setLessonInvoicingGedus] = React.useState([]);
  const [lessonMuniInvoicing, setLessonMuniInvoicing] = React.useState([]);

  const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  // The names of the needed CodeDomains
  const codeDomainNames = [
    "LESSON_STATUS",
    "LESSON_ENTRY_STATUS",
    "GAMER_PROFILE_VARIABLE",
    "ASSIGNMENT_TYPE",
  ];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  const appUserRoles = params?.appAuthInfo?.appUserRoles ?? [];

  const prepareTime = (time) => {
    let returnValue = undefined;
    if (time) {
      const splitted = time.split(":");
      if (splitted.length > 1) returnValue = splitted[0] + ":" + splitted[1];
      else returnValue = time;
    }
    return returnValue;
  };

  function fmt(date, format = "YYYY-MM-DD hh:mm") {
    if (!date) return undefined;

    const pad2 = (n) => n.toString().padStart(2, "0");

    const map = {
      YYYY: date.getFullYear(),
      MM: pad2(date.getMonth() + 1),
      DD: pad2(date.getDate()),
      hh: pad2(date.getHours()),
      mm: pad2(date.getMinutes()),
      ss: pad2(date.getSeconds()),
    };

    return Object.entries(map).reduce(
      (prev, entry) => prev.replace(...entry),
      format
    );
  }

  useEffect(() => {
    const time = getClubStartTime();
    setTimeToLessonDate(time, new Date());
  }, []);

  useEffect(() => {
    // console.log("useEffect on ClubLessons " + groupId);
    if (data) {
      prepareData();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (dataCodeDomains) {
      createCodeDomains();
    }
    // eslint-disable-next-line
  }, [dataCodeDomains]);

  const prepareData = async () => {
    // console.log("prepareData for ClubLessons");

    console.log("LessonData >>>>>>>>>>");
    console.log(data);

    const lessonsForCards = [];
    for (const lesson of data) {
      const card = await createLessonCard(lesson);
      lessonsForCards.push(card);
    }
    updateLessonCount(lessonsForCards);
    const time = getClubStartTime();
    setLessonTime(time);
  };

  const setTimeToLessonDate = async (time, date) => {
    const newDate = date ? date : lessonDate;
    newDate.setHours(time.split(":")[0]);
    newDate.setMinutes(time.split(":")[1]);
    setLessonDate(newDate);
  };

  const getClubStartTime = () => {
    // get default time from clubData
    if (clubData?.meetings && clubData.meetings.length > 0) {
      const firstMeeting = clubData.meetings[0];
      return prepareTime(firstMeeting.startTime);
    }
    return "15:00"; // default if not meetings
  };

  const createLessonCard = async (lesson) => {
    if (!lesson) return undefined;

    const card = {
      id: lesson.id,
      notes: lesson.notes,
      status: lesson.status?.name,
      statusId: lesson.status.id,
      rewards: lesson.rewards.map((reward, i) => ({ ...reward, id: i })),
      lesson: lesson,
    };

    if (lesson.date) {
      const lessonDate = new Date(lesson.date);
      card.dateRaw = lessonDate;
      card.date = fmt(lessonDate, "DD.MM.YYYY");
      card.time = fmt(lessonDate, "hh:mm");
      card.weekday = weekdays[lessonDate.getDay()];
    }

    // handle invoicing information for all lessons (if exists)
    if (lesson.geduInvoicings && lesson.geduInvoicings.length > 0) {
      const invoicingGedus = [];
      for (const geduInvoicing of lesson.geduInvoicings) {
        if (geduInvoicing.geduUser) {
          invoicingGedus.push({
            userId: geduInvoicing.geduUser.id,
            firstName: geduInvoicing.geduUser.firstName,
            lastName: geduInvoicing.geduUser.lastName,
            type: geduInvoicing.type?.name,
            status: geduInvoicing.status?.name,
          });
        }
      }
      card.invoicingGedus = invoicingGedus;
    }

    if (lesson.muniInvoicings && lesson.muniInvoicings.length > 0) {
      const muniInvoicing = [];
      for (const muni of lesson.muniInvoicings) {
        if (muni.status) {
          muniInvoicing.push({
            status: muni.status.name,
          });
        }
      }
      card.muniInvoicings = muniInvoicing;
    }

    return card;
  };

  const updateLessonCount = (newCardList) => {
    if (newCardList) {
      setCardData(newCardList);
      if (params.onLessonCountChange)
        params.onLessonCountChange(newCardList.length);
    }
  };

  const createCodeDomains = () => {
    // console.log("DOMAINS:");
    // console.log(dataCodeDomains);

    let lessonStatusCodesRaw = [];
    let lessonEntryStatusCodesRaw = [];
    let assignmentCodesRaw = [];
    let rewardCodesRaw = [];
    let firstLessonStatus = undefined;

    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name === "LESSON_STATUS") {
        codeDomain.codes.forEach((code) => {
          lessonStatusCodesRaw.push(createCode(code));
          if (!firstLessonStatus) {
            firstLessonStatus = code.id;
          }
        });
      } else if (codeDomain.name === "LESSON_ENTRY_STATUS") {
        codeDomain.codes.forEach((code) => {
          lessonEntryStatusCodesRaw.push(createCode(code));
        });
      } else if (codeDomain.name === "GAMER_PROFILE_VARIABLE") {
        codeDomain.codes.forEach((code) => {
          rewardCodesRaw.push(createCode(code));
        });
      } else if (codeDomain.name === "ASSIGNMENT_TYPE") {
        codeDomain.codes.forEach((code) => {
          assignmentCodesRaw.push(createCode(code));
        });
      }
    });

    // console.log("rewardCodesRaw");
    // console.log(rewardCodesRaw);

    setDefaultLessonStatus(firstLessonStatus);
    setLessonStatus(firstLessonStatus);
    setLessonStatusCodes(lessonStatusCodesRaw);
    setLessonEntryStatusCodes(lessonEntryStatusCodesRaw);
    setRewardCodes(rewardCodesRaw);
    setAssignmentCodes(assignmentCodesRaw);
  };

  const createCode = (code) => {
    return {
      value: code.id,
      name: code.name,
      label: t("code." + code.name),
    };
  };

  const handleEditLessonOpen = (selected) => {
    clearLessonValues();
    if (selected) {
      setSelectedLesson(selected);
      setLessonDate(selected.dateRaw);
      setLessonTime(fmt(selected.dateRaw, "hh:mm"));
      setLessonNotes(selected.notes);
      setLessonStatus(selected.statusId);
      setLessonRewards(selected.rewards);
      setLessonSubstitutions(selected.substitutions);
      setLessonInvoicingGedus(selected.invoicingGedus);
      setLessonMuniInvoicing(selected.muniInvoicings);
    }
    setOpenEditLesson(true);
  };

  const handleRemoveLesson = (selected, index) => {
    console.log(selected);
    if (selected) {
      if (
        window.confirm(
          t("clubLessons.removeLessonConfirmText") +
            "\n\n" +
            t("weekday." + selected.weekday) +
            " " +
            selected.date +
            " " +
            selected.time +
            "?"
        )
      ) {
        mutateDeleteLesson(selected.id, {
          onSuccess: (e) => {
            queryClient.cancelQueries({ queryKey: ["groupId", groupId] });
            queryClient.invalidateQueries({ queryKey: ["groupId", groupId] });
            // filter removed card out of card data
            const newList = cardData.filter(function (c) {
              return c.id !== selected.id;
            });

            updateLessonCount(newList);
          },
          onError: (error) => {
            console.log("ERROR");
          },
        });
      }
    }
  };

  const clearLessonValues = () => {
    setSelectedLesson(undefined);
    const time = getClubStartTime();
    setLessonTime(time);
    setTimeToLessonDate(time, new Date());
    setLessonNotes(undefined);
    setLessonStatus(defaultLessonStatus);
    setLessonRewards(undefined);
    setLessonSubstitutions([]);
    setLessonInvoicingGedus([]);
    setLessonMuniInvoicing([]);
  };

  const markLessonUpdating = (id) => {
    setUpdatingLessons((oldUpdatingLessons) => {
      const newUpdatingLessons = new Set(oldUpdatingLessons);
      newUpdatingLessons.add(id);
      return newUpdatingLessons;
    });
  };

  const markLessonNotUpdating = (id) => {
    setUpdatingLessons((oldUpdatingLessons) => {
      const newUpdatingLessons = new Set(oldUpdatingLessons);
      newUpdatingLessons.delete(id);
      return newUpdatingLessons;
    });
  };

  const handleEditLessonClose = () => {
    setOpenEditLesson(false);
  };

  const handleAddLessonOpen = () => {
    clearLessonValues();
    setOpenAddLesson(true);
  };

  const handleAddLessonClose = () => {
    setOpenAddLesson(false);
  };

  const createLessonToDatabase = async (event) => {
    event.preventDefault();
    console.log("Create Lesson " + lessonDate + " " + lessonStatus);

    mutate(
      {
        date: lessonDate,
        notes: lessonNotes,
        status: lessonStatus,
        rewards: lessonRewards,
        group: groupId,
      },
      {
        onSuccess: (e) => {
          queryClient.cancelQueries({ queryKey: ["groupId", groupId] });
          queryClient.invalidateQueries({ queryKey: ["groupId", groupId] });
          console.log(e.data.lesson);

          createLessonCard(e.data.lesson).then((card) => {
            // sort cards
            const copy = [...cardData, card];
            copy.sort(function compareFn(a, b) {
              return a.dateRaw - b.dateRaw;
            });

            console.log("NEW LESSON", card);

            updateLessonCount(copy);
            //clearLessonValues();
          });
        },
      }
    );

    setOpenAddLesson(false);
  };

  const markLessonUndone = async (id, groupId) => {
    markLessonUpdating(id);
    const statusUndone = lessonStatusCodes.find(
      (code) => code.name === "LESSON_STATUS_UNDONE"
    ).value;
    const data = {
      id,
      fields: {
        group: groupId,
        status: statusUndone,
      },
    };

    mutateEditLesson(
      data,
      {
        onSuccess: (e) => {
          queryClient.cancelQueries({ queryKey: ["groupId", groupId] });
          queryClient.invalidateQueries({ queryKey: ["groupId", groupId] });
          createLessonCard(e.data.lesson).then((card) => {
            // find original card
            const found = cardData.find(function (c) {
              return c.id === id;
            });

            console.log("FOUND");
            console.log(found);

            if (found) {
              // update its data
              found.date = card.date;
              found.dateRaw = card.dateRaw;
              found.notes = card.notes;
              found.status = card.status;
              found.statusId = card.statusId;
              found.time = card.time;
              found.weekday = card.weekday;
              found.rewards = card.rewards.map((reward, i) => ({
                id: i,
                code: reward.code,
                amount: reward.amount,
              }));

              // setCardData with new list to refresh the view
              const copy = [...cardData];
              copy.sort(function compareFn(a, b) {
                return a.dateRaw - b.dateRaw;
              });
              //const test = [found];
              updateLessonCount(copy);
              markLessonNotUpdating(id);
            }
          });
        },
      },
      {
        onError: (e) => {
          console.log("ERROR ON updateLesson");
        },
      }
    );
  };

  const updateLessonToDatabase = async (event, lesson) => {
    event.preventDefault();
    markLessonUpdating(lesson.id);

    const data = {
      id: lesson.id,
      fields: {
        group: groupId,
        // rewards: [],
      },
    };

    if (lessonDate) data.fields.date = lessonDate;
    if (lessonNotes) data.fields.notes = lessonNotes;
    if (lessonStatus) data.fields.status = lessonStatus;
    if (lessonRewards)
      data.fields.rewards = lessonRewards
        .map((reward) => ({ code: reward.code, amount: reward.amount }))
        .filter((reward) => reward.code !== "");

    if (lesson) {
      mutateEditLesson(
        data,
        {
          onSuccess: (e) => {
            queryClient.cancelQueries({ queryKey: ["groupId", groupId] });
            queryClient.invalidateQueries({ queryKey: ["groupId", groupId] });
            console.log(e);
            console.log("HERERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
            createLessonCard(e.data.lesson).then((card) => {
              markLessonNotUpdating(lesson.id);
              // find original card
              console.log(lesson.id);
              const found = cardData.find(function (c) {
                return c.id === lesson.id;
              });

              console.log("FOUND");
              console.log(found);

              console.log(card.dateRaw);

              if (found) {
                // update its data
                found.date = card.date;
                found.dateRaw = card.dateRaw;
                found.notes = card.notes;
                found.status = card.status;
                found.statusId = card.statusId;
                found.time = card.time;
                found.weekday = card.weekday;
                found.rewards = card.rewards.map((reward, i) => ({
                  id: i,
                  code: reward.code,
                  amount: reward.amount,
                }));

                // setCardData with new list to refresh the view
                const copy = [...cardData];
                copy.sort(function compareFn(a, b) {
                  return a.dateRaw - b.dateRaw;
                });
                //const test = [found];
                updateLessonCount(copy);
              }
            });
          },
        },
        {
          onError: (e) => {
            console.log("ERROR ON updateLesson");
          },
        }
      );
    } else {
      console.log("No selected lesson to edit");
    }

    //clearLessonValues();
    setOpenEditLesson(false);
  };

  const updateLessonSubstitutions = async (newSubstitution) => {
    // event.preventDefault();
    // markLessonUpdating(lesson.id);

    console.log("updateLessonSubstitutions");

    const data = {
      id: selectedLesson.id,
      fields: newSubstitution,
    };

    mutateEditLesson(
      data,
      {
        onSuccess: (e) => {
          queryClient.cancelQueries({ queryKey: ["groupId", groupId] });
          queryClient.invalidateQueries({ queryKey: ["groupId", groupId] });
          console.log(e);
          createLessonCard(e.data.lesson).then((card) => {
            // markLessonNotUpdating(e.data.lesson.id);
            // find original card
            console.log(e.data.lesson.id);
            const found = cardData.find(function (c) {
              return c.id === e.data.lesson.id;
            });

            console.log("FOUND2");
            console.log(found);

            console.log("CARD2");
            console.log(card.dateRaw);
            console.log(card);

            if (found) {
              // update its data
              found.lesson = card.lesson;
              found.date = card.date;
              found.dateRaw = card.dateRaw;
              found.notes = card.notes;
              found.status = card.status;
              found.statusId = card.statusId;
              found.time = card.time;
              found.weekday = card.weekday;
              found.rewards = card.rewards.map((reward, i) => ({
                id: i,
                code: reward.code,
                amount: reward.amount,
              }));

              setSelectedLesson(found);

              // setCardData with new list to refresh the view
              const copy = [...cardData];
              copy.sort(function compareFn(a, b) {
                return a.dateRaw - b.dateRaw;
              });

              //const test = [found];
              updateLessonCount(copy);
            }
          });
        },
      },
      {
        onError: (e) => {
          console.log("ERROR ON updateLesson");
        },
      }
    );

    // clearLessonValues();
    // setOpenEditLesson(false);
  };

  const setSelectedLessonStatus = async (e) => {
    setLessonStatus(e.target.value);
  };

  const setSelectedLessonNotes = async (e) => {
    setLessonNotes(e.target.value);
  };

  const assignmentTypeGroupGedu = assignmentCodes.find(
    (code) => code.name === "ASSIGNMENT_TYPE_GROUP_MAIN_GEDU"
  );
  const assignmentTypeAssistantGedu = assignmentCodes.find(
    (code) => code.name === "ASSIGNMENT_TYPE_GROUP_ASSISTANT_GEDU"
  );
  const geduSubstitution = (selectedLesson?.lesson?.substitutions ?? []).find(
    (subst) => subst.type === assignmentTypeGroupGedu?.value
  );
  const assistantGeduSubstitution = (
    selectedLesson?.lesson?.substitutions ?? []
  ).find((subst) => subst.type === assignmentTypeAssistantGedu?.value);

  const getGroupMainGedu = () => {
    let mainGedu = (params.group?.assignments ?? []).find(
      (assignment) => assignment.type === assignmentTypeGroupGedu?.value
    )?.user;

    if (!mainGedu) {
      // setLessonInvoicingGedus the owner of the club to main gedu
      mainGedu = clubData.owner;
      if (mainGedu)
        // exists, setLessonInvoicingGedus fromOwner flag to true
        mainGedu.fromOwner = true;
    }
    return mainGedu;
  };

  const groupMainGedu = getGroupMainGedu();

  const groupAssistantGedu = (params.group?.assignments ?? []).find(
    (assignment) => assignment.type === assignmentTypeAssistantGedu?.value
  )?.user;

  const maxReportLength = 1000;

  return (
    <Box sx={{ textAlign: "left" }}>
      {error && <ErrorAlert txt={error} />}
      {errorCreateLesson && <ErrorAlert txt={errorCreateLesson} />}
      {/* Add modal */}
      <Dialog
        fullWidth={true}
        open={openAddLesson}
        onClose={() => handleAddLessonClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock
      >
        <DialogTitle>{t("clubLessons.createNewLessonTitle")}</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => createLessonToDatabase(e)}>
            <Typography sx={{ fontSize: 14 }} color="text.primary">
              <label>{t("clubLessons.selectDate")}</label>
            </Typography>
            <TranslatedDatePicker
              value={lessonDate}
              onChange={(newValue) => setLessonDate(newValue)}
            />
            <br />
            <br />
            <Typography sx={{ fontSize: 14 }} color="text.primary">
              <label>{t("clubLessons.selectHour")}</label>
            </Typography>
            <Input
              type="time"
              className="hour-select"
              onChange={(e) => {
                let newDate = new Date(lessonDate);
                newDate.setHours(e.target.value.split(":")[0]);
                newDate.setMinutes(e.target.value.split(":")[1]);
                setLessonDate(newDate);
              }}
              defaultValue={lessonTime ? lessonTime : ""}
            />
            <FormControl fullWidth sx={{ marginTop: "2em" }}>
              <InputLabel id="lesson-status-select-label">
                {t("clubLessons.selectLessonStatus")}
              </InputLabel>
              <Select
                labelId="lesson-status-select-label"
                id="lesson-status-select"
                value={lessonStatus}
                label={t("clubLessons.selectLessonStatus")}
                onChange={setSelectedLessonStatus}
              >
                {lessonStatusCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {t("code." + code.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              sx={{ marginTop: "2em", marginBottom: "2em" }}
            >
              <InputLabel id="lesson-notes-label">
                {t("clubLessons.notesLabel")}
              </InputLabel>
              <OutlinedInput
                type="textarea"
                id="lesson-notes"
                defaultValue={lessonNotes}
                label={t("clubLessons.notesLabel")}
                onChange={setSelectedLessonNotes}
                multiline
                minRows={3}
                helperText={lessonNotes?.length ?? 0}
                error={(lessonNotes?.length ?? 0) > maxReportLength}
              >
                {lessonStatusCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {t("code." + code.name)}
                  </MenuItem>
                ))}
              </OutlinedInput>
              <FormHelperText variant="outlined">
                {lessonNotes?.length ?? 0} / {maxReportLength}
              </FormHelperText>
            </FormControl>
            {/* <RewardList rewards={lessonRewards ?? []} setRewards={setLessonRewards}/> */}
            <br />
            <Button
              onClick={() => handleAddLessonClose()}
              variant="outlined"
              style={{ marginRight: "0.5em" }}
            >
              {t("general.dialog.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{ marginRight: "0.5em" }}
              disabled={
                !isValid(lessonDate) ||
                (lessonNotes?.length ?? 0) > maxReportLength ||
                (lessonRewards ?? []).some((row) => row.code === "")
              }
            >
              {t("clubLessons.create")}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      {/* Edit modal */}
      <Dialog
        fullWidth={true}
        open={openEditLesson}
        onClose={() => handleEditLessonClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableScrollLock
      >
        <DialogTitle>{t("clubLessons.editLessonTitle")}</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => updateLessonToDatabase(e, selectedLesson)}>
            {(groupMainGedu || geduSubstitution) &&
              (geduSubstitution ? (
                <Typography sx={{ m: 1 }}>
                  {t("clubLessons.substituteGedu")}:{" "}
                  {`${geduSubstitution?.geduUser?.firstName ?? ""} ${
                    geduSubstitution?.geduUser?.lastName ?? ""
                  }`}
                </Typography>
              ) : (
                <Typography sx={{ m: 1 }}>
                  {t("clubLessons.gedu")}:{" "}
                  {`${groupMainGedu?.firstName ?? ""} ${
                    groupMainGedu?.lastName ?? ""
                  }`}{" "}
                  {groupMainGedu?.fromOwner
                    ? t("clubView.groupInfo.fromOwner")
                    : ""}
                </Typography>
              ))}
            {isAdmin(appUserRoles) && (
              <GeduSelect
                label={t("clubLessons.chooseSubstituteGedu")}
                defaultGedu={undefined}
                saveSelectedGedu={async (newSubstitution) => {
                  await updateLessonSubstitutions({
                    geduSubstitution: newSubstitution.id,
                  });
                }}
              />
            )}
            {isAdmin(appUserRoles) && geduSubstitution && (
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={async () => {
                  await updateLessonSubstitutions({ geduSubstitution: null });
                }}
              >
                {t("clubLessons.removeSubstituteGedu")}
              </Button>
            )}
            {(groupAssistantGedu || assistantGeduSubstitution) &&
              (assistantGeduSubstitution ? (
                <Typography sx={{ m: 1 }}>
                  {t("clubLessons.substituteAssistantGedu")}:{" "}
                  {`${assistantGeduSubstitution?.geduUser?.firstName ?? ""} ${
                    assistantGeduSubstitution?.geduUser?.lastName ?? ""
                  }`}
                </Typography>
              ) : (
                <Typography sx={{ m: 1 }}>
                  {t("clubLessons.assistantGedu")}:{" "}
                  {`${groupAssistantGedu?.firstName ?? ""} ${
                    groupAssistantGedu?.lastName ?? ""
                  }`}
                </Typography>
              ))}
            {isAdmin(appUserRoles) && (
              <GeduSelect
                label={t("clubLessons.chooseSubstituteAssistant")}
                defaultGedu={undefined}
                saveSelectedGedu={async (newSubstitution) => {
                  await updateLessonSubstitutions({
                    assistantGeduSubstitution: newSubstitution.id,
                  });
                }}
              />
            )}
            {isAdmin(appUserRoles) && assistantGeduSubstitution && (
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={async () => {
                  await updateLessonSubstitutions({
                    assistantGeduSubstitution: null,
                  });
                }}
              >
                {t("clubLessons.removeSubstituteAssistant")}
              </Button>
            )}
            <Typography sx={{ fontSize: 14 }} color="text.primary">
              <label>{t("clubLessons.selectDate")}</label>
            </Typography>
            <TranslatedDatePicker
              value={lessonDate}
              onChange={(newValue) => setLessonDate(newValue)}
            />
            <br />
            <br />
            <Typography sx={{ fontSize: 14 }} color="text.primary">
              <label>{t("clubLessons.selectHour")}</label>
            </Typography>
            <Input
              type="time"
              className="hour-select"
              onChange={(e) => {
                let newDate = new Date(lessonDate);
                newDate.setHours(e.target.value.split(":")[0]);
                newDate.setMinutes(e.target.value.split(":")[1]);
                setLessonDate(newDate);
              }}
              defaultValue={lessonTime ? lessonTime : ""}
            />
            <FormControl fullWidth sx={{ marginTop: "2em" }}>
              <InputLabel id="lesson-status-select-label2">
                {t("clubLessons.selectLessonStatus")}
              </InputLabel>
              <Select
                labelId="lesson-status-select-label2"
                id="lesson-status-select-edit"
                value={lessonStatus}
                label={t("clubLessons.selectLessonStatus")}
                onChange={setSelectedLessonStatus}
              >
                {lessonStatusCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {t("code." + code.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {lessonInvoicingGedus &&
              lessonInvoicingGedus.map((gedu) => (
                <Typography
                  key={gedu.type + gedu.userId}
                  sx={{ marginTop: "0.5em", fontSize: 12 }}
                  color="text.secondary"
                >
                  {gedu.firstName} {gedu.lastName} ({t("code." + gedu.type)}/
                  {t("code." + gedu.status)})
                </Typography>
              ))}

            {lessonMuniInvoicing &&
              lessonMuniInvoicing.map((muni, i) => (
                <Typography
                  key={i}
                  sx={{ marginTop: "0.5em", fontSize: 12 }}
                  color="text.secondary"
                >
                  {t("clubLessons.muniInvoicingStatus")}:
                  {t("code." + muni.status)}
                </Typography>
              ))}

            <FormControl
              fullWidth
              sx={{ marginTop: "2em", marginBottom: "2em" }}
            >
              <InputLabel id="lesson-notes-label">
                {t("clubLessons.notesLabel")}
              </InputLabel>
              <OutlinedInput
                type="textarea"
                id="lesson-notes"
                defaultValue={lessonNotes}
                label={t("clubLessons.notesLabel")}
                onChange={setSelectedLessonNotes}
                multiline
                minRows={3}
                error={(lessonNotes?.length ?? 0) > maxReportLength}
              >
                {lessonStatusCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {t("code." + code.name)}
                  </MenuItem>
                ))}
              </OutlinedInput>
              <FormHelperText variant="outlined">
                {lessonNotes?.length ?? 0} / {maxReportLength}
              </FormHelperText>
            </FormControl>
            {/* <RewardList rewards={lessonRewards ?? []} setRewards={setLessonRewards}/> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={(lessonRewards ?? []).length != 0}
                  onChange={(e) => {
                    const goodBehaviorCode = rewardCodes.find(
                      (code) =>
                        code.name === "GAMER_PROFILE_VARIABLE_GOOD_BEHAVIOR"
                    )?.value;
                    if (e.target.checked && goodBehaviorCode) {
                      setLessonRewards([
                        { code: goodBehaviorCode, amount: 1, id: 0 },
                      ]);
                    } else {
                      setLessonRewards([]);
                    }
                  }}
                />
              }
              label={t("clubLessons.lessonRewardGoodBehavior")}
            />
            <br />
            <br />
            <Button
              onClick={() => handleEditLessonClose()}
              variant="outlined"
              style={{ marginRight: "0.5em" }}
            >
              {t("general.dialog.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{ marginRight: "0.5em" }}
              disabled={
                !isValid(lessonDate) ||
                (lessonNotes?.length ?? 0) > maxReportLength ||
                (lessonRewards ?? []).some((row) => row.code === "")
              }
            >
              {t("clubLessons.update")}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Card
        key="addNewCard"
        sx={{
          display: "inline-block",
          mx: "0.5em",
          my: "0.5em",
          // minWidth: 330,
          // maxWidth: 500,
          width: 360,
          textAlign: "left",
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t("clubLessons.addNewLessonCardHeader")}
          </Typography>
        </CardContent>
        <CardActions sx={{ alignContent: "center" }}>
          {isLoadingCodeDomains && <CircularProgress />}
          {!isLoadingCodeDomains && (
            <Button size="small" onClick={handleAddLessonOpen}>
              <AddCircleIcon className="add-lesson-icon" />
            </Button>
          )}
        </CardActions>
      </Card>
      {isLoading && <CircularProgress />}
      {cardData &&
        cardData.map((card, index) => (
          <Card
            sx={{
              display: "inline-block",
              mx: "0.5em",
              my: "0.5em",
              // minWidth: 330,
              // maxWidth: 500,
              width: 360,
              textAlign: "left",
            }}
            key={"lessonCard" + index}
          >
            <CardContent key={"contentCard" + index}>
              {card.weekday && (
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {t("weekday." + card.weekday)}
                </Typography>
              )}
              <Typography sx={{ fontSize: 20 }} color="text.primary">
                {card.date}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.primary">
                {card.time}
              </Typography>
              <ClubStatus
                minWidth="280px"
                key={"status" + index + card.status}
                status={card.status}
              />
              <Typography
                sx={{ marginTop: "0.5em", fontSize: 12 }}
                color="text.primary"
              >
                {card.notes}
              </Typography>

              {card.invoicingGedus &&
                card.invoicingGedus.map((gedu) => (
                  <Typography
                    key={gedu.type + gedu.userId}
                    sx={{ marginTop: "0.5em", fontSize: 12 }}
                    color="text.secondary"
                  >
                    {gedu.firstName} {gedu.lastName} ({t("code." + gedu.type)}/
                    {t("code." + gedu.status)})
                  </Typography>
                ))}
              {card.muniInvoicings &&
                card.muniInvoicings.map((muni, i) => (
                  <Typography
                    key={i}
                    sx={{ marginTop: "0.5em", fontSize: 12 }}
                    color="text.secondary"
                  >
                    {t("clubLessons.muniInvoicingStatus")}:
                    {t("code." + muni.status)}
                  </Typography>
                ))}

              {/* {rewardCodes &&
                card.rewards.map((reward) => (
                  <Typography
                    sx={{ marginTop: "0.5em", fontSize: 12 }}
                    color="text.primary"
                    key={reward.id}
                  >
                    {t(
                      `code.${
                        rewardCodes.find((code) => reward.code === code.value)?
                          .name
                      }`
                    )}
                    : {reward.amount > 0 ? "+" : ""}
                    {reward.amount}
                  </Typography>
                ))} */}
            </CardContent>
            <CardActions>
              <ClubLessonParticipants
                appUserRoles={appUserRoles}
                setSaving={() => markLessonUpdating(card.lesson.id)}
                setNotSaving={() => markLessonNotUpdating(card.lesson.id)}
                lessonData={card.lesson}
                updateGeneration={params.updateGeneration}
                group={params.group}
                clubData={params.clubData}
                minParticipantCount={clubData.metadata?.minParticipantCount}
                codes={lessonEntryStatusCodes}
                locked={
                  card.status === "LESSON_STATUS_DONE" ||
                  updatingLessons.has(card.id)
                }
                status={card.status}
                parentUpdateLessonEntries={(newEntries) => {
                  console.log("CUR:", card.lesson.lessonEntries);
                  console.log("NEW:", newEntries);
                  const newLessonEntries = newEntries.map((newEntry) => {
                    const curEntry = card.lesson.lessonEntries.find(
                      (oldEntry) =>
                        newEntry.id === oldEntry.participation?.gamer?.id
                    );
                    if (curEntry) {
                      return {
                        ...curEntry,
                        status: newEntry.status,
                        rewards: newEntry.rewards,
                      };
                    }
                    return {
                      id: newEntry.participation.id,
                      participation: newEntry.participation,
                      user: { id: newEntry.id },
                      status: newEntry.status,
                      rewards: newEntry.rewards,
                    };
                  });

                  console.log("UPDATED:", newLessonEntries);
                  setCardData(
                    cardData.map((c) =>
                      c.id === card.id
                        ? {
                            ...card,
                            lesson: {
                              ...card.lesson,
                              lessonEntries: newLessonEntries,
                            },
                          }
                        : c
                    )
                  );
                }}
              />
              <Button
                size="small"
                className="lessonEditButton"
                onClick={async () => {
                  if (card.status === "LESSON_STATUS_DONE") {
                    await markLessonUndone(card.id, card.groupId);
                  } else if (!updatingLessons.has(card.id)) {
                    handleEditLessonOpen(card);
                  }
                }}
              >
                {updatingLessons.has(card.id) ? (
                  <CircularProgress size={20} />
                ) : card.status === "LESSON_STATUS_DONE" ||
                  updatingLessons.has(card.id) ? (
                  <LockOpenIcon />
                ) : (
                  <EditIcon />
                )}
              </Button>
              <Button
                size="small"
                className="lessonRemoveButton"
                disabled={
                  card.status === "LESSON_STATUS_DONE" ||
                  updatingLessons.has(card.id)
                }
                onClick={() => {
                  handleRemoveLesson(card, index);
                }}
              >
                <DeleteIcon />
              </Button>
            </CardActions>
          </Card>
        ))}
    </Box>
  );
}
