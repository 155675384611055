import React, { useEffect, useState } from "react";
import "./entity.css";
import { useMutation, useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import ContainerBox from "../../Container";
import { getEntities, addChildEntity, deleteEntity } from "../../Hooks/entity";
import ErrorAlert from "../../errorAlert";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import AddIcon from "@mui/icons-material/Add";
import { Button, Modal, TextField } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function Entities() {
  const { data, error, isLoading, refetch } = useQuery(["entity"], getEntities);
  const { mutate } = useMutation(addChildEntity);
  const { mutate: deleteMutate } = useMutation(deleteEntity);
  const [parent, setParent] = useState("");
  const [excludedEntities, setExcludedEntities] = useState([]);
  const [entities, setEntities] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (data) {
      console.log(data);
      setEntities(data.entities);
      data.entities.map((entity) => prepareData(entity));
    }
    // eslint-disable-next-line
  }, [data]);

  const prepareData = (entity) => {
    if (entities.length > 0 && entity.name === entities[0].name)
      setExcludedEntities([]);

    if (entity.parent?.name) {
      if (!excludedEntities.includes(entity.parent.id))
        setExcludedEntities((oldArray) => [...oldArray, entity.parent.id]);
      prepareData(entity.parent);
    }
  };

  const treeArm = (entity, i) => {
    if (entity.name == "test child ") console.log(entity);
    return (
      <TreeItem
        key={i}
        nodeId={`${entity.id}`}
        label={`${entity.name}`}
        endIcon={
          <div>
            <AddIcon
              onClick={() => {
                console.log(entity.id);
                setParent(entity.id);
                handleOpen();
              }}
            />
            <DeleteForeverIcon
              onClick={() => {
                deleteEntityFunc(entity.id);
              }}
            />
          </div>
        }
      >
        {
          //Check if the entity has childrens
          entity.parent?.name && treeArm(entity.parent, (i += 100))
        }
      </TreeItem>
    );
  };

  const deleteEntityFunc = (id) => {
    console.log(id);
    deleteMutate(id, {
      onSuccess: (e) => {
        console.log(e);
        refetch();
      },
    });
  };
  const createNewEntity = (e) => {
    e.preventDefault();

    mutate(
      {
        name: document.getElementById("name-entity").value,
        contact: document.getElementById("contact-entity").value,
        type: document.getElementById("type-entity").value,
        parent: parent,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          refetch();
        },
      }
    );
    console.log(parent);
  };
  return (
    <ContainerBox>
      {/* Accordion */}
      {error && <ErrorAlert txt={error} />}
      {isLoading && <CircularProgress />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={createNewEntity}>
          <TextField
            required
            id="name-entity"
            label="Name"
            sx={{ m: 1, width: "100%" }}
          />
          <TextField
            required
            id="type-entity"
            label="Type"
            sx={{ m: 1, width: "100%" }}
          />
          <TextField
            required
            id="contact-entity"
            label="Contact"
            sx={{ m: 1, width: "100%" }}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ left: "30%", marginTop: "1rem" }}
            type="submit"
          >
            Create Entity{" "}
          </Button>
        </Box>
      </Modal>
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{ height: 40, mr: 1, mb: 5 }}
        >
          Add New Entity
        </Button>
      </Box>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ height: "100%", flexGrow: 1, maxWidth: "30%", overflowY: "auto" }}
      >
        {entities.length > 0 &&
          entities.map(
            (entity, i) =>
              !excludedEntities.includes(entity.id) && treeArm(entity, i)
          )}
      </TreeView>
    </ContainerBox>
  );
}
