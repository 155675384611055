import axios from "axios";
import { API } from "./API";

export const getRegistrationMunicipalities = async (country) => {
  const { data } = await axios.get(
    `${API}/registration/municipalities?country=${country}`
  );
  return data.data;
};

export const getRegistrationSchools = async (municipality) => {
  const { data } = await axios.get(
    `${API}/registration/schools?municipality=${municipality}`
  );
  return data.data;
};

export const getRegistrationClubsForMunicipality = async (municipality) => {
  const { data } = await axios.get(
    `${API}/registration/clubs?municipality=${municipality}`
  );
  return data.data;
};

export const getRegistrationClubsForSchool = async (school) => {
  const { data } = await axios.get(
    `${API}/registration/clubs?school=${school}`
  );
  return data.data;
};

export const addParticipation = async (body) => {
  const { data } = await axios.post(
    `${API}/registration/addParticipation`,
    body
  );

  return data;
};
