import React from "react";
import { TextField, MenuItem } from "@mui/material";

const CustomFieldSelect = ({ customField, selectedValue, onChange }) => {
  const { id, label, values, isRequired } = customField;

  return (
    <TextField
      id={id}
      select
      label={label}
      value={selectedValue ? selectedValue : ""}
      onChange={(e) => onChange(id, e.target.value)}
      required={isRequired} // Set the 'required' attribute based on the 'isRequired' flag
      fullWidth
    >
      {values.map((value) => (
        <MenuItem key={customField.id + value.id} value={value.id}>
          {value.text}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomFieldSelect;
