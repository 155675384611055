import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { fi, enGB, es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import i18n from "i18next";

const datePickerLocales = {
  fi: fi,
  en: enGB,
  es: es,
};

export const TranslatedDatePicker = ({ label, onChange, value }) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={datePickerLocales[i18n.language.slice(0, 2)] ?? enGB}
  >
    <DatePicker label={label} value={value} onChange={onChange} />
  </LocalizationProvider>
);
