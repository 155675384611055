import axios from "axios";
import { API } from "./API";

export const getGedus = async () => {
  const { data } = await axios.get(`${API}/gedus`, { withCredentials: true });
  return data.data;
};

export const setFilteringData = async (input) => {
  const userId = input.id;
  const body = input.body;

  const { data } = await axios.post(
    `${API}/gedus/${userId}/setfilteringdata`,
    body,
    {
      withCredentials: true,
    }
  );
  return data.data;
};
