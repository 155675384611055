import React from 'react';

import {ReactComponent as TheLogo} from '../Static/img/logo_black.svg';
import './arena_logo.css';

export default function ArenaLogo() {
    
  return (  
    <div className="arena-logo"> 
      <TheLogo />
      <div className="arena-logo-text">Gamers' Arena</div>
    </div>
    
  );
}