import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { generateActivity } from "../Hooks/activities";

import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../errorAlert";
import ContainerBox from "../Container";
import { Button, Box } from "@mui/material";
import "./activityGenerator.css";


export default function CarouselComponent() {
    const { data, error, isLoading } = useQuery(["activities"], generateActivity);
    const [showActivity, setShowActivity] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false);
    const [slideSpeed, setSlideSpeed] = useState(50);
    const stopTimeout = useRef(null);
    const slideInterval = useRef(null);

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            setShowActivity(true);
        }
    }, [isLoading, data]);

    const startGenerator = () => {
        setIsSliding(true);
        clearInterval(slideInterval.current); // Clear any existing interval
        setCurrentIndex(0); // Reset currentIndex to start from the beginning

        slideInterval.current = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % (data ? data.length : 1));
        }, slideSpeed);

        clearTimeout(stopTimeout.current);
        stopTimeout.current = setTimeout(() => {
            setIsSliding(false);
            clearInterval(slideInterval.current); // Clear the interval after 5 seconds
            stopSliding();
        }, 6000);
    };

    const stopSliding = () => {
        const randomIndex = Math.floor(Math.random() * (data ? data.length : 1));
        setCurrentIndex(randomIndex);
    }

    return (
        <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: "100%",
              height: "100vh",
            }}
        >
            <div className="background">
                <div className="carousel">
                    {error && <ErrorAlert txt={error} />}
                    {isLoading ? (
                        <CircularProgress />
                    ) : showActivity ? (
                        <div className="carousel-frame">
                            <div className="activity" style={{ /*transform: `translateX(-${currentIndex * 400}px)`*/ }}>
                                <h2>{data[currentIndex].name}</h2>
                                <p>{data[currentIndex].description}</p>
                                {data[currentIndex].image && (<img className="activity-generator-image" src={data[currentIndex].image} alt={data[currentIndex].name} />)}
                            </div>
                        </div>
                    ) : null}
                    <div className="button">
                        <Button onClick={startGenerator} color="secondary" variant="contained">
                            Generate Random Activity
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    );
};
