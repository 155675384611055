import React, { useEffect } from "react";
import "./clubCalendar.css";
import { DataGrid } from "@mui/x-data-grid";
import OpenIcon from "@mui/icons-material/OpenInNew";

import { prepareClubName } from "../Util/clubHelper";

import { useTranslation } from "react-i18next";

export default function ClubCalendarTable(props) {
  const { t } = useTranslation();
  const [width, setWidth] = React.useState(window.innerWidth);

  const [sortModel, setSortModel] = React.useState([
    {
      field: "clubTime",
      sort: "asc",
    },
  ]);

  const [clubs, setClubs] = React.useState([]);

  const columns = [
    {
      field: "id",
      flex: 1,
      minWidth: 35,
      maxWidth: 40,
      renderCell: (params) => {
        return <OpenIcon size="14" onClick={() => openClub(params.row)} />;
      },
    },
    {
      field: "clubTime",
      headerName: t("clubCalendar.tableHeader_Time"),
      flex: 1,
      minWidth: 70,
      maxWidth: 150,
      hide: width < 600 ? true : false, // this is only hidden from vertical phones
    },
    // {
    //   field: "sku",
    //   headerName: t("clubCalendar.tableHeader_SKU"),
    //   flex: 1,
    //   minWidth: 100,
    //   hide: width < 850 ? true : false,
    // },
    {
      field: "name",
      headerName: t("clubCalendar.tableHeader_Name"),
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return params.row.name;
      },
    },
    {
      field: "owner",
      headerName: t("clubCalendar.tableHeader_Owner"),
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      hide: width < 850 ? true : false,
    },
    // {
    //   field: "language",
    //   headerName: t("clubCalendar.tableHeader_Language"),
    //   flex: 1,
    //   minWidth: 50,
    //   maxWidth: 50,
    //   hide: width < 850 ? true : false,
    // },
    {
      field: "type",
      headerName: t("clubCalendar.tableHeader_clubtype"),
      flex: 1,
      minWidth: 50,
      maxWidth: 75,
      hide: width < 850 ? true : false,
      renderCell: (params) => {
        return params.row.type ? t("code." + params.row.type) : "";
      },
    },
    {
      field: "status",
      headerName: t("clubCalendar.tableHeader_Status"),
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      hide: width < 850 ? true : false,
      renderCell: (params) => {
        return params.row.status ? t("code." + params.row.status) : "";
      },
    },
    {
      field: "attendance",
      headerName: t("clubCalendar.tableHeader_Attendance"),
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      hide: width < 850 ? true : false,
      renderCell: (params) => {
        return params.row.attendance ? t("code." + params.row.attendance) : "";
      },
    },
    {
      field: "contentType",
      headerName: t("clubCalendar.tableHeader_clubcontenttype"),
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      hide: width < 600 ? true : false, // this is only hidden from vertical phones
      renderCell: (params) => {
        return params.row.contentType
          ? t("code." + params.row.contentType)
          : "";
      },
    },
    {
      field: "participantCount",
      headerName: t("clubCalendar.tableHeader_clubparticipantCount"),
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      hide: width < 600 ? true : false, // this is only hidden from vertical phones
      renderCell: (params) =>
        params.row.maxParticipants !== null
          ? params.row.participantCount + " / " + params.row.maxParticipants
          : params.row.participantCount,
    },
  ];
  useEffect(() => {
    window.addEventListener(
      "resize",
      function (event) {
        //console.log(window.innerWidth);
        setWidth(window.innerWidth);
      },
      true
    );

    const newClubs = [];
    if (props && props.clubs) {
      //console.log("DAY: " + props.day);
      //console.log(props.clubs);

      props.clubs.map((club) => {
        if (club.meetings && club.meetings.length > 0) {
          club.meetings.map((meeting) => {
            if (!meeting.weekday || meeting.weekday === props.day) {
              // attendance: {id: '327500066040328192', name: 'CLUB_ATTENDANCE_REMOTE', domain: {…}}
              // country: "Finland"
              // id: "358518800661882880"
              // language: "fi"
              // meetings
              //     endTime: "18:30:00"
              //     id: "358518800905152512"
              //     startTime: "17:00:00"
              //     weekday: "mon"
              // name: "Maanantain Minecraft"
              // owner: {}
              // sku: "FI_MINE_MON_1"
              // status: {id: '332062158097492992', name: 'CLUB_STATUS_ONGOING', domain: {…}}
              // type: {id: '333214125901491200', name: 'CLUB_TYPE_CONSUMER', domain: {…}}

              const theClub = createClubEntry(club, meeting);

              newClubs.push(theClub);
            }
          });
        } else {
          const theClub = createClubEntry(club, undefined);
          newClubs.push(theClub);
        }
      });
    }

    //    console.log(newClubs);
    setClubs(newClubs);
  }, [props.clubs]);

  function createClubEntry(club, meeting) {
    let ownerName = "";
    if (club.owner) {
      if (club.owner?.lastName) {
        ownerName = club.owner.lastName;
      }
      if (club.owner?.firstName) {
        ownerName += ", " + club.owner.firstName;
      }
    }

    let startTime = "";
    let endTime = "";
    let clubTime = "-";
    if (meeting) {
      if (meeting.startTime) {
        const split = ("" + meeting.startTime).split(":");
        if (split.length > 1) startTime = split[0] + ":" + split[1];
        else startTime = meeting.startTime;
        clubTime = startTime;
      }

      if (meeting.endTime) {
        const split = meeting.endTime.split(":");
        if (split.length > 1) endTime = split[0] + ":" + split[1];
        else endTime = meeting.endTime;
        clubTime += "-" + endTime;
      }
    }

    let clubFullName = prepareClubName(club, t);

    const theClub = {
      id: club.id,
      country: club.country,
      attendance: club.attendance?.name,
      language: club.language,
      name: clubFullName,
      owner: ownerName,
      sku: club.sku,
      status: club.status?.name,
      type: club.type?.name,
      contentType: club.contentType?.name,
      weekday: meeting?.weekday,
      clubTime: clubTime,
      startTime: startTime,
      endTime: endTime,
      participantCount: club.participantCount,
      maxParticipants: club.maxParticipants,
    };
    return theClub;
  }

  const openClub = (club) => {
    window.open(`/club/?id=${club.id}`, "_blank").focus();
  };

  return (
    <div style={{ width: "100%" }}>
      {props && clubs && clubs.length > 0 && (
        <DataGrid
          key={"dayDataGrid_" + props.day}
          autoHeight
          pageSize={25}
          rowsPerPageOptions={[25, 50, 75]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          rows={clubs}
          columns={columns}
          onCellDoubleClick={(params, event) => {
            if (!event.ctrlKey) {
              event.defaultMuiPrevented = true;
            }
            openClub(params.row);
          }}
        />
      )}
    </div>
  );
}
