import axios from "axios"
import { API } from "./API";

export const getCountries = async () => {
    const { data } = await axios.get(`${API}/countries`, { withCredentials: true });      
    return data.data
}

export const createCountry = async (body) => {
    const { data } = await axios.post(`${API}/countries/create`, body, { withCredentials: true });
    return data
}

export const editCountry = async (body) => {
    const { data } = await axios.put(`${API}/countries/${body.id}`, {isActive: body.isActive}, { withCredentials: true });
    return data
}

export const deleteCountry = async (id) => {
    const { data } = await axios.delete(`${API}/countries/${id}`, { withCredentials: true });
    return data
}