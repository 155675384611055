import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";

import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

// data = {
//    key: {
//      value: "",
//      label: "",
//    },

const GeneralTextFieldEditor = ({ buttonText, title, data, onSave }) => {
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState(
    JSON.parse(JSON.stringify(data))
  );
  const [editedData, setEditedData] = useState({ ...data });
  const [hasChanges, setHasChanges] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log("GeneralTextFieldEditor.UseEffect");
    console.log(initialData["discordUserId"]);
    console.log(editedData["discordUserId"]);

    // Check for changes when data or editedData changes
    const changes = Object.keys(initialData).some(
      (key) => editedData[key].value !== initialData[key].value
    );

    console.log("Changes");
    console.log(changes);

    setHasChanges(changes);
  }, [editedData]);

  useEffect(() => {
    console.log("GeneralTextFieldEditor.initialData.UseEffect");
    console.log(initialData["discordUserId"]);
  }, [initialData]);

  const handleFieldChange = (key, newValue) => {
    const updatedData = {
      ...editedData,
    };

    console.log("handleFieldChange");
    console.log(key);
    console.log(newValue);

    updatedData[key].value = newValue;

    setEditedData(updatedData);
  };

  const handleSave = () => {
    onSave(editedData);
    setInitialData(JSON.parse(JSON.stringify(editedData))); // Update initialData with the editedData
    setHasChanges(false);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {buttonText}
      </Button>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ display: "block" }}>
          <Stack direction="column" alignItems="flex-start" gap={0}>
            {Object.entries(editedData).map(([key, item]) => (
              // loop through the editedData.keys
              <TextField
                key={key}
                label={item.label}
                value={item.value}
                onChange={(event) => handleFieldChange(key, event.target.value)}
                sx={{ marginBottom: 2, marginTop: 1 }}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            {t("general.close")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!hasChanges}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralTextFieldEditor;
