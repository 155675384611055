import axios from "axios";
import { API } from "./API";

export const getGamers = async (body) => {
  const { data } = await axios.post(`${API}/slotselection/gamers`, body, {
    withCredentials: true,
  });
  return data.data;
};

export const getSlots = async (body) => {
  const { data } = await axios.post(`${API}/slotselection/clubs`, body, {
    withCredentials: true,
  });
  return data.data;
};

export const sendForm = async (body) => {
  const { data } = await axios.post(`${API}/slotselection/confirm`, body, {
    withCredentials: true,
  });
  return data.data;
};
export const checkValid = async (body) => {
  const data = await axios.post(`${API}/slotselection/precondition`, body, {
    withCredentials: true,
  });
  return data;
};
