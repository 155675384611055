import axios from "axios";
import { API } from "./API";

export const getGroup = async (id, guildId) => {
  const { data } = await axios.get(`${API}/groups/${id}?guildId=${guildId}`, {
    withCredentials: true,
  });
  return data.data;
};

export const getGroupWithActiveParticipantsOnly = async (id, guildId) => {
  const { data } = await axios.get(
    `${API}/groups/activeparticipantsonly/${id}?guildId=${guildId}`,
    {
      withCredentials: true,
    }
  );
  return data.data;
};

export const editGroup = async (id, props) => {
  const { data } = await axios.put(`${API}/groups/${id}/edit`, props, {
    withCredentials: true,
  });
  return data;
};

export const setGroupGedu = async (params) => {
  console.log(params);
  const { data } = await axios.put(
    `${API}/groups/${params.groupId}/setgedu`,
    { geduUserId: params.geduUserId, type: params.type },
    {
      withCredentials: true,
    }
  );
  return data;
};
