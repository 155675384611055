import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useTranslation } from "react-i18next";

function BetaNotification() {
  const { t } = useTranslation();

  return (
    <Alert
      severity="info"
      sx={{
        backgroundColor: "#FF993D",
        color: "#FFFFFF",
        margin: 1.5,
        mb: 2.5,
        "& .MuiSvgIcon-root": {
          // Change the color of the icon here
          fill: "#FFFFFF", // Customize the icon color
        },
      }}
    >
      <AlertTitle>{t("betaNotification.header")}</AlertTitle>
      {t("betaNotification.description")}
    </Alert>
  );
}

export default BetaNotification;
