import React, { useEffect, useState } from "react";
import "./club.css";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { getClubByID } from "../Hooks/clubs";
import ErrorAlert from "../errorAlert";
import Box from "@mui/material/Box";

import ClubStatus from "./clubStatus";
import { prepareClubName } from "../Util/clubHelper";

import { useTranslation } from "react-i18next";

export default function ClubInfo(params) {
  const { t } = useTranslation();
  const clubId = params.clubId;
  const [clubData, setClubData] = useState({});
  const { data, error, isLoading } = useQuery(["clubId", clubId], () =>
    getClubByID(clubId)
  );

  //const clubData = data ?? {};

  // if (clubData) console.log(clubData);

  useEffect(() => {
    // console.log("useEffect on ClubData " + clubId);
    if (data) {
      prepareData();
    }
    // eslint-disable-next-line
  }, [data]);

  const prepareData = async () => {
    const preparedData = {
      sku: data.sku,
      name: data.name,
      status: data.status,
      language: data.language,
      attendance: data.attendance,
      clubMeetingTime: data.clubMeetingTime,
      type: data.type,
      contentType: data.contentType,
      ownerId: data.ownerId,
      registration: data.registration,
      text: prepareClubName(data, t),
    };

    setClubData(preparedData);
  };

  return (
    <Box sx={{ display: "inline-flex" }}>
      {error && <ErrorAlert txt={error} />}
      {isLoading && <CircularProgress />}
      {clubData && clubData.text && (
        <div style={{ display: "inline-flex", gap: "1em" }}>
          <ClubStatus status={clubData.status?.name} />
          <h2>{clubData.text}</h2>
        </div>
      )}
    </Box>
  );
}
