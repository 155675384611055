import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import Login from "./Auth/login";
import Logout from "./Auth/logout";
import Navbar from "./Navbar/Navbar";
import Footer from "./Navbar/Footer";
import Home from "./Home/home";
import ClubCalendar from "./Club-Calendar/clubCalendar";
import ClubView from "./Club-Calendar/clubView";
import ClubList from "./Admin/Clubs/clubs";
import ClubInstructionList from "./Admin/ClubInstructions/clubInstructions";
import UserList from "./Admin/Users/users";
import ActivityList from "./Admin/GamersGym/activityList";
import CarouselComponent from "./Activity-Generator/activityGenerator";
import CodeDomainList from "./Admin/Codes/codedomains";
import CodeList from "./Admin/Codes/codes";
import Store from "./Store/store";
import PathPicker from "./Order/pathPicker";
import Entities from "./Admin/Entity/entity";
import MuniRegister from "./Muni-Register/muni-register";
import CountryList from "./Admin/MuniManagement/country";
import MuniManagement from "./Admin/MuniManagement/managements";
import Purchase from "./Purchase/purchase";
import DiscordLink from "./Discord/link";
import axios from "axios";
import { API } from "./Hooks/API";
import { useEffect, useState } from "react";
import { check } from "./Hooks/auth";
import { isAdmin, isGedu, isParent, isGamer } from "./Util/authHelper";
import AchievementList from "./Admin/Achievements/achievements";
import GamerProfile from "./Home/profile";
import ContainerBox from "./Container";
import GeduInvoicingMainView from "./Report/GeduInvoicingMainView";
import GeduInvoicingStatusMainView from "./Report/GeduInvoicingStatusMainView";
import GeduSearch from "./Search/GeduSearch";
import GamerSearch from "./Search/GamerSearch";
import ParentSearch from "./Search/ParentSearch";
import GeduSubstituteSearch from "./Search/GeduSubstituteSearch";
import UserSearch from "./Search/UserSearch";
import ClubSearch from "./Search/ClubSearch";
import GeduDetails from "./Search/GeduDetails";
import GamerDetails from "./Search/GamerDetails";
import ParentDetails from "./Search/ParentDetails";
import MuniReportMainView from "./Report/MuniReportMainView";
import TrusterReportMainView from "./Report/TrusterReportMainView";
import MuniSummaryReportMainView from "./Report/MuniSummaryReportMainView";

const queryClient = new QueryClient();

function App() {
  const [authValid, setAuthValid] = useState(false);
  const [appUserRoles, setAppUserRoles] = useState([]);
  const [appAuthInfo, setAppAuthInfo] = useState({});

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const data = await axios.post(
          `${API}/auth/check`,
          {},
          { withCredentials: true }
        );

        // const data = {
        //   status: 200,
        //   data: {
        //     message: "Valid User",
        //     roles: ["USER_ROLE_ADMIN"],
        //   }
        // };

        // console.log(data.status);

        let isAuthValid = false;

        if (data.status === 200) isAuthValid = true;

        setAuthValid(isAuthValid);
        setAppUserRoles(data.data.roles);

        setAppAuthInfo({
          authValid: isAuthValid,
          appUserRoles: data.data.roles,
        });
      } catch (error) {
        console.log(error);
        if (
          !window.location.href.includes("login") &&
          !window.location.href.includes("logout") &&
          !window.location.href.includes("purchase") &&
          !window.location.href.includes("muni-reg") &&
          !window.location.href.includes("discord")
        )
          window.location.href = "/login";
      }
    };
    checkAuth();
  }, []);

  // const isAdmin = () => {
  //   return appUserRoles?.includes("USER_ROLE_ADMIN");
  // };
  // const isGedu = () => {
  //   return appUserRoles?.includes("USER_ROLE_GEDU");
  // };
  // const isParent = () => {
  //   return appUserRoles?.includes("USER_ROLE_PARENT");
  // };
  // const isGamer = () => {
  //   return appUserRoles?.includes("USER_ROLE_GAMER");
  // };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {authValid && (
            <Route
              path="/profile/:id"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <GamerProfile appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (isAdmin(appUserRoles) || isGedu(appUserRoles)) && (
            <Route
              path="/geduInvoicing"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <GeduInvoicingMainView appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/report-muni"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <MuniReportMainView appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/report-muni-summary"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <MuniSummaryReportMainView appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/report-truster"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <TrusterReportMainView appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/report-gedu-invoicing-status"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <GeduInvoicingStatusMainView appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/search-gedu"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <GeduSearch appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/search-gamer"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <GamerSearch appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (isAdmin(appUserRoles) || isGedu(appUserRoles)) && (
            <Route
              path="/search-user"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <UserSearch appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/search-parent"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <ParentSearch appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/search-substitute"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <GeduSubstituteSearch appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/search-club"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ContainerBox>
                    <ClubSearch appAuthInfo={appAuthInfo} />
                  </ContainerBox>
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}

          {authValid && (
            <Route
              path="/"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <Home appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (isAdmin(appUserRoles) || isGedu(appUserRoles)) && (
            <Route
              path="/club"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ClubView appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/geduDetails"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <GeduDetails appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/gamerDetails"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <GamerDetails appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/parentDetails"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ParentDetails appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (isAdmin(appUserRoles) || isGedu(appUserRoles)) && (
            <Route
              path="/club-calendar"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ClubCalendar appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (isAdmin(appUserRoles) || isGedu(appUserRoles)) && (
            <Route
              path="/activity-generator"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <CarouselComponent /*appAuthInfo={appAuthInfo}*/ />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {true && (
            <Route
              path="/muni-reg"
              element={
                <>
                  <MuniRegister
                    appAuthInfo={appAuthInfo}
                    id="app-muni-register"
                  />
                </>
              }
            />
          )}
          <Route
            path="/purchase"
            element={
              <>
                <Purchase />
              </>
            }
          />
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/achievements"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <AchievementList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/clubs"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ClubList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/clubInstructions"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ClubInstructionList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/municipalities"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <MuniManagement appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/entities"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <Entities appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/codedomains"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <CodeDomainList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/codes"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <CodeList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/activities"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <ActivityList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && isAdmin(appUserRoles) && (
            <Route
              path="/admin/users"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <UserList appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (
            <Route
              path="/store"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <Store appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          {authValid && (
            <Route
              path="/order/path"
              element={
                <>
                  <Navbar appAuthInfo={appAuthInfo} />
                  <PathPicker appAuthInfo={appAuthInfo} />
                  <Footer appAuthInfo={appAuthInfo} />
                </>
              }
            />
          )}
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/discord/link" element={<DiscordLink />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
