import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import ContainerBox from "../../Container";
import { Box, Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Typography, CircularProgress, Select, MenuItem } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getAchievements, createAchievement, editAchievement, deleteAchievement } from "../../Hooks/achievements"
import { RewardList } from "../../Util/rewardList";

const languages = [
  { id: "en", nativeName: "English" },
  { id: "fi", nativeName: "Suomi" },
  { id: "es", nativeName: "España" },
];

export default function AchievementList() {
  const [achievements, setAchievements] = useState(undefined);
  const [updatingAchievements, setUpdatingAchievements] = useState(new Set([]));
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState({ id: "", unlockCondition: "", translations: { "en": { title: "", description: ""}} });

  const { t } = useTranslation();

  useEffect(
    () => {
      // console.log("FETCHING ACHIEVEMENTS");
      fetchAchievements().then(data => setAchievements(data));
    }, 
    []
  );

  const fetchAchievements = async () => {
    const fetchedAchievements = await getAchievements();
    const achievementObjs = fetchedAchievements.map((achievement) => ({
      ...achievement,
      rewards: achievement.rewards.map((reward, i) => ({ id: i, code: reward.rewardId, amount: reward.amount })),
    }));
    // console.log("FETCHED:", fetchedAchivements);
    return achievementObjs;
  };

  const markAchievementUpdating = (id) => {
    setUpdatingAchievements(oldUpdatingAchievements => {
      const newUpdatingAchievements = new Set(oldUpdatingAchievements);
      newUpdatingAchievements.add(id);
      return newUpdatingAchievements;
    });
  }

  const markAchievementNotUpdating = (id) => {
    setUpdatingAchievements(oldUpdatingAchievements => {
      const newUpdatingAchievements = new Set(oldUpdatingAchievements);
      newUpdatingAchievements.delete(id);
      return newUpdatingAchievements;
    });
  }

  const onCreateAchievement = async () => {
    const tmpId = Math.random();
    const tmpAchievement = { ...selectedAchievement, id: tmpId };

    // since snowflakes are time-sortable,
    // this will be the last achievement by ascending id
    setAchievements(achievements.concat(tmpAchievement));
    markAchievementUpdating(tmpId);

    // console.log("CREATING:", selectedAchievement);
    const createdAchievement = { ...selectedAchievement };
    createdAchievement.rewards = (createdAchievement.rewards ?? []).map((reward) => ({ rewardId: reward.code, amount: reward.amount }))
    await createAchievement(createdAchievement)
      .then(({ id: newAchievementId }) => {
        console.log("CREATED:", newAchievementId);
        markAchievementNotUpdating(tmpId);
        setAchievements((achievements2) => 
          achievements2.map((achievement) => achievement.id === tmpId ? { ...createdAchievement, id: newAchievementId } : achievement)
        );
      })
      .catch(() => {
        alert("Failed to create achievements");
        markAchievementNotUpdating(tmpId);
        setAchievements((achievements2) => 
          achievements2.filter((achievement) => achievement.id !== tmpId)
        );
      });
  };

  const onDeleteAchievement = async () => {
    markAchievementUpdating(selectedAchievement.id);

    await deleteAchievement(selectedAchievement.id);
    // console.log("DELETED:", selectedAchievement);
    setAchievements( 
      achievements.filter((achievement) => achievement.id !== selectedAchievement.id)
    );

    markAchievementNotUpdating(selectedAchievement.id);
  };

  const onUpdateAchievement = async () => {
    markAchievementUpdating(selectedAchievement.id);

    const updatedAchievementObj = { ...selectedAchievement };
    updatedAchievementObj.rewards = (updatedAchievementObj.rewards ?? []).map((reward) => ({ rewardId: reward.code, amount: reward.amount }))
    const updatedAchievement = await editAchievement(updatedAchievementObj);
    console.log("UPDATED:", updatedAchievement);
    setAchievements( 
      achievements.map((achievement) => achievement.id === updatedAchievement.id ? selectedAchievement : achievement)
    );

    markAchievementNotUpdating(selectedAchievement.id);
  };

  const openCreateDialog = () => {
    setSelectedAchievement({ translations: {"en": { title: "", description: "" }}, unlockCondition: "" });
    setIsCreateDialogOpen(true);
  };

  const columns = [
    {
      field: "title",
      headerName: t("admin.achievements.achievementTitle"),
      flex: 1,
      editable: false,
      valueGetter: params => params.row.translations["en"]?.title,
    },
    {
      field: "description",
      headerName: t("admin.achievements.achievementDescription"),
      flex: 1,
      editable: false,
      valueGetter: params => params.row.translations["en"]?.description,
    },
    {
      field: "unlockCondition",
      headerName: t("admin.achievements.achievementUnlockCondition"),
      flex: 1,
      editable: false,
    },
    {
      field: "image",
      headerName: t("admin.achievements.achievementImage"),
      flex: 1,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={0}
          icon={updatingAchievements.has(params.id) ? <CircularProgress size={17}/> : <EditIcon />}
          label="Edit"
          onClick={() => {
            if (!updatingAchievements.has(params.id)) {
              setSelectedAchievement(achievements.find(achievement => achievement.id === params.id));
              setIsEditDialogOpen(true);
            }
          }}
        />,
      ],
    },
  ];

  return (
    <ContainerBox>
      <Typography variant="h3">{t("admin.achievements.title")}</Typography>
      {<DataGrid
          sx={{
            "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
              display: "none"
            }
          }}
          disableSelectionOnClick
          autoHeight
          rowsPerPageOptions={[25, 50, 100]}
          rows={achievements ?? []}
          columns={columns}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}>{achievements === undefined && <CircularProgress />}</Box>
            ),
            Toolbar: () => (
              <Box sx={{
                display: 'flex',
                width: '100%',
              }}>
                <Button
                  variant="contained"
                  disabled={achievements === undefined}
                  sx={{justifyContent:"left", m: 1, mb: 0 }}
                  onClick={openCreateDialog}
                >{t("general.create")}</Button>
              </Box>
            ),
          }}
      />}
      {/* Create */}
      <CreateEditAchievementDialog
        isEdit={false}
        isOpen={isCreateDialogOpen}
        setIsOpen={setIsCreateDialogOpen}
        onCommit={onCreateAchievement}
        onDelete={() => {}}
        achievement={selectedAchievement}
        setAchievement={setSelectedAchievement}
      />
      {/* Edit */}
      <CreateEditAchievementDialog
        isEdit={true}
        isOpen={isEditDialogOpen}
        setIsOpen={setIsEditDialogOpen}
        onCommit={onUpdateAchievement}
        onDelete={onDeleteAchievement}
        achievement={selectedAchievement}
        setAchievement={setSelectedAchievement}
      />
    </ContainerBox>
  );
}

const CreateEditAchievementDialog = ({ onDelete, onCommit, isOpen, setIsOpen, achievement, setAchievement, isEdit }) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const conditionRegex = /^(:?manual_gedu|manual_parent|(?:{[A-Z_]*}\s*>\s*[1-9][0-9]*(?:\s+(:?and|or)\s+{[A-Z_]*}\s*>\s*[1-9][0-9]*)*)\s*)$/;
  const resetAndClose = () => {
    setSelectedLanguage("en");
    setIsOpen(false);
  };

  const changeLanguage = (newLang) => {
    setAchievement({
      ...achievement,
      translations: {
        ...achievement.translations,
        [newLang]: achievement.translations[newLang] ?? { title: "", description: "" },
      }
    });
    setSelectedLanguage(newLang);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
      {isEdit ? t("admin.achievements.editAchievement") : t("admin.achievements.createNewAchievement")}
      </DialogTitle>
      <DialogContent>
        <Select
          value={selectedLanguage}
          onChange={e => {
            changeLanguage(e.target.value);
          }}
        >
          {languages.map(lang => <MenuItem key={lang.id} value={lang.id}>{lang.nativeName}</MenuItem>)}
        </Select>
        <TextField 
          label={t("admin.achievements.achievementTitle")}
          autoFocus
          margin="normal"
          fullWidth
          value={achievement.translations[selectedLanguage]?.title}
          onChange={e => setAchievement({
            ...achievement,
            translations: { 
              ...achievement.translations,
              [selectedLanguage]: {
                ...achievement.translations[selectedLanguage],
                title: e.target.value
              }
            }
          })}
        />
        <TextField
          label={t("admin.achievements.achievementDescription")}
          margin="normal"
          multiline
          fullWidth
          value={achievement.translations[selectedLanguage]?.description}
          onChange={e => setAchievement({
            ...achievement,
            translations: { 
              ...achievement.translations,
              [selectedLanguage]: {
                ...achievement.translations[selectedLanguage],
                description: e.target.value
              }
            }
          })}
        />
        <TextField
          label="Unlock Condition"
          margin="normal"
          multiline
          fullWidth
          error={!conditionRegex.test(achievement.unlockCondition)}
          value={achievement.unlockCondition}
          onChange={e => setAchievement({
            ...achievement,
            unlockCondition: e.target.value
          })}
        />
        <TextField
          label="Image"
          margin="normal"
          multiline
          fullWidth
          value={achievement.image}
          onChange={e => setAchievement({
            ...achievement,
            image: e.target.value
          })}
        />
        <RewardList 
          rewards={achievement.rewards ?? []} 
          setRewards={(newRewards) => setAchievement({
            ...achievement,
            rewards: newRewards,
          })} 
        />
      </DialogContent>
      <DialogActions>
        {isEdit && <Button
          variant="contained"
          color="error" 
          onClick={async () => {
            if (confirm("Delete achievement?")) {
              resetAndClose();
              return onDelete();
            }
          }}
        >{t("general.delete")}</Button>}
        <Button onClick={resetAndClose}>{t("general.cancel")}</Button>
        <Button 
          disabled={!conditionRegex.test(achievement.unlockCondition) || (achievement.rewards ?? []).some((row) => row.code === "")}
          variant="contained"
          onClick={async () => {
            resetAndClose();
            return onCommit();
          }}
        >{isEdit ? t("general.update") : t("general.create")}</Button>
      </DialogActions>
    </Dialog>
  );
}
