import axios from "axios";
import { API } from "./API";
import { fi } from "date-fns/locale";

export const sendWelcomeEmails = async (id) => {
  const body = {};
  const { data } = await axios.post(
    `${API}/clubs/sendwelcomeemails/${id}`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getClubs = async () => {
  const { data } = await axios.get(`${API}/clubs`, { withCredentials: true });

  return data.data.clubs;
};

export const getActiveClubs = async () => {
  const { data } = await axios.get(`${API}/clubs?activeonly=true`, {
    withCredentials: true,
  });

  return data.data.clubs;
};

export const getClubByID = async (id) => {
  const { data } = await axios.get(`${API}/clubs/${id}`, {
    withCredentials: true,
  });
  return data.data.club;
};

export const getSubscriptionData = async (id) => {
  const { data } = await axios.get(`${API}/chargebee/${id}`, {
    withCredentials: true,
  });
  return data.data;
};

export const moveParticipant = async (body) => {
  const { data } = await axios.post(`${API}/clubs/moveParticipant`, body, {
    withCredentials: true,
  });
  return data;
};

export const manualSubscriptionMapping = async (body) => {
  const { data } = await axios.post(
    `${API}/slotselection/manualmapping`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const createClub = async (body) => {
  const { data } = await axios.post(`${API}/clubs/create`, body, {
    withCredentials: true,
  });
  return data;
};

export const createClubInstructions = async (body) => {
  const { data } = await axios.post(
    `${API}/clubs/createClubInstruction`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const editClub = async (body) => {
  let newBody = {};
  // if (body.field === "sku") newBody.sku = body.value;
  // if (body.field === "name") newBody.name = body.value;
  // if (body.field === "status") newBody.status = body.value;
  // if (body.field === "language") newBody.language = body.value;
  // if (body.field === "attendance") newBody.attendance = body.value;
  // if (body.field === "clubMeetingTime") newBody.clubMeetingTime = body.value;
  // if (body.field === "type") newBody.type = body.value;
  // if (body.field === "contentType") newBody.contentType = body.value;
  // if (body.field === "ownerId") newBody.ownerId = body.value;
  // if (body.field === "registration") newBody.registration = body.value;

  // an array
  for (const theField of body.fields ?? []) {
    newBody[theField.field] = theField.value;
  }

  // old way
  if (body.field) newBody[body.field] = body.value;

  const { data } = await axios.put(`${API}/clubs/${body.id}`, newBody, {
    withCredentials: true,
  });
  return data;
};

export const editClubInstructions = async (body) => {
  let newBody = {};
  newBody[body.field] = body.value;

  console.log(newBody);
  const { data } = await axios.put(
    `${API}/clubs/clubinstructions/${body.id}`,
    newBody,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const setClubMeetingTimes = async (body) => {
  const { data } = await axios.put(
    `${API}/clubs/setMeetingTimes/${body.id}`,
    body,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const deleteClub = async (id) => {
  const { data } = await axios.delete(`${API}/clubs/${id}`, {
    withCredentials: true,
  });
  return data;
};

export const deleteClubInstructions = async (id) => {
  const { data } = await axios.delete(`${API}/clubs/clubinstructions/${id}`, {
    withCredentials: true,
  });
  return data;
};

export const createGroup = async (id, name, type) => {
  const { data } = await axios.put(
    `${API}/clubs/${id}/creategroup`,
    { name: name, type: type },
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getClubInstructions = async () => {
  const { data } = await axios.get(`${API}/clubs/clubinstructions`, {
    withCredentials: true,
  });
  return data.data.clubInstructions;
};
