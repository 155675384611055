import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Container,
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  IconButton,
  Link,
} from "@mui/material";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";
import OpenIcon from "@mui/icons-material/OpenInNew";

import { parentSearchDb } from "../Hooks/search"; // Adjust the path accordingly
import "../App.css";
import { useTranslation } from "react-i18next";
import { getStatusSpan } from "./common";

const ParentSearch = () => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [activeSearchQuery, setActiveSearchQuery] = useState(undefined);
  const [activeOnly, setActiveOnly] = useState(false); // State for the checkbox

  const processData = (data) => {
    const parents = data.map((parent) => ({
      id: parent.id,
      name: parent.lastName + ", " + parent.firstName,
      email: parent.email,
      phoneNumber: parent.phoneNumber,
      discordUserId: parent.discordUserId,
      discordUserName: parent.discordUserName,
      avatar: parent.discordAvatar ? parent.discordAvatar : "",
      gamers: parent.gamers.map((gamer) => ({
        id: gamer.id,
        name: gamer.lastName + ", " + gamer.firstName,
      })),
      subscriptions: parent.subscriptions,
      // subscriptionPlans: parent.subscriptionPlans.map((plan) => ({
      //   id: plan.id,
      //   name: plan.chargebeePlan.name,
      //   chargebeeId: plan.chargebeeSubscription.chargebeeId,
      // })),
    }));
    // sort by name
    parents.sort((a, b) => a.name.localeCompare(b.name));
    return parents;
  };

  const { data: parentData, isLoading, error } = useQuery(
    ["parentSearch", activeSearchQuery, activeOnly],
    () => parentSearchDb(activeSearchQuery, activeOnly).then(processData),
    { enabled: activeSearchQuery !== undefined }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setActiveSearchQuery(searchQuery);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {t("parentSearch.title")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="baseline"
          gap={1}
        >
          <Stack direction="column" alignItems="flex-start" gap={0}>
            <TextField
              label={t("parentSearch.searchLabel")}
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginBottom: 2 }}
              helperText={t("parentSearch.searchHelpText")}
            />
          </Stack>
          <Button variant="contained" type="submit">
            {t("parentSearch.searchButtonText")}
          </Button>
        </Stack>
      </form>

      {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
      {error && <Typography color="error">{error.message}</Typography>}

      {parentData && (
        <div>
          {parentData.map((result, index) => (
            <Card key={result.id} sx={{ marginTop: 2 }}>
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    {result.avatar && (
                      <CardMedia
                        component="img"
                        className="user-icon"
                        image={result.avatar}
                        alt="Avatar"
                      />
                    )}
                    {!result.avatar && <NoAvatarIcon className="user-icon" />}
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      // gap={1}
                    >
                      <Typography variant="h5">
                        {result.name}
                      </Typography>
                      {parent.discordUserId && <Typography variant="body1">
                        {result.discordUserName
                          ? `${result.discordUserName}`
                          : ``}
                        {result.discordUserId
                          ? " [" + result.discordUserId + "]"
                          : ``}
                      </Typography>}
                      <Typography variant="body1">
                        {result.email}
                        {result.phoneNumber ? ` - ${result.phoneNumber}` : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.id ? `${t("parentSearch.id")}: ${result.id}` : ``}
                      </Typography>
                      {result.gamers.length > 0 && 
                        <Typography variant="h6">{t("parentSearch.gamers")}:</Typography>
                      }
                      {result.gamers.map((gamer) => 
                        <Link key={gamer.id} href={`../gamerDetails/?id=${gamer.id}`}>{gamer.name} [{gamer.id}]</Link>
                      )}
                      {result.subscriptions.length > 0 && 
                        <Typography variant="h6">{t("parentSearch.subscriptions")}:</Typography>
                      }
                      {result.subscriptions.map((subscription) => {
                        const planNames = subscription.chargebeeSubscriptionPlans.map((plan) => plan.chargebeePlan.name).join(", ");
                        return (<Typography key={subscription.id}>({getStatusSpan(subscription.status, t)}) {planNames} [{subscription.chargebeeId}]</Typography>);
                      })}
                      {/* Add more fields as needed */}
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      window
                        .open(`./parentDetails/?id=${result.id}`, "_blank")
                        .focus();
                    }}
                  >
                    <OpenIcon className="action-icon"/>
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </Container>
  );
};

export default ParentSearch;
