import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getSlots } from "../Hooks/purchase";
import FormGroup from "@mui/material/FormGroup";
import "./slotSelection.css";
import { useTranslation, Trans } from "react-i18next";

export default function SlotSelection({ token }) {
  const { t } = useTranslation();
  const { mutate: getSlotsList } = useMutation(getSlots);
  const [clubs, setClubs] = useState([]);
  const [disable, setDisable] = useState(false);
  const [selected, setSelected] = useState(0);
  const [selectedInfo, setSelectedInfo] = useState([]);
  const days = [
    t("slotSelection.steps.secondStep.weekDays.monday"),
    t("slotSelection.steps.secondStep.weekDays.tuesday"),
    t("slotSelection.steps.secondStep.weekDays.wednesday"),
    t("slotSelection.steps.secondStep.weekDays.thursday"),
    t("slotSelection.steps.secondStep.weekDays.friday"),
    t("slotSelection.steps.secondStep.weekDays.saturday"),
    t("slotSelection.steps.secondStep.weekDays.sunday"),
  ];

  useEffect(() => {
    try {
      let slotsSelected = JSON.parse(sessionStorage.getItem("slots")) || [];
      if (slotsSelected.length > 0) {
        setSelected(slotsSelected.length);
        setSelectedInfo(slotsSelected);
        if (slotsSelected.length === token.plan.seatCount) setDisable(true);
      }
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      //First we get the token and the list of Slots
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      getSlotsList(
        {
          token: urlParams.get("p"),
          country: token.country || "346995052465106944",
        },
        {
          onSuccess: (e) => {
            //console.log(e.clubs);
            let rawDays = [[], [], [], [], [], [], []];
            /*
                        The rawDays variable (that is gonna be the "Clubs" variable once we filter the data)
                        has the next structure: 
                        [
                            [],
                            [],
                            ... 7times
                        ]
                        So basically its an array that includes 7 arrays (1 for each day of the week)
                        and inside all the arrays we have the clubs for each day
                    */
            e.clubs.forEach((club) => {
              if (club.meetings[0].weekday === "mon") rawDays[0].push(club);
              if (club.meetings[0].weekday === "tue") rawDays[1].push(club);
              if (club.meetings[0].weekday === "wed") rawDays[2].push(club);
              if (club.meetings[0].weekday === "thu") rawDays[3].push(club);
              if (club.meetings[0].weekday === "fri") rawDays[4].push(club);
              if (club.meetings[0].weekday === "sat") rawDays[5].push(club);
              if (club.meetings[0].weekday === "sun") rawDays[6].push(club);
            });

            // sort the clubs by start time
            let sortedDays = [[], [], [], [], [], [], []];
            for (let i = 0; i < 7; i++) {
              const rawClubs = rawDays[i];
              const sortedClubs2 = rawClubs.sort((a, b) => {
                if (a.meetings[0].startTime > b.meetings[0].startTime) {
                  return 1;
                } else if (a.meetings[0].startTime < b.meetings[0].startTime) {
                  return -1;
                } else {
                  return 0;
                }
              });

              sortedDays[i] = sortedClubs2;
            }

            setClubs(sortedDays);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <h4>
        {t("slotSelection.steps.secondStep.infoSlots1")}{" "}
        {/* <strong className="seatCount-slot">{token.plan.seatCount}</strong>{" "} */}
        <span className="slot-txt">
          {t("slotSelection.steps.secondStep.infoSlots2", {
            count: token.plan.seatCount,
          })}
        </span>{" "}
        {t("slotSelection.steps.secondStep.infoSlots3")}
      </h4>
      <p>
        {t("slotSelection.steps.secondStep.selectionStatus")}&nbsp;
        {selected}/{token.plan.seatCount}
      </p>
      <div className="slot-form">
        {
          //We loop the variable that includes all the days of the week
          days.map((day, i) => (
            <FormGroup key={i}>
              <h3 id={day + i}>{day}</h3>
              {
                //Now we show the slots for that day
                clubs.map((club, j) => {
                  if (j === i && club.length < 1)
                    document.getElementById(day + i).style.display = "none";
                  let slotsSelected =
                    JSON.parse(sessionStorage.getItem("slots")) || [];

                  return (
                    j === i &&
                    club.map((slot) => (
                      <div key={slot.id} className={`div-slot-page label-slot`}>
                        <input
                          name={slot.id}
                          id={slot.id}
                          type={"checkbox"}
                          defaultChecked={
                            slotsSelected.length > 0
                              ? slotsSelected.find((o) => o.id === slot.id)
                                ? true
                                : false
                              : false
                          }
                          disable={disable}
                          className={`checkbox-slot`}
                          onChange={(e) => {
                            /*
                                                All the things that are done here are to handle the max number of seatcounts that you can select
                                            */
                            let comodin = selectedInfo;
                            if (e.target.checked) {
                              if (comodin.length >= token.plan.seatCount) {
                                e.target.checked = false;
                              } else {
                                if (
                                  comodin.length + 1 ===
                                  token.plan.seatCount
                                ) {
                                  setDisable(true);
                                }

                                setSelected(selected + 1);
                                comodin.push(slot);
                              }
                            } else {
                              comodin = comodin.filter((x) => x.id !== slot.id);
                              setSelected(selected - 1);
                              setDisable(false);
                            }

                            if (comodin.length > 0)
                              document
                                .getElementById("next-btn")
                                .classList.remove("disable-next");
                            if (comodin.length <= 0)
                              document
                                .getElementById("next-btn")
                                .classList.add("disable-next");
                            sessionStorage.setItem(
                              "slots",
                              JSON.stringify(comodin)
                            );
                            setSelectedInfo(comodin);
                          }}
                        />
                        <label
                          htmlFor={slot.id}
                          className={`${disable ? "disable-check" : ""}`}
                        >
                          {" " +
                            slot.meetings[0].startTime.split(":")[0] +
                            ":" +
                            slot.meetings[0].startTime.split(":")[1] +
                            " - " +
                            slot.meetings[0].endTime.split(":")[0] +
                            ":" +
                            slot.meetings[0].endTime.split(":")[1] +
                            "  " +
                            slot.name}
                        </label>
                      </div>
                    ))
                  );
                })
              }
            </FormGroup>
          ))
        }
      </div>
    </div>
  );
}
