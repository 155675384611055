import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import { deleteCountry, getCountries, editCountry } from "../../Hooks/country";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import CreateCountry from "./createCountry";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function CountryList() {
  const { mutate: editCountryActive } = useMutation(editCountry);
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const columns = [
    //{ field: 'id', headerName: 'ID', flex: 1, editable: false },
    { field: "name", headerName: "Country Name", flex: 1, editable: true },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        let comodin = params.row.isActive;
        return (
          <Checkbox
            {...label}
            defaultChecked={comodin}
            onChange={(e) => changeActive(params.row)}
          />
        );
      },
    },
    {
      field: " ",
      headerName: "Municipalities",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              (window.location.href = `./municipalities/?country=${params.row.id}`)
            }
            sx={{ height: 40 }}
          >
            See municipalities
          </Button>
        );
      },
    },
  ];
  const { data, error, isLoading, refetch } = useQuery(
    ["countries"],
    getCountries
  );
  const { mutate } = useMutation(deleteCountry);

  useEffect(() => {
    if (data) {
      //do rows
      console.log(data);
      setRows(data.countries);
    }
  }, [data]);

  const processRowUpdate = () => {
    refetch();
  };

  const changeActive = ({ id, isActive }) => {
    editCountryActive(
      { id: id, isActive: !isActive },
      {
        onSuccess: (e) => {
          console.log(e);
        },
      }
    );
  };
  const deleteRows = () => {
    selectionModel.forEach((code) => {
      mutate(code, {
        onSuccess: (e) => {
          console.log(e);
        },
      });
    });
    window.location.reload();
  };

  return (
    <ContainerBox>
      <div style={{ width: "100%" }}>
        {error && <ErrorAlert txt={error} />}
        {isLoading && <CircularProgress />}
        {
          // rows.length > 0 &&
          <>
            <CreateCountry
              handleNewCountry={processRowUpdate}
              DeleteButton={
                <Button
                  variant="contained"
                  onClick={deleteRows}
                  color="secondary"
                  startIcon={<DeleteForeverIcon />}
                  disabled={disableBtn}
                  sx={{ height: 40 }}
                >
                  Delete Rows
                </Button>
              }
            />
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[]}
              rows={rows}
              columns={columns}
              onCellEditCommit={processRowUpdate}
            />
          </>
        }
      </div>
    </ContainerBox>
  );
}
