import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../errorAlert";

import InvoicingLines from "./InvoicingLines";
import InvoicingLinesNoPeriod from "./InvoicingLinesNoPeriod";
import InvoicingStatus from "../Util/InvoicingStatus";
import BetaNotification from "../Util/BetaNotification";

import { invoicingGedus, getGeduInvoicingPeriods } from "../Hooks/report";
import { formatDate } from "../Util/clubHelper";

import { useTranslation } from "react-i18next";
import { isAdmin, isGedu } from "../Util/authHelper";

const GeduInvoicingMainView = (params) => {
  const { t } = useTranslation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const geduId = urlParams.get("id");

  // State to store the selected value from the dropdown
  const [selectedGedu, setSelectedGedu] = useState("");
  const [gedus, setGedus] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [geduInfo, setGeduInfo] = useState({});
  const [appUserRoles, setAppUserRoles] = useState(undefined);
  const [periodHeaderData, setPeriodHeaderData] = useState({});
  const [invoicingLinesLoading, setInvoicingLinesLoading] = useState({});
  const [hasInvoicingLinesWithoutPeriod, setHasInvoicingLinesWithoutPeriod] =
    useState(true);

  // Fetch data for the Select dropdown
  const {
    data: gedusData,
    error: gedusError,
    isLoading: gedusLoading,
  } = useQuery("invoicingGedus", invoicingGedus, {
    enabled: isAdmin(params.appAuthInfo.appUserRoles),
  });

  // Fetch data based on the selected value
  const {
    data: invoicingPeriodsData,
    error: invoicingPeriodsError,
    isLoading: invoicingPeriodsLoading,
    refetch: refetchInvoicingPeriods,
  } = useQuery(
    ["geduInvoicingPeriods", selectedGedu],
    () => getGeduInvoicingPeriods(selectedGedu.value),
    {
      enabled: !!selectedGedu, // Only enable this query when selectedValue is truthy
    }
  );

  useEffect(() => {
    console.log("useEffect on geduInvoicingMainView");
    console.log(params);
    if (params.appAuthInfo?.appUserRoles) {
      setAppUserRoles(params.appAuthInfo.appUserRoles);
      if (
        !isAdmin(params.appAuthInfo.appUserRoles) &&
        isGedu(params.appAuthInfo.appUserRoles)
      ) {
        setSelectedGedu({
          value: "@me",
          label: "",
        });
      }
    }
  }, [params]);

  useEffect(() => {
    if (gedusData) {
      const geduList = [];
      gedusData.map((gedu) => {
        const discordUserName = gedu.discordUserName
          ? " (" + gedu.discordUserName + ")"
          : "";
        geduList.push({
          value: gedu.id,
          label: gedu.firstName + " " + gedu.lastName + discordUserName,
        });
      });

      setGedus(geduList);

      // enable giving gedu id as a parameter
      if (isAdmin(params.appAuthInfo.appUserRoles)) {
        // if geduId is given, select it
        if (geduId && geduId != "") {
          // and id is found from geduList
          const found = geduList.find((gedu) => gedu.value === geduId);

          if (found) {
            setSelectedGedu({
              value: found.value,
              label: found.label,
            });
          }
        }
      }
    }
  }, [gedusData]);

  useEffect(() => {
    if (invoicingPeriodsData) {
      prepareInvoicingPeriods(true);
    }
    // eslint-disable-next-line
  }, [invoicingPeriodsData]);

  const prepareInvoicingPeriods = async (collectFilterData = false) => {
    console.log("prepareInvoicingPeriods");
    console.log(invoicingPeriodsData);

    const list = [];

    //Do a loop in data and assing to each day
    invoicingPeriodsData.periods.map((period) => {
      // Format the dates
      const periodData = {};
      const startDate = new Date(period.startDate);
      if (period.startDate)
        periodData.startDate = formatDate(startDate, "DD.MM.YYYY");

      periodData.sortDate = startDate;

      if (period.endDate)
        periodData.endDate = formatDate(new Date(period.endDate), "DD.MM.YYYY");

      periodData.id = period.id;

      periodData.status = period.statusCode.name;

      list.push(periodData);
    });

    // order list by startDate DESC
    list.sort((a, b) => {
      return b.sortDate - a.sortDate;
    });

    // gedu info
    const geduInfo = {};
    if (invoicingPeriodsData.gedu) {
      geduInfo.id = invoicingPeriodsData.gedu.id;
      geduInfo.firstName = invoicingPeriodsData.gedu.firstName;
      geduInfo.lastName = invoicingPeriodsData.gedu.lastName;

      const billingMethod =
        invoicingPeriodsData.gedu.gameEducatorProfile?.billingMethod;
      geduInfo.billingMethod = billingMethod
        ? t("code." + billingMethod.name)
        : t("geduInvoicing.billingMethodNotSet");

      geduInfo.billingMethodCode =
        invoicingPeriodsData.gedu.gameEducatorProfile?.billingMethod;
    }

    setGeduInfo(geduInfo);
    setPeriods(list);
  };

  // Handle Select value change
  const handleSelectChange = (event) => {
    setSelectedGedu(event);
  };

  const setPeriodHeaderInfo = (periodid, data) => {
    const newPeriodHeaderData = { ...periodHeaderData };
    newPeriodHeaderData[periodid] = data;
    setPeriodHeaderData(newPeriodHeaderData);
  };

  return (
    <div>
      {/* {<BetaNotification />} */}
      {gedusError && <ErrorAlert txt={gedusError} />}
      {(gedusLoading || invoicingPeriodsLoading) && <CircularProgress />}

      {/* Show FilterDiv only for admins */}
      {isAdmin(appUserRoles) && (
        <div id="FilterDiv" style={{ marginBottom: 10 }}>
          <div
            style={{
              marginRight: "0.25em",
              marginBottom: "0.25em",
              maxWidth: 300,
              display: "inline-flex",
            }}
          >
            <Select
              name="geduFilter"
              options={gedus}
              className="basic-multi-select"
              placeholder={t("geduInvoicing.geduSelector")}
              value={selectedGedu}
              onChange={(event) => handleSelectChange(event)}
              // isSearchable={true}
              // isMulti
              multiple={false} // Set to false for single-select
              native={false} // Set to false to use the custom Select component
            />
          </div>
        </div>
      )}

      {/* Display data based on the selected value */}
      {periods && periods.length > 0 && (
        <div>
          <Typography sx={{ fontSize: 16, mb: 1 }} color="text.primary">
            {t("geduInvoicing.periodTitle")}: {geduInfo.firstName}{" "}
            {geduInfo.lastName}
          </Typography>
          <Typography sx={{ fontSize: 14, mb: 1 }} color="text.secondary">
            {t("geduInvoicing.invoicingMethodTitle")}: {geduInfo.billingMethod}
          </Typography>
          {periods.map((period, index) => (
            <MuiAccordion key={period.id}>
              <MuiAccordionSummary
                aria-controls="panel1d-content"
                id={index + "panel1d-header"}
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: "#f0f0f0" }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  {/* Updating the view did not work as expexted, the third line status was not updated for some reason */}
                  {/* <InvoicingStatus
                    status={
                      periodHeaderData[period.id]
                        ? periodHeaderData[period.id].status
                        : ""
                    }
                    minHeight={"3.5em"}
                    //isLoading={invoicingLinesLoading[period.id]}
                  /> */}
                  <Typography>
                    {periodHeaderData[period.id]
                      ? t("code." + periodHeaderData[period.id].status)
                      : ""}
                  </Typography>
                  <Typography>
                    {period.startDate} -{period.endDate}
                  </Typography>
                </Stack>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <InvoicingLines
                  appUserRoles={appUserRoles}
                  period={period}
                  geduInfo={geduInfo}
                  setHeaderData={setPeriodHeaderInfo}
                  // isLoading={(value) => {
                  //   const copy = { ...invoicingLinesLoading };
                  //   copy[period.id] = value;
                  //   setInvoicingLinesLoading(copy);
                  // }}
                />
              </MuiAccordionDetails>
            </MuiAccordion>
          ))}

          {hasInvoicingLinesWithoutPeriod && (
            <MuiAccordion key={"noperiod" + selectedGedu.value}>
              <MuiAccordionSummary
                aria-controls="panel1d-content"
                id={"panel1d-header" + selectedGedu.value}
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: "#f0f0f0" }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>{t("geduInvoicing.noPeriodHeader")}</Typography>
                </Stack>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <InvoicingLinesNoPeriod
                  appUserRoles={appUserRoles}
                  geduId={selectedGedu.value}
                  refetchInvoicingPeriods={refetchInvoicingPeriods}
                  hasInvoicingLines={(value) => {
                    console.log("hasInvoicingLinesWithoutPeriod" + value);
                    setHasInvoicingLinesWithoutPeriod(value);
                  }}
                />
              </MuiAccordionDetails>
            </MuiAccordion>
          )}
        </div>
      )}
      {/* Without the following the gedu select opens with scrollbar ??!? */}
      {periods && periods.length === 0 && (
        <div>
          <Typography
            sx={{ fontSize: 140, height: "350px" }}
            color="text.primary"
          >
            &nbsp;
          </Typography>
        </div>
      )}
    </div>
  );
};

export default GeduInvoicingMainView;
