import React, { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { createClub } from "../../Hooks/clubs";

import ErrorAlert from "../../errorAlert";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery, useMutation } from "react-query";
import { getCountries } from "../../Hooks/country";
import { getMunicipalities } from "../../Hooks/municipality";
import { getSchoolsForMuni } from "../../Hooks/school";

import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function AddClubs({
  handleNewClub,
  DeleteButton,
  statusCodes,
  clubTypeCodes,
  clubContentTypeCodes,
  attendanceCodes,
  clubLanguageCodes,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [maxParticipants, setMaxParticipants] = React.useState("12");
  const [language, setLanguage] = React.useState("not set");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(createClub);
  const {
    data: countries,
    error: errorCountries,
    isLoading,
  } = useQuery(["countries"], getCountries);
  const {
    data: municipalitiess,
    error: errorMunicipalities,
    isLoading: isLoadingmuni,
  } = useQuery(["munis"], () => getMunicipalities(), {
    enabled: true,
  });
  const {
    data: schoolss,
    error: errorSchools,
    isLoading: isLoadingSchools,
  } = useQuery(["schools"], () => getSchoolsForMuni(), {
    enabled: true,
  });
  const [municipalities, setMunicipalities] = useState([]);
  const [schools, setSchools] = useState([]);
  const [statusSelect, setStatusSelect] = useState(-1);
  const [clubTypeSelect, setClubTypeSelect] = useState(-1);
  const [clubContentTypeSelect, setClubContentTypeSelect] = useState(-1);
  const [clubLanguageSelect, setClubLanguageSelect] = useState(-1);
  const [attendanceSelect, setAttendanceSelect] = useState(-1);
  const [weekDay, setWeekDay] = useState(-1);
  const [country, setCountry] = useState(-1);
  const [municipality, setMunicipality] = useState(-1);
  const [school, setSchool] = useState(-1);

  const [discordRoleId, setDiscordRoleId] = useState("");
  const [discordGuildId, setDiscordGuildId] = useState("689038248596734117");

  const handleSubmit = async (e) => {
    // console.log("municipality: " + municipality);
    // console.log("school: " + school);

    e.preventDefault();
    mutate(
      {
        name: document.getElementById("club-name").value,
        sku: document.getElementById("club-sku").value,
        status: statusSelect,
        type: clubTypeSelect,
        contentType: clubContentTypeSelect,
        attendance: attendanceSelect,
        languageCode: clubLanguageSelect,
        //language: language,
        maxParticipants: Number(maxParticipants),
        clubMeetingTimes: [], // initially empty
        country: country != -1 ? country : "",
        municipality: municipality != -1 ? municipality : "",
        school: school != -1 ? school : "",
        discordRoleId: discordRoleId == "" ? undefined : discordRoleId,
        discordGuildId: discordRoleId == "" ? undefined : discordGuildId,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleNewClub(e.data.club);
          handleClose();
        },
      }
    );
  };
  const searchMunicipalities = (countryId) => {
    getMunicipalities(countryId).then((res) => {
      // sort municipalities by name alphabetically
      const municipalities = res.municipalities;
      municipalities.sort((a, b) => (a.name > b.name ? 1 : -1));
      setMunicipalities(municipalities);
    });
  };
  const searchSchools = (muniId) => {
    console.log("searchSchools: " + muniId);
    getSchoolsForMuni(muniId).then((res) => {
      const schools = res.schools;
      // sort schools by name alphabetically
      schools.sort((a, b) => (a.name > b.name ? 1 : -1));
      setSchools(schools);
    });
  };

  const isFormValid = () => {
    // Add validation logic for all required fields
    return (
      document.getElementById("club-name")?.value &&
      document.getElementById("club-sku")?.value &&
      statusSelect !== -1 &&
      clubTypeSelect !== -1 &&
      clubContentTypeSelect !== -1 &&
      attendanceSelect !== -1 &&
      clubLanguageSelect !== -1 &&
      country !== -1 &&
      (discordRoleId == "" || 
        (discordRoleId.match(/^[0-9]+$/) && discordGuildId.match(/^[0-9]+$/))
      )
    );
  };

  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ height: 40, mr: 1 }}
          onClick={handleOpen}
        >
          Add Club
        </Button>
        {DeleteButton}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {attendanceCodes.length > 0 && (
            <div>
              <TextField
                required
                id="club-name"
                label={t("admin.clubs.addClub_Name")}
                sx={{ m: 1, width: "100%" }}
              />
              <TextField
                required
                id="club-sku"
                label={t("admin.clubs.addClub_SKU")}
                sx={{ m: 1, width: "100%" }}
              />
              {/* <TextField
                required
                id="club-status"
                label={t("admin.clubs.addClub_Status")}
                sx={{ m: 1, width: "100%" }}
                value={"322964831495794688"}
              /> */}

              <TextField
                required
                id="club-status"
                select
                label={t("admin.clubs.addClub_Status")}
                value={statusSelect}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => setStatusSelect(e.target.value)}
                defaultValue={-1}
              >
                <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClub_StatusFirstEntry")}{" "}
                </MenuItem>
                {statusCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {code.label}{" "}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="club-type"
                select
                label={t("admin.clubs.addClub_Type")}
                value={clubTypeSelect}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => setClubTypeSelect(e.target.value)}
                defaultValue={-1}
              >
                <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClub_TypeFirstEntry")}{" "}
                </MenuItem>
                {clubTypeCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {code.label}{" "}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="club-content-type"
                select
                label={t("admin.clubs.addClub_ContentType")}
                value={clubContentTypeSelect}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => setClubContentTypeSelect(e.target.value)}
                defaultValue={-1}
              >
                <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClub_ContentTypeFirstEntry")}{" "}
                </MenuItem>
                {clubContentTypeCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {code.label}{" "}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="select-attendance"
                select
                label={t("admin.clubs.addClub_Attendance")}
                value={attendanceSelect}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => setAttendanceSelect(e.target.value)}
                defaultValue={-1}
              >
                <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClub_AttandanceFirstEntry")}{" "}
                </MenuItem>
                {attendanceCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {code.label}{" "}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="club-maxparticipants"
                label={t("admin.clubs.addClub_maxParticipants")}
                sx={{ m: 1, width: "100%" }}
                value={maxParticipants}
                onChange={(e) => setMaxParticipants(e.target.value)}
              />

              <TextField
                id="club-discord-role-id"
                label={t("admin.clubs.addClub_discordRoleId")}
                sx={{ m: 1, width: "100%" }}
                value={discordRoleId}
                error={discordRoleId != "" && !discordRoleId.match(/^[0-9]+$/)}
                onChange={(e) => setDiscordRoleId(e.target.value)}
              />

              {discordRoleId &&
                <TextField
                  required
                  id="club-discord-guild-id"
                  label={t("admin.clubs.addClub_discordGuildId")}
                  sx={{ m: 1, width: "100%" }}
                  value={discordGuildId}
                  error={discordGuildId != "" && !discordGuildId.match(/^[0-9]+$/)}
                  onChange={(e) => setDiscordGuildId(e.target.value)}
                />
              }

              <TextField
                required
                id="club-language-code"
                select
                label={t("admin.clubs.addClub_Language")}
                value={clubLanguageSelect}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => setClubLanguageSelect(e.target.value)}
                defaultValue={-1}
              >
                <MenuItem key={-1} disabled value={-1}>
                  {t("admin.clubs.addClub_LanguageFirstEntry")}{" "}
                </MenuItem>
                {clubLanguageCodes.map((code, i) => (
                  <MenuItem key={i} value={code.value}>
                    {code.label}{" "}
                  </MenuItem>
                ))}
              </TextField>
              {/* 
              <TextField
                required
                id="club-language"
                label={t("admin.clubs.addClub_Language")}
                sx={{ m: 1, width: "100%" }}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              /> */}
              <TextField
                required
                id="select-country"
                select
                label={t("admin.clubs.addClub_Country")}
                value={country}
                sx={{ m: 1, width: "100%" }}
                onChange={(e) => {
                  setCountry(e.target.value);
                  searchMunicipalities(e.target.value);
                }}
                defaultValue={-1}
              >
                <MenuItem disabled value={-1}>
                  {t("admin.clubs.addClub_CountryFirstEntry")}{" "}
                </MenuItem>
                {countries &&
                  countries.countries.length > 0 &&
                  countries.countries.map((coun, index) => (
                    <MenuItem key={coun.id} value={coun.id}>
                      {coun.name}{" "}
                    </MenuItem>
                  ))}
              </TextField>
              {municipalities && municipalities.length > 0 && (
                <TextField
                  id="select-municipality"
                  select
                  label="Municipality"
                  value={municipality}
                  sx={{ m: 1, width: "100%" }}
                  onChange={(e) => {
                    setMunicipality(e.target.value);
                    searchSchools(e.target.value);
                  }}
                  defaultValue={-1}
                >
                  <MenuItem disabled value={-1}>
                    {"Select Municipality..."}{" "}
                  </MenuItem>
                  {municipalities.map((muni) => (
                    <MenuItem key={muni.id} value={muni.id}>
                      {muni.name}{" "}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {schools && schools.length > 0 && (
                <TextField
                  id="select-school"
                  select
                  label="School"
                  value={school}
                  sx={{ m: 1, width: "100%" }}
                  onChange={(e) => setSchool(e.target.value)}
                  defaultValue={-1}
                >
                  <MenuItem disabled value={-1}>
                    {"Select School..."}{" "}
                  </MenuItem>
                  {schools.map((scho) => (
                    <MenuItem key={scho.id} value={scho.id}>
                      {scho.name}{" "}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Button
                variant="contained"
                color="primary"
                sx={{ left: "35%", marginTop: "1rem" }}
                type="submit"
                disabled={!isFormValid()}
              >
                Save Club{" "}
              </Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
