import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import NoAvatarIcon from "@mui/icons-material/AccountCircle";
import OpenIcon from "@mui/icons-material/OpenInNew";

import { geduSearchDb } from "../Hooks/search"; // Adjust the path accordingly
import "../App.css";
import { useTranslation } from "react-i18next";

const GeduSearch = () => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const [geduData, setGeduData] = useState([]);
  const [activeOnly, setActiveOnly] = useState(false); // State for the checkbox
  const { data, isLoading, error, refetch } = useQuery(
    ["geduSearch", searchQuery, activeOnly],
    () => geduSearchDb(searchQuery, activeOnly),
    {
      enabled: false,
    }
  );

  const handleSearch = () => {
    refetch();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  useEffect(() => {
    // console.log("Data changed");
    // console.log(data);
    if (data) prepareData();
  }, [data]);

  const prepareData = () => {
    const gedus = [];
    for (const gedu of data) {
      const cardData = {
        id: gedu.id,
        name: gedu.lastName + ", " + gedu.firstName,
        email: gedu.email,
        phoneNumber: gedu.phoneNumber,
        discordUserId: gedu.discordUserId,
        discordUserName: gedu.discordUserName,
        avatar: gedu.discordAvatar ? gedu.discordAvatar : "",
        isActive: gedu.geduProfile?.isActive
          ? gedu.geduProfile.isActive
          : false,
      };

      if (gedu.geduProfile && gedu.geduProfile.billingMethod) {
        cardData.billingMethod = t(
          "code." + gedu.geduProfile.billingMethod.name
        );
      }

      if (gedu.languages && gedu.languages.length > 0) {
        cardData.languages = gedu.languages.map((l) => {
          return t("code." + l.name);
        });
      }

      if (gedu.locations && gedu.locations.length > 0) {
        cardData.locations = gedu.locations.map((l) => {
          return l.name;
        });
      }

      gedus.push(cardData);
    }
    // sort by name
    gedus.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    setGeduData(gedus);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {t("geduSearch.title")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          key={"GeduSearchStack1"}
          direction="row"
          alignItems="baseline"
          gap={1}
        >
          <Stack direction="column" alignItems="flex-start" gap={0}>
            <TextField
              label={t("geduSearch.searchLabel")}
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Typography variant="body2" sx={{ mt: -1.5 }}>
              {t("geduSearch.searchHelpText")}
            </Typography>
          </Stack>
          {/* <FormControlLabel
          control={
            <Checkbox
              checked={activeOnly}
              onChange={(e) => setActiveOnly(e.target.checked)}
            />
          }
          label="Active Only"
          sx={{ marginBottom: 2 }}
        /> */}
          <Button variant="contained" type="submit">
            {t("geduSearch.searchButtonText")}
          </Button>
        </Stack>
      </form>

      {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
      {error && <Typography color="error">{error.message}</Typography>}

      {geduData && (
        <div>
          {geduData.map((result, index) => (
            <Card key={result.id} sx={{ marginTop: 2 }}>
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                  >
                    {result.avatar && (
                      <CardMedia
                        component="img"
                        className="user-icon"
                        image={result.avatar}
                        alt="Avatar"
                        key={"geduCardMedia1." + index}
                      />
                    )}
                    {!result.avatar && <NoAvatarIcon className="user-icon" />}
                    <Stack
                      key={"geduStack2" + index}
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gap={1}
                    >
                      <Typography variant="h6">
                        {result.name}
                        {" ["}
                        <span
                          style={{ color: result.isActive ? "green" : "red" }}
                        >
                          {result.isActive
                            ? t("geduDetails.active")
                            : t("geduDetails.inactive")}
                        </span>
                        {"]"}
                      </Typography>
                      <Typography variant="body1">
                        {result.discordUserName
                          ? `${result.discordUserName}`
                          : ``}
                        {result.discordUserId
                          ? " [" + result.discordUserId + "]"
                          : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.email}
                        {result.phoneNumber ? ` - ${result.phoneNumber}` : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.id ? `${t("geduSearch.id")}: ${result.id}` : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.languages
                          ? `${t(
                              "geduSearch.languages"
                            )}: ${result.languages.join(", ")}`
                          : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.locations
                          ? `${t(
                              "geduSearch.locations"
                            )}: ${result.locations.join(", ")}`
                          : ``}
                      </Typography>
                      <Typography variant="body1">
                        {result.billingMethod
                          ? `${t("geduSearch.invoicingMethod")}: ${
                              result.billingMethod
                            }`
                          : ``}
                      </Typography>
                      {/* Add more fields as needed */}
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      window
                        .open(`./geduDetails/?id=${result.id}`, "_blank")
                        .focus();
                        }}
                  >
                    <OpenIcon className="action-icon"/>
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </Container>
  );
};

export default GeduSearch;
