import axios from "axios";
import { API } from "./API";

export const generateActivity = async (body) => {
    try {
        const { data } = await axios.get(`${API}/gamersgym/`, {
            params: body,
            withCredentials: true,
        });
        console.log(data);

        const activities = Array.from(data.data.activities);
        console.log(data.data);
        console.log(activities);
        console.log(activities.length);


        return activities;
    } catch (error) {
        console.error(error);

        throw new Error('Failed to generate random activity');
    }
}

export const getAllActivities = async (body) => {
    const { data } = await axios.get(`${API}/gamersgym`, body, {
        withCredentials: true,
    });
    return data.data;
}

export const createActivity = async (formData) => {
    const { data } = await axios.post(`${API}/gamersgym/create`, formData, {
        withCredentials: true,
    });
    return data;
};



export const editActivity = async (id, updates) => {
    try {
        const { data } = await axios.put(`${API}/gamersgym/${id}`, updates, {
            withCredentials: true,
        });
        return data;
    } catch (error) {
        throw new Error('Failed to edit activity');
    }
};


export const getActivity = async (name) => {
    const { data } = await axios.get(`${API}/gamersgym/${name}`, {
        withCredentials: true
    });

    return data.data;
}

export const deleteActivity = async (id) => {
    const { data } = await axios.delete(`${API}/gamersgym/${id}`, {
        withCredentials: true,
    });
    return data;
}

