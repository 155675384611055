import React, { useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import { createCountry } from "../../Hooks/country";
import { useMutation } from "react-query";
import ErrorAlert from "../../errorAlert";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #8f00e2",
  boxShadow: 24,
  p: 4,
};

export default function CreateCountry({
  handleNewCountry,
  DeleteButton,
  attendanceCodes,
}) {
  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { mutate, error } = useMutation(createCountry);

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate(
      {
        name: document.getElementById("country-name").value,
      },
      {
        onSuccess: (e) => {
          console.log(e);
          handleNewCountry(e.data.country);
          handleClose();
        },
      }
    );
  };

  return (
    <div>
      {error && <ErrorAlert txt={error} />}
      <Box
        m={1} //margin
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ height: 40, mr: 1 }}
          onClick={handleOpen}
        >
          Create Country
        </Button>
        {DeleteButton}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          {
            <div>
              <TextField
                required
                id="country-name"
                label="Name"
                sx={{ m: 1, width: "100%" }}
              />

              <Button
                variant="contained"
                color="primary"
                sx={{ left: "30%", marginTop: "1rem" }}
                type="submit"
              >
                Create Country{" "}
              </Button>
            </div>
          }
        </Box>
      </Modal>
    </div>
  );
}
