import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { getCodeDomainsByNames } from "../Hooks/codes";
import { TranslatedDatePicker } from "../Util/translatedDatePicker";

import { useTranslation } from "react-i18next"; // Import i18n
import { formatDate } from "../Util/clubHelper";

const MunicipalityParticipantRegistrationDialog = ({
  open,
  onClose,
  onSave,
  clubId,
}) => {
  const { t } = useTranslation(); // Initialize t for translation

  const [formData, setFormData] = useState({
    parentFirstName: "",
    parentLastName: "",
    parentEmail: "",
    parentPhoneNumber: "",
    gamerFirstName: "",
    gamerLastName: "",
    gamerBirthdate: "",
    gamerGender: "",
  });

  const [formErrors, setFormErrors] = useState({
    parentFirstName: false,
    parentLastName: false,
    parentEmail: false,
    parentPhoneNumber: false,
    gamerFirstName: false,
    gamerLastName: false,
    gamerBirthdate: false,
    gamerGender: false,
  });

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  function isValidPhonenumber(value) {
    return /^\+\d{7,}$/.test(value.replace(/[\s()\-\.]|ext/gi, ""));
  }

  const [genderList, setGenderList] = useState([]);

  const codeDomainNames = ["USER_GENDER"];
  const {
    data: dataCodeDomains,
    error: errorCodeDomains,
    isLoading: isLoadingCodeDomains,
    refetch: refetchDomains,
  } = useQuery(["codes", codeDomainNames], () =>
    getCodeDomainsByNames(codeDomainNames)
  );

  useEffect(() => {
    if (dataCodeDomains) createCodeDomains();
  }, [dataCodeDomains]);

  const createCodeDomains = () => {
    let genders = [];

    dataCodeDomains.forEach((codeDomain) => {
      if (codeDomain.name === "USER_GENDER") {
        codeDomain.codes.forEach((code) => {
          genders.push({
            id: code.id,
            label: t("code." + code.name),
          });
        });
      }
    });

    setGenderList(genders);
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSave = () => {
    // Validate all fields as required
    const requiredFields = [
      "parentFirstName",
      "parentLastName",
      "parentEmail",
      "parentPhoneNumber",
      "gamerFirstName",
      "gamerLastName",
      "gamerBirthdate",
      "gamerGender",
    ];

    const errors = {};
    let hasErrors = false;

    // Check required fields
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = true;
        hasErrors = true;
      }
    });

    // Validate email
    if (!isValidEmail(formData.parentEmail)) {
      errors.parentEmail = true;
      hasErrors = true;
    }

    // validate phone number
    if (!isValidPhonenumber(formData.parentPhoneNumber)) {
      errors.parentPhoneNumber = true;
      hasErrors = true;
    }

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("participantRegistration.title")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("participantRegistration.parentFirstName")}
          fullWidth
          value={formData.parentFirstName}
          onChange={(e) => handleChange("parentFirstName", e.target.value)}
          margin="normal"
          error={formErrors.parentFirstName}
          required
        />
        <TextField
          label={t("participantRegistration.parentLastName")}
          fullWidth
          value={formData.parentLastName}
          onChange={(e) => handleChange("parentLastName", e.target.value)}
          margin="normal"
          error={formErrors.parentLastName}
          required
        />
        <TextField
          label={t("participantRegistration.parentEmail")}
          fullWidth
          value={formData.parentEmail}
          onChange={(e) => handleChange("parentEmail", e.target.value)}
          margin="normal"
          required
          error={!isValidEmail(formData.parentEmail)}
          helperText={
            !isValidEmail(formData.parentEmail)
              ? t("muniRegister.participant.infoValidEmail")
              : ""
          }
        />
        <TextField
          label={t("participantRegistration.parentPhoneNumber")}
          fullWidth
          value={formData.parentPhoneNumber}
          onChange={(e) => handleChange("parentPhoneNumber", e.target.value)}
          margin="normal"
          required
          error={!isValidPhonenumber(formData.parentPhoneNumber)}
          helperText={
            !isValidPhonenumber(formData.parentPhoneNumber)
              ? t("muniRegister.participant.infoValidPhone")
              : ""
          }
        />
        <TextField
          label={t("participantRegistration.gamerFirstName")}
          fullWidth
          value={formData.gamerFirstName}
          onChange={(e) => handleChange("gamerFirstName", e.target.value)}
          margin="normal"
          error={formErrors.gamerFirstName}
          required
        />
        <TextField
          label={t("participantRegistration.gamerLastName")}
          fullWidth
          value={formData.gamerLastName}
          onChange={(e) => handleChange("gamerLastName", e.target.value)}
          margin="normal"
          error={formErrors.gamerLastName}
          required
        />
        <TranslatedDatePicker
          label={t("participantRegistration.gamerBirthdate")}
          value={formData.gamerBirthdate}
          onChange={(date) => {
            handleChange("gamerBirthdate", date);
          }}
          error={formErrors.gamerBirthdate}
          required
        />
        <FormControl fullWidth margin="normal" sx={{ marginTop: "2em" }}>
          <InputLabel id="participantRegistration.gamerGender">
            {t("participantRegistration.gamerGender")}
          </InputLabel>
          <Select
            labelId="participantRegistration.gamerGender"
            value={formData.gamerGender}
            onChange={(e) => handleChange("gamerGender", e.target.value)}
            error={formErrors.gamerGender}
            required
          >
            {genderList.map((gender) => (
              <MenuItem key={gender.id} value={gender.id}>
                {gender.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("general.dialog.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("general.dialog.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MunicipalityParticipantRegistrationDialog;
