import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API } from "./API";

export const getUsers = async () => {
  const { data } = await axios.get(`${API}/users`, { withCredentials: true });

  //  console.log(data.data.users);
  return data.data.users;
};

export const getGamers = async (id) => {
  const { data } = await axios.get(`${API}/users/gamers/${id}`, {
    withCredentials: true,
  });

  //  console.log(data.data.users);
  return data.data.gamers;
};

export const createUser = async (body) => {
  const { data } = await axios.post(`${API}/auth/create`, body, {
    withCredentials: true,
  });
  return data.data.user;
};

export const setPassword = async (body) => {
  const { data } = await axios.post(`${API}/auth/setpw/${body.id}`, body.body, {
    withCredentials: true,
  });
  return data.data.user;
};

export const updateUser = async (body) => {
  const { data } = await axios.post(`${API}/users/${body.id}`, body.body, {
    withCredentials: true,
  });
  return data.data.user;
};

export const updateGeduProfile = async (body) => {
  const { data } = await axios.post(
    `${API}/users/gedus/${body.id}/profile`,
    body.body,
    {
      withCredentials: true,
    }
  );
  return data.data;
};

export const setUserRoles = async (body) => {
  const { data } = await axios.post(
    `${API}/users/setuserroles/${body.id}`,
    body.body,
    {
      withCredentials: true,
    }
  );
  return data.data.user;
};

export const setUserNote = async (body) => {
  const { data } = await axios.post(
    `${API}/users/setusernote/${body.id}`,
    body.body,
    {
      withCredentials: true,
    }
  );
  return data.data.user;
};

export const setUserWelcomeLetterStatus = async (body) => {
  const { data } = await axios.post(
    `${API}/users/setuserwelcomeemailstatus/${body.id}`,
    body.body,
    {
      withCredentials: true,
    }
  );
  return data.data.user;
};

export const setCriminalRecordStatusChecked = async (body) => {
  const { data } = await axios.post(
    `${API}/users/criminalrecordchecked/${body.id}`,
    body.body,
    {
      withCredentials: true,
    }
  );
  return data.data.user;
};

export const deleteUser = async (id) => {
  console.log(id);
  const { data } = await axios.delete(`${API}/users/${id}`, {
    withCredentials: true,
  });
  return data;
};

export const getGeduDetails = async (id) => {
  const { data } = await axios.get(`${API}/users/gedu/${id}`, {
    withCredentials: true,
  });

  return data.data;
};

export const getGamerDetails = async (id) => {
  const { data } = await axios.get(`${API}/users/gamer/${id}`, {
    withCredentials: true,
  });

  return data.data;
};

export const getParentDetails = async (id) => {
  const { data } = await axios.get(`${API}/users/parent/${id}`, {
    withCredentials: true,
  });

  return data.data;
};
