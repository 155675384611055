import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import {
  deleteSchool,
  getSchoolsForMuni,
  editSchool,
} from "../../Hooks/school";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import CreateSchool from "./createSchool";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function SchoolList() {
  const { mutate: editSchoolField } = useMutation(editSchool);
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isUpdating, setIsUpdating] = React.useState("");
  const columns = [
    //{ field: 'id', headerName: 'ID', flex: 1, editable: false },
    { field: "name", headerName: "School Name", flex: 1, editable: true },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const updatesThis = params.row.id + "isActive" == isUpdating;
        return (
          <div>
            {updatesThis && <CircularProgress />}
            {!updatesThis && (
              <Checkbox
                {...label}
                checked={params.row.isActive}
                onChange={(e) =>
                  changeField("isActive", params.row.id, !params.row.isActive)
                }
              />
            )}
          </div>
        );
      },
    },
    {
      field: "registration",
      headerName: "Registration Enabled",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const updatesThis = params.row.id + "registration" == isUpdating;
        return (
          <div>
            {updatesThis && <CircularProgress />}
            {!updatesThis && (
              <Checkbox
                {...label}
                checked={params.row.registration}
                onChange={(e) =>
                  changeField(
                    "registration",
                    params.row.id,
                    !params.row.registration
                  )
                }
              />
            )}
          </div>
        );
      },
    },

    { field: "contact", headerName: "Contact", flex: 1, editable: true },
  ];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { data, error, isLoading, refetch } = useQuery(
    ["municipalities", urlParams.get("muni")],
    getSchoolsForMuni
  );
  const { mutate } = useMutation(deleteSchool);

  useEffect(() => {
    if (data) {
      //do rows
      console.log(data);
      setRows(data.schools);
    }
  }, [data]);

  const processRowUpdate = () => {
    refetch();
  };

  const handleCellChange = (params) => {
    //console.log(params);
    changeField(params.field, params.id, params.value);
  };

  const changeField = (fieldName, id, value) => {
    //console.log(id + fieldName);
    setIsUpdating(id + fieldName);

    const sentData = { id: id };
    sentData[fieldName] = value;

    editSchoolField(sentData, {
      onSuccess: (e) => {
        console.log(e);

        const rowsCopy = [...rows];
        var updatedschool = rowsCopy.filter(function (el) {
          return el.id === id;
        });
        if (updatedschool && updatedschool.length > 0) {
          const theschool = updatedschool[0];
          console.log(theschool);
          theschool[fieldName] = e.data.school[fieldName];
        }
        console.log(rowsCopy);

        setRows(rowsCopy);
        setIsUpdating("");
      },
    });
  };
  const deleteRows = () => {
    selectionModel.forEach((code) => {
      mutate(code, {
        onSuccess: (e) => {
          console.log(e);
        },
      });
    });
    window.location.reload();
  };

  return (
    <ContainerBox>
      <div style={{ width: "100%" }}>
        {error && <ErrorAlert txt={error} />}
        {isLoading && <CircularProgress />}
        {
          // rows.length > 0 &&
          <>
            <CreateSchool
              handleNewSchool={processRowUpdate}
              DeleteButton={
                <Button
                  variant="contained"
                  onClick={deleteRows}
                  color="secondary"
                  startIcon={<DeleteForeverIcon />}
                  disabled={disableBtn}
                  sx={{ height: 40 }}
                >
                  Delete Rows
                </Button>
              }
            />
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[]}
              rows={rows}
              columns={columns}
              onCellEditCommit={handleCellChange}
            />
          </>
        }
      </div>
    </ContainerBox>
  );
}
