import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  Box,
  Button,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";

import OpenIcon from "@mui/icons-material/OpenInNew";

import { TranslatedDatePicker } from "../Util/translatedDatePicker";

import { isAdmin, isGedu, isParent, isGamer } from "../Util/authHelper";
import { formatDate } from "../Util/clubHelper";
import InvoicingStatus from "../Util/InvoicingStatus";

import { getGeduInvoicingPeriodsBetweenDates } from "../Hooks/report";

import { useTranslation } from "react-i18next";

export default function GeduInvoicingStatusMainView(params) {
  const { t } = useTranslation();

  const [invoicingPeriodData, setInvoicingPeriodData] = useState([]);
  const [isLoadingInvoicingData, setIsLoadingInvoicingData] = useState(false);

  const [periodType, setPeriodType] = useState("currentMonth");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const appUserRoles = params?.appAuthInfo?.appUserRoles;

  useEffect(() => {
    // Set the default dates for current month
    const { startDate, endDate } = getPreviousMonth();
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [invoicingPeriodData]);

  const prepareInvoicingPeriods = async (data) => {
    console.log("prepareInvoicingPeriods");
    console.log(data);

    // if not data, return empty array
    if (!data || (data && data.length === 0)) {
      return [];
    }

    const allPeriods = [];

    // loop through the keys of data
    for (const key of Object.keys(data)) {
      // get the data for the key
      const periodData = data[key];
      const user = periodData.user;
      const sortedPeriods = periodData.periods;
      // sort periods by startDate
      sortedPeriods.sort((a, b) => {
        if (a.startDate > b.startDate) {
          return -1;
        }
        if (a.startDate < b.startDate) {
          return 1;
        }
        return 0;
      });
      user.periods = sortedPeriods;

      user.name = user.lastName + ", " + user.firstName;

      allPeriods.push(user);
    }

    // sort users by Name
    allPeriods.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    setInvoicingPeriodData(allPeriods);
  };

  const getCurrentMonth = () => {
    // create a struct with startDate and endDate for current month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const getPreviousMonth = () => {
    // create a struct with startDate and endDate for previous month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const fetchData = async () => {
    setIsLoadingInvoicingData(true);
    const body = {
      startDate: formatDate(startDate, "YYYY-MM-DD"),
      endDate: formatDate(endDate, "YYYY-MM-DD"),
    };

    console.log(body);
    const response = await getGeduInvoicingPeriodsBetweenDates(body);

    console.log(response);

    const users = response.data.users;

    console.log("users");
    console.log(users);

    prepareInvoicingPeriods(users);

    setIsLoadingInvoicingData(false);
  };

  const handleButtonClick = (period) => {
    setPeriodType(period);

    if (period === "currentMonth") {
      const { startDate, endDate } = getCurrentMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "previousMonth") {
      const { startDate, endDate } = getPreviousMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "custom") {
      // don't change the dates
    }
  };

  return (
    <>
      {/* <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Button
            variant={periodType === "previousMonth" ? "contained" : "outlined"}
            onClick={() => handleButtonClick("previousMonth")}
          >
            {t("geduInvoicing.previousMonth")}
          </Button>
          <Button
            variant={periodType === "currentMonth" ? "contained" : "outlined"}
            onClick={() => handleButtonClick("currentMonth")}
          >
            {t("geduInvoicing.currentMonth")}
          </Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TranslatedDatePicker
            fullWidth
            label={t("geduInvoicing.startDate")}
            value={startDate}
            onChange={(newValue) => handleStartDateChange(newValue)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TranslatedDatePicker
            fullWidth
            type="date"
            label={t("geduInvoicing.endDate")}
            value={endDate}
            onChange={(newValue) => handleEndDateChange(newValue)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!startDate || !endDate}
          >
            {t("geduInvoicing.submit")}
          </Button>
        </Stack>
      </Stack> */}

      <Box>
        <Stack direction="row" spacing={2} sx={{ display: "block", m: 1 }}>
          <TranslatedDatePicker
            fullWidth
            label={t("geduInvoicing.startDate")}
            value={startDate}
            onChange={(newValue) => handleStartDateChange(newValue)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TranslatedDatePicker
            fullWidth
            type="date"
            label={t("geduInvoicing.endDate")}
            value={endDate}
            onChange={(newValue) => handleEndDateChange(newValue)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            sx={{ m: 1 }}
            variant="contained"
            color="primary"
            onClick={fetchData}
            disabled={!startDate || !endDate}
          >
            {t("geduInvoicingStatusReport.submit")}
          </Button>
        </Stack>
      </Box>

      {isLoadingInvoicingData && <CircularProgress sx={{ m: 3 }} />}
      {invoicingPeriodData && invoicingPeriodData.length > 0 && (
        <Typography
          key={"headerTypography"}
          sx={{ marginTop: "1.5em", fontSize: 18 }}
          color="text.primary"
        >
          {t("geduInvoicingStatusReport.title")}
        </Typography>
      )}

      <List key={"invoicingStatusList"} sx={{ margin: "auto" }}>
        {invoicingPeriodData.map((user, index) => (
          <div key={"divdiv" + index}>
            {user.periods.map((period, index2) => (
              <ListItem key={"index0" + index + index2}>
                <OpenIcon
                  sx={{ mr: "1em" }}
                  onClick={() => {
                    window
                      .open(`geduinvoicing/?id=${user.id}`, "_blank")
                      .focus();
                  }}
                />
                <ListItemText
                  key={"index1" + index + index2}
                  primary={`${user.name} [${user.discordUserName}]`}
                  secondary={
                    <span>
                      <span>
                        <span
                          style={{
                            fontWeight:
                              user.billingMethod?.name ===
                              "GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE"
                                ? "bold"
                                : "normal",
                            color:
                              user.billingMethod?.name ===
                              "GEDU_BILLING_METHOD_TRUSTER_COLLECTIVE"
                                ? "#F76C6C"
                                : "inherit",
                          }}
                        >
                          {`${t("codedomain.GEDU_BILLING_METHOD")}: ${t(
                            "code." + user.billingMethod?.name
                          )}`}
                        </span>
                      </span>
                      <br />
                      <span>
                        {`${t("geduInvoicingStatusReport.statusChanges")}`}:
                      </span>
                      <br />
                      <span>
                        {`${t("invoicingStatus.approvedDate")} ${formatDate(
                          new Date(period.geduApproved?.timestamp)
                        )}`}
                      </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {`${t("invoicingStatus.sogApprovedDate")} ${formatDate(
                          new Date(period.sogApproved?.timestamp)
                        )}`}
                      </span>
                    </span>
                  }
                />

                <ListItemText
                  key={"index2" + index + index2}
                  primary={`${formatDate(
                    period.startDate,
                    "DD.MM.YYYY"
                  )} - ${formatDate(period.endDate, "DD.MM.YYYY")}`}
                  secondary={
                    <span>
                      {Object.entries(period.invoicingByType).map(
                        ([type, amount]) => (
                          <span key={"APPROVED_" + type + index2}>
                            {`${t("code." + type.split(".")[0])}: ${
                              amount
                                ? amount.toFixed(2)
                                : t("geduInvoicingStatusReport.undefinedAmount")
                            } ${t("code." + type.split(".")[1])}`}
                            <br />
                          </span>
                        )
                      )}
                      {Object.entries(period.totalInvoicingByCurrency).map(
                        ([currency, amount]) => (
                          <span
                            style={{ fontWeight: "bold" }}
                            key={"APPROVED_" + currency + index2}
                          >
                            <span>
                              {t("geduInvoicingStatusReport.total")}:{" "}
                            </span>
                            {`${
                              amount
                                ? amount.toFixed(2)
                                : t("geduInvoicingStatusReport.undefinedAmount")
                            } `}
                            {`${t("code." + currency)}`}
                            <br />
                          </span>
                        )
                      )}
                      {Object.entries(
                        period.totalInvoicingByCurrencyIncludingVAT
                      ).map(([currency, amount]) => (
                        <span
                          style={{ fontWeight: "bold" }}
                          key={"APPROVED_VAT_" + currency + index2}
                        >
                          <span>
                            {t("geduInvoicingStatusReport.totalIncludingVAT")}:{" "}
                          </span>
                          {`${
                            amount
                              ? amount.toFixed(2)
                              : t("geduInvoicingStatusReport.undefinedAmount")
                          } `}
                          {`${t("code." + currency)}`}
                          <br />
                        </span>
                      ))}
                    </span>
                  }
                />

                <InvoicingStatus
                  key={"status" + index + index2}
                  status={period.status?.name}
                />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </>
  );
}
