import axios from "axios";
import { API } from "./API";

export const login = async (body) => {
  const { data } = await axios.post(`${API}/auth/login`, body, {
    withCredentials: true,
  });
  return data;
};

export const check = async (body) => {
  console.log(body);
  const { data } = await axios.post(`${API}/auth/check`, body, {
    withCredentials: true,
  });
  console.log(data);
  return data;
};
