import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";

let text = "Unable to connect to the server"
export default function ErrorAlert(props) {   

    const [state, setState] = React.useState({
        open: true,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const navigate = useNavigate();

    useEffect(() => {  
        console.log(props.txt);
        try {
            if(props.txt.response.data) text = props.txt.response.data.message !== "" ? props.txt.response.data.message : "Not found"
            if(text === "Unauthorized") navigate('/login', { replace: true });    
        } catch (error) {
             
        }
        
    }, )  
    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return(         
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}  
            autoHideDuration={6000}          
            key={vertical + horizontal}
        >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {text}
            </Alert>
        </Snackbar>
    )
    
}