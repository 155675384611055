import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const FloatInputField = ({ label, initialValue, onChange, sx }) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Replace commas with dots to ensure consistent format
    inputValue = inputValue.replace(",", ".");

    // Remove any non-numeric characters except the dot
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure there's only one dot
    const dotCount = inputValue.split(".").length - 1;
    if (dotCount > 1) {
      const parts = inputValue.split(".");
      inputValue = `${parts[0]}.${parts.slice(1).join("")}`;
    }

    // Limit the number of decimal places to two
    const decimalParts = inputValue.split(".");
    if (decimalParts[1] && decimalParts[1].length > 2) {
      inputValue = `${decimalParts[0]}.${decimalParts[1].substring(0, 2)}`;
    }

    // Update the state with the formatted value
    setValue(inputValue);

    // Call the onChange callback with the new value
    onChange(inputValue);
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      onChange={handleChange}
      sx={sx}
    />
  );
};

export default FloatInputField;
