import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import ParticipantInfo from "./participantInfo";
import SelectClub from "./selectClub";
import Summary from "./summary";
import AdditionalQuestions from "./additionalQuestions";

import { addParticipation } from "../Hooks/municipalityRegistration";

import i18n from "../i18n";
import { useTranslation } from "react-i18next";

import "./muni-register.css";
import { set } from "date-fns";

const MuniRegister = () => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [participantInfo, setParticipantInfo] = React.useState({});

  const [selectedMunicipality, setSelectedMunicipality] = useState({});
  const [selectedSchool, setSelectedSchool] = useState({});
  const [selectedClub, setSelectedClub] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");
  const [registeredParticipant, setRegisteredParticipant] = useState({});

  const [additionalQuestionAnswers, setAdditionalQuestionAnswers] = useState(
    {}
  );

  const [requiredFieldStatus, setRequiredFieldStatus] = useState(false);

  const stepNumberStyle = {
    //color: "#FAA901", // orange
    color: "#8F00E2", // purple
  };

  const steps = [
    "muniRegister.steps.selectClub",
    "muniRegister.steps.participant",
    "muniRegister.steps.additionalQuestions",
    "muniRegister.steps.confirmRegistration",
  ];

  const countryId = "346995052465106944"; // Finland

  useEffect(() => {
    // eslint-disable-next-line
    i18n.changeLanguage("fi"); // force Finnish
  }, []);

  const handleStepClick = (step) => {
    const stepToCheck = step - 1 >= 0 ? step - 1 : 0;
    if (stepIsOk(stepToCheck)) setActiveStep(step);
  };

  const handleNext = () => {
    if (isLastStep()) {
      handleSubmit();
    } else setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleParticipantInfo = (participant) => {
    console.log(participant);
    setParticipantInfo(participant);
  };

  const handleMunicipalityChange = (municipality) => {
    console.log("selectedMunicipality");
    console.log(municipality);
    setSelectedMunicipality(municipality);
  };

  const handleSchoolChange = (school) => {
    console.log("selectedSchool");
    console.log(school);
    setSelectedSchool(school);
  };

  const handleClubChange = (club) => {
    console.log("selectedClub");
    console.log(club);
    setSelectedClub(club);
  };

  const onAdditionalInfoChange = (changedAnswers) => {
    console.log("muni-register.onAdditionalInfoChange");
    console.log("OLD");
    console.log(additionalQuestionAnswers);
    console.log("NEW");
    console.log(changedAnswers);

    setAdditionalQuestionAnswers(changedAnswers);
  };

  const onRequiredFieldStatusChanged = (status) => {
    setRequiredFieldStatus(status);
  };

  function isLastStep() {
    return activeStep === steps.length - 1;
  }

  function stepIsOk(step) {
    let isOk = false;
    if (isSubmitted) return false;
    switch (step) {
      case 0:
        isOk = selectedMunicipality.id && selectedClub.id;
        break;
      case 1:
        isOk =
          stepIsOk(0) && 
          participantInfo.parentFirstName &&
          participantInfo.parentLastName &&
          participantInfo.parentPhone &&
          participantInfo.parentEmail &&
          participantInfo.childFirstName &&
          participantInfo.childLastName &&
          participantInfo.childBirthDate &&
          participantInfo.consent;
        break;
      case 2:
        console.log("STEP check");
        console.log(requiredFieldStatus);
        isOk = stepIsOk(1) && requiredFieldStatus;
        break;
      case 3:
        isOk =
          stepIsOk(2) &&
          participantInfo.parentFirstName &&
          participantInfo.parentLastName &&
          participantInfo.parentPhone &&
          participantInfo.parentEmail &&
          participantInfo.childFirstName &&
          participantInfo.childLastName &&
          participantInfo.childBirthDate &&
          selectedMunicipality.id &&
          selectedClub.id;
        break;
      default:
        isOk = false;
    }

    return isOk;
  }

  function nextButtonDisabled() {
    return !stepIsOk(activeStep);
  }

  function backButtonDisabled() {
    return activeStep === 0 || isSubmitted;
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SelectClub
            sx={{ width: "100%" }}
            countryId={countryId}
            selectedMunicipalityId={
              selectedMunicipality.id ? selectedMunicipality.id : ""
            }
            selectedSchoolId={selectedSchool.id ? selectedSchool.id : ""}
            selectedClubId={selectedClub.id ? selectedClub.id : ""}
            onMunicipalityChange={handleMunicipalityChange}
            onSchoolChange={handleSchoolChange}
            onClubChange={handleClubChange}
            />
            );
      case 1:
        return (
          <ParticipantInfo
            onParticipantInfoSet={handleParticipantInfo}
            participant={participantInfo}
          />
        );
      case 2:
        return (
          <AdditionalQuestions
            answers={additionalQuestionAnswers}
            selectedClub={selectedClub}
            onAnswerChange={onAdditionalInfoChange}
            onRequiredFieldStatusChanged={onRequiredFieldStatusChanged}
          />
        );
      case 3:
        return (
          <Summary
            formData={participantInfo}
            selectedClub={selectedClub}
            registrationData={registeredParticipant}
            answers={additionalQuestionAnswers}
            registrationErrorMsg={submitErrorMsg}
          />
        );
      default:
        return null;
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSubmit = async () => {
    try {
      // Perform form submission using the addParticipation hook
      setIsSubmitting(true);

      console.log("Submitting form");
      console.log(additionalQuestionAnswers);

      const formData = {
        parentFirstName: participantInfo.parentFirstName,
        parentLastName: participantInfo.parentLastName,
        locale: "fi",
        parentEmail: participantInfo.parentEmail,
        parentPhoneNumber: participantInfo.parentPhone,
        gamerFirstName: participantInfo.childFirstName,
        gamerLastName: participantInfo.childLastName,
        gamerBirthdate: formatDate(participantInfo.childBirthDate),
        gamerGender: additionalQuestionAnswers["GENDER"],
        consent: participantInfo.consent,

        clubId: selectedClub.id,

        customFieldAnwers: additionalQuestionAnswers,
      };

      console.log(formData);

      const response = await addParticipation(formData);
      console.log("Form submitted successfully");

      console.log(response);
      setRegisteredParticipant(response);
      setSubmitErrorMsg("");
      setIsSubmitted(true);

      // Add any necessary success handling or navigation logic
    } catch (error) {
      console.error("Form submission error:", error);
      // Add error handling logic, display error message, etc.
      const errorCode = error.response?.data?.code
        ? error.response.data.code
        : "ERROR_UNKNOWN_ERROR";
      setSubmitErrorMsg(errorCode);
    }
    setIsSubmitting(false);
  };

  return (
    <div id="muni-register">
      <Stepper activeStep={activeStep} alternativeLabel sx={{ width: "100%" }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel
                StepIconProps={{ style: stepNumberStyle }}
                {...labelProps}
              >
                {t(label)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>{renderStepContent(activeStep)}</div>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, alignItems: "center" }}>
        <Button
          // color="inherit"
          disabled={backButtonDisabled() || isSubmitting}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {t("muniRegister.back")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button disabled={nextButtonDisabled() || isSubmitting} onClick={handleNext} endIcon={isLastStep() && (isSubmitting ? <CircularProgress size={20}/> : <SendIcon />)}>
          {isLastStep() ? t("muniRegister.confirm") : t("muniRegister.next")}
        </Button>
      </Box>
    </div>
  );
};

export default MuniRegister;
