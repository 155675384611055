import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import { deleteCode, getCodeDomain } from "../../Hooks/codes";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorAlert from "../../errorAlert";
import ContainerBox from "../../Container";
import AddCodes from "./addCodes";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import { editCodeField } from "../../Hooks/codes";

export default function CodeList() {
  let id = "";

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const columns = [
    { field: "index", headerName: "Index", flex: 0, editable: true },
    { field: "name", headerName: "Name", flex: 1, editable: true },
    { field: "id", headerName: "ID", flex: 2, editable: false },
  ];
  const { data, error, isLoading, refetch } = useQuery(
    ["codes", id],
    () => getCodeDomain(id),
    {
      enabled: false,
    }
  );
  const { mutate } = useMutation(deleteCode);
  const { mutate: mutateEdit } = useMutation(editCodeField);

  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [codeDomain, setCodeDomain] = React.useState([]);

  // const [error, setError] = React.useState("");
  // const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    id = urlParams.get("codedomainid");

    refetch(id);
    if (data) setDomainData(data);
  }, [data]);

  const setDomainData = (domain) => {
    console.log("Domain");
    console.log(domain);
    if (domain) {
      setCodeDomain(domain);
      if (domain.codes) setRows(domain.codes);
    }
  };

  const processRowUpdate = async (e, i) => {
    //console.log(e.field + ": " + e.value);
    try {
      setIsDataLoading(true);
      var body = {
        id: e.id,
      };

      body[e.field] = e.value;

      console.log(body);

      mutateEdit(body, {
        onSuccess: (e) => {
          console.log(e);
          refetch(id);
        },
      });
      // console.log( response);
    } catch (editerror) {
      //error = editerror;
      console.log(editerror);
    }
    setIsDataLoading(false);
  };
  const handleNewCode = (code) => {
    setRows((rows) => [...rows, code]);
  };
  const deleteRows = () => {
    selectionModel.forEach((code) => {
      mutate(code, {
        onSuccess: (e) => {
          console.log(e);
        },
      });
    });
    window.location.reload();
  };

  return (
    <ContainerBox>
      <h1>Codes for domain {codeDomain.name}</h1>
      <div style={{ width: "100%" }}>
        {error && <ErrorAlert txt={error} />}
        {(isLoading || isDataLoading) && <CircularProgress />}
        {
          // rows.length > 0 &&
          <>
            <AddCodes
              CodeDomainId={codeDomain.id}
              handleNewCode={handleNewCode}
              DeleteButton={
                <Button
                  variant="contained"
                  onClick={deleteRows}
                  color="secondary"
                  startIcon={<DeleteForeverIcon />}
                  disabled={disableBtn}
                  sx={{ height: 40 }}
                >
                  Delete selected
                </Button>
              }
            />
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                setDisableBtn(!newSelectionModel.length > 0);
              }}
              disableSelectionOnClick
              selectionModel={selectionModel}
              autoHeight
              rowsPerPageOptions={[]}
              rows={rows}
              columns={columns}
              onCellEditCommit={processRowUpdate}
            />
          </>
        }
      </div>
    </ContainerBox>
  );
}
