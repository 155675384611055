import React, { useEffect, useState } from "react";

import { useQuery } from "react-query";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./club.css";
import ContainerBox from "../Container";
import { createGroup, getClubByID } from "../Hooks/clubs";

import ClubInfo from "./clubInfo";
import ClubData from "./clubData";
import ClubLessons from "./clubLessons";
import ClubParticipants from "./clubParticipants";

import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import GroupInfo from "./clubGroupInfo";
import { isAdmin } from "../Util/authHelper";
import { prepareGroupName } from "../Util/clubHelper";

export default function ClubView(params) {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const [participantCounts, setParticipantCounts] = useState(new Map());
  const [waitingListCounts, setWaitingListCounts] = useState(new Map());
  const [cancelledGroupExists, setCancelledGroupExists] = useState(false);
  const [updateGeneration, setUpdateGeneration] = useState(0);
  const [groups, setGroups] = React.useState([]);
  const [lessonCounts, setLessonCounts] = React.useState(new Map());
  const urlParams = new URLSearchParams(queryString);
  const clubId = urlParams.get("id");
  const { data, error, isLoading, refetch, isFetching } = useQuery(
    ["clubId", clubId],
    () => getClubByID(clubId)
  );

  const appUserRoles = params?.appAuthInfo?.appUserRoles;

  useEffect(() => {
    if (data) {
      // Sort the groups data using the custom sorting function
      const sortedGroups = data?.groups
        ? data.groups.slice().sort(customSort)
        : [];
      const copy = [...sortedGroups];
      setGroups(copy);

      // check if data.groups has group with type = GROUP_TYPE_CANCELLED
      const cancelledGroup = copy.find(
        (group) => group.type?.name === "GROUP_TYPE_CANCELLED"
      );

      if (cancelledGroup) {
        setCancelledGroupExists(true);
      }
    }
  }, [data]);

  const showParticipantsOnly = (group) => {
    switch (group.type?.name) {
      case "GROUP_TYPE_CANCELLED":
        return true;
      case "GROUP_TYPE_WAITING_LIST":
        return true;
      case "GROUP_TYPE_DEFAULT":
        return false;
      default:
        return false;
    }
  };

  const showLessons = (group) => {
    switch (group.type?.name) {
      case "GROUP_TYPE_CANCELLED":
        return false;
      case "GROUP_TYPE_WAITING_LIST":
        return false;
      case "GROUP_TYPE_DEFAULT":
        return true;
      default:
        return true;
    }
  };

  // const copyEmails = async () => {
  // let emails = "";
  // console.log(data);
  // data.map((e, i) => {
  //   if (e["WaitingList"] != true) {
  //     if (i + 1 === data.length) {
  //       emails += e["Parent's email"];
  //     } else {
  //       emails += e["Parent's email"] + ", ";
  //     }
  //   }
  // });
  // if (emails.charAt(emails.length - 1) == " ") emails = emails.slice(0, -2);
  // const el = document.createElement("textarea");
  // el.value = emails;
  // document.body.appendChild(el);
  // el.select();
  // document.execCommand("copy");
  // document.body.removeChild(el);
  // };

  // const openLessons = () => {
  //   window.open(`./clubLessons/?id=${clubId}`, "_blank").focus();
  // };

  // original code
  // const groups = (data?.groups ?? []).toSorted((a, b) => {
  //   const aa = a.name.toLowerCase() + a.id;
  //   const bb = b.name.toLowerCase() + b.id;
  //   return (aa > bb) - (aa < bb);
  // });

  // Define the custom sorting order
  const sortingOrder = [
    "GROUP_TYPE_DEFAULT",
    "GROUP_TYPE_REGULAR",
    "GROUP_TYPE_WAITING_LIST",
    "GROUP_TYPE_CANCELLED",
  ];

  // Custom sorting function
  function customSort(a, b) {
    const typeA = a.type.name;
    const typeB = b.type.name;

    const indexA = sortingOrder.indexOf(typeA);
    const indexB = sortingOrder.indexOf(typeB);

    // Compare the indexes to determine the sorting order
    if (indexA < indexB) {
      return -1;
    } else if (indexA > indexB) {
      return 1;
    } else {
      // If the types are the same, sort alphabetically by group name
      return a.name.localeCompare(b.name);
    }
  }

  return (
    <ContainerBox>
      <ClubInfo clubId={clubId} appAuthInfo={params.appAuthInfo} />
      <MuiAccordion key="clubData">
        <MuiAccordionSummary
          aria-controls="panel1d-content-clubView"
          id="panel1d-header-clubView"
          expandIcon={<ExpandMoreIcon />}
          sx={{ background: "#f0f0f0" }}
        >
          <Typography>{t("clubView.clubData")}</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <ClubData
            clubId={clubId}
            appAuthInfo={params.appAuthInfo}
            refetch={refetch}
          />
        </MuiAccordionDetails>
      </MuiAccordion>
      {groups.map((group) => (
        <Box key={group.id}>
          {showParticipantsOnly(group) && (
            <MuiAccordion key={`group ${group.id}`}>
              <MuiAccordionSummary
                aria-controls="panel3d-content-clubView"
                id="panel3d-headerclubView"
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: "#f0f0f0" }}
              >
                <Typography>
                  {prepareGroupName(group, t)}{" "}
                  <small>({participantCounts.get(group.id) ?? "-"})</small>
                </Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <ClubParticipants
                  groups={groups}
                  updateGeneration={updateGeneration}
                  clubData={data}
                  refetch={() => {
                    setUpdateGeneration((x) => x + 1);
                  }}
                  appAuthInfo={params.appAuthInfo}
                  groupId={group.id}
                  clubId={clubId}
                  showAddParticipantButton={showLessons(group)}
                  onParticipantCountChange={(newCount) =>
                    setParticipantCounts((oldParticipantCounts) => {
                      let newParticipantCounts = new Map(oldParticipantCounts);
                      newParticipantCounts.set(group.id, newCount);
                      return newParticipantCounts;
                    })
                  }
                />
              </MuiAccordionDetails>
            </MuiAccordion>
          )}
          {!showParticipantsOnly(group) && (
            <MuiAccordion key={`group ${group.id}`}>
              <MuiAccordionSummary
                // aria-controls="panel2d-content-clubView"
                // id="panel2d-headerclubView"
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: "#f0f0f0" }}
              >
                {/* TODO: translate */}
                <Typography>{prepareGroupName(group, t)}</Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <GroupInfo
                  club={data}
                  group={group}
                  refetch={refetch}
                  appAuthInfo={params.appAuthInfo}
                />
                {showLessons(group) && (
                  <MuiAccordion key="lessonData">
                    <MuiAccordionSummary
                      aria-controls="panel2d-content-clubView"
                      id="panel2d-headerclubView"
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ background: "#f0f0f0" }}
                    >
                      <Typography>
                        {t("clubView.lessonData")}{" "}
                        <small>({lessonCounts.get(group.id) ?? "-"})</small>
                      </Typography>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <ClubLessons
                        appAuthInfo={params.appAuthInfo}
                        updateGeneration={updateGeneration}
                        group={group}
                        clubData={data}
                        onLessonCountChange={(newCount) =>
                          setLessonCounts((oldLessonCounts) => {
                            let newLessonCounts = new Map(oldLessonCounts);
                            newLessonCounts.set(group.id, newCount);
                            return newLessonCounts;
                          })
                        }
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                )}

                <MuiAccordion key="participantData">
                  <MuiAccordionSummary
                    aria-controls="panel3d-content-clubView"
                    id="panel3d-headerclubView"
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ background: "#f0f0f0" }}
                  >
                    <Typography>
                      {t("clubView.participantData")}{" "}
                      <small>({participantCounts.get(group.id) ?? "-"})</small>
                    </Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    <ClubParticipants
                      groups={groups}
                      updateGeneration={updateGeneration}
                      clubData={data}
                      refetch={() => {
                        setUpdateGeneration((x) => x + 1);
                      }}
                      appAuthInfo={params.appAuthInfo}
                      groupId={group.id}
                      clubId={clubId}
                      showAddParticipantButton={showLessons(group)}
                      onParticipantCountChange={(newCount) =>
                        setParticipantCounts((oldParticipantCounts) => {
                          let newParticipantCounts = new Map(
                            oldParticipantCounts
                          );
                          newParticipantCounts.set(group.id, newCount);
                          return newParticipantCounts;
                        })
                      }
                    />
                  </MuiAccordionDetails>
                </MuiAccordion>
              </MuiAccordionDetails>
            </MuiAccordion>
          )}
        </Box>
      ))}
      {isAdmin(appUserRoles) && (
        <Box>
          <Button
            variant="outlined"
            sx={{ m: 1 }}
            onClick={async () => {
              await createGroup(
                clubId,
                t("clubView.regularGroupName"),
                "GROUP_TYPE_REGULAR"
              );
              refetch();
            }}
          >
            {t("clubView.addGroup")}
          </Button>

          {!cancelledGroupExists && (
            <Button
              variant="outlined"
              sx={{ m: 1 }}
              onClick={async () => {
                await createGroup(
                  clubId,
                  t("clubView.cancelledGroupName"),
                  "GROUP_TYPE_CANCELLED"
                );
                refetch();
              }}
            >
              {t("clubView.addCancelledGroup")}
            </Button>
          )}
        </Box>
      )}
    </ContainerBox>
  );
}
